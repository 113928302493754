import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {ghostsave} from './PlanApi'
import {Alert,Spinner} from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useToasts } from "react-toast-notifications";
export default function GhostPlan(props) {
  const { handleClose, currentplan } = props;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async(data) =>{
    setLoading(true);
    const formdata = new FormData()
    Object.keys(data).forEach((key) => (
      formdata.append(key, data[key])
    ))
    formdata.append('LockTypeID',1)
    formdata.append('PlanID',currentplan.PlanID)
    const api_res = await ghostsave(formdata)
    setLoading(false);
    if(api_res.success == 1){
      addToast(api_res.message, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000
      })
      handleClose()

    }
    else{
      addToast(api_res.message, {
        appearance: 'error',
        autoDismiss: true,
      })
      handleClose()
    }
  }
  return (
    <Modal show={true} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Active Ghost Selected Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>
              <strong>Reason</strong>
            </label>
            <input type="text" className="form-control validate[required]" placeholder="UnlockReason" {...register("UnlockReason", {validate: (value) => value !== '',})} />
            {errors.UnlockReason && <Alert variant='danger'>
   Unlcok Reason is required
  </Alert>
}
          </div>
          <div className="form-group">
            <label>
              <strong>Duration</strong>
            </label>
           
            <select  
            defaultValue={0} className="form-control validate[required]" {...register("UnlockDuration",  {validate: (value) => value !== '' && value !==0,})}>
       <option value={0}>
                Select Duration
              </option>
              <option value={5}>5 Hours</option>
              <option value={10}>10 Hours</option>
              <option value={15}>15 Hours</option>
              <option value={20}>20 Hours</option>
              <option value={24}>24 Hours</option>
              <option value={48}>48 Hours</option>
              <option value={72}>72 Hours</option>
      </select>
      {errors.UnlockReason &&<Alert variant='danger'>
   Unlcok duration is required
  </Alert>}
          </div>
          <div className="form-group">
            <label>
              <strong>Plan</strong>
            </label>
            <input
              type="text"
              className="form-control"
              readOnly
              defaultValue={currentplan.PlanDescription}
              name="PlanName"
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {loading ? (
              <Spinner animation="border" variant="info" />
            ) : ( <Button variant="primary" onClick={()=>handleSubmit(onSubmit)()}>
          Confirm
        </Button>)}
      </Modal.Footer>
    </Modal>
  );
}
