import { Table } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
export default function ServicesTable(props) {
  const datalist = useSelector((state) => state.Planservices);
  console.log("dl", datalist);
  const { servicetablelist, serviceimgs } = props;
  return (
    <Table striped size="sm">
      <thead>
        <tr>
          <th>Service Image</th>
          <th>#</th>
          <th>Services Title</th>
          <th>$</th>
          <th>Auto Redeem Services</th>
          <th>Services Type</th>
          <th>Stop Mileage</th>
          <th>Validity Days</th>
          <th>Reminder MILES / KM</th>
          <th>Service Mileage</th>
          <th>Manager Approval</th>
          <th>Mark Services</th>
          <th>Yearly Used</th>
          <th>Perpetual Services</th>
          <th>Hide Price</th>
          <th>Is Gifted</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Dropdown fluid selection options={serviceimgs} />
          </td>
          {/* <td>{datalist.ser_no}</td>
          <td>{datalist.ser_type.ServiceDesc}</td>
          <td>{datalist.ser_unit_price}</td> */}
        </tr>
      </tbody>
    </Table>
  );
}
