import React, { useState, useEffect } from "react";
import styles from "./unusedservices.module.css";
import GroupedButton from "../../../components/GroupedButton";
export default function UnusedServices(props) {
  const { contractcoupon, setFieldValue } = props;
  const [totalCoupon, setTotalCoupon] = useState("");
  const [usedcoupons, setUsedcoupons] = useState("");
  const [passwordcoupons, setPasswordcoupons] = useState("");
  let unlimitedServicecount = 0;
  let IsManagerApproval = 0;
  let TotalServicesRemaining = 0;
  const Couponcount = async (
    counter,
    CouponID,
    ServiceType,
    selectedcoupon
  ) => {
    let service = [];
    let color = "";
    if (+ServiceType === 1) {
      color = "Green";
    } else if (+ServiceType === 2) {
      color = "Orange";
    } else if (+ServiceType === 3) {
      color = "Blue";
    } else if (+ServiceType === 4) {
      color = "purple";
    } else {
      color = "Green";
    }
    service[color + "ContractID" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.ContractID;
    service["ContractServiceID" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.ServiceID;
    service[
      color + "ContractCouponCount" + "[" + selectedcoupon.CouponID + "]"
    ] = selectedcoupon.totalCoupon;
    service[
      color + "ContractServiceType" + "[" + selectedcoupon.CouponID + "]"
    ] = selectedcoupon.totalCoupon - counter;
    service["ContractServiceType" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.ServiceType;
    service[color + "MileageCoupon" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.MileageCoupon;
    service[color + "MileageCouponCond" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.MileageCouponCond;
    service[color + "ContractStopDay" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.StopDay;
    service[
      color + "ContractStopMileage" + "[" + selectedcoupon.CouponID + "]"
    ] = selectedcoupon.StopMileage;
    service[color + "DisplayOrder" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.DisplayOrder;
    service[
      "AllowRedemptionMultipleTimes" + "[" + selectedcoupon.CouponID + "]"
    ] = selectedcoupon.AllowRedemptionMultipleTimes;
    service[color + "SelectedService" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.totalCoupon - counter;
    service["xp_points" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.xp_point;
    service[
      color + "ContractCouponTitle" + "[" + selectedcoupon.CouponID + "]"
    ] = selectedcoupon.CouponTitle;
    service["CouponTitle" + "[" + selectedcoupon.CouponID + "]"] =
      selectedcoupon.CouponTitle;
    service[
      color + "ContractCouponRemaining" + "[" + selectedcoupon.CouponID + "]"
    ] = selectedcoupon.totalCoupon - counter;

    setTotalCoupon((prevState) => ({ ...prevState, [CouponID]: service }));

    setUsedcoupons((prevState) => ({
      ...prevState,
      [CouponID]: counter,
    }));
    //set couponids for managercode
    if (+selectedcoupon.IsManagerApproval > 0) {
      setPasswordcoupons((prevState) => ({
        ...prevState,
        [CouponID]: 1,
      }));
    }
  };

  useEffect(() => {
    if (contractcoupon != "" && typeof contractcoupon !== "undefined") {
      contractcoupon.map((item, i) => {
        TotalServicesRemaining = TotalServicesRemaining + +item.totalCoupon;
        if (+item.ServiceType === 4) {
          unlimitedServicecount++;
        }
        if (+item.IsManagerApproval > 0) {
          IsManagerApproval++;
        }
      });
    }

    if (unlimitedServicecount > 0) {
      setFieldValue("UnlimitedService", 1);
    }
    setFieldValue("RedeemCouponID", passwordcoupons);

    setFieldValue("TotalServicesRemaining", TotalServicesRemaining);

    setFieldValue("totalCoupon", totalCoupon);
  }, [totalCoupon]);
  const Couponcountincrement = (counter, CouponID) => {
    delete totalCoupon[CouponID];

    setUsedcoupons((prevState) => ({
      ...prevState,
      [CouponID]: counter,
    }));
  };
  return (
    <table className="table mt-4 table-bordered">
      <thead>
        <tr>
          <th scope="col">Available</th>
          <th scope="col">Services</th>
          <th scope="col">Used</th>
          <th scope="col">Price</th>
          <th scope="col">Detail</th>
        </tr>
      </thead>
      <tbody>
        {contractcoupon != "" && typeof contractcoupon !== "undefined" ? (
          contractcoupon.map((item, i) => (
            <tr className={item.GrayOutStatus == 1 ? styles.grayout : ""}>
              <td>
                <div className="qty d-flex align-items-center">
                  <i
                    className={
                      +item.ServiceType === 1
                        ? `fas fa-circle mr-2 ${styles.green}`
                        : +item.ServiceType === 2
                        ? `fas fa-circle mr-2 ${styles.orange}`
                        : +item.ServiceType === 3
                        ? `fas fa-circle mr-2 ${styles.blue}`
                        : +item.ServiceType === 4
                        ? `fas fa-circle mr-2 ${styles.purple}`
                        : `fas fa-circle mr-2 ${styles.green}` //default
                    }
                    aria-hidden="true"
                  ></i>

                  <GroupedButton
                    key={i}
                    selectedcoupon={item}
                    CouponID={item.CouponID}
                    Couponcount={Couponcount}
                    disable={item.GrayOutStatus == 1 ? true : false}
                    count={item.totalCoupon}
                    ServiceType={item.ServiceType}
                    Couponcountincrement={Couponcountincrement}
                    counternum="residents"
                    name="residents"
                  />
                </div>
              </td>

              <td>{item.CouponTitle}</td>
              <td>
                {usedcoupons.hasOwnProperty(item.CouponID)
                  ? item.totalCoupon - usedcoupons[item.CouponID]
                  : 0}
              </td>
              <td>${item.CouponValue}</td>
              <td></td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={8} align="center">
              <div className="alert alert-info text-center">
                No services left to use
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
