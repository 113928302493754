import React, { useEffect, useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import "./sidebar.css";
import { mainmenu, dealershiplist } from "./SidebarApi";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { FaTachometerAlt, FaFileInvoice, FaList } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Topmenu from "../topmenu/topmenu";
import { token } from "../../CurrentUser";
import { useSelector, useDispatch } from "react-redux";
require("dotenv").config();
function SideBar(props) {
  const dispatch = useDispatch();
  // set values in store
  const unfoldable = useSelector(
    (state) => state.Sidebarstore.sidebarUnfoldable
  );
  const menulist = useSelector((state) => state);

  const [menu, setMenu] = useState([]);
  const location = useLocation().pathname;
  useEffect(() => {
    fetchdealership();
    fetchdata();
  }, []);
  async function fetchdata() {
    const mainmenu_api = await mainmenu();
    if (mainmenu_api.success === 1) {
      setMenu(mainmenu_api.menu);
    }
  }
  async function fetchdealership() {
    const dealershiplist_api = await dealershiplist();
    if (dealershiplist_api.success === 1) {
      if (
        dealershiplist_api.dealers !== "" &&
        typeof dealershiplist_api.dealers !== "undefined"
      ) {
        let data = [];
        data.push({
          key: -1,
          text: "ALL",
          value: -1,
        });
        dealershiplist_api.dealers.map((item) => {
          //console.log(item);
          data.push({
            key: item.DealerID,
            text: item.DealerTitle,
            value: item.DealerID,
          });
        });
        dispatch({ type: "set", dealerships: data });
      }
    }
  }
  const redirectclassic = async (data) => {
    // const formData = new FormData();
    // const classicapi_api = await classicapi(url);
    if (data.ismodern === 1) {
      window.location.href = process.env.REACT_APP_BASE_URL + data.url;
    } else {
      window.location.href =
        process.env.REACT_APP_APP_URL +
        "/Loginreactcopy/classicapi/" +
        token +
        "?RedirectURL=" +
        data.url +
        "&isLogin=1";
    }
  };
  return (
    <div id="no-print">
      <Topmenu />
      <div className="left-sidenav" style={{ top: 0 }}>
        <div className="leftbar-profile w-100">
          <ProSidebar
            image={false}
            collapsed={unfoldable}
            toggled={false}
            breakPoint="md"
          >
            <SidebarHeader>
              <div className="text-center">
                <img
                  style={{
                    width: 100,
                  }}
                  className="img-fluid"
                  src={require("../../resources/images/logo.png").default}
                  alt="logo"
                />
              </div>
            </SidebarHeader>

            <SidebarContent>
              <Menu iconShape="circle">
                <Link to="/contracts/summary">
                  <MenuItem
                    active={location === "/contracts/summary" ? true : false}
                    icon={<FaTachometerAlt />}
                    suffix={<span className="badge red"></span>}
                  >
                    Dashboard
                  </MenuItem>
                </Link>
              </Menu>
              {menu !== ""
                ? menu.map((item, i) => (
                    <Menu key={i} iconShape="circle">
                      <SubMenu
                        defaultOpen={
                          location.includes("/contracts/") &&
                          !location.includes("/contracts/summary")
                            ? true
                            : false
                        }
                        title={item.main}
                        icon={<FaFileInvoice />}
                      >
                        {item.submenu !== "" &&
                        typeof item.submenu != "undefined"
                          ? item.submenu.map((item, i) => (
                              <MenuItem
                                key={i}
                                onClick={() => redirectclassic(item)}
                                icon={<FaList />}
                              >
                                {item.title}
                              </MenuItem>
                            ))
                          : ""}
                      </SubMenu>
                    </Menu>
                  ))
                : ""}
            </SidebarContent>
          </ProSidebar>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
