import React, { useState, useEffect } from "react";
import Reserve from "./Reserve";
import Redemption from "./Redemption";
import { saveAs } from "file-saver";
import { useToasts } from "react-toast-notifications";
import PreLoad from "../../components/PreLoad";
import {
  pdfreservedgraph,
  reservedgraphdetailpdfapi,
  pdf_reserved_graph_api,
} from "../DashboardApi";
import moment from "moment";
import ContractSoldGraph from "./ContractSoldGraph";
import TrendGraph from "./TrendGraph";
function ReserveGraph(props) {
  const {
    resdata,
    dashboardContract,
    DealerTitle,
    ReserveGraphdata,
    DealerID,
    fromdate,
    toDate,
    FixedDateParameter,
    dealerredemption,
  } = props;
  useEffect(() => {}, [resdata]);

  const { addToast } = useToasts();
  //state
  const [loading, setLoading] = useState(false);
  const [redemption, setRedemption] = useState("");
  const [reserved, setReserved] = useState("");
  const [contractsoldimg, setContractSoldimg] = useState("");
  const [trendimg, setTrendimg] = useState("");

  //define varibale
  let redemption_TotalContracts = 0;
  let ClaimedAmount = 0;
  let ClaimPayOutAmount = 0;
  let reserve_TotalContracts = 0;
  let reserve_amount = 0;
  let reserve_batch_amount = 0;

  //define varibale

  // left graph
  let reservepreload1 = resdata.hasOwnProperty("result")
    ? resdata.result.comp_amount
    : [];
  let reservepreload2 = resdata.hasOwnProperty("result")
    ? resdata.result.comp_amount_batch
    : [];
  let reserveSold1 = resdata.hasOwnProperty("result")
    ? resdata.result.total_amount
    : [];
  let reserveSold2 = resdata.hasOwnProperty("result")
    ? resdata.result.total_amount_batch
    : [];

  let reservexaxis = resdata.hasOwnProperty("result")
    ? resdata.result.xaxis
    : [];

  let reserve_title = resdata.hasOwnProperty("result")
    ? resdata.result.title
    : "";
  // end Left graph

  // start Right graph //ServiceClaim
  let redemption_row1 = resdata.hasOwnProperty("ServicePaidouttostore")
    ? resdata.ServicePaidouttostore.claim
    : [0];
  let redemption_row2 = resdata.hasOwnProperty("ServicePaidouttostore")
    ? resdata.ServicePaidouttostore.claimpaidout
    : [0];
  let redemption_xaxis = resdata.hasOwnProperty("ServicePaidouttostore")
    ? resdata.ServicePaidouttostore.xaxis
    : "";
  let redemption_title = resdata.hasOwnProperty("ServicePaidouttostore")
    ? resdata.ServicePaidouttostore.title
    : "";
  // end Right graph
  let totalcontracts = dashboardContract.hasOwnProperty("total")
    ? dashboardContract.total
    : 0;

  //contractsold chart data
  let contractsold_data = resdata.hasOwnProperty("result")
    ? resdata.result.googledata2
    : [];

  //Trend Graph chart data

  let filter_type = resdata.hasOwnProperty("drawtrendline")
    ? resdata.drawtrendline.filter_type
    : [];
  let googledata = resdata.hasOwnProperty("drawtrendline")
    ? resdata.drawtrendline.googledata
    : [];
  let ticks = resdata.hasOwnProperty("drawtrendline")
    ? resdata.drawtrendline.ticks
    : [];
  // left table data
  var tabledata = resdata?.result?.grapharray;
  // right table data
  var righttabledata = resdata?.ServicePaidouttostore?.grapharray;

  const handlegraphpdf = async () => {
    //setLoading(true);

    let maturearray = [];
    if (+dealerredemption.PlanServicesReservedAmountOverrite === 1) {
      let mature =
        dealerredemption.row3.maturedcontract +
        dealerredemption.row4.maturedcontract;
      let Contract_Reserve =
        dealerredemption.row3.Contract_Reserve +
        dealerredemption.row4.Contract_Reserve;
      let Forfeited =
        dealerredemption.row3.Forfeited + dealerredemption.row4.Forfeited;
      maturearray.push({
        mature: mature,
        Contract_Reserve: Contract_Reserve,
        Forfeited: Forfeited,
      });
    } else {
      let mature = dealerredemption.row1.maturedcontract;
      let Contract_Reserve = dealerredemption.row1.Contract_Reserve;
      let Forfeited = dealerredemption.row1.Forfeited;
      maturearray.push({
        mature: mature,
        Contract_Reserve: Contract_Reserve,
        Forfeited: Forfeited,
      });
    }
    const formData = new FormData();

    formData.append("Redemption", redemption);
    formData.append("Reserved", reserved);
    formData.append(
      "FutureMaturedBalance",
      ReserveGraphdata.FutureMaturedBalance
    );
    formData.append(
      "ContractMaturedBalance",
      ReserveGraphdata.maturedcontracttotalCost
    );
    formData.append("dateprint", reserve_title);
    formData.append("DealerID", DealerID);
    formData.append("fromdate", fromdate);
    formData.append("toDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    formData.append("DealerTitle", DealerTitle);
    formData.append("AgencyLogo", ReserveGraphdata.AgencyLogo);
    formData.append("TotalContracts", totalcontracts);
    //
    formData.append("result", JSON.stringify(resdata.result));
    formData.append(
      "ServicePaidouttostore",
      JSON.stringify(resdata.ServicePaidouttostore)
    );
    formData.append(
      "cancelledreservedgraph",
      JSON.stringify(resdata.cancelledreservedgraph)
    );
    formData.append("MaturedContract", JSON.stringify(...maturearray));

    getdata(formData);
  };
  async function getdata(formData) {
    //setLoading(true);
    const pdfreservedgraph_api = await pdfreservedgraph({ formData });
    //setLoading(false);
    if (pdfreservedgraph_api.success === 1) {
      var date = moment(new Date()).format("YYYY_MM_DD-HH_mm_ss");
      saveAs(pdfreservedgraph_api.pdf, date + ".pdf");
    } else {
      addToast("pdf not generated", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }
  const DetailPdf = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("fromdate", fromdate);
    formData.append("toDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    const reservedgraphdetailpdf_api = await reservedgraphdetailpdfapi({
      formData,
    });
    setLoading(false);
    if (reservedgraphdetailpdf_api.success === 1) {
      var date = moment(new Date()).format("YYYY_MM_DD-HH_mm_ss");
      saveAs(reservedgraphdetailpdf_api.pdf, date + ".pdf");
    } else {
      addToast("pdf not generated", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const func_pdf_reserved_graph = async () => {
    setLoading(true);
    const formData = new FormData();
    let ReservedContractsAmount = 0;
    let ReceivedPaymentAmount = 0;
    let PreLoad = 0;
    let Total = 0;
    let TotalMonth = 0;
    tabledata.map(
      (item, i) => (
        (ReservedContractsAmount =
          ReservedContractsAmount + +item.TotalContracts),
        (ReceivedPaymentAmount =
          ReceivedPaymentAmount +
          +item.pending +
          +item.redeem +
          +item.comp_pending +
          +item.comp_redeem),
        (PreLoad = PreLoad + +item.comp_total),
        (Total = Total + +item.total)
      )
    );
    let ClaimPendingAmount = 0;
    let ClaimPaidAmount = 0;
    let TotalServices = 0;
    let ClaimPaid = 0;
    let ClaimPending = 0;
    righttabledata.map(
      (item, i) => (
        (ClaimPendingAmount = ClaimPendingAmount + +item.ClaimedAmount),
        (ClaimPaidAmount = ClaimPaidAmount + +item.ClaimPayOutAmount),
        (TotalServices = TotalServices + +item.TotalContracts),
        (ClaimPaid = ClaimPaid + +item.ClaimPaidServices),
        (ClaimPending = ClaimPending + +item.TotalContracts)
      )
    );
    // redemption details
    formData.append("ClaimPendingAmount", ClaimPendingAmount);
    formData.append("ClaimPaidAmount", ClaimPaidAmount);
    formData.append("TotalServices", TotalServices);
    formData.append("ClaimPaid", ClaimPaid);
    formData.append("ClaimPending", ClaimPending);
    // reserve detail
    formData.append("ReservedContractsAmount", ReservedContractsAmount);
    formData.append("ReceivedPaymentAmount", ReceivedPaymentAmount);
    formData.append("PreLoad", PreLoad);
    formData.append("Total", Total);
    formData.append("TotalMonth", tabledata.length);
    // redemotion detail
    formData.append(
      "FutureMaturedBalance",
      ReserveGraphdata.FutureMaturedBalance
    );
    formData.append(
      "ContractMaturedBalance",
      ReserveGraphdata.maturedcontracttotalCost
    );
    formData.append("dateprint", reserve_title);
    formData.append("DealerID", DealerID);
    formData.append("fromdate", fromdate);
    formData.append("toDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    formData.append("DealerTitle", DealerTitle);
    formData.append("AgencyLogo", ReserveGraphdata.AgencyLogo);
    formData.append("TotalContracts", totalcontracts);
    //
    formData.append("result", JSON.stringify(resdata.result));
    formData.append(
      "ServicePaidouttostore",
      JSON.stringify(resdata.ServicePaidouttostore)
    );
    formData.append("trend", resdata.trend);

    //
    formData.append("Redemption", redemption);
    formData.append("Reserved", reserved);

    formData.append("contractChart", contractsoldimg);
    formData.append("drawtrenline", trendimg);
    const res_api = await pdf_reserved_graph_api({ formData });
    setLoading(false);
    if (res_api.success === 1) {
      var date = moment(new Date()).format("YYYY_MM_DD-HH_mm_ss");
      saveAs(res_api.pdf, date + ".pdf");
    } else {
      addToast("pdf not generated", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <section id="top-sec">
      <PreLoad loading={loading} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <p className="m-0" style={{ color: "#222" }}>
              {DealerTitle}
            </p>
            <h1 className="m-0" style={{ color: "#00adef", fontSize: 60 }}>
              <strong>{totalcontracts}</strong>
            </h1>
            <p className="m-0">Total number of contact sold</p>
            <p className="m-0">{reserve_title}</p>
          </div>
          <div className="col-md-2 mt-5">
            <p className="m-0">
              <i
                className="fa fa-circle"
                aria-hidden="true"
                style={{ color: "#00adef", fontSize: 10 }}
              ></i>{" "}
              Sold
            </p>
            <p className="m-0">
              <i
                className="fa fa-circle"
                aria-hidden="true"
                style={{ color: "#f57215", fontSize: 10 }}
              ></i>{" "}
              Pre-Load
            </p>
            <p className="m-0">
              <i
                className="fa fa-circle"
                aria-hidden="true"
                style={{ color: "#231f20", fontSize: 10 }}
              ></i>{" "}
              Reserve in account
            </p>
          </div>
          <div className="col-md-5 mt-5">
            <p className="m-0">{reserve_title}</p>
            <p className="m-0">
              <strong style={{ color: "#14b3f0" }}>
                ${ReserveGraphdata.maturedcontracttotalCost}:&nbsp;
              </strong>{" "}
              Contract Surplus
            </p>
            <p className="m-0">
              <strong style={{ color: "#40ba56" }}>
                ${ReserveGraphdata.FutureMaturedBalance}:&nbsp;
              </strong>
              Contract Surplus estimated for{" "}
              {moment().add(1, "M").format("MMM YYYY")}
            </p>
          </div>
          <div className="col-md-2 text-right">
            <a href="">
              <img
                src={ReserveGraphdata.AgencyLogo}
                alt="AgencyLogo"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 col-sm-12">
            <Reserve
              reservepreload1={reservepreload1}
              reservepreload2={reservepreload2}
              reserveSold1={reserveSold1}
              reserveSold2={reserveSold2}
              title={reserve_title}
              xaxis={reservexaxis}
              setReserved={setReserved}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Redemption
              color={["#000000", "#00adef"]}
              row1={redemption_row1}
              row2={redemption_row2}
              xaxis={redemption_xaxis}
              title={redemption_title}
              setRedemption={setRedemption}
            />
          </div>
          <div className="col-12 d-none">
            <ContractSoldGraph
              contractsold_data={contractsold_data}
              title={reserve_title}
              setContractSoldimg={setContractSoldimg}
            />
          </div>
          <div className="col-12 d-none">
            <TrendGraph
              title={redemption_title}
              filter_type={filter_type}
              ticks={ticks}
              googledata={googledata}
              setTrendimg={setTrendimg}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Contracts</th>
                  <th scope="col">Contract Reserve</th>
                  <th scope="col">Reserve Recieved</th>
                </tr>
              </thead>
              <tbody>
                {typeof tabledata !== "undefined" && tabledata !== ""
                  ? tabledata.map(
                      (item, i) => (
                        (reserve_TotalContracts += +item.TotalContracts),
                        (reserve_amount +=
                          +item.pending +
                          +item.redeem +
                          +item.comp_pending +
                          +item.comp_redeem),
                        (reserve_batch_amount +=
                          +item.pending_batch +
                          +item.redeem_batch +
                          +item.comp_pending_batch +
                          +item.comp_redeem_batch),
                        (
                          <tr key={i}>
                            <th>{item.contractdate}</th>
                            <td>{item.TotalContracts}</td>
                            <td>
                              <span style={{ color: "#90d2eb" }}>
                                ({item.total})
                              </span>
                              &nbsp;${(item.pending + item.redeem).toFixed(2)}
                              &nbsp;
                              <span style={{ color: "#f57215" }}>
                                ({item.comp_total})
                              </span>
                              &nbsp;$
                              {(item.comp_pending + item.comp_redeem).toFixed(
                                2
                              )}
                            </td>
                            <td>
                              <span style={{ color: "#90d2eb" }}>
                                ({item.total_batch})
                              </span>
                              &nbsp;$
                              {(item.pending_batch + item.redeem_batch).toFixed(
                                2
                              )}
                              &nbsp;
                              <span style={{ color: "#f57215" }}>
                                ({item.comp_total_batch})
                              </span>
                              &nbsp;$
                              {(
                                item.comp_pending_batch + item.comp_redeem_batch
                              ).toFixed(2)}
                            </td>
                          </tr>
                        )
                      )
                    )
                  : ""}
                <tr>
                  <th></th>
                  <th>{reserve_TotalContracts}</th>
                  <th>${reserve_amount.toFixed(2)}</th>
                  <th>${reserve_batch_amount.toFixed(2)}</th>
                </tr>{" "}
                <tr>
                  <th></th>
                  <th></th>
                  <th>
                    <strong>Variance</strong>{" "}
                    <span className="text_red">
                      ${(reserve_amount - reserve_batch_amount).toFixed(2)}
                    </span>
                  </th>
                  <th></th>
                </tr>
                {resdata != "" && typeof resdata != "undefined" ? (
                  <tr>
                    <th style={{ color: "#f00" }}>Cancellations</th>
                    <td className="text_red">
                      {resdata.cancelledreservedgraph.TotalContracts}
                    </td>
                    <td className="text_red"></td>
                    <td> </td>
                  </tr>
                ) : (
                  ""
                )}
                {dealerredemption != "" &&
                typeof dealerredemption != "undefined" ? (
                  <tr>
                    <th style={{ color: "#f00" }}>Matured</th>
                    <td className="text_red">
                      {+dealerredemption.PlanServicesReservedAmountOverrite ===
                      1
                        ? dealerredemption.row3.maturedcontract +
                          dealerredemption.row4.maturedcontract
                        : dealerredemption.row1.maturedcontract}
                    </td>

                    <td className="text_red">
                      ${" "}
                      {+dealerredemption.PlanServicesReservedAmountOverrite ===
                      1
                        ? dealerredemption.row3.Contract_Reserve +
                          dealerredemption.row4.Contract_Reserve
                        : dealerredemption.row1.Contract_Reserve}
                    </td>
                    <td className="text_red">
                      {" "}
                      ${" "}
                      {+dealerredemption.PlanServicesReservedAmountOverrite ===
                      1
                        ? dealerredemption.row3.Forfeited +
                          dealerredemption.row4.Forfeited
                        : dealerredemption.row1.Forfeited}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Services</th>
                  <th scope="col">Claim Amount</th>
                  <th scope="col">Claims Paid</th>
                </tr>
              </thead>
              <tbody>
                {typeof righttabledata !== "undefined" && righttabledata !== ""
                  ? righttabledata.map(
                      (item, i) => (
                        (redemption_TotalContracts += +item.TotalContracts),
                        (ClaimedAmount += +item.ClaimedAmount),
                        (ClaimPayOutAmount += +item.ClaimPayOutAmount),
                        (
                          <tr key={i}>
                            <th>
                              {typeof item.dd != "undefined"
                                ? moment(
                                    item.mm + "/" + item.dd + "/" + item.yy
                                  ).format("MM/DD/YYYY")
                                : typeof item.mm != "undefined"
                                ? moment(item.mm + "/" + "01" + "/" + item.yy)
                                    .endOf("month")
                                    .format("MM/DD/YYYY")
                                : item.yy}
                            </th>
                            <td>{item.TotalContracts}</td>
                            <td>${(+item.ClaimedAmount).toFixed(2)}</td>
                            <td>
                              ${(+item.ClaimPayOutAmount).toFixed(2)} (
                              {+item.ClaimPaidServices})
                            </td>
                          </tr>
                        )
                      )
                    )
                  : ""}
                {righttabledata != "" &&
                typeof righttabledata != "undefined" ? (
                  <tr>
                    <th></th>

                    <th>{redemption_TotalContracts}</th>
                    <th>${ClaimedAmount.toFixed(2)}</th>
                    <th>${ClaimPayOutAmount.toFixed(2)}</th>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <th></th> <th></th> <th></th> <th></th>{" "}
                </tr>
              </tbody>
            </table>
            <div className="col-sm-12 text-center">
              <strong>Variance</strong>{" "}
              <span className="text_red">
                ${(ClaimedAmount - ClaimPayOutAmount).toFixed(2)}
              </span>
            </div>
          </div>
        </div>

        <div className="row mt-5 ">
          <div className="col-md-12 text-center">
            <button
              className="btn btn-info mr-2"
              onClick={() => handlegraphpdf()}
              target="_blank"
            >
              Graph PDF
            </button>
            <button
              className="btn btn-info mr-2"
              onClick={() => func_pdf_reserved_graph()}
            >
              Detail Graph PDF
            </button>
            <button className="btn btn-info" onClick={() => DetailPdf()}>
              Detail PDF
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReserveGraph;
