import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import PreLoad from "../components/PreLoad";
import {
  f_dashboardexport,
  f_subscriptionexport,
} from "../components/helpers";
import ReactTooltip from "react-tooltip";
function DashboardOtherDetails(props) {
  const { ShopingCartEnable, DealerID, fromdate, toDate, FixedDateParameter } =
    props;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const exportdata = (export_type) => {
    if (DealerID == "" || typeof DealerID == "undefined") {
      addToast("please select dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("FomDate", fromdate);
    formData.append("ToDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    formData.append("export_type", export_type);
    setLoading(true);
    //api
    if (ShopingCartEnable === 1) {
      f_subscriptionexport(formData).then((response) => {
        setLoading(false);
      });
    } else {
      f_dashboardexport(formData).then((response) => {
        setLoading(false);
      });
    }
  };
  let tooltip1 = "This is the number of Subscription contract";
  let tooltip5 = "This is the number of Marketplace contract";
  let tooltip4 = "This is the number of Classic contract";
  let tooltip3 = "This is the number of Complimentary contract";
  let tooltip2 = "This is the number of Express contract";
  return (
    <React.Fragment>
      <PreLoad loading={loading} />
      <div className="col-md-4">
        <div className="card">
          <div className="card-body font-14" style={{ padding: "0.75rem" }}>
            <span>
              Total Contracts{" "}
              {props.PcpBreakDown.Total ? props.PcpBreakDown.Total : 0}
            </span>
            <br />
            <br />
            <span
              style={{
                color: "#ff9800",
                backgroundColor: "#ff9800",
                padding: "1px 6px",
              }}
            >
              ff
            </span>{" "}
            <span>
              Subscriptions{" "}
              {props.PcpBreakDown.subscriptions_per
                ? props.PcpBreakDown.subscriptions_per
                : 0}
              % -{" "}
              {props.PcpBreakDown.subscriptions
                ? props.PcpBreakDown.subscriptions
                : 0}
            </span>{" "}
            <a href="#" style={{ color: "#26a69a" }} data-tip={tooltip1}>
              <i className="dripicons-question"></i>
            </a>
            <br />
            <br />
            <span
              style={{
                color: "#ffcd03",
                backgroundColor: "#ffcd03",
                padding: "1px 6px",
              }}
            >
              ff
            </span>{" "}
            <span>
              {" "}
              Express{" "}
              {props.PcpBreakDown.express_per
                ? props.PcpBreakDown.express_per
                : 0}
              % $
              {props.ReservedAmountBreakDown.express
                ? props.ReservedAmountBreakDown.express
                : 0}{" "}
              - {props.PcpBreakDown.express ? props.PcpBreakDown.express : 0}
            </span>{" "}
            <a href="#" style={{ color: "#26a69a" }} data-tip={tooltip2}>
              <i className="dripicons-question"></i>
            </a>
            <a
              href="#"
              onClick={() => exportdata(21)}
              style={{ color: "#26a69a", marginLeft: 10 }}
            >
              <i
                className="dripicons-document-delete"
                style={{ padding: 2, fontSize: 18 }}
              ></i>
            </a>
            <br />
            <br />
            <span
              style={{
                color: "#fcba17",
                backgroundColor: "#fcba17",
                padding: "1px 6px",
              }}
            >
              ff
            </span>{" "}
            <span>
              {" "}
              Complimentary{" "}
              {props.PcpBreakDown.complimentary_per
                ? props.PcpBreakDown.complimentary_per
                : 0}
              % $
              {props.ReservedAmountBreakDown.complimentary
                ? props.ReservedAmountBreakDown.complimentary
                : 0}{" "}
              -{" "}
              {props.PcpBreakDown.complimentary
                ? props.PcpBreakDown.complimentary
                : 0}
            </span>{" "}
            <a href="#" style={{ color: "#26a69a" }} data-tip={tooltip3}>
              <i className="dripicons-question"></i>
            </a>
            <a
              href="#"
              onClick={() => exportdata(20)}
              style={{ color: "#26a69a", marginLeft: 10 }}
            >
              <i
                className="dripicons-document-delete"
                style={{ padding: 2, fontSize: 18 }}
              ></i>
            </a>
            <br />
            <br />
            <span
              style={{
                color: "#00aeef",
                backgroundColor: "#00aeef",
                padding: "1px 6px",
              }}
            >
              ff
            </span>{" "}
            <span>
              {" "}
              Classic{" "}
              {props.PcpBreakDown.classic_per
                ? props.PcpBreakDown.classic_per
                : 0}
              % $
              {props.ReservedAmountBreakDown.classic
                ? props.ReservedAmountBreakDown.classic
                : 0}{" "}
              - {props.PcpBreakDown.classic ? props.PcpBreakDown.classic : 0}
            </span>{" "}
            <a href="#" style={{ color: "#26a69a" }} data-tip={tooltip4}>
              <i className="dripicons-question"></i>
            </a>
            <a
              href="#"
              onClick={() => exportdata(19)}
              style={{ color: "#26a69a", marginLeft: 10 }}
            >
              <i
                className="dripicons-document-delete"
                style={{ padding: 2, fontSize: 18 }}
              ></i>
            </a>
            <br />
            <br />
            <span
              style={{
                color: "#165b76",
                backgroundColor: "#165b76",
                padding: "1px 6px",
              }}
            >
              ff
            </span>{" "}
            <span>
              {" "}
              Marketplace{" "}
              {props.PcpBreakDown.marketplace
                ? props.PcpBreakDown.marketplace
                : 0}
              % $
              {props.ReservedAmountBreakDown.TotalMarketPlaceAmount
                ? props.ReservedAmountBreakDown.TotalMarketPlaceAmount
                : 0}{" "}
              -{" "}
              {props.PcpBreakDown.TotalMarketPlace
                ? props.PcpBreakDown.TotalMarketPlace
                : 0}
            </span>{" "}
            <a style={{ color: "#26a69a" }} href="#" data-tip={tooltip5}>
              <i className="dripicons-question"></i>
            </a>
          </div>
        </div>
        <ReactTooltip multiline={true} />
      </div>
    </React.Fragment>
  );
}

export default DashboardOtherDetails;
