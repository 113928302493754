import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Table,
  Spinner,
} from "react-bootstrap";
import {
  get_resell_plans,
  resellcontractplanservices,
  continuewithoutresellcontract,
  resellmatured,
} from "./EditContractApi";
export function ResellContract(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [planprice, setPlanprice] = useState(0);
  const [resellContractPopup, setResellContractPopup] = useState(0);
  const [plans, setPlans] = useState("");
  const [services, setServices] = useState("");
  const { contractinfo, getDealerByID, isResellContract } = props;
  const selected_plan = useRef(null);
  const Close = () => {
    props.closeresell();
  };
  useEffect(() => {
    getplandata();
  }, [contractinfo]);
  async function getplandata() {
    if (typeof isResellContract != "undefined" && isResellContract == 0) {
      if (contractinfo.DealerID) {
        if (contractinfo.ContractID && contractinfo.Status == "M") {
          if (getDealerByID.ResellMaturedContract == 1) {
            if (
              contractinfo.PurpleBall &&
              (contractinfo.PurpleBall == 0 || contractinfo.PurpleBall == 1)
            ) {
              setResellContractPopup(1);
            } else if (
              contractinfo.PurpleBall &&
              contractinfo.PurpleBall == 1
            ) {
              setResellContractPopup(2);
            } else {
              setResellContractPopup(1);
            }
          }
        } else if (contractinfo.ContractID) {
          if (getDealerByID.ResellMarkedContract == 1) {
            if (contractinfo.PurpleBall && contractinfo.PurpleBall == 1) {
              setResellContractPopup(2);
            } else {
              setResellContractPopup(0);
            }
          }
        }
      }
    } else {
      setResellContractPopup(0);
    }

    // api request

    const formdata = new FormData();
    formdata.append("ContractID", contractinfo.ContractID);
    formdata.append("DealerID", contractinfo.DealerID);
    formdata.append("ResellContractPopup", resellContractPopup);

    const getresellplans_response = await get_resell_plans(formdata);
    if (getresellplans_response.success == 1) {
      setPlans(getresellplans_response.planlist);
    }
  }
  const changeplan = async () => {
    setServices("");
    setError("");
    const selectedplan = plans.filter(
      ({ PlanID }) => PlanID == selected_plan.current.value
    );

    setPlanprice(selectedplan[0].PlanCost);
  };
  async function getplanservcies() {
    setServices("");
    setLoading(true);
    const formdata = new FormData();
    formdata.append("PlanID", selected_plan.current.value);

    const resellcontractplanservices_response =
      await resellcontractplanservices(formdata);

    if (resellcontractplanservices_response.success == 1) {
      setLoading(false);
      setServices(resellcontractplanservices_response.services);
    }
  }
  const withoutsellingcontract = async () => {
    if (typeof contractinfo != "undefined" && contractinfo != "") {
      const formdata = new FormData();
      formdata.append("ContractID", contractinfo.ContractID);
      formdata.append("ResellContractPopup", resellContractPopup);
      const continuewithoutresellcontract_response =
        await continuewithoutresellcontract(formdata);
      props.closeresell();
    }
  };
  const resellcontract = async () => {
    setError("");
    if (
      typeof contractinfo != "undefined" &&
      contractinfo != "" &&
      selected_plan.current.value != ""
    ) {
      localStorage.setItem("ContractID", contractinfo.ContractID);
      localStorage.setItem("PlanID", selected_plan.current.value);
      localStorage.setItem("ResellContractPopup", resellContractPopup);
      window.open("/contracts/create");
    } else {
      setError("please select plan");
    }
  };
  return (
    <React.Fragment>
      <Modal show={true} size={"lg"} onHide={Close}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-exclamation-circle"></i> Resell Contract
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {+resellContractPopup === 1 ? (
            <h6>This Customer's Contract has Expired</h6>
          ) : (
            <h6>This customer has used all of their Marked Service</h6>
          )}
          <Row>
            <Col sm={6}>
              <button
                onClick={resellcontract}
                className="btn btn-block btn-lg btn-success"
              >
                Resell a new plan
              </button>
            </Col>
            <Col sm={6}>
              <button
                onClick={withoutsellingcontract}
                className="btn btn-block btn-lg btn-warning"
                style={{ color: "#fff" }}
              >
                Continue without Reselling
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="input-group input-group-lg">
                <InputGroup className="m-3">
                  <select
                    className="validate[required] form-control"
                    id="resellplanid"
                    onChange={changeplan}
                    ref={selected_plan}
                    name="resellplanid"
                    defaultValue="-1"
                  >
                    <option value="-1">Select Plan to Offer</option>

                    {typeof plans != "undefined" && plans != ""
                      ? plans.map((item, i) => (
                          <option key={i} value={item.PlanID}>
                            {item.PlanDescription}
                          </option>
                        ))
                      : ""}
                  </select>

                  <InputGroup.Text id="basic-addon2">
                    $ {planprice}
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <a
                href="#"
                onClick={getplanservcies}
                className="viewreselplanservices"
              >
                View selected plans services
              </a>
            </Col>
          </Row>
          <small className="help-block text-danger">{error}</small>
          {typeof services != "undefined" && services != "" && !loading ? (
            <Row>
              <Col lg={12}>
                <h4>
                  <i className="fa fa-exclamation-triangle"></i>Plan Detail
                  <button
                    type="button"
                    className="bootbox-close-button close"
                    onClick={() => setServices("")}
                  >
                    ×
                  </button>
                </h4>

                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Sr.#</th>
                      <th>Service Name</th>
                      <th>No of Services</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeof services != "undefined" && services != ""
                      ? services.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item.CouponTitle}</td>
                            <td>{item.CouponServiceCount}</td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : loading ? (
            <span style={{ marginLeft: "50%" }}>
              <Spinner animation="border" variant="success" />
            </span>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
