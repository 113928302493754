import axios from "axios";
import { user_id, role_id, pcp_user_id, user_uuid } from "../CurrentUser";
const token = JSON.parse(localStorage.getItem("token"));
const API = process.env.REACT_APP_APP_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const header = {
  "Content-Type": "application/json",
};
export const retentionmap = async ({ formData }) => {
  let url = API + "/dashboard/retentionmap";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const reservedgraph = async ({ formData }) => {
  let url = API + "/dashboard/reservedgraph";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  formData.append("UUID", user_uuid);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const dealerredemption = async ({ formData }) => {
  let url = API + "/dashboard/dealerredemption";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  formData.append("UUID", user_uuid);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const pdfreservedgraph = async ({ formData }) => {
  let url = API + "/dashboard/pdfreservedgraph";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  formData.append("UUID", user_uuid);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const reservedgraphdetailpdfapi = async ({ formData }) => {
  let url = API + "/dashboard/reservedgraphdetailpdf";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const pcpbreakdown = async ({ formData }) => {
  let url = API + "/dashboard/pcpbreakdown";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const customerinteraction = async ({ formData }) => {
  let url = API + "/dashboard/customerinteraction";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const comparasion30day = async ({ formData }) => {
  let url = API + "/dashboard/comparasion30day";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const contractSoldGraph = async ({ formData }) => {
  let url = API + "/dashboard/contractSoldGraph";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const pdf_reserved_graph_api = async ({ formData }) => {
  let url = API + "/dashboard/pdf_reserved_graph";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);

  formData.append("UUID", user_uuid);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const genratenewdashboard = async ({ formData }) => {
  let url = API + "/dashboard/genratenewdashboard";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const dashboardcomparegraph = async ({ formData }) => {
  let url = API + "/dashboard/dashboardcomparegraph";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  formData.append("UUID", user_uuid);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const dashboaremailpopup = async (formData) => {
  let url = API + "/dashboard/dashboaremailpopup";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  formData.append("UUID", user_uuid);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const emaildashboard_post = async (formData) => {
  let url = API + "/dashboard/emaildashboard";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  formData.append("UUID", user_uuid);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
