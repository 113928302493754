import React, { useState } from "react";

import { Modal, Tabs, Tab, InputGroup, FormControl } from "react-bootstrap";
import $ from "jquery";
import { payment_done, statesapi } from "../create/CreateContractApi";
import PreLoad from "../../../components/PreLoad";
export default function Subcription(props) {
  const [paymentThrough, setpaymentThrough] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [states, setStates] = useState([]);
  const { hidesubcription, plancost, paymentdata, Countries, allowoverride } =
    props;
  const handleClose = () => {
    hidesubcription(false);
  };
  const onsubmit = async () => {
    setLoading(true);
    let form = $("#BookPackageForm").serializeArray();
    const formData = new FormData();
    form.map((item, index) => {
      if (item.value != "") {
        formData.append(item.name, item.value);
      }
    });
    //create data
    Object.keys(paymentdata).forEach((key) =>
      formData.append(key, paymentdata[key])
    );
    formData.append("PaymentThrough", paymentThrough);
    formData.append("allowoverride", allowoverride);
    formData.append(
      "customerprice_hidden",
      plancost.PlanCostAndExpiry.customerprice
    );
    formData.append(
      "Hidden_ContractTotalCost",
      plancost.PlanCostAndExpiry.PlanCost
    );
    const payment_done_api = await payment_done(formData);
    if (payment_done_api.success === 1) {
      setLoading(false);
      window.location.href =
        process.env.REACT_APP_BASE_URL +
        "/contracts/edit/" +
        payment_done_api.ContractID;
    } else {
      setError(payment_done_api.message);
      setTimeout(() => setError(""), 3000);
    }
    console.log(payment_done_api);
  };
  const handleSelect = (key) => {
    setpaymentThrough(key);
  };
  const getstates = async (e) => {
    setLoading(true);
    var CountryID = e.target.value;
    const formData = new FormData();
    formData.append("CountryID", parseInt(CountryID));
    const states = await statesapi({ formData });
    if (states.success == 1) {
      setStates(states.States);
    }
    setLoading(false);
  };
  return (
    <div>
      <PreLoad loading={loading} />
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-money" /> Credit Card Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="" method="post" id="BookPackageForm">
            <Tabs
              defaultActiveKey={1}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(e) => handleSelect(e)}
            >
              <Tab eventKey={1} title="Credit Card">
                <div className="text_14">
                  <div className="row form-group">
                    <div className="col-sm-6">
                      <label htmlFor="cardNumber">First Name</label>
                      <input
                        type="text"
                        className="form-control required"
                        name="card_name"
                        id="card_name"
                        placeholder="First Name"
                        autofocus
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="cardNumber">Last Name</label>
                      <input
                        type="text"
                        className="form-control required"
                        id="card_lastname"
                        name="card_lastname"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="cardNumber">CARD NUMBER</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control required nospace"
                        name="card_no"
                        placeholder="Valid Card Number"
                        required
                        autofocus
                        maxLength={20}
                      />
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-lock" />
                      </span>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-12" htmlFor="payment_method">
                      <strong> Card </strong>
                    </div>
                    <div className="col-sm-12 col-lg-12 pl-ziro">
                      <select
                        name="payment_method"
                        className="form-control required"
                      >
                        <option value selected>
                          --Select---
                        </option>
                        <option value="AmericanExpress">
                          American Express
                        </option>
                        <option value="DinersClub">Diner's Club</option>
                        <option value="discover">Discover</option>
                        <option value="JCB">JCB</option>
                        <option value="Mastercard">MasterCard</option>
                        <option value="Visa">Visa</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7 col-md-7">
                      <div className="row form-group">
                        <div className="col-sm-12" htmlFor="expiryMonth">
                          <strong> EXPIRY DATE</strong>
                        </div>
                        <div className="col-sm-6 col-lg-6">
                          <input
                            type="text"
                            className="form-control required"
                            id="expiryMonth"
                            name="expiryMonth"
                            max={2}
                            placeholder="MM"
                            required
                          />
                        </div>
                        <div className="col-sm-6 col-lg-6">
                          <input
                            type="text"
                            max={4}
                            className="form-control required"
                            id="expiryYear"
                            name="expiryYear"
                            placeholder="YYYY"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-5 col-md-5 pull-right">
                      <div className="form-group">
                        <div className="col-sm-12" htmlFor="cvCode">
                          CCV2
                        </div>
                        <input
                          type="password"
                          maxLength={4}
                          className="form-control required"
                          id="cvCode"
                          name="cvCode"
                          placeholder="CCV2"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      className="form-control "
                      name="Company"
                      id="Company"
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      className="form-control required"
                      name="Address2"
                      id="Address2"
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      name="Country2"
                      className="form-control required"
                      name="Country2"
                      onChange={(e) => getstates(e)}
                    >
                      <option value>Select Country</option>{" "}
                      {Countries != "" && typeof Countries != "undefined"
                        ? Countries.map((item, i) => (
                            <option id={item.id} key={i + 1} value={item.id}>
                              {item.nicename}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>State</label>
                    <select name="State2" className="form-control required">
                      <option key={-1} value={0}>
                        Select Option
                      </option>
                      {states != ""
                        ? states.map((item, i) => (
                            <option key={i} value={item.StateID}>
                              {item.StateTitle}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>City Name</label>
                    <input
                      className="form-control required"
                      name="City2"
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>Zip Code</label>
                    <input
                      className="form-control required"
                      name="Zip2"
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone #.</label>
                    <input
                      className="form-control "
                      name="PhoneNumber2"
                      type="text"
                    />
                  </div>
                  <div className="form-group text-center">
                    <img
                    alt="acceptance_marks"
                      src={
                        require("../../../resources/images/acceptance_marks.png")
                          .default
                      }
                      className="img-responsive"
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <InputGroup className="mb-3">
                        <FormControl
                          disabled
                          placeholder="Add Card Info"
                          aria-label="Add Card Info"
                          aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <input
                            defaultValue={1}
                            type="radio"
                            name="add_credit"
                          />{" "}
                          Yes &nbsp;
                          <input
                            defaultValue={0}
                            type="radio"
                            defaultChecked="checked"
                            name="add_credit"
                          />{" "}
                          &nbsp; No
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <span className="add-on">
                      <input
                        type="checkbox"
                        className="required mcheckbox"
                        name="term_condition"
                        defaultValue={1}
                        required
                      />
                    </span>
                    &nbsp; I have read and agree to the &nbsp;
                    <a
                      className="btn-link term_condition_popup"
                      href="javascript:void(0)"
                    >
                      Terms and Conditions
                    </a>{" "}
                    &nbsp; and &nbsp;{" "}
                    <a
                      className="btn-link"
                      href="https://mypcp.us/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="Bank Account">
                <div className="text_14">
                  <div className="row form-group">
                    <div className="col-sm-6">
                      <label htmlFor="cardNumber">First Name</label>
                      <input
                        type="text"
                        className="form-control required"
                        name="card_name"
                        placeholder="First Name"
                        autofocus
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="cardNumber">Last Name</label>
                      <input
                        type="text"
                        className="form-control required"
                        name="card_lastname"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="AccountNumber">Account Number</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control required nospace"
                        name="AccountNumber"
                        placeholder="Valid Account Number"
                        required
                        autofocus
                        maxLength={20}
                      />
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-lock" />
                      </span>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-12" htmlFor="AccountTitle">
                      <label htmlFor="AccountTitle"> Account Title</label>
                    </div>
                    <div className="col-sm-12 col-lg-12 pl-ziro">
                      <input
                        type="text"
                        className="form-control required nospace"
                        name="NameOnAccount"
                        placeholder="Account Title"
                        required
                        autofocus
                        maxLength={20}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div className="row form-group">
                        <div className="col-sm-12" htmlFor="BankName">
                          <label htmlFor="BankName"> Bank Name</label>
                          <input
                            type="text"
                            className="form-control required nospace"
                            name="BankName"
                            placeholder="Valid Bank Name"
                            required
                            autofocus
                            maxLength={20}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Routing Number</label>
                    <input
                      className="form-control "
                      name="RoutingNumber"
                      type="text"
                      placeholder="Routing Number"
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <InputGroup className="mb-3">
                        <FormControl
                          disabled
                          placeholder="Add Bank Info"
                          aria-label="Add Bank Info"
                          aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <input
                            defaultValue={1}
                            type="radio"
                            name="add_bank_info"
                          />{" "}
                          Yes &nbsp;
                          <input
                            defaultValue={0}
                            type="radio"
                            defaultChecked="checked"
                            name="add_bank_info"
                          />{" "}
                          &nbsp; No
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <span className="add-on">
                      <input
                        type="checkbox"
                        className="required mcheckbox"
                        name="term_condition"
                        id="term_condition"
                        defaultValue={1}
                        required
                      />
                    </span>
                    &nbsp; I have read and agree to the &nbsp;
                    <a
                      className="btn-link term_condition_popup"
                      href="javascript:void(0)"
                    >
                      Terms and Conditions
                    </a>{" "}
                    &nbsp; and &nbsp;{" "}
                    <a
                      className="btn-link"
                      href="https://mypcp.us/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <div className="row">
              <div className="col-sm-2">
                <img className="img-thumbnail" 
                alt="featured_img"
                src={plancost?.featured_img} />
              </div>
              <div className="col-lg-10">
                <h4 class="media-heading">{plancost.product_title}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: plancost.product_desc }}
                ></div>
              </div>
            </div>
            <div className="row mt-5">
              <div
                className="col-lg-10"
                dangerouslySetInnerHTML={{ __html: plancost?.card_html }}
              ></div>
              <small className="help-block text-danger">{error}</small>
              <button
                type="button"
                class="btn btn-block btn-success"
                onClick={onsubmit}
              >
                Proceed &gt;&gt;
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
