import moment from "moment";
import "./RedemptionHead.css";
import { Table } from "react-bootstrap";
import styled from "styled-components";
export default function RedemptionHead(props) {
  const data = typeof props.data[0] == "undefined" ? "" : props.data[0];
  const updateClipboard = () => {
    navigator.clipboard.writeText(data.ContractNo).then(
      () => {
        console.log("Copied!");
      },
      () => {
        console.log("Copy failed!");
      }
    );
  };
  const Redemptiontable = styled.div`
    table {
      width: 50%;
    }
    td {
      color: #005f7f !important;
      font-weight: bolder !important;
    }
  `;
  return (
    <div id="redemption-sec" className="row mb-4">
      <div className="col-md-12">
        <p style={{ color: "#222" }} className="ml-5 pl-5">
          <b>Batch # {data.batch}</b>
        </p>
        <Redemptiontable>
          <Table size="sm">
            <tbody>
              <tr>
                <td>Contract:</td>
                <td>
                  {data.ContractNo}
                  <i
                    onClick={updateClipboard}
                    style={{ cursor: "pointer" }}
                    className="fas fa-copy"
                  ></i>
                </td>
                <td>Expiration Date:</td>
                <td>
                  {data.UnlimiatedTerm === 1
                    ? "N/A"
                    : moment.unix(data.ValidityDate).format("MM/DD/YYYY")}
                </td>
              </tr>
              <tr>
                <td>Dealership:</td>
                <td>{data.dealertitle}</td>
                <td>Expiration Mileage:</td>
                <td>
                  {data.UnlimitedMileage === 1 ? "N/A" : data.ValidityMileage}
                </td>
              </tr>
            </tbody>
          </Table>
        </Redemptiontable>
      </div>
    </div>
  );
}
