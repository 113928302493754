import React from 'react'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import MuiTablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function CustomFooter (props) {
  
  const { count, classes, textLabels, rowsPerPage, page,changePage } = props
  const [pages, setPages] = React.useState(1);
  const handlePageChange = (event, value) => {
    changePage(value,'');
    setPages(value);
  };



    const footerStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      BorderBottom: '0px solid transparent',
    }

    return (
      <TableFooter>
        <TableRow>
        <TableCell colSpan={6}>
                Total: {count}
              </TableCell> <TableCell style={footerStyle} colSpan={12}>
              <Stack spacing={2}>
      <Typography></Typography>
      <Pagination count={(count/10).toFixed(0)} page={pages} onChange={handlePageChange} />
    </Stack>
              </TableCell>
          
        </TableRow>
      </TableFooter>
    )
  
}

export default CustomFooter
