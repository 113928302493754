const initialState = {
  ServicesList: [],
};

const changeserviceState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return {
        arr: [...state.ServicesList, rest],
      };
    default:
      return state;
  }
};

const Planservices = changeserviceState;
export default Planservices;
