import React from "react";

const DashboardUpSell = (props) => {
  return (
    <div className="col-md-3 text-center" style={{ display: "grid" }}>
      <div className="card">
        <div className="card-body" style={{ padding: "0.75rem" }}>
          <span className="font-18">Upsell From Services</span>
          <br />
          <br />
          <span className="mr-4">
            <i
              style={{ color: "#26a69a" }}
              className="services_font_28 fas fa-arrow-up"
            ></i>
          </span>
          <span className="mr-4 services_font_28">${props.UpsellAmount}</span>
          <span>
            <i className="services_font_28 mdi mdi-chart-areaspline "></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardUpSell;
