import React, { Component, useEffect } from "react";
import Chart from "react-apexcharts";

class RadialBar extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.loadComponent();
  }
  loadComponent() {}

  render() {
    let labelthis = this.props.label ? this.props.label : "";
    let percent = this.props.percent ? this.props.percent : 0;

    let mystate = {
      optionsRadial: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "80%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -20,
                show: true,
                color: "#888",
                fontSize: "15px",
              },
              value: {
                formatter: function (val) {
                  return val + "%";
                },
                color: "#111",
                fontSize: "20px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: [labelthis],
      },
      seriesRadial: [percent],
    };
    return (
      <Chart
        options={mystate.optionsRadial}
        series={mystate.seriesRadial}
        type="radialBar"
        height={160}
      />
    );
  }
}
export default RadialBar;
