import React, { useEffect, useState } from "react";
import { Tabs, Tab, Form, FormControl } from "react-bootstrap";
import PreLoad from "../../../components/PreLoad";
import { useToasts } from "react-toast-notifications";
import InputGroup from "react-bootstrap/InputGroup";
import DealersDropdown from "../../../components/DealersDropdown";
import AddModel from "../../../components/AddModel";
import AddBankAddress from "../AddBankAddress";
import "../create/createcontract.css";
import axios from "axios";
import * as Yup from "yup";
import $ from "jquery";
import { useFormik } from "formik";
import RedemptionHead from "./RedemptionHead";
import { useParams } from "react-router-dom";
import LoyaltyCash from "../Loyaltycash/LoyaltyCash";
import {
  verifyvin,
  plancostapi,
  planexpiryapi,
  statesapi,
  modelsapi,
  addbankapi,
  editcontract,
  setdefaultplanforuser,
  setdefaultsalerepforuser,
} from "../create/CreateContractApi";
import {
  updatecustomer,
  updatecontract,
  emailcustomercontract,
} from "./EditContractApi";
import moment from "moment";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
import ServiceRedmption from "./ServiceRedmption";
import { ResellContract } from "./ResellContract";
import { checknull } from "../../../components/helpers";
import CustomerCallLog from "../../../components/CustomerCallLog";
import { VinHistory } from "./VinHistory";
import { VehicleDetail } from "./VehicleDetail";
import { CustomerWallet } from "./CustomerWallet";
import { f_customer_info } from "./customerinfo_helper";
require("dotenv").config();

export default function CreateContract() {
  let lastmessage = localStorage.getItem("contract");
  const { ContractID } = useParams();
  const [yeardata, setYeardata] = useState("");

  const [newbank, setNewbank] = useState(true);
  const [selecteddealer, setSelecteddealer] = useState();
  const [dealerdata, setDealerdata] = useState("");
  const [banklist, setBanklist] = useState("");
  const [bankaddress, setBankaddress] = useState("");
  const [states, setStates] = useState("");
  const [model, setModel] = useState("");
  const [bankaddressmodal, setBankaddressmodal] = useState(false);
  const [Countries, setCountries] = useState("");
  const [plancost, setPlancost] = useState("");
  const [loading, setLoading] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [headdata, setHeaddata] = useState("");
  const [isResellContract, setIsResellContract] = useState("");
  const [contractinfo, setContractinfo] = useState("");

  const [loyaltycashshow, setLoyaltycashshow] = useState(false);
  const { addToast } = useToasts();
  const [serviceredmptiondata, setServiceredmptiondata] = useState("");
  const [closeresellcontract, setCloseresellcontract] = useState("");
  const [user_defaultplan, setUser_defaultplan] = useState(false);
  const [user_salerep, setUser_salerep] = useState(false);
  const [vinhistory, setVinhistory] = useState(false);
  const [cardetail, setCardetail] = useState(false);
  const [customer_call_log, setCustomer_call_log] = useState(false);
  const [customer_wallet, SetCustomer_wallet] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  //save customer info to change api for update call
  const [customerinfo, setCustomerinfo] = useState([]);
  const handlemodal = () => {
    setShowmodal(!showmodal);
  };
  const closeresell = () => {
    setCloseresellcontract(!closeresellcontract);
  };
  const handledealerchange = (dealer_id) => {
    setSelecteddealer(dealer_id);
    formik.setFieldValue("DealerID", dealer_id);
    if (!Number.isNaN(dealer_id)) {
      getadata(dealer_id);
    }
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const phoneRegExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  const validationSchema = Yup.object().shape({
    PrimaryEmail: Yup.string().email("Invalid Email").required("Required"),
    SellingRep: Yup.number().required("Required"),
    PhoneHome: Yup.string().matches(
      phoneRegExp,
      "phone Required in this format 123-123-1234"
    ),
    VIN: Yup.string().required("Vin required"),
    ModelID: Yup.number().required("Required"),
    VehYear: Yup.number().required("Required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      CustomerFName: "",
      CustomerLName: "",
      CustomerAddressLine1: "",
      CountryID: 0,
      CityName: "",
      StateID: "",
      PrimaryEmail: "",
      CustomerZIP: "",
      PhoneHome: "",
      CustomerDOB: "",
      password: "",
      HideTestContract: false,
      AddNewbank: "",
      DealerID: "",
      VIN: "",
      contractno: "",
      new_Contract_Number: "",
      MakeID: "",
      PlanID: "",
      OldPlanID: "",
      ModelID: "",
      SellingRep: "",
      SIManagerID: "",
      VehYear: "",
      VehicleType: "N",
      Mileage: "",
      StockDealNo: "",
      SaleDate: "",
      ValidityDate: "",
      RO: "",
      RO_NUMBER: "",
      ContractTotalCost: "",
      BankId: "",
      ServiceContract: "",
      SendEmail: "",
      SendSms: "",
      CustomerPrice: "",
      HidePrice: "",
      EnteredCost: "",
      CarfaxVin: "",
      isServiceContract: false,
      Loyalty_Map: false,
      LoyaltyCashEnable: false,
      EnableWelcomeSms: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      setLoading(true);
      data.LienholderAddress = bankaddress;
      data.SendEmail = data.SendEmail ? 1 : 0;
      data.SendSms = data.SendSms ? 1 : 0;
      data.isServiceContract = data.isServiceContract ? "YES" : "NO";
      data.EnableWelcomeSms = data.EnableWelcomeSms ? 1 : 0;
      data.Loyalty_Map = data.Loyalty_Map ? 1 : 0;
      data.HideTestContract = data.HideTestContract ? 1 : 0;
      const formData = new FormData();
      formData.append("ContractID", ContractID);
      let res = f_customer_info(customerinfo, data);
      if (res.count > 0) {
        const updatecustomer_res = await updatecustomer(res.fomdata);
        if (updatecustomer_res.success === 0) {
          addToast(updatecustomer_res, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }

      //update contract
      const update_data = new FormData();
      Object.keys(data).forEach((key) => update_data.append(key, data[key]));
      const addcontract = await updatecontract(update_data);
      if (addcontract.success == 1) {
        addToast(addcontract.message, {
          appearance: "success",
          autoDismiss: true,
        });
        setLoading(false);
      } else if (addcontract.success == 0) {
        setLoading(false);
        addToast(addcontract.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setLoading(false);
    },
  });
  const getBankAddress = async (event) => {
    formik.setFieldValue("BankId", event.target.value);
    let city = event.target.selectedOptions[0].getAttribute("city");
    let state = event.target.selectedOptions[0].getAttribute("statetitle");
    let statecode = event.target.selectedOptions[0].getAttribute("statecode");
    let lineofaddress = "";
    if (city != "") {
      lineofaddress = city;
    } else if (state != "") {
      lineofaddress = lineofaddress + ", " + state;
    } else if (statecode != "") {
      lineofaddress = lineofaddress + " - " + statecode;
    }
    setBankaddress(lineofaddress);
  };
  const functionaddnewbank = () => {
    setLoading(true);
    let AddNewbank = formik.values.AddNewbank;
    if (AddNewbank === "") {
      formik.errors.AddNewbank = "Please Add Bank Name";
      formik.touched.AddNewbank = true;

      setLoading(false);
      return false;
    }
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", selecteddealer);
    formData.append("AddNewbank", AddNewbank);
    const newbankresponse = addbankapi({ formData });
    setLoading(false);
    if (newbankresponse.success == 1) {
      formik.setFieldValue("BankId", +newbankresponse.BankID);
      setNewbank(true);
      setBanklist(newbankresponse.Banks);
    }
  };
  const handlebankaddress = (event, Banks) => {
    if (typeof Banks != "undefined") {
      setBanklist(Banks);
    }
    setBankaddressmodal(!bankaddressmodal);
  };
  const handleplanchange = async (val, setFieldValue) => {
    setLoading(true);
    if (selecteddealer == "undefined") {
      formik.touched.DealerID = "Please Select Dealership";
      formik.errors.DealerID = "Please Select Dealership";
      setLoading(false);
      return false;
    }
    var PlanID = val;
    if (PlanID !== formik.values.PlanID) {
      setFieldValue("PlanID", PlanID);
      setFieldValue("OldPlanID", formik.values.PlanID);
    } else {
      setFieldValue("PlanID", PlanID);
    }
    let saledate = $("#SaleDate").val();
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", selecteddealer);
    formData.append("SaleDate", saledate.replace(/-/g, "/"));
    formData.append("PlanID", parseInt(PlanID));

    const getplancost = await plancostapi({ formData });
    if (getplancost.success == 1) {
      setPlancost(getplancost);
      formik.setFieldValue(
        "ContractTotalCost",
        getplancost.PlanCostAndExpiry.customerprice
      );
    }

    setLoading(false);
  };
  const getplanexpiry = async (e, setFieldValue) => {
    setLoading(true);

    if (typeof selecteddealer == "undefined") {
      formik.touched.DealerID = "Please Select Dealership";
      formik.errors.DealerID = "Please Select Dealership";
      setLoading(false);
      return false;
    }
    let saledate = e.target.value;
    setFieldValue("SaleDate", saledate);

    let planid = formik.values.PlanID;
    if (planid == "") {
      formik.touched.PlanID = "Please select Plan";
      formik.errors.PlanID = "Please select Plan";
      setLoading(false);
      return false;
    }
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", selecteddealer);
    formData.append("SaleDate", saledate.replace(/-/g, "/"));
    formData.append("PlanID", parseInt(planid));
    const planexpiry = await planexpiryapi({ formData });
    if (planexpiry.success == 1) {
      formik.setFieldValue(
        "ValidityDate",
        planexpiry.contractexpirydate.MakePlanExpiryDate
      );

      setLoading(false);
    }
  };
  const updateClipboard = () => {
    navigator.clipboard.writeText(formik.values.contractno).then(
      () => {
        console.log("Copied!");
      },
      () => {
        console.log("Copy failed!");
      }
    );
  };
  const getmodel = async (e, setFieldValue) => {
    setLoading(true);
    var makeid = e.target.value;
    setFieldValue("MakeID", makeid);

    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", parseInt(selecteddealer));
    formData.append("MakeID", parseInt(makeid));

    const modelapi = await modelsapi({ formData });
    if (modelapi.success == 1) {
      setModel(modelapi.SpecifixModels);
    }
    setLoading(false);
  };
  const getadata = (dealer_id) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", parseInt(dealer_id));
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/dataagainstdealerid",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == 1) {
          setLoading(false);
          setDealerdata(response.data);
          setBanklist(response.data.Banks);
        }
      });
    setLoading(false);
  };

  const getstates = async (e, setFieldValue) => {
    setLoading(true);
    var CountryID = e.target.value;

    setFieldValue("CountryID", +CountryID);
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("CountryID", parseInt(CountryID));
    const states = await statesapi({ formData });
    if (states.success == 1) {
      setStates(states.States);
    }
    setLoading(false);
  };
  const functionverifyvin = async () => {
    setLoading(true);
    if (typeof selecteddealer === "undefined") {
      formik.touched.DealerID = "Please Select Dealership";
      formik.errors.DealerID = "Please Select Dealership";
      setLoading(false);
      return false;
    }
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", parseInt(selecteddealer));
    formData.append("VIN", formik.values.VIN);

    const verifyvinresponse = await verifyvin({ formData });
    setLoading(false);
    if (verifyvinresponse.success == 1) {
      setModel(verifyvinresponse.SpecifixModels);
      formik.setFieldValue("ModelID", parseInt(verifyvinresponse.ModelID));
      formik.setFieldValue("MakeID", parseInt(verifyvinresponse.MakeID));
      formik.setFieldValue("VehYear", parseInt(verifyvinresponse.ModelYear));
    }
  };
  useEffect(() => {
    var vehyear = [];
    for (let i = 0; i <= 100; i++) {
      vehyear.push({ year: moment().year() - i });
    }
    setYeardata(vehyear);

    fetechdata();
  }, []);
  async function fetechdata() {
    setLoading(true);
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("ContractID", ContractID);
    const editresponse = await editcontract({ formData });
    if (editresponse.success == 1) {
      setSelecteddealer(editresponse.DealerID);
      if (editresponse.UserPlans != "") {
        setUser_defaultplan(!user_defaultplan);
      }

      if (checknull(editresponse.UserSpecifixSellingRep) !== "") {
        setUser_salerep(!user_salerep);
      }
      var staticdata = [];
      setDealerdata(editresponse);

      //resell contract modal
      if (
        editresponse.contractinfo.Status === "M" ||
        editresponse.contractinfo.Status === "S"
      ) {
        setCloseresellcontract(true);
      }
      //Services/Redemption
      var services_redemption = [];
      services_redemption["expiredservices"] =
        editresponse.GetMaturedContractById;
      services_redemption["giftedservices"] =
        editresponse.GetGiftedContractById;
      services_redemption["getDealerByID"] = editresponse.getDealerByID;
      services_redemption["latestRedeem"] =
        typeof editresponse.GetLatestDate !== "undefined" &&
        editresponse.GetLatestDate
          ? editresponse.GetLatestDate
          : "";
      services_redemption["hidden_service"] =
        typeof editresponse.GetContractCouponById != "undefined"
          ? editresponse.GetContractCouponById.length
          : "a";
      services_redemption["contractinfo"] = editresponse.contractinfo;
      services_redemption["redeemcontract"] =
        editresponse.GetRedemmedContractById;
      services_redemption["contractcoupon"] =
        editresponse.GetContractCouponById;
      services_redemption["android"] = editresponse.getuserbyid?.android;
      services_redemption["iphone"] = editresponse.getuserbyid?.iphone;
      services_redemption["web"] = editresponse.getuserbyid?.web;
      services_redemption["window_phone"] =
        editresponse.getuserbyid?.window_phone;
      services_redemption["RecentMileage"] =
        editresponse.contractinfo.RecentMileage;
      if (checknull(editresponse.customereinfo) !== "") {
        services_redemption["customer_name"] =
          editresponse.customereinfo.CustomerFName +
          "" +
          editresponse.customereinfo.CustomerLName;
      }
      services_redemption["PlanDescription"] = editresponse.PlanDescription;
      setServiceredmptiondata(services_redemption);

      setCountries(editresponse.Countries);
      // set customer info
      setCustomerinfo(editresponse.customereinfo);

      setStates(editresponse.States);
      setContractinfo(editresponse.contractinfo);
      setIsResellContract(editresponse.isResellContract);
      setBanklist(editresponse.Banks);
      setModel(editresponse.SpecifixModels);
      formik.setValues(checknull(editresponse.customereinfo));
      formik.setValues(checknull(editresponse.contractinfo));
      formik.setFieldValue("contractno", editresponse.contractinfo.ContractNo);
      formik.setFieldValue("OldPlanID", editresponse.contractinfo.PlanID);
      formik.setFieldValue(
        "new_Contract_Number",
        editresponse.contractinfo.ContractNo
      );
      formik.setFieldValue(
        "CustomerFName",
        editresponse.customereinfo.CustomerFName
      );
      formik.setFieldValue(
        "CustomerLName",
        editresponse.customereinfo.CustomerLName
      );
      formik.setFieldValue(
        "CustomerAddressLine1",
        editresponse.customereinfo.CustomerAddressLine1
      );
      formik.setFieldValue("PhoneHome", editresponse.customereinfo.PhoneHome);
      formik.setFieldValue("CityName", editresponse.customereinfo.CityName);
      formik.setFieldValue("StateID", editresponse.customereinfo.StateID);
      formik.setFieldValue("CountryID", editresponse.customereinfo.CountryID);
      formik.setFieldValue(
        "CustomerZIP",
        editresponse.customereinfo.CustomerZIP
      );
      formik.setFieldValue(
        "PrimaryEmail",
        editresponse.customereinfo.PrimaryEmail
      );
      formik.setFieldValue(
        "CustomerDOB",
        moment(editresponse.customereinfo.CustomerCustomerDOB).format(
          "YYYY-MM-DD"
        )
      );
      formik.setFieldValue("SendEmail", +editresponse.customereinfo.SendEmail);
      formik.setFieldValue("SendSms", +editresponse.customereinfo.SendSms);
      formik.setFieldValue("contractno", editresponse.contractinfo.ContractNo);
      formik.setFieldValue(
        "Mileage",
        editresponse.contractinfo.ContractMileage
      );
      formik.setFieldValue("SellingRep", editresponse.contractinfo.FIManagerID);

      formik.setFieldValue(
        "isServiceContract",
        editresponse.contractinfo.isServiceContract === "YES" ? true : false
      );
      formik.setFieldValue(
        "ValidityDate",
        moment.unix(editresponse.contractinfo.ValidityDate).format("MM/DD/YYYY")
      );
      formik.setFieldValue(
        "SaleDate",
        moment.unix(editresponse.contractinfo.SaleDate).format("YYYY-MM-DD")
      );
      formik.setFieldValue("password", editresponse.getuserbyid.pass);
      formik.setFieldValue("Loyalty_Map", editresponse.contractinfo.ContractNo);
      formik.setFieldValue(
        "LoyaltyCashEnable",
        editresponse.contractinfo.ContractNo
      );
      formik.setFieldValue(
        "EnableWelcomeSms",
        editresponse.contractinfo.ContractNo
      );
      setBankaddress(editresponse.contractinfo.LienholderAddress);

      //static data
      staticdata.push({
        batch: editresponse.BatchNo,
        dealertitle: editresponse.DealerTitle,
        ValidityDate: editresponse.contractinfo.ValidityDate,
        ValidityMileage: editresponse.contractinfo.ValidityMileage,
        UnlimitedMileage: +editresponse.contractinfo.UnlimitedMileage,
        UnlimiatedTerm: +editresponse.contractinfo.UnlimiatedTerm,
        ContractNo: editresponse.contractinfo.ContractNo,
      });
      setHeaddata(staticdata);
    }
    setLoading(false);

    //get last message from local storgae
    if (
      checknull(lastmessage) &&
      lastmessage != "" &&
      typeof lastmessage != "undefined"
    ) {
      addToast(lastmessage, {
        appearance: "success",
        autoDismiss: true,
      });
      localStorage.removeItem("contract");
    }
  }
  const f_defaultplan = async () => {
    if (
      typeof selecteddealer != "undefined" &&
      typeof formik.values.PlanID != ""
    ) {
      setUser_defaultplan(!user_defaultplan);
      const formdata = new FormData();
      formdata.append("DealerID", selecteddealer);
      formdata.append("PlanID", formik.values.PlanID);
      formdata.append("isDefault", user_defaultplan ? 0 : 1);
      const setdefaultplanforuser_response = await setdefaultplanforuser(
        formdata
      );
    } else {
      addToast("Select Dealership and plan", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const f_defaultsalerep = async () => {
    if (
      typeof selecteddealer != "undefined" &&
      typeof formik.values.SellingRep != ""
    ) {
      setUser_salerep(!user_salerep);
      const formdata = new FormData();
      formdata.append("DealerID", selecteddealer);
      formdata.append("FIManagerID", formik.values.SellingRep);
      formdata.append("isDefault", user_salerep ? 0 : 1);
      const setdefaultsalerepforuser_response = await setdefaultsalerepforuser(
        formdata
      );
    } else {
      addToast("Select Dealership and Selling rep", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const functionLoyaltyCash = () => {
    setLoyaltycashshow(!loyaltycashshow);
  };
  const f_vinhistory = () => {
    setVinhistory(!vinhistory);
  };
  const f_vehicalhistory = () => {
    setCardetail(!cardetail);
  };
  const f_customerlog = () => {
    setCustomer_call_log(!customer_call_log);
  };
  const f_cusotmer_wallet = () => {
    SetCustomer_wallet(!customer_wallet);
  };
  const handlecontractno = (e) => {
    if (e.target.value !== formik.values.contractno) {
      formik.setFieldValue("contractno", e.target.value);

      formik.setFieldValue("new_Contract_Number", e.target.value);
    }
  };
  // re send emial contract
  const resendemailcontract = async (useremail) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("UserEmail", useremail);
    formdata.append("ContractID", ContractID);
    const resendemailcontract_response = await emailcustomercontract(formdata);
    setLoading(false);
    if (resendemailcontract_response.success === 1) {
      addToast(resendemailcontract_response.message, {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };
  return (
    <React.Fragment>
      <PreLoad loading={loading} />
      {loyaltycashshow && (
        <LoyaltyCash
          show={true}
          handlemodalloyalty={functionLoyaltyCash}
          contractid={ContractID}
        />
      )}
      {vinhistory && (
        <VinHistory f_vinhistory={f_vinhistory} contractid={ContractID} />
      )}

      {cardetail && (
        <VehicleDetail
          contractid={ContractID}
          contractno={formik.values.contractno}
          VIN={formik.values.VIN}
          CarfaxVin={checknull(formik.values.CarfaxVin)}
          f_vehicalhistory={f_vehicalhistory}
        />
      )}

      {customer_wallet && (
        <CustomerWallet
          f_cusotmer_wallet={f_cusotmer_wallet}
          contractid={ContractID}
        />
      )}
      {customer_call_log && (
        <CustomerCallLog
          contractid={ContractID}
          f_customerlog={f_customerlog}
        />
      )}
      {checknull(formik.values.BankId) !== "" ? (
        <AddBankAddress
          show={bankaddressmodal}
          BankID={checknull(formik.values.BankId)}
          handlebankaddress={handlebankaddress}
          DealerID={selecteddealer}
        />
      ) : (
        ""
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box" style={{ padding: 0 }}>
              <div className="float-right">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">MyPCP</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Account</a>
                  </li>
                  <li className="breadcrumb-item active">Contract Details</li>
                </ol>
              </div>
              {/* <h4 className="page-title">Contract Details</h4> */}
              <RedemptionHead data={headdata} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <Tabs
                  defaultActiveKey={
                    checknull(lastmessage) &&
                    lastmessage != "" &&
                    typeof lastmessage != "undefined"
                      ? "redeem"
                      : "fill_contract"
                  }
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="fill_contract" title="Fill Contract">
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-12 ">
                        <h5>Contact Info</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Customer First Name*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CustomerFName"
                                style={{ textTransform: "uppercase" }}
                                value={formik.values.CustomerFName}
                                onChange={formik.handleChange}
                                required={true}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Customer Last Name*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CustomerLName"
                                style={{ textTransform: "uppercase" }}
                                required={true}
                                value={formik.values.CustomerLName}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Street Address*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CustomerAddressLine1"
                                style={{ textTransform: "uppercase" }}
                                required={true}
                                value={formik.values.CustomerAddressLine1}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="useremail">Country*</label>
                              <select
                                onChange={(e) =>
                                  getstates(e, formik.setFieldValue)
                                }
                                value={formik.values.CountryID}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                name="CountryID"
                              >
                                <option value={0}>Select option</option>
                                {Countries != "" &&
                                typeof Countries != "undefined"
                                  ? Countries.map((item, i) => (
                                      <option
                                        id={item.id}
                                        key={i + 1}
                                        value={item.id}
                                      >
                                        {item.nicename}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>City*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CityName"
                                style={{ textTransform: "uppercase" }}
                                value={formik.values.CityName}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="State_Providence">
                                State/Providence*
                              </label>
                              <select
                                defaultValue={0}
                                name="StateID"
                                value={formik.values.StateID}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                              >
                                <option key={-1} value={0}>
                                  Select Option
                                </option>
                                {states != ""
                                  ? states.map((item, i) => (
                                      <option key={i} value={item.StateID}>
                                        {item.StateTitle}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                              {formik.errors.StateID &&
                                formik.touched.StateID && (
                                  <div className="input-feedback">
                                    {formik.errors.StateID}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email Address*</label>
                              <input
                                type="Email"
                                className="form-control"
                                name="PrimaryEmail"
                                required={true}
                                value={formik.values.PrimaryEmail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.errors.PrimaryEmail &&
                                formik.touched.PrimaryEmail && (
                                  <div className="input-feedback">
                                    {formik.errors.PrimaryEmail}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="zipcode">Zipcode*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CustomerZIP"
                                required={true}
                                value={formik.values.CustomerZIP}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Phone #*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="PhoneHome"
                                required={true}
                                onBlur={formik.handleBlur}
                                value={formik.values.PhoneHome}
                                onChange={formik.handleChange}
                              />
                            </div>

                            {formik.errors.PhoneHome &&
                              formik.touched.PhoneHome && (
                                <div className="input-feedback">
                                  {formik.errors.PhoneHome}
                                </div>
                              )}
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="useremail">Date of Birth</label>
                              <input
                                type="date"
                                className="form-control"
                                name="CustomerDOB"
                                value={formik.values.CustomerDOB}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="my-2">
                              <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox
                                    aria-label="Checkbox for following text input"
                                    name="SendEmail"
                                    value={
                                      +formik.values.SendEmail ? false : true
                                    }
                                    checked={
                                      +formik.values.SendEmail === 1
                                        ? true
                                        : false
                                    }
                                    onChange={formik.handleChange}
                                  />
                                </InputGroup.Prepend>
                                <FormControl
                                  placeholder="Opt out for Smart Marketing"
                                  aria-label="Text input with checkbox"
                                  disabled={true}
                                />
                              </InputGroup>
                            </div>
                            <div className="my-2">
                              <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox
                                    aria-label="Checkbox for following text input"
                                    name="SendSms"
                                    value={
                                      +formik.values.SendSms ? false : true
                                    }
                                    checked={
                                      +formik.values.SendSms === 1
                                        ? true
                                        : false
                                    }
                                    onChange={formik.handleChange}
                                  />
                                </InputGroup.Prepend>
                                <FormControl
                                  placeholder=" Opt out customer from SMS Marketing"
                                  aria-label="Text input with checkbox"
                                  disabled={true}
                                />
                              </InputGroup>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-8">
                            <InputGroup
                              className="mb-3"
                              style={{ position: "relative" }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  aria-label="Checkbox for following text input"
                                  name="HideTestContract"
                                  value={
                                    +formik.values.HideTestContract
                                      ? false
                                      : true
                                  }
                                  checked={
                                    +formik.values.HideTestContract === 1
                                      ? true
                                      : false
                                  }
                                  onChange={formik.handleChange}
                                />
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Hide Test Contract from Remittance and Marketplace history "
                                aria-label="Text input with checkbox"
                                disabled={true}
                              />
                              <img
                                className="img-thumnail opt-ghost-image"
                                width="24"
                                src={
                                  require(`../../../resources/images/remitance.png`)
                                    .default
                                }
                                alt="Remittance"
                              />
                            </InputGroup>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6 col-sm-12 ">
                        <h5>Plan & Vehicle Info</h5>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Select Account</label>
                              <DealersDropdown
                                id="contract_dealer_dropdown"
                                deafultvalue={selecteddealer}
                                disabled={
                                  typeof serviceredmptiondata.redeemcontract !=
                                    "undefined" &&
                                  serviceredmptiondata.redeemcontract != ""
                                    ? true
                                    : false
                                }
                                handledealerchange={handledealerchange}
                              />
                            </div>
                            {formik.errors.DealerID &&
                              formik.touched.DealerID && (
                                <div className="input-feedback">
                                  {formik.errors.DealerID}
                                </div>
                              )}
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>VIN*</label>
                              <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                  <button
                                    type="button"
                                    onClick={functionverifyvin}
                                    className="vin-button"
                                  ></button>
                                </InputGroup.Prepend>
                                <FormControl
                                  name="VIN"
                                  value={formik.values.VIN}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              {formik.errors.VIN && formik.touched.VIN && (
                                <div className="input-feedback">
                                  {formik.errors.VIN}
                                </div>
                              )}
                            </div>

                            <div className="form-group">
                              <label>Make*</label>
                              <select
                                onChange={(e) => {
                                  getmodel(e, formik.setFieldValue);
                                }}
                                onBlur={formik.handleBlur}
                                name="MakeID"
                                value={formik.values.MakeID}
                                required={true}
                                className="form-control"
                              >
                                <option key={0} value={0}>
                                  Select option
                                </option>
                                {dealerdata != ""
                                  ? dealerdata.Makes.map((item, i) => (
                                      <option key={i + 1} value={item.MakeID}>
                                        {item.Make}
                                      </option>
                                    ))
                                  : ""}
                              </select>

                              {formik.errors.MakeID &&
                                formik.touched.MakeID && (
                                  <div className="input-feedback">
                                    {formik.errors.MakeID}
                                  </div>
                                )}
                            </div>
                            <div className="form-group">
                              <label>Model*</label>
                              <select
                                name="ModelID"
                                value={formik.values.ModelID}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                              >
                                <option>Select option</option>
                                {model != "" && model != "undefined"
                                  ? model.map((item, i) => (
                                      <option key={i + 1} value={item.ModelID}>
                                        {item.Model}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                              {formik.errors.ModelID &&
                                formik.touched.ModelID && (
                                  <div className="input-feedback">
                                    {formik.errors.ModelID}
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <button
                                    type="button"
                                    onClick={handlemodal}
                                    className="btn btn-success btn-square waves-effect waves-light"
                                    style={{ MarginTop: -14 }}
                                  >
                                    Add Model
                                  </button>
                                  <AddModel
                                    show={showmodal}
                                    handlemodal={handlemodal}
                                    DealerID={selecteddealer}
                                    MakeID={formik.values.MakeID}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Year*</label>
                                  <select
                                    onChange={formik.handleChange}
                                    value={formik.values.VehYear}
                                    onBlur={formik.handleBlur}
                                    className="form-control"
                                    name="VehYear"
                                  >
                                    <option value={0}>Select option</option>

                                    {yeardata != ""
                                      ? yeardata.map((item) => (
                                          <option
                                            key={item.year}
                                            value={item.year}
                                          >
                                            {item.year}
                                          </option>
                                        ))
                                      : ""}
                                  </select>
                                  {formik.errors.VehYear &&
                                    formik.touched.VehYear && (
                                      <div className="input-feedback">
                                        {formik.errors.VehYear}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Vehicle Type</label>
                              <select
                                name="VehicleType"
                                value={formik.values.VehicleType}
                                onChange={formik.handleChange}
                                className="form-control"
                              >
                                <option key={1} value="N">
                                  New Vehicle
                                </option>
                                <option key={2} value="O">
                                  Old Vehicle
                                </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Stock/Deal #</label>
                              <input
                                type="text"
                                className="form-control"
                                name="StockDealNo"
                                required={
                                  dealerdata != ""
                                    ? dealerdata.dealersetting.StockDealNo
                                    : false
                                }
                                value={formik.values.StockDealNo}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label>RO #</label>
                              <input
                                type="text"
                                className="form-control"
                                name="RO"
                                value={formik.values.RO}
                                onChange={formik.handleChange}
                              />
                            </div>
                            {plancost != "" &&
                            plancost.PlanCostAndExpiry.IsAutoReedem == 1 ? (
                              <div className="form-group">
                                <label>Auto Redeem ro number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="RO_NUMBER"
                                  value={formik.values.RO_NUMBER}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="contractno">Contract No</label>
                              <div className="input-group input-group-lg">
                                <InputGroup className="m-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="contractno"
                                    onChange={(e) => handlecontractno(e)}
                                    value={formik.values.contractno}
                                  />

                                  <InputGroup.Text id="basic-addon2">
                                    <i
                                      onClick={updateClipboard}
                                      style={{ cursor: "pointer" }}
                                      className="fas fa-copy"
                                    ></i>
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="my-2">
                                <label htmlFor="PlanID">Plan*</label>
                                <InputGroup className="mb-3">
                                  <select
                                    disabled={
                                      typeof serviceredmptiondata.redeemcontract !=
                                        "undefined" &&
                                      serviceredmptiondata.redeemcontract != ""
                                        ? true
                                        : false
                                    }
                                    id="PlanID"
                                    name="PlanID"
                                    value={formik.values.PlanID}
                                    onChange={(e) =>
                                      handleplanchange(
                                        e.target.value,
                                        formik.setFieldValue
                                      )
                                    }
                                    className="form-control"
                                  >
                                    <option value={""}>Select option</option>
                                    {dealerdata != ""
                                      ? dealerdata.Plans.map((item, i) => (
                                          <option key={i} value={item.PlanID}>
                                            {item.PlanDescription}
                                          </option>
                                        ))
                                      : ""}
                                  </select>

                                  <InputGroup.Prepend>
                                    <InputGroup.Checkbox
                                      aria-label="Checkbox for following text input"
                                      name="user_defaultplan"
                                      checked={user_defaultplan}
                                      onChange={f_defaultplan}
                                      disabled={
                                        typeof serviceredmptiondata.redeemcontract !=
                                          "undefined" &&
                                        serviceredmptiondata.redeemcontract !=
                                          ""
                                          ? true
                                          : false
                                      }
                                    />
                                  </InputGroup.Prepend>
                                </InputGroup>
                                {formik.errors.PlanID &&
                                  formik.touched.PlanID && (
                                    <div className="input-feedback">
                                      {formik.errors.PlanID}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="my-2">
                                <label htmlFor="SellingRep">
                                  Product Selling Rep*
                                </label>
                                <InputGroup className="mb-3">
                                  <select
                                    disabled={
                                      typeof serviceredmptiondata.redeemcontract !=
                                        "undefined" &&
                                      serviceredmptiondata.redeemcontract != ""
                                        ? true
                                        : false
                                    }
                                    name="SellingRep"
                                    value={formik.values.SellingRep}
                                    onChange={formik.handleChange}
                                    required={true}
                                    className="form-control"
                                    onBlur={formik.handleBlur}
                                  >
                                    <option key={0} value={0}>
                                      Select option
                                    </option>
                                    {dealerdata != ""
                                      ? dealerdata.ProductSellingReps.map(
                                          (item, i) => (
                                            <option
                                              key={i}
                                              value={item.FIManagerID}
                                            >
                                              {item.FIManagerName}
                                            </option>
                                          )
                                        )
                                      : ""}
                                  </select>
                                  <InputGroup.Prepend>
                                    <InputGroup.Checkbox
                                      aria-label="Checkbox for following text input"
                                      name="user_salerep"
                                      disabled={
                                        typeof serviceredmptiondata.redeemcontract !=
                                          "undefined" &&
                                        serviceredmptiondata.redeemcontract !=
                                          ""
                                          ? true
                                          : false
                                      }
                                      checked={user_salerep ? true : false}
                                      onChange={f_defaultsalerep}
                                    />
                                  </InputGroup.Prepend>
                                </InputGroup>
                                {formik.errors.SellingRep &&
                                  formik.touched.SellingRep && (
                                    <div className="input-feedback">
                                      {formik.errors.SellingRep}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div
                              className="form-group"
                              style={{
                                position: "relative",
                              }}
                            >
                              <label>Password</label>
                              <input
                                type={passwordShown ? "text" : "password"}
                                disabled={true}
                                className="form-control"
                                name="password"
                                value={formik.values.password}
                              />

                              {passwordShown ? (
                                <i
                                  className="fa fa-eye fa-1x"
                                  onClick={togglePasswordVisiblity}
                                  style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    top: "60%",
                                    right: "3%",
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash fa-1x"
                                  onClick={togglePasswordVisiblity}
                                  style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    top: "60%",
                                    right: "3%",
                                  }}
                                ></i>
                              )}
                            </div>
                            <div className="form-group">
                              <label>Sales Person*</label>
                              <select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="SIManagerID"
                                value={formik.values.SIManagerID}
                                required={true}
                                className="form-control"
                              >
                                <option key={0} value={0}>
                                  Select option
                                </option>
                                {dealerdata != ""
                                  ? dealerdata.SalesSellingReps.map(
                                      (item, i) => (
                                        <option
                                          key={i + 1}
                                          value={item.FIManagerID}
                                        >
                                          {item.FIManagerName}
                                        </option>
                                      )
                                    )
                                  : ""}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Mileage</label>
                              <input
                                type="text"
                                className="form-control"
                                name="Mileage"
                                value={formik.values.Mileage}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label>Sale Date*</label>
                              <input
                                type="date"
                                className="form-control"
                                name="SaleDate"
                                id="SaleDate"
                                required={true}
                                value={formik.values.SaleDate}
                                onChange={(e) =>
                                  getplanexpiry(e, formik.setFieldValue)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Expiration Date*</label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="ValidityDate"
                                value={formik.values.ValidityDate}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12"></div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <button
                              type="submit"
                              disabled={formik.isSubmitting}
                              style={{ float: "right" }}
                              className="btn btn-success btn-square waves-effect waves-light"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="price_print" title="Price Print">
                    <div className="tab-content">
                      <div
                        className="tab-pane p-3 active"
                        id="#price_print"
                        role="tabpanel"
                      >
                        <div>
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                              <h5>Contract Price Info</h5>
                              <div className="row">
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Price on printed Contract*
                                        </label>

                                        <input
                                          type="text"
                                          className="form-control"
                                          name="ContractTotalCost"
                                          value={
                                            formik.values.ContractTotalCost
                                          }
                                          disabled={
                                            dealerdata != "" &&
                                            dealerdata.dealersetting
                                              .SellingpriceOverride
                                              ? true
                                              : false
                                          }
                                          onChange={formik.handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      {newbank ? (
                                        <div className="form-group">
                                          <label htmlFor="useremail">
                                            Bank*
                                          </label>
                                          <select
                                            onChange={getBankAddress}
                                            required={
                                              dealerdata != "" &&
                                              dealerdata.dealersetting
                                                .BankRequired
                                                ? true
                                                : false
                                            }
                                            value={formik.values.BankId}
                                            name="BankId"
                                            className="form-control"
                                          >
                                            <option>Select option</option>
                                            {banklist != "" &&
                                            typeof banklist != "undefined"
                                              ? banklist.map((item, i) => (
                                                  <option
                                                    key={i + 1}
                                                    value={item.BankID}
                                                    city={item.CityName}
                                                    statetitle={item.StateTitle}
                                                    statecode={item.StateCode}
                                                  >
                                                    {item.BankName}
                                                  </option>
                                                ))
                                              : ""}
                                          </select>
                                        </div>
                                      ) : (
                                        <div
                                          className="form-group"
                                          style={{ position: "relative" }}
                                        >
                                          <label>Bank Name*</label>
                                          <FormControl
                                            name="AddNewbank"
                                            value={formik.values.AddNewbank}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            aria-describedby="basic-addon1"
                                          />

                                          <button
                                            type="button"
                                            onClick={functionaddnewbank}
                                            className="btn btn-info btn-xs add-new-bank"
                                          >
                                            save
                                          </button>

                                          {formik.errors.AddNewbank &&
                                            formik.touched.AddNewbank && (
                                              <div className="input-feedback">
                                                {formik.errors.AddNewbank}
                                              </div>
                                            )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6"></div>{" "}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Address</label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          value={bankaddress}
                                          className="form-control"
                                          name="LienholderAddress"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Customer Price</label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          name="CustomerPrice"
                                          className="form-control"
                                          value={formik.values.CustomerPrice}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <button
                                          type="button"
                                          className="btn btn-success btn-square waves-effect waves-light ml-2"
                                          onClick={() => setNewbank(!newbank)}
                                        >
                                          {newbank ? "Add Bank" : "List Bank"}
                                        </button>

                                        <button
                                          type="button"
                                          className="btn btn-success btn-square waves-effect waves-light ml-2"
                                          onClick={handlebankaddress}
                                        >
                                          Add Address
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Plan Price</label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          name="EnteredCost"
                                          className="form-control"
                                          value={formik.values.EnteredCost}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Hide Price</label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          name="HidePrice"
                                          className="form-control"
                                          value={formik.values.HidePrice}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <div className="checkbox checkbox-primary">
                                          <input
                                            id="isServiceContract"
                                            type="checkbox"
                                            name="isServiceContract"
                                            onChange={formik.handleChange}
                                            value={
                                              formik.values.isServiceContract
                                                ? true
                                                : false
                                            }
                                            checked={
                                              formik.values.isServiceContract
                                                ? true
                                                : false
                                            }
                                          />

                                          <label htmlFor="isServiceContract">
                                            Service Contract
                                          </label>
                                        </div>
                                        {formik.values.isServiceContract ? (
                                          <div className="my-2">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                              <Form.Control
                                                as="textarea"
                                                rows={2}
                                                name="ServiceContract"
                                                onChange={formik.handleChange}
                                                value={checknull(
                                                  checknull(
                                                    formik.values
                                                      .ServiceContract
                                                  )
                                                )}
                                                placeholder="Enter Your Contract Term & Condition Here"
                                              />
                                            </Form.Group>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {dealerdata != "" &&
                                        dealerdata.dealersetting
                                          .EnableWelcomeSms ? (
                                          <div className="my-2">
                                            <div className="checkbox checkbox-primary">
                                              <input
                                                id="EnableWelcomeSms"
                                                type="checkbox"
                                                name="EnableWelcomeSms"
                                                onChange={formik.handleChange}
                                                value={
                                                  +formik.values
                                                    .EnableWelcomeSms === 1
                                                    ? false
                                                    : true
                                                }
                                                checked={
                                                  +formik.values
                                                    .EnableWelcomeSms === 1
                                                    ? true
                                                    : false
                                                }
                                              />

                                              <label htmlFor="EnableWelcomeSms">
                                                Send Welcome SMS
                                              </label>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        {dealerdata != "" &&
                                        dealerdata.dealersetting
                                          .EnableLoyaltyCash ? (
                                          <div className="my-2">
                                            <div className="checkbox checkbox-primary">
                                              <input
                                                id="Loyalty_Map"
                                                type="checkbox"
                                                name="Loyalty_Map"
                                                onChange={formik.handleChange}
                                                value={
                                                  +formik.values.Loyalty_Map
                                                    ? false
                                                    : true
                                                }
                                                checked={
                                                  +formik.values.Loyalty_Map ===
                                                  1
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label htmlFor="Loyalty_Map">
                                                Enroll in Loyalty Cash
                                              </label>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {formik.values.Loyalty_Map ? (
                                          <div className="my-2">
                                            <div className="checkbox checkbox-primary">
                                              <input
                                                id="LoyaltyCashEnable"
                                                type="checkbox"
                                                onChange={formik.handleChange}
                                                value={
                                                  +formik.values
                                                    .LoyaltyCashEnable
                                                    ? false
                                                    : true
                                                }
                                                checked={
                                                  +formik.values
                                                    .LoyaltyCashEnable === 1
                                                    ? true
                                                    : false
                                                }
                                                name="LoyaltyCashEnable"
                                              />
                                              <label htmlFor="LoyaltyCashEnable">
                                                Loyalty Points Vehicle Sale
                                                Bonus
                                              </label>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6 col-lg-6 col-sm-12">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label>Email Address*</label>
                                      <div style={{ display: "flex" }}>
                                        <input
                                          className="col-md-8 form-control"
                                          type="Email"
                                          name="PrimaryEmail"
                                          required={true}
                                          value={formik.values.PrimaryEmail}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        />
                                        <button
                                          type="button"
                                          onClick={() =>
                                            resendemailcontract(
                                              formik.values.PrimaryEmail
                                            )
                                          }
                                          className="btn btn-success btn-square waves-effect waves-light ml-4"
                                        >
                                          Email info
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-5">
                                    <div className="col-md-12">
                                      <a
                                        target="_blank"
                                        href={`${process.env.REACT_APP_APP_URL}/contract/printcontract?Contract_Id=${ContractID}`}
                                      >
                                        <img
                                          src={
                                            require("../../../resources/img/print.png")
                                              .default
                                          }
                                          height="40"
                                          alt="print-img"
                                        />
                                      </a>
                                      {/* <button
                                        type="button"
                                        className="btn btn-success btn-square waves-effect waves-light ml-2"
                                      >
                                        Print Contract
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={() =>
                                          window.open(
                                            process.env.REACT_APP_APP_URL +
                                              `/contract/printcustomerletter?Contract_Id=${ContractID}`,
                                            "_blank",
                                            "location=yes,height=1000,width=1000,scrollbars=yes,status=yes"
                                          )
                                        }
                                        className="btn btn-success btn-square waves-effect waves-light ml-2"
                                      >
                                        Print Letter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="redeem" title="Services/Redemption">
                    <div
                      className="tab-pane p-3 active"
                      style={{ paddingTop: "8px !important" }}
                      id="#price_print"
                      role="tabpanel"
                    >
                      {closeresellcontract && (
                        <ResellContract
                          closeresell={closeresell}
                          isResellContract={isResellContract}
                          contractinfo={contractinfo}
                          getDealerByID={serviceredmptiondata.getDealerByID}
                        />
                      )}
                      <ServiceRedmption data={serviceredmptiondata} />
                    </div>
                  </Tab>
                </Tabs>

                <div className="right-icons">
                  <img
                    className="img-icon"
                    style={{ width: 150 }}
                    src={
                      require(`../../../resources/images/carfax-icon.png`)
                        .default
                    }
                    onClick={f_vehicalhistory}
                    alt="car-fax"
                  />
                  {typeof dealerdata.dealersetting != "undefined" &&
                  dealerdata.dealersetting.EnableWalletBucks ? (
                    <img
                      className="img-icon"
                      onClick={f_cusotmer_wallet}
                      src={
                        require(`../../../resources/images/wallet-bucks-icon.png`)
                          .default
                      }
                      alt="wallet"
                    />
                  ) : (
                    ""
                  )}
                  {typeof dealerdata.getDealerByID != "undefined" &&
                  dealerdata.getDealerByID.EnableLoyaltyCash == 1 ? (
                    <img
                      onClick={functionLoyaltyCash}
                      className="img-icon"
                      alt="xp"
                      src={require(`../../../resources/images/xp.png`).default}
                    />
                  ) : (
                    ""
                  )}
                  <img
                    onClick={f_customerlog}
                    className="img-icon"
                    src={
                      require(`../../../resources/images/customer-call-log-button.png`)
                        .default
                    }
                    alt="call-log"
                  />
                  <img
                    className="img-icon"
                    src={
                      require(`../../../resources/images/renew-contract-button.png`)
                        .default
                    }
                    alt="renew-contract"
                  />
                  <img
                    onClick={f_vinhistory}
                    className="img-icon"
                    src={
                      require(`../../../resources/images/make-vin-change-history.png`)
                        .default
                    }
                    alt="history"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
