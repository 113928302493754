import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useToken from "../useToken";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
require("dotenv").config();
export const SigninWithToken = () => {
  const { token, setToken } = useToken();
  const { usertoken } = useParams();
  useEffect(() => {
    getuserdata();
  }, [usertoken]);
  async function getuserdata() {
    $.ajax({
      headers: {
        Authorization: `${usertoken}`,
      },
      url: process.env.REACT_APP_APP_URL + "/loginreactcopy/getuserinfo",
      type: "post",
      data: {},
      dataType: "json",
      success: function (user_data_res) {
        if (user_data_res.success === 1) {
          localStorage.setItem("user", JSON.stringify(user_data_res));
          localStorage.setItem("user_id", JSON.stringify(uuidv4()));
          setToken(usertoken);
          console.log("redi");
          window.location.href =
            "https://modern.mypcp.us/contracts/summary/";
        } else {
          localStorage.setItem("user", "1");
          localStorage.setItem("token", "1");
          window.location.href = "https://modern.mypcp.us";
        }
      },
    });
  }
  return <React.Fragment></React.Fragment>;
};
