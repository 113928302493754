import { React, useState, useEffect } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import PreLoad from "../../components/PreLoad";
import { formatbankaddress } from "../../components/helpers";
import { user_id, role_id, pcp_user_id } from "../../CurrentUser";
import { checknull } from "../../components/helpers";
require("dotenv").config();
export default function AddBankAddress(props) {
  const { show, handlebankaddress, DealerID, BankID } = props;
  const [loading, setLoading] = useState(false);
  const [dealersbanks, setDealersbanks] = useState("");
  const [bankaddressdata, setBankaddressdata] = useState([]);

  const validationSchema = Yup.object().shape({
    lienholder_city: Yup.string().required("Required"),

    StateCode: Yup.string().required("Required"),
  });
  const useformik = useFormik({
    initialValues: {
      lienholder_city: "",
      StateCode: "",
      StateTitle: "",
      BankID: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      setLoading(true);
      data.user_id = 1;
      data.pcp_user_id = 1;
      data.role_id = 1;
      data.DealerID = DealerID;

      const formData = new FormData();
      Object.keys(data).forEach((key) => formData.append(key, data[key]));

      await axios
        .post(
          process.env.REACT_APP_APP_URL + "/contract/savelienholderaddress",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === 1) {
            let address = formatbankaddress(
              data.lienholder_city,
              data.StateTitle,
              data.StateCode
            );
            props.handlebankaddress("", response.data.Banks, address);
            useformik.resetForm();
          }
          setLoading(false);
        });
    },
  });
  const handleClose = () => {
    handlebankaddress();
    useformik.resetForm();
  };
  useEffect(() => {
    if (BankID !== "" && DealerID !== "") {
      getdata();
    }
  }, [BankID]);
  const deletelineaddress = async (LienHolderID) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", DealerID);
    formData.append("BankID", BankID);
    formData.append("LienHolderID", LienHolderID);
    await axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/deletelienholderaddress",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === 1) {
          setDealersbanks(response.data.dealersbanks);
        }
        setLoading(false);
      });
  };
  const handleusedata = (item) => {
    useformik.setFieldValue("lienholder_city", item.CityName);
    useformik.setFieldValue("StateCode", item.StateCode);
    useformik.setFieldValue("StateTitle", item.StateTitle);
  };
  const handlestate = (e) => {
    var selectedOption = e.target.selectedOptions[0];
    useformik.setFieldValue("StateCode", selectedOption.value);
    useformik.setFieldValue("StateTitle", selectedOption.text);
  };
  function getdata() {
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", DealerID);
    formData.append("BankID", BankID);
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/lienholderaddress",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == 1) {
          setBankaddressdata(checknull(response.data));
          useformik.setFieldValue("BankID", +response.data.Bank.BankID);
          setDealersbanks(response.data.dealersbanks);
        }
      });
  }
  return (
    <div>
      <PreLoad loading={loading} />
      <Modal scrollable={true} size={"lg"} show={show}>
        <Modal.Header>
          <Modal.Title>ADD BANK ADDRESS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form onSubmit={useformik.handleSubmit} style={{ width: "100%" }}>
              <div className="col-md-12">
                <div className="form-group">
                  <label>BANK SELECTED</label>
                  <input
                    type="text"
                    disabled={true}
                    value={""}
                    className="form-control"
                    name="bank_id"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    placeholder="City Name"
                    className="form-control"
                    name="lienholder_city"
                    value={useformik.values.lienholder_city}
                    onChange={useformik.handleChange}
                    onBlur={useformik.handleBlur}
                  />
                </div>
                {useformik.errors.lienholder_city &&
                  useformik.touched.lienholder_city && (
                    <div className="input-feedback">
                      {useformik.errors.lienholder_city}
                    </div>
                  )}
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="State_Providence">State</label>
                  <select
                    onChange={handlestate}
                    value={useformik.values.StateCode}
                    name="StateCode"
                    onBlur={useformik.handleBlur}
                    className="form-control"
                  >
                    <option key={-1} value={0}>
                      Select Option
                    </option>
                    {typeof bankaddressdata.List_States !== "undefined" &&
                    bankaddressdata.List_States !== ""
                      ? bankaddressdata.List_States.map((item, i) => (
                          <option
                            key={i}
                            data={item.StateTitle}
                            value={item.StateCode}
                          >
                            {item.StateTitle}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                {useformik.errors.StateCode && useformik.touched.StateCode && (
                  <div className="input-feedback">
                    {useformik.errors.StateCode}
                  </div>
                )}
              </div>
              <Button
                variant="primary"
                type="submit"
                style={{ float: "right" }}
                disabled={useformik.isSubmitting}
              >
                Continue
              </Button>
            </form>
          </div>

          <div className="row">
            <div className="col-xs-12 text-center">
              <h6>Previously Selected Addresses</h6>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Bank Name</th>
                  <th>Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {typeof dealersbanks !== "undeifned" && dealersbanks !== ""
                  ? dealersbanks.map((item, i) => (
                      <tr key={i}>
                        <td>{item.BankName}</td>
                        <td>
                          {item.CityName} , {item.StateTitle} - {item.StateCode}{" "}
                        </td>
                        <td>
                          <a
                            className="btn btn-info btn-xs"
                            href="#"
                            onClick={() => handleusedata(item)}
                          >
                            use
                          </a>
                          &nbsp;
                          <a
                            href="#"
                            onClick={() => deletelineaddress(item.LienHolderID)}
                            className="btn btn-danger btn-xs deleteconfirm"
                          >
                            <i className="far fa-trash-alt"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
