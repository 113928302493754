import React, { useState, useRef } from "react";
import { useToasts } from "react-toast-notifications";
import { Modal, Button, Spinner } from "react-bootstrap";
import { resendemail } from "./EditContractApi";
export default function OtherMail(props) {
  const [spinner, setSpinner] = useState(false);
  const { addToast } = useToasts();
  const inputmail = useRef(null);
  const { setOther_email_modal, contractinfo } = props;
  const handleClose = () => {
    setOther_email_modal(false);
  };
  const save = async () => {
    setSpinner(true);
    const formdata = new FormData();
    formdata.append("DealerID", contractinfo.DealerID);
    formdata.append("ContractID", contractinfo.ContractID);
    formdata.append("ContractNo", contractinfo.ContractNo);
    formdata.append("CustomerID", contractinfo.CustomerID);
    formdata.append("Email", inputmail.current.value);

    //api
    const resendemail_response = await resendemail(formdata);
    setOther_email_modal(false);
    if (resendemail_response.success === 1) {
      addToast(resendemail_response.message, {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };

  return (
    <div>
      <div>
        <Modal show={true}>
          <Modal.Header>
            <Modal.Title>Gifted Contract Email Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="email"
              required={true}
              name="Email"
              id="Email_GiftedContract"
              className="form-control"
              ref={inputmail}
              placeholder="Enter Email Address Here"
            />
          </Modal.Body>
          <Modal.Footer>
            {spinner ? (
              <Spinner animation="border" variant="info" />
            ) : (
              <React.Fragment>
                <Button variant="secondary" onClick={handleClose}>
                  <i className="fas fa-times"></i> Cancel
                </Button>
                <Button variant="primary" onClick={save}>
                  Send Mail
                </Button>
              </React.Fragment>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
