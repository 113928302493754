import React, { Component } from "react";

import { useSelector, useDispatch } from "react-redux";
import DashboardFilter from "./filterDashboard";
import DashboardReport from "./reportDashboard";
import DashboardSoldGraph from "./soldgraphDashboard";
import Row from "./Row";
import ReserveGraph from "./Reservegraph/ReserveGraph";
import DashboardCustomerInteraction from "./customerInteractionDashboard";
import DashboardPCPBreakDown from "./pcpBreakDownDashboard";
import DashboardOtherDetails from "./otherDetailsDashboard";
import DashboardSalesRetention from "./SalesRetention";
import DashboardUpSell from "./upselldashboard";
import Dashboard30Days from "./dashboard30days";
import DashboardMaturedContract from "./DashboardMaturedContract";
import DashboardGraphContract from "./DashboardGraphContract";
import RetentionMapPreDefined from "./RetentionMapPreDefined";
import { WaveLoading } from "react-loadingg";
import { useToasts } from "react-toast-notifications";
import {
  pcp_user_id,
  role_id,
  user_id,
  token,
  user_uuid,
} from "../CurrentUser";
import {
  reservedgraph,
  dealerredemption,
  pcpbreakdown,
  customerinteraction,
  comparasion30day,
  contractSoldGraph,
  genratenewdashboard,
  retentionmap,
} from "./DashboardApi";
import $ from "jquery";
require("dotenv").config();
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mypwd: "",
      setclasscard: "",
      isloading: "0",
      isloadingRedumtion: "0",
      isLoadingCustomerInteraction: "0",
      isLoadingPCPBreakDown: "0",
      isLoadingOtherDetails: "0",
      isLoadingSalesRetention: "0",
      isLoadingThirtDays: "0",
      isLoadingUpSell: "0",
      UniqueVisit: "0",
      UpsellAmount: "0",
      Servicemultiple: "0",
      isgenratenewdashboardLoading: "0",
      dashboardContract: [],
      dealerredemption: [],
      pcpbreakdown: [],
      contractSoldGraphservices: [],
      contractSoldGraphcontracts: [],
      genratenewdashboardData: [],
      PcpBreakDown: {},
      CurrentThirtDays: {},
      LastThirtDays: {},
      ReservedAmountBreakDown: {},
      Mobileussage: {},
      pcpbreakdown2: [],
      isretentionmapLoading: "0",
      retentionmapData: [],
      DealerID: "",
      fromDate: "",
      toDate: "",
      fixeddateparameter: "",
      apidata: "",
      EnableReserveGraph: 0,
      ReserveGraphdata: "",
      isloadingreservegraph: true,
      heatdistribution: [],
    };
  }
  handledealerchange = (val) => {
    this.setState({ DealerID: val });
  };
  applyfilter = async (e) => {
    e.preventDefault();
    if (this.state.DealerID == "") {
      this.props.addToast("Please Select Dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    let self = this;
    self.setState({ isloading: "1" });
    self.setState({ isloadingRedumtion: "1" });
    self.setState({ isgenratenewdashboardLoading: "1" });
    self.setState({ isLoadingCustomerInteraction: "1" });
    self.setState({ isLoadingPCPBreakDown: "1" });
    self.setState({ isLoadingOtherDetails: "1" });
    self.setState({ isLoadingSalesRetention: "1" });
    self.setState({ isLoadingUpSell: "1" });
    self.setState({ isLoadingThirtDays: "1" });
    self.setState({ isretentionmapLoading: "1" });

    let FomDate = $("#fromDate").val();
    this.setState({ fromDate: FomDate });
    let ToDate = $("#todate").val();
    this.setState({ toDate: ToDate });
    let FixedDateParameter = "YTD";
    FixedDateParameter = "";
    //create uuid and save in local storage

    $(".selectionbtn1").each(function (index, value) {
      if ($(this).css("background-color").toLowerCase() == "rgb(0, 174, 239)") {
        FixedDateParameter = $(this).val();
      }
    });
    this.setState({ fixeddateparameter: FixedDateParameter });
    $.ajax({
      headers: {
        Authorization: `${token}`,
      },
      url: process.env.REACT_APP_APP_URL + "/dashboard/dealersetting",
      type: "post",
      data: {
        pcp_user_id: pcp_user_id,
        role_id: role_id,
        user_id: user_id,
        DealerID: this.state.DealerID,
        FomDate: FomDate,
        ToDate: ToDate,
        FixedDateParameter: FixedDateParameter,
        UUID: user_uuid,
      },
      dataType: "json",
      success: function (adata) {
        self.setState({ isloading: "0" });

        if (adata.success == "1") {
          self.setState({ dashboardContract: adata.dashboardContract });

          $("#dealerimage").attr("src", adata.dealer.DealerLogo);
          if (adata.dealer.xp_enable == "1") {
            $("#xp_points_status").css("filter", "");
            $("#xp_points_status").parent().css("color", "");
          } else {
            $("#xp_points_status").css("filter", "grayscale(100%)");
            $("#xp_points_status").parent().css("color", "rgb(162, 162, 162)");
          }

          if (adata.dealer.EnableClaimProduct == "1") {
            $("#claim_status").css("filter", "");
            $("#claim_status").parent().css("color", "");
          } else {
            $("#claim_status").css("filter", "grayscale(100%)");
            $("#claim_status").parent().css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.EnableLoyaltyCash == "1") {
            $("#loyalty_cash_status").css("filter", "");
            $("#loyalty_cash_status").parent().css("color", "");
          } else {
            $("#loyalty_cash_status").css("filter", "grayscale(100%)");
            $("#loyalty_cash_status")
              .parent()
              .css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.ServiceVideoEnable == "1") {
            $("#n_gauge_status").css("filter", "");
            $("#n_gauge_status").parent().css("color", "");
          } else {
            $("#n_gauge_status").css("filter", "grayscale(100%)");
            $("#n_gauge_status").parent().css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.EnableValueTrade == "1") {
            $("#value_my_trade_status").css("filter", "");
            $("#value_my_trade_status").parent().css("color", "");
          } else {
            $("#value_my_trade_status").css("filter", "grayscale(100%)");
            $("#value_my_trade_status")
              .parent()
              .css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.EnableCodeChest == "1") {
            $("#referral_status").css("filter", "");
            $("#referral_status").parent().css("color", "");
          } else {
            $("#referral_status").css("filter", "grayscale(100%)");
            $("#referral_status").parent().css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.ShopingCartEnable == "1") {
            $("#marketplace_status").css("filter", "");
            $("#marketplace_status").parent().css("color", "");
          } else {
            $("#marketplace_status").css("filter", "grayscale(100%)");
            $("#marketplace_status")
              .parent()
              .css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.EnableCarfax == "1") {
            $("#integration_status").css("filter", "");
            $("#integration_status").parent().css("color", "");
          } else {
            $("#integration_status").css("filter", "grayscale(100%)");
            $("#integration_status")
              .parent()
              .css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.AllowAutoFill == "1") {
            $("#autofill").css("filter", "");
            $("#autofill").parent().css("color", "");
          } else {
            $("#autofill").css("filter", "grayscale(100%)");
            $("#autofill").parent().css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.EnableGifted == "1") {
            $("#gifting").css("filter", "");
            $("#gifting").parent().css("color", "");
          } else {
            $("#gifting").css("filter", "grayscale(100%)");
            $("#gifting").parent().css("color", "rgb(162, 162, 162)");
          }
          if (adata.dealer.GuestRoadmaps == "1") {
            $("#guest").css("filter", "");
            $("#guest").parent().css("color", "");
          } else {
            $("#guest").css("filter", "grayscale(100%)");
            $("#guest").parent().css("color", "rgb(162, 162, 162)");
          }
        } else {
          self.setState({ dashboardContract: {} });
          $("#xp_points_status").css("filter", "grayscale(100%)");
          $("#xp_points_status").parent().css("color", "rgb(162, 162, 162)");

          $("#claim_status").css("filter", "grayscale(100%)");
          $("#claim_status").parent().css("color", "rgb(162, 162, 162)");

          $("#loyalty_cash_status").css("filter", "grayscale(100%)");
          $("#loyalty_cash_status").parent().css("color", "rgb(162, 162, 162)");

          $("#n_gauge_status").css("filter", "grayscale(100%)");
          $("#n_gauge_status").parent().css("color", "rgb(162, 162, 162)");

          $("#value_my_trade_status").css("filter", "grayscale(100%)");
          $("#value_my_trade_status")
            .parent()
            .css("color", "rgb(162, 162, 162)");

          $("#referral_status").css("filter", "grayscale(100%)");
          $("#referral_status").parent().css("color", "rgb(162, 162, 162)");

          $("#marketplace_status").css("filter", "grayscale(100%)");
          $("#marketplace_status").parent().css("color", "rgb(162, 162, 162)");

          $("#integration_status").css("filter", "grayscale(100%)");
          $("#integration_status").parent().css("color", "rgb(162, 162, 162)");

          $("#autofill").css("filter", "grayscale(100%)");
          $("#autofill").parent().css("color", "rgb(162, 162, 162)");

          $("#gifting").css("filter", "grayscale(100%)");
          $("#gifting").parent().css("color", "rgb(162, 162, 162)");

          $("#guest").css("filter", "grayscale(100%)");
          $("#guest").parent().css("color", "rgb(162, 162, 162)");
        }
      },
    });
    const formData = new FormData();
    formData.append("DealerID", this.state.DealerID);
    formData.append("FomDate", FomDate);
    formData.append("ToDate", ToDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    const dealerredemption_resp = await dealerredemption({ formData });
    if (dealerredemption_resp.success === 1) {
      self.setState({ dealerredemption: dealerredemption_resp });
      self.setState({ Servicemultiple: dealerredemption_resp.Servicemultiple });
      self.setState({ isloadingRedumtion: "0" });
      self.setState({
        EnableReserveGraph: dealerredemption_resp.EnableReserveGraph,
      });
      self.setState({
        ReserveGraphdata: dealerredemption_resp.ReserveGraph,
      });
    }

    //pcpbreakdown_api
    const pcpbreakdown_api = await pcpbreakdown({ formData });

    if (pcpbreakdown_api.success === 1) {
      self.setState({ PcpBreakDown: pcpbreakdown_api.PcpBreakDown });
      self.setState({
        ReservedAmountBreakDown: pcpbreakdown_api.ReservedAmountBreakDown,
      });
      self.setState({ isLoadingPCPBreakDown: "0" });
      self.setState({ isLoadingOtherDetails: "0" });
    }

    //customerinteraction_api
    const customerinteraction_api = await customerinteraction({ formData });

    if (customerinteraction_api.success === 1) {
      self.setState({ Mobileussage: customerinteraction_api.Mobileussage });
      self.setState({ isLoadingCustomerInteraction: "0" });
    }
    const comparasion30day_api = await comparasion30day({ formData });
    if (comparasion30day_api.success === 1) {
      self.setState({ isLoadingThirtDays: "0" });
      self.setState({
        CurrentThirtDays: comparasion30day_api.CurrentThirtyday,
      });
      self.setState({ LastThirtDays: comparasion30day_api.LastThirtyday });
    }
    const contractSoldGraph_api = await contractSoldGraph({ formData });
    if (contractSoldGraph_api.success === 1) {
      self.setState({ isLoadingUpSell: "0" });

      self.setState({ isLoadingSalesRetention: "0" });

      self.setState({
        contractSoldGraphservices: contractSoldGraph_api.services,
      });
      self.setState({
        contractSoldGraphcontracts: contractSoldGraph_api.contracts,
      });

      self.setState({ UniqueVisit: contractSoldGraph_api.UniqueVisit });
      self.setState({
        UpsellAmount: contractSoldGraph_api.dashboard_upsell.UpsellAmount,
      });
      self.setState({
        heatdistribution: contractSoldGraph_api.heat_distribution,
      });
    }

    const genratenewdashboard_api = await genratenewdashboard({ formData });

    if (genratenewdashboard_api.success === 1) {
      self.setState({ isgenratenewdashboardLoading: "0" });
      self.setState({ genratenewdashboardData: genratenewdashboard_api });
      self.setState({ setclasscard: "card" });
    }

    $.ajax({
      headers: {
        Authorization: `${token}`,
      },
      url: process.env.REACT_APP_APP_URL + "/dashboard/retentionmap",
      type: "post",
      data: {
        pcp_user_id: pcp_user_id,
        role_id: role_id,
        user_id: user_id,
        DealerID: this.state.DealerID,
        FomDate: FomDate,
        ToDate: ToDate,
        FixedDateParameter: FixedDateParameter,
        monthbefore: 0,
        submonthbefore: 0,
        SearchType: 0,
        AllContractStatus: 1,
        IsSubscription: 0,
        IsMarketPlace: 0,
        ContractStatus: ["l"],
      },
      dataType: "json",
      success: function (adata) {
        self.setState({ isretentionmapLoading: "0" });
        self.setState({ retentionmapData: adata });
        var latcenter = parseFloat(adata.json.center.lat);
        var lngcenter = parseFloat(adata.json.center.lng);
        var mypublic = "https://newdash.mypcp.us/mypcp/public";
        var mygetplaces = JSON.stringify(adata.json.myPlaces);
        $.ajax({
          headers: {
            Authorization: `${token}`,
          },
          url: process.env.REACT_APP_BASE_URL + "/mapbuildercustom.php",
          //url: "https://newdash.modern.mypcp.us/mapbuildercustom.php",
          type: "post",
          data: {
            mapCenterLat: latcenter,
            mapCenterLong: lngcenter,
            MyPlaces: mygetplaces,
            mapview_rad: "1",
            isNormalView: 0,
          },
          success: function (result) {
            window.mytestingfunction(result);
          },
        });
      },
    });
    if (+this.state.EnableReserveGraph === 1) {
      this.getdata(FomDate, ToDate, FixedDateParameter);
    }
  };
  getdata = async (FomDate, ToDate, FixedDateParameter) => {
    const formData = new FormData();
    formData.append("DealerID", this.state.DealerID);
    formData.append("FomDate", FomDate);
    formData.append("ToDate", ToDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    const reservedgraph_api = await reservedgraph({ formData });
    if (reservedgraph_api.success === 1) {
      this.setState({ apidata: reservedgraph_api });
      this.setState({ isloadingreservegraph: false });
    } else {
      // this.setState({ apidata: "" });
    }
  };
  render() {
    let classnameset = this.state.setclasscard;
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="float-right">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
                <h4 className="page-title">Dashboard</h4>
              </div>
            </div>
          </div>
          <div className="row">
            {/* first portion */}

            <DashboardFilter
              isLoading={this.state.isloading}
              handledealerchange={this.handledealerchange}
              changed={(e) => this.applyfilter(e)}
              DealerID={this.state.DealerID}
              fromdate={this.state.fromDate}
              toDate={this.state.toDate}
              FixedDateParameter={this.state.fixeddateparameter}
              dealerredemptiondata={this.state.dealerredemption}
              DashboardContract={this.state.dashboardContract}
              ReservedAmountBreakDown={this.state.ReservedAmountBreakDown}
              PcpBreakDown={this.state.PcpBreakDown}
              UpsellAmount={this.state.UpsellAmount}
              UniqueVisit={this.state.UniqueVisit}
              CurrentThirtDays={this.state.CurrentThirtDays}
              LastThirtDays={this.state.LastThirtDays}
              Mobileussage={this.state.Mobileussage}
            ></DashboardFilter>
            <DashboardReport
              isLoading={this.state.isloading}
              DealerID={this.state.DealerID}
              fromdate={this.state.fromDate}
              toDate={this.state.toDate}
              FixedDateParameter={this.state.fixeddateparameter}
            ></DashboardReport>
            {this.state.isloading == "1" ? (
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <WaveLoading color="#4ac7ec" />
                  </div>
                </div>
              </div>
            ) : (
              <DashboardSoldGraph
                dashboardContract={this.state.dashboardContract}
                ShopingCartEnable={
                  this.state.dealerredemption.ShopingCartEnable
                }
                DealerID={this.state.DealerID}
                fromdate={this.state.fromDate}
                toDate={this.state.toDate}
                FixedDateParameter={this.state.fixeddateparameter}
              ></DashboardSoldGraph>
            )}
            {+this.state.dealerredemption.row1_show === 1 ? (
              <div className="col-md-12">
                {this.state.isloadingRedumtion == "1" ? (
                  <div className="card-body">
                    <WaveLoading color="#4ac7ec" />
                  </div>
                ) : (
                  <Row
                    row={1}
                    EnableDisplayServiceWtithAmount={
                      this.state.dealerredemption
                        .EnableDisplayServiceWtithAmount
                    }
                    dealerredemption={this.state.dealerredemption.row1}
                    ShopingCartEnable={
                      this.state.dealerredemption.ShopingCartEnable
                    }
                    DealerID={this.state.DealerID}
                    fromdate={this.state.fromDate}
                    toDate={this.state.toDate}
                    FixedDateParameter={this.state.fixeddateparameter}
                    f1={2}
                    f2={4}
                    f3={5}
                    f4={3}
                    f5={"c4"}
                    f6={"c5"}
                  ></Row>
                )}
              </div>
            ) : (
              ""
            )}
            {+this.state.dealerredemption.row2_show === 1 ? (
              <div className="col-md-12">
                {this.state.isloadingRedumtion == "1" ? (
                  <div className="card">
                    <div className="card-body">
                      <WaveLoading color="#4ac7ec" />
                    </div>
                  </div>
                ) : (
                  <Row
                    row={2}
                    EnableDisplayServiceWtithAmount={
                      this.state.dealerredemption
                        .EnableDisplayServiceWtithAmount
                    }
                    dealerredemption={this.state.dealerredemption.row2}
                    ShopingCartEnable={
                      this.state.dealerredemption.ShopingCartEnable
                    }
                    DealerID={this.state.DealerID}
                    fromdate={this.state.fromDate}
                    toDate={this.state.toDate}
                    FixedDateParameter={this.state.fixeddateparameter}
                    f1={12}
                    f2={14}
                    f3={15}
                    f4={16}
                  ></Row>
                )}
              </div>
            ) : (
              ""
            )}
            {+this.state.dealerredemption.row3_show === 1 ? (
              <div className="col-md-12">
                {this.state.isloadingRedumtion == "1" ? (
                  <div className="card">
                    <div className="card-body">
                      <WaveLoading color="#4ac7ec" />
                    </div>
                  </div>
                ) : (
                  <Row
                    row={3}
                    EnableDisplayServiceWtithAmount={
                      this.state.dealerredemption
                        .EnableDisplayServiceWtithAmount
                    }
                    dealerredemption={this.state.dealerredemption.row3}
                    ShopingCartEnable={
                      this.state.dealerredemption.ShopingCartEnable
                    }
                    DealerID={this.state.DealerID}
                    fromdate={this.state.fromDate}
                    toDate={this.state.toDate}
                    FixedDateParameter={this.state.fixeddateparameter}
                    f1={"p2"}
                    f2={"p4"}
                    f3={"p5"}
                    f4={"p3"}
                  ></Row>
                )}
              </div>
            ) : (
              ""
            )}
            {+this.state.dealerredemption.row4_show === 1 ? (
              <div className="col-md-12">
                {this.state.isloadingRedumtion == "1" ? (
                  <div className="card">
                    <div className="card-body">
                      <WaveLoading color="#4ac7ec" />
                    </div>
                  </div>
                ) : (
                  <Row
                    row={4}
                    EnableDisplayServiceWtithAmount={
                      this.state.dealerredemption
                        .EnableDisplayServiceWtithAmount
                    }
                    dealerredemption={this.state.dealerredemption.row4}
                    ShopingCartEnable={
                      this.state.dealerredemption.ShopingCartEnable
                    }
                    DealerID={this.state.DealerID}
                    fromdate={this.state.fromDate}
                    toDate={this.state.toDate}
                    FixedDateParameter={this.state.fixeddateparameter}
                    f1={"np2"}
                    f2={"np4"}
                    f3={"np5"}
                    f4={"np3"}
                  ></Row>
                )}
              </div>
            ) : (
              ""
            )}
            {+this.state.dealerredemption.row5_show === 1 ? (
              <div className="col-md-12">
                {this.state.isloadingRedumtion == "1" ? (
                  <div className="card">
                    <div className="card-body">
                      <WaveLoading color="#4ac7ec" />
                    </div>
                  </div>
                ) : (
                  <Row
                    row={5}
                    EnableDisplayServiceWtithAmount={
                      this.state.dealerredemption
                        .EnableDisplayServiceWtithAmount
                    }
                    dealerredemption={this.state.dealerredemption.row5}
                    ShopingCartEnable={
                      this.state.dealerredemption.ShopingCartEnable
                    }
                    DealerID={this.state.DealerID}
                    fromdate={this.state.fromDate}
                    toDate={this.state.toDate}
                    FixedDateParameter={this.state.fixeddateparameter}
                    f1={12}
                    f2={14}
                    f3={15}
                    f4={13}
                  ></Row>
                )}
              </div>
            ) : (
              ""
            )}

            {+this.state.EnableReserveGraph === 1 ? (
              <div className="container-fluid">
                <div id="customer-interaction" className="row">
                  <div className="col-md-12">
                    {this.state.isloadingreservegraph ? (
                      <div className="card">
                        <div className="card-body">
                          <WaveLoading color="#4ac7ec" />
                        </div>
                      </div>
                    ) : (
                      <div className="card">
                        <div className="card-body">
                          <ReserveGraph
                            ReserveGraphdata={this.state.ReserveGraphdata}
                            DealerTitle={
                              this.state.retentionmapData.DealerTitle
                            }
                            dashboardContract={this.state.dashboardContract}
                            resdata={this.state.apidata}
                            DealerID={this.state.DealerID}
                            fromdate={this.state.fromDate}
                            toDate={this.state.toDate}
                            dealerredemption={this.state.dealerredemption}
                            FixedDateParameter={this.state.fixeddateparameter}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="container-fluid">
              <div id="customer-interaction" className="row">
                {this.state.isLoadingCustomerInteraction == "1" ? (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <WaveLoading color="#4ac7ec" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <DashboardCustomerInteraction
                    Mobileussage={this.state.Mobileussage}
                    ShopingCartEnable={
                      this.state.dealerredemption.ShopingCartEnable
                    }
                    DealerID={this.state.DealerID}
                    fromdate={this.state.fromDate}
                    toDate={this.state.toDate}
                    FixedDateParameter={this.state.fixeddateparameter}
                  ></DashboardCustomerInteraction>
                )}
                {this.state.isLoadingPCPBreakDown == "1" ? (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <WaveLoading color="#4ac7ec" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <DashboardPCPBreakDown
                    ReservedAmountBreakDown={this.state.ReservedAmountBreakDown}
                    PcpBreakDown={this.state.PcpBreakDown}
                  ></DashboardPCPBreakDown>
                )}
                {this.state.isLoadingOtherDetails == "1" ? (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <WaveLoading color="#4ac7ec" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <DashboardOtherDetails
                    ReservedAmountBreakDown={this.state.ReservedAmountBreakDown}
                    PcpBreakDown={this.state.PcpBreakDown}
                    ShopingCartEnable={
                      this.state.dealerredemption.ShopingCartEnable
                    }
                    DealerID={this.state.DealerID}
                    fromdate={this.state.fromDate}
                    toDate={this.state.toDate}
                    FixedDateParameter={this.state.fixeddateparameter}
                  ></DashboardOtherDetails>
                )}
              </div>
            </div>
            {this.state.isLoadingSalesRetention == "1" ? (
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <WaveLoading color="#4ac7ec" />
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                {this.state.contractSoldGraphcontracts != "" ? (
                  <DashboardSalesRetention
                    isLoading={this.state.isloading}
                    ServiceMultiple={this.state.Servicemultiple}
                    ContractData={this.state.contractSoldGraphcontracts}
                    ServiceRedData={this.state.contractSoldGraphservices}
                    DealerID={this.state.DealerID}
                    fromdate={this.state.fromDate}
                    toDate={this.state.toDate}
                    FixedDateParameter={this.state.fixeddateparameter}
                    heatdistribution={this.state.heatdistribution}
                  ></DashboardSalesRetention>
                ) : (
                  ""
                )}
              </div>
            )}
            <div style={{ width: "100%", display: "inline-flex" }}>
              {this.state.isLoadingUpSell == "1" ? (
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <WaveLoading color="#4ac7ec" />
                    </div>
                  </div>
                </div>
              ) : (
                <DashboardUpSell UpsellAmount={this.state.UpsellAmount} />
              )}
              {this.state.isLoadingUpSell == "1" ? (
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <WaveLoading color="#4ac7ec" />
                    </div>
                  </div>
                </div>
              ) : (
                <DashboardMaturedContract
                  DealerID={this.state.DealerID}
                  fromdate={this.state.fromDate}
                  toDate={this.state.toDate}
                  FixedDateParameter={this.state.fixeddateparameter}
                  UniqueVisit={this.state.UniqueVisit}
                ></DashboardMaturedContract>
              )}
              {this.state.isLoadingThirtDays == "1" ? (
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <WaveLoading color="#4ac7ec" />
                    </div>
                  </div>
                </div>
              ) : (
                <Dashboard30Days
                  Current30Days={this.state.CurrentThirtDays}
                  Last30Days={this.state.LastThirtDays}
                ></Dashboard30Days>
              )}
            </div>
            <div className="col-md-12">
              <div className={classnameset}>
                <div className="card-body" style={{ padding: "0.30rem" }}>
                  {this.state.genratenewdashboardData != "" ? (
                    <div className="row">
                      <div className="col-sm-12"></div>
                      <div className="col-md-6 text-center font-16">
                        Contracts Sale
                      </div>
                      <div className="col-md-6 text-center font-16">
                        Service Redemption
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.isgenratenewdashboardLoading == "1" ? (
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <WaveLoading color="#4ac7ec" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      {this.state.genratenewdashboardData != "" ? (
                        <DashboardGraphContract
                          mysampledata={this.state.genratenewdashboardData}
                        ></DashboardGraphContract>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {this.state.isretentionmapLoading == "1" ? (
                <div className="card">
                  <div className="card-body">
                    <WaveLoading color="#4ac7ec" />
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.retentionmapData.hasOwnProperty("json") ? (
                    <div className="card">
                      <div className="card-body">
                        <RetentionMapPreDefined
                          DealerID={this.state.DealerID}
                          fromdate={this.state.fromDate}
                          toDate={this.state.toDate}
                          FixedDateParameter={this.state.fixeddateparameter}
                          mapdata={this.state.retentionmapData}
                        ></RetentionMapPreDefined>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

export default withToast(Dashboard);
