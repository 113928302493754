import { React, useState, useRef, useContext } from "react";
import moment from "moment";
import PreLoad from "../../../components/PreLoad";
import styles from "./unusedservices.module.css";
import EditRedemmedContract from "./EditRedemmedContract";
import { role_id } from "../../../CurrentUser";
import {
  reactivateservice,
  reactivateservicewithstatuschange,
} from "./EditContractApi";

import printinfocontext from "../../../context/print-info-context";
export default function RedemmedContract(props) {
  const coupons_cntx = useContext(printinfocontext);
  const { redeemcontract, contractinfo } = props;
  const inputselect = useRef(null);
  const [editredeemcontract, setEditredeemcontract] = useState(false);

  const [loading, setLoading] = useState(false);
  const [edititem, setEdititem] = useState("");
  const [success, setSuccess] = useState("");
  const handleedit = (item) => {
    setSuccess("");
    if (typeof item !== "undefined" && item != "") {
      setEdititem(item);
    }
    setEditredeemcontract(!editredeemcontract);
  };
  const changestatus = async (item) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("ContractID", item.ContractID);
    formData.append("CouponID", item.CouponID);
    if (+item.isGrayoutsameService == 0) {
      const api_response = await reactivateservicewithstatuschange({
        formData,
      });
      setLoading(false);
      //api response
      if (api_response.success == 1) {
        setSuccess(api_response.message);
        window.location.reload();
      } else if (api_response.success == 0) {
        setSuccess(api_response.message);
      }
    } else if (
      +item.isGrayoutsameService != 0 &&
      (role_id == 1 || role_id == 2)
    ) {
      const reactivateservice_response = await reactivateservice({ formData });
      setLoading(false);
      //api response
      if (reactivateservice_response.success == 1) {
        window.location.reload();
        setSuccess(reactivateservice_response.message);
      } else if (reactivateservice_response.success == 0) {
        setSuccess(reactivateservice_response.message);
      }
    }
    setTimeout(() => setSuccess(""), 5000);
  };
  const printselectedservices = async (e, item) => {
    if (e.target.checked) {
      coupons_cntx.add(item);
    } else if (!e.target.checked) {
      coupons_cntx.remove(item);
    }
  };
  return (
    <div className="row">
      {loading && <PreLoad loading={loading} />}
      {editredeemcontract && (
        <EditRedemmedContract
          handleedit={handleedit}
          edititem={edititem}
          contractinfo={contractinfo}
        />
      )}
      {success != "" ? (
        <div className="alert alert-success mt-2">{success}</div>
      ) : (
        ""
      )}
      <div className="col-md-12">
        <table className="table mt-4 table-bordered">
          <thead>
            <tr>
              <th scope="col">ID/OP CODE</th>
              <th scope="col">Services</th>
              <th scope="col">Mileage</th>
              <th scope="col">RO#</th>
              <th scope="col">Date of Service</th>
              <th scope="col">Redeemed Vehicle</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {redeemcontract != "" && typeof redeemcontract !== "undefined" ? (
              redeemcontract.map((item, i) => (
                <tr>
                  <td>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        ref={inputselect}
                        onClick={(e) => printselectedservices(e, item)}
                        value={item.CouponID}
                        id={item.CouponID}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {item.CouponID}
                        <br />
                        <span className={styles.blue}>{item.OpCode}</span>
                      </label>
                    </div>
                    <p>
                      <i
                        className={
                          +item.ServiceType === 1
                            ? `fas fa-circle p-1 ${styles.green}`
                            : +item.ServiceType === 2
                            ? `fas fa-circle p-1 ${styles.orange}`
                            : +item.ServiceType === 3
                            ? `fas fa-circle p-1 ${styles.blue}`
                            : +item.ServiceType === 4
                            ? `fas fa-circle p-1 ${styles.purple}`
                            : `fas fa-circle p-1 ${styles.green}` //default
                        }
                        aria-hidden="true"
                      ></i>
                    </p>
                  </td>
                  <td>{item.CouponTitle}</td>
                  <td>{item.CouponMileage}</td>
                  <td>{item.RepairOrderNo}</td>
                  <td>{moment.unix(item.RecievedDate).format("MM/DD/YYYY")}</td>
                  <td>{item.VehicleType}</td>

                  <td style={{ width: 80 }}>
                    <button
                      type="button"
                      onClick={() => changestatus(item)}
                      className="btn btn-primary btn-sm refresh float-left"
                    >
                      <i className="fa fa-undo" aria-hidden="true"></i>
                    </button>

                    {(role_id == 1 || role_id == 2) &&
                    +item.isGrayoutsameService != 0 &&
                    +item.IsPaidOut === 0 ? (
                      <button
                        type="button"
                        onClick={() => handleedit(item)}
                        className="btn btn-primary btn-sm edit float-right"
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} align="center">
                  <div className="alert alert-info text-center">
                    NO SERVICES USED
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
