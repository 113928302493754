import React, { useState, useEffect } from "react";

import { Chart } from "react-google-charts";
const TrendGraph = (props) => {
  const {
    title,
    setContractSoldimg,
    filter_type,
    googledata,
    ticks,
    setTrendimg,
  } = props;
  const [charttitle, setChartTitle] = useState("");
  const [chartImageURI, setChartImageURI] = useState(null);

  useEffect(() => {
    setChartTitle(title);
    if (chartImageURI !== null) {
      setTrendimg(chartImageURI);
    }
  }, [chartImageURI]);
  var dataArray2 = JSON.stringify(googledata);
  var IDs = [];
  var Ticks = [];
  var obj = JSON.parse(dataArray2);
  Object.keys(obj).forEach(function (k) {
    if (k != 0) {
      IDs.push([new Date(obj[k].year, obj[k].month, obj[k].day), obj[k].total]);
      Ticks.push([new Date(obj[k].year, obj[k].month, obj[k].day)]);
    } else {
      //IDs.push([obj[k].Type, obj[k].Type2]);
    }
  });

  let chart_events = [
    {
      eventName: "ready",
      callback: (ChartRef) => {
        // Returns Chart so you can access props and  the ChartWrapper object from chart.wrapper
        setChartImageURI(ChartRef.chartWrapper.getChart().getImageURI());
      },
    },
  ];
  const [chartEvents, setChartEvents] = useState(chart_events);
  return (
    <Chart
      width={"100%"}
      height={"300px"}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={[["Date", "Service"], ...IDs]}
      options={{
        // Material design options
        chart: {
          title: "Trend",
        },
        colors: ["#91CA7C"],
        trendlines: {
          0: { type: "exponential", visibleInLegend: false },
          1: { type: "polynomial", degree: 3, visibleInLegend: false },
        },
        vAxis: {
          format: "decimal",
        },
        hAxis: {
          format: filter_type,
          ticks: JSON.stringify(ticks),
        },
      }}
      chartEvents={chartEvents}
      // For tests
      rootProps={{ "data-testid": "1" }}
    />
  );
};

export default TrendGraph;
