import React, { useState } from "react";
import $ from "jquery";

import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { pcp_user_id, role_id, user_id, token } from "../CurrentUser";

import PreLoad from "../components/PreLoad";
import { f_dashboardexport, f_subscriptionexport } from "../components/helpers";
require("dotenv").config();

function DashboardReport(props) {
  const [dupContarct, setDupContarct] = useState(0);
  const [dupContarctspinner, setDupContarctspinner] = useState(0);
  const [dayContract, setDayContract] = useState(0);
  const [dayContractSpinner, setDayContractSpinner] = useState(0);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const exportdata = (export_type) => {
    if (props.DealerID == "" || typeof props.DealerID == "undefined") {
      addToast("please select dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", props.DealerID);
    formData.append("FomDate", props.fromdate);
    formData.append("ToDate", props.toDate);
    formData.append("FixedDateParameter", props.FixedDateParameter);
    formData.append("export_type", export_type);
    setLoading(true);
    //api
    if (props.ShopingCartEnable === 1) {
      f_subscriptionexport(formData).then((response) => {
        setLoading(false);
      });
    } else {
      f_dashboardexport(formData).then((response) => {
        setLoading(false);
      });
    }
  };
  const getDupContract = (e) => {
    e.preventDefault();
    setDupContarctspinner("1");
    let username = $("#dealerSelection").val();
    let FomDate = $("#fromDate").val();
    let ToDate = $("#todate").val();
    let noofdays = $(".days_input").val();

    $.ajax({
      headers: {
        Authorization: `${token}`,
      },
      url: process.env.REACT_APP_APP_URL + "/dashboard/duplicatecontract",
      type: "post",
      data: {
        pcp_user_id: pcp_user_id,
        role_id: role_id,
        user_id: user_id,
        DealerID: username,
      },
      dataType: "json",
      success: function (adata) {
        setDupContarct(adata.dup_contracts);

        setDupContarctspinner("0");
      },
    });
  };

  const getDaysResp = (e) => {
    e.preventDefault();

    setDupContarctspinner("1");
    let username = $("#dealerSelection").val();
    let FomDate = $("#fromDate").val();
    let ToDate = $("#todate").val();
    let noofdays = $(".days_input").val();

    $.ajax({
      headers: {
        Authorization: `${token}`,
      },
      url: process.env.REACT_APP_APP_URL + "/dashboard/unpaidbatchcontract",
      type: "post",
      data: {
        pcp_user_id: pcp_user_id,
        role_id: role_id,
        user_id: user_id,
        DealerID: username,
        noofDays: noofdays,
      },
      dataType: "json",
      success: function (adata) {
        setDayContract(adata.batch_contracts);
        setDupContarctspinner("0");
      },
    });
  };

  let tooltip1 =
    "This is the number of duplicate VIN #,<br /> Customer First Name, <br />Last Name and Email Address<br/> contract issued in the selected date period";
  let tooltip2 =
    "This is the number of contracts issued,<br /> but not payed from unpaid past days";
  return (
    <div className="col-md-12">
      <PreLoad loading={loading} />
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <span className="font-18 dash-report-color">
                Your Dashboard Report
              </span>
            </div>
            <div className="col-md-5 col-sm-6 font-14 pd_contracts">
              <div className="row">
                <div className="col-md-1 col-sm-2">
                  <span
                    onClick={(e) => getDupContract(e)}
                    className="font-16 mr-1 pdc_refresh_ico"
                  >
                    <i className="mdi mdi-refresh"></i>
                  </span>
                </div>
                <div className="col-md-8 col-sm-9">
                  <span className="mr-2">Possible Duplicate Contracts</span>

                  <span className="mr-4">
                    <i data-tip={tooltip1} className="dripicons-question"></i>
                  </span>

                  <span className="mr-4 dash-report-color">
                    {dupContarctspinner == "1" ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      dupContarct
                    )}
                  </span>
                  <a
                    href="#"
                    onClick={() => exportdata(9)}
                    style={{ color: "#26a69a", marginLeft: 10 }}
                  >
                    <span
                      style={{
                        background: "#26a69a",
                        borderRadius: "4px",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        color: "white",
                      }}
                    >
                      <i className="far fa-file-excel"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 font-14">
              <span
                onClick={(e) => getDaysResp(e)}
                className="mr-1 pdc_refresh_ico"
              >
                <i className="mdi mdi-refresh"></i>
              </span>
              <span>
                <input className="days_input" type="text" /> Days{" "}
              </span>
              <span className="mr-4">
                {" "}
                <i
                  data-tip={tooltip2}
                  className="dripicons-question"
                  style={{ marginLeft: "5px" }}
                ></i>
              </span>
              <span className="mr-2 dash-report-color">
                {dayContractSpinner == "1" ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  dayContract
                )}
              </span>
              <a
                href="#"
                onClick={() => exportdata(10)}
                style={{ color: "#26a69a", marginLeft: 10 }}
              >
                <span
                  className=""
                  style={{
                    background: "#26a69a",
                    borderRadius: "4px",
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    color: "white",
                  }}
                >
                  <i className="mdi mdi-file-table"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip multiline={true} />
    </div>
  );
}

export default DashboardReport;
