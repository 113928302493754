import React, { Component } from 'react'
import Script from 'react-load-script'

class MyScripts extends Component {
render(){
return(
<Script url={this.props.url} />
)

}
}

export default MyScripts;