import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
export default function AddModel(props) {
  const { handlecontact } = props;
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [captcha_value, setCaptcha_value] = useState("");
  const { register, handleSubmit } = useForm();
  const handleClose = () => {
    handlecontact();
  };

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    formData.append("g-recaptcha-response", captcha_value);
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/loginreactcopy/sendcontactusemail",
        formData,

        { headers }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success === 1) {
          handlecontact();
          addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(response.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  function onChange(value) {
    setCaptcha_value(value);
  }
  return (
    <div>
      <div>
        <Modal show={true}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>CONTACT US</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control {...register("Name")} type="text" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control {...register("EmailAddress")} type="email" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Dealership Name</Form.Label>
                <Form.Control {...register("DealerShip")} type="text" />
              </Form.Group>

              <div className="form-group row">
                <label className="col-lg-12 col-sm-4 col-form-label text-left">
                  Issue
                </label>
                <div className="col-lg-12 col-sm-8">
                  <select {...register("Issued")} className="form-control">
                    <option key={0} value="">
                      Select
                    </option>
                    <option key={1} value={"Can’t Log in to site or App"}>
                      Can’t Log in to site or App
                    </option>
                    <option key={2} value={"Lost Password"}>
                      Lost Password
                    </option>
                    <option key={3} value={"Lost Username"}>
                      Lost Username
                    </option>
                    <option key={4} value={"Service Question"}>
                      Service Question
                    </option>
                    <option key={5} value={"App Question"}>
                      App Question
                    </option>
                  </select>
                </div>
              </div>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Details</Form.Label>
                <Form.Control {...register("Details")} as="textarea" rows={5} />
              </Form.Group>
              <div className="col-lg-9 col-md-9 col-sm-9 col-sm-9">
                <ReCAPTCHA
                  sitekey={`${process.env.REACT_APP_CAPTCHA}`}
                  onChange={onChange}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              {loading ? (
                <span style={{ marginLeft: "50%" }}>
                  <Spinner animation="border" variant="success" />
                </span>
              ) : (
                <React.Fragment>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                    Send
                  </Button>
                </React.Fragment>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
