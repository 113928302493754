import { React, useState, useEffect } from "react";
import {
  Modal,
  Button,
  FormControl,
  FormGroup,
  Form,
  InputGroup,
} from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import { user_id, role_id, pcp_user_id } from "../CurrentUser";
import { useToasts } from "react-toast-notifications";
export default function AddModel(props) {
  const { show, handlemodal, DealerID, MakeID } = props;
  const [error, setError] = useState("");
  const { addToast } = useToasts();
  useEffect(() => {
    if (MakeID === "" || DealerID === "") {
      setError("Please select Make and Dealer");
    } else {
      setError("");
    }
  }, [show]);
  const handleClose = () => {
    handlemodal();
  };
  const save = () => {
    let AddVehicleModel = $("#AddVehicleModel").val();
    if (AddVehicleModel.length <= 0) {
      addToast("Enter a valid reason", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", DealerID);
    formData.append("MakeID", MakeID);
    formData.append("AddVehicleModel", AddVehicleModel);

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/addmodel",
        formData,

        { headers }
      )
      .then((response) => {
        //console.log(response.data.contracts_all);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div>
        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>Add New Model</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Model Name</Form.Label>
              <Form.Control
                id="AddVehicleModel"
                name="AddVehicleModel"
                type="text"
              />
            </Form.Group>
            <small className="help-block text-danger">{error}</small>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={MakeID === "" || DealerID === "" ? true : false}
              onClick={save}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
