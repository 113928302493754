import { React, useState, useEffect } from "react";
import { Modal, Button, FormControl, InputGroup } from "react-bootstrap";

import "./loyaltycash.css";
import moment from "moment";
import PreLoad from "../../../components/PreLoad";
import { verifyvin, modelsapi, make_api } from "../create/CreateContractApi";
import { savenewcarvin, editcarinfo } from "./LoyaltyCashApi";
import { useFormik } from "formik";

export default function EnrollCar(props) {
  const { show, enrollnewcar, contractid, DealerID, item } = props;
  const [yeardata, setYeardata] = useState("");
  const [error, setError] = useState(props.error);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState("");
  const [make, setMake] = useState("");
  const carformik = useFormik({
    initialValues: {
      VehicleMake: "",
      VehicleModel: "",
      VehicleYear: "",
      VinNumber: "",
    },
    // validationSchema: validationSchema,
    onSubmit: async (data) => {
      setLoading(true);
      setError("");
      const formData = new FormData();
      formData.append("DealerID", DealerID);
      formData.append("ContractID", contractid);

      if (item.ID !== "" && typeof item.ID !== "undefined") {
        formData.append("ID", item.ID);
      }
      Object.keys(data).forEach((key) => formData.append(key, data[key]));
      var save_vin_api = "";
      if (item.ID !== "" && typeof item.ID !== "undefined") {
        save_vin_api = await editcarinfo({ formData });
      } else {
        save_vin_api = await savenewcarvin({ formData });
      }
      setLoading(false);
      if (save_vin_api.success == 1) {
        enrollnewcar("", save_vin_api);
      } else if (save_vin_api.success == 0) {
        setError(save_vin_api.message);
      }
    },
  });
  const handleClose = () => {
    enrollnewcar("", "");
    setError("");
    carformik.resetForm();
  };
  useEffect(() => {
    var vehyear = [];
    for (let i = 0; i <= 100; i++) {
      vehyear.push({ year: moment().year() - i });
    }
    setYeardata(vehyear);
    getmake();
    if (item.ID !== "" && typeof item.ID !== "undefined") {
      getmodel(item.MakeID);
      carformik.setFieldValue("VinNumber", item.VIN);
      carformik.setFieldValue("VehicleMake", +item.MakeID);
      carformik.setFieldValue("VehicleModel", +item.ModelID);
      carformik.setFieldValue("VehicleYear", +item.VehYear);
    }
    setLoading(true);
  }, [show]);
  async function getmake() {
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    const makepi = await make_api({ formData });
    if (makepi.success == 1) {
      setMake(makepi.Makes);
    }
    setLoading(false);
  }
  const getmodel = async (val) => {
    setLoading(true);
    carformik.setFieldValue("VehicleMake", val);

    const formData = new FormData();

    formData.append("DealerID", DealerID);
    formData.append("MakeID", parseInt(val));

    const modelapi = await modelsapi({ formData });
    if (modelapi.success == 1) {
      setModel(modelapi.SpecifixModels);
    }
    setLoading(false);
  };
  const functionverifyvin = async () => {
    setLoading(true);
    setError("");
    if (carformik.values.VinNumber == "") {
      setError("please enter VIN");

      setLoading(false);
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("VIN", carformik.values.VinNumber);

    const verifyvinresponse = await verifyvin({ formData });
    setLoading(false);
    if (verifyvinresponse.success == 1) {
      setModel(verifyvinresponse.SpecifixModels);
      carformik.setFieldValue("VehicleMake", +verifyvinresponse.MakeID);

      carformik.setFieldValue("VehicleModel", +verifyvinresponse.ModelID);

      carformik.setFieldValue("VehicleYear", verifyvinresponse.ModelYear);
    } else if (verifyvinresponse.success == 0) {
      setError(verifyvinresponse.message);
    }
  };
  return (
    <div>
      <PreLoad loading={loading} />

      <Modal
        dialogClassName="redemption-modal"
        size={"md"}
        show={show}
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title style={{ width: "100%" }}>
            <span className="text-center">
              {item.ID !== "" && typeof item.ID !== "undefined"
                ? "UPDATE YOUR CAR INFO"
                : "ENROLL YOUR NEW CAR"}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small className="help-block text-danger">{error}</small>
          <form onSubmit={carformik.handleSubmit}>
            <div className="col-xs-12">
              <div className="form-group">
                <label>VIN</label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <button
                      type="button"
                      onClick={functionverifyvin}
                      className="vin-button"
                    ></button>
                  </InputGroup.Prepend>
                  <FormControl
                    name="VinNumber"
                    value={carformik.values.VinNumber}
                    onChange={carformik.handleChange}
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </div>
              <div className="form-group ">
                <label>Select Make</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    getmodel(e.target.value);
                  }}
                  value={carformik.values.VehicleMake}
                  name="VehicleMake"
                >
                  <option value={0}>Select option</option>

                  {make != ""
                    ? make.map((item, i) => (
                        <option key={i} value={item.MakeID}>
                          {item.Make}
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              <div className="form-group ">
                <label>Select Model</label>
                <select
                  className="form-control"
                  value={carformik.values.VehicleModel}
                  onChange={carformik.handleChange}
                  name="VehicleModel"
                >
                  <option value={0}>Select option</option>

                  {model != ""
                    ? model.map((item, i) => (
                        <option key={i + 1} value={item.ModelID}>
                          {item.Model}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="form-group ">
                <label>Select Year</label>
                <select
                  className="form-control"
                  onChange={carformik.handleChange}
                  value={carformik.values.VehicleYear}
                  name="VehicleYear"
                >
                  <option value={0}>Select option</option>

                  {yeardata != ""
                    ? yeardata.map((item) => (
                        <option key={item.year} value={item.year}>
                          {item.year}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <button
                type="submit"
                disabled={carformik.isSubmitting}
                className="btn btn-success btn-square waves-effect waves-light"
              >
                Save Changes
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
