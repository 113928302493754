import React, { useState, useRef } from "react";
import { useToasts } from "react-toast-notifications";
import { Modal, Button, Spinner, InputGroup } from "react-bootstrap";
import { contractservicesreedem } from "./EditContractApi";
import $ from "jquery";
export default function RedemptionConfirmation(props) {
  const {
    setRedeemconfirm,
    alertmsg,
    IsManagerApproval,
    data,
    disbaleapi,
    setDisbaleapi,
  } = props;
  const [spinner, setSpinner] = useState(false);
  const { addToast } = useToasts();
  const handleClose = () => {
    setRedeemconfirm(false);
    setDisbaleapi(false);
  };
  const save = async () => {
    setSpinner(true);
    //api
    let pass = $("#ManagerApproval").val();
    data.append("ManagerApproval", pass);

    const contractservicesreedem_response = await contractservicesreedem(data);
    if (contractservicesreedem_response.success === 0) {
      addToast(contractservicesreedem_response.messages, {
        appearance: "error",
        autoDismiss: true,
      });
      setRedeemconfirm(false);
    }
    if (contractservicesreedem_response.success === 1) {
      localStorage.setItem(
        "contract",
        contractservicesreedem_response.messages
      );
      setRedeemconfirm(false);
      window.location.reload();
    }
  };

  return (
    <div>
      <div>
        <Modal show={true}>
          <Modal.Header>
            <Modal.Title>Service Redemption</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: alertmsg }}></div>
          </Modal.Body>
          <Modal.Footer>
            {disbaleapi ? (
              <Button variant="primary" onClick={handleClose}>
                Ok
              </Button>
            ) : spinner ? (
              <Spinner animation="border" variant="info" />
            ) : (
              <React.Fragment>
                <Button variant="secondary" onClick={handleClose}>
                  <i className="fas fa-times"></i> Cancel
                </Button>
                <Button variant="primary" onClick={save}>
                  <i className="fas fa-check"></i>Confirm
                </Button>
              </React.Fragment>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
