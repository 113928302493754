import axios from "axios";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
const token = JSON.parse(localStorage.getItem("token"));
const API = process.env.REACT_APP_APP_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const header = {
  "Content-Type": "application/json",
};
export const verifyvin = async ({ formData }) => {
  let url = API + "/contract/apivin";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const plancostapi = async ({ formData }) => {
  let url = API + "/contract/plancost";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);

  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const autofillapi = async ({ formData }) => {
  let url = API + "/contract/autofillvalue";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);

  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const contractcreateapi = async ({ formData }) => {
  let url = API + "/contract/addcontract";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);

  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const planexpiryapi = async ({ formData }) => {
  let url = API + "/contract/getplanexpirydate";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const statesapi = async ({ formData }) => {
  let url = API + "/contract/countrystates";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const modelsapi = async ({ formData }) => {
  let url = API + "/contract/modelagainstmake";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const addbankapi = async ({ formData }) => {
  let url = API + "/contract/addbank";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const dataagainstdealerid = async ({ formData }) => {
  let url = API + "/contract/dataagainstdealerid";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const editcontract = async ({ formData }) => {
  let url = API + "/contract/editcontract";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const make_api = async ({ formData }) => {
  let url = API + "/contract/makeagainstdealer";
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const resellmatured = async (formdata) => {
  let url = API + "/contract/resellmatured";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const setdefaultplanforuser = async (formdata) => {
  let url = API + "/contract/setdefaultplanforuser";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const setdefaultsalerepforuser = async (formdata) => {
  let url = API + "/contract/setdefaultsalerepforuser";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const payment_done = async (formdata) => {
  let url = API + "/contract/payment_done";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
