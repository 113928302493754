import { React } from "react";

export default function LoginLoad(props) {
  const loading = props.loading;
  return (
    <div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "38%",
            left: "50%",
            transform: "translate(-50%, 38%)",
            width: "50%",
            borderRadius: 40,
            display: "block",
            zIndex: 9999999999,
          }}
        >
          <div
            className="ui-state-highlight ui-corner-all ui-msg"
            style={{
              left: "50%",
              width: "28%",
              top: "40%",
              transform: "translate(-50%, -50%)",
              borderRadius: 15,
            }}
          >
            <p style={{ paddingTop: 46, color: "#222" }}>
              <span style={{ float: "left", marginRight: ".3em" }}></span>
              <img
              alt="loading"
                src="https://mypcp.us/images/icon-login.png"
                style={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  position: "absolute",
                  webkitTransform: "translate3d(-50%, -50%, 0)",
                  mozTransform: "translate3d(-50%, -50%, 0)",
                  transform: "translate3d(-50%, -50%, 0);",
                }}
              />
              Validation in progress...
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
