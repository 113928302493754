import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Table,
  Card,
  Spinner,
} from "react-bootstrap";
import {
  customer_wallet,
  awardedwallet,
  redeemedwallet,
} from "./Customer_wallet_api";
import PreLoad from "../../../components/PreLoad";
import { useToasts } from "react-toast-notifications";
export function CustomerWallet(props) {
  const [error, setError] = useState("");
  const [balance, setBalance] = useState("");
  const [wallet, setWallet] = useState("");
  const [loading, setLoading] = useState(false);
  const { contractid, f_cusotmer_wallet } = props;
  const AwardAmount = useRef(0);
  const RedeemAmount = useRef(0);
  const { addToast } = useToasts();
  const Close = () => {
    f_cusotmer_wallet();
  };
  useEffect(() => {
    get_data();
  }, [contractid]);
  async function get_data() {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("ContractID", contractid);
    const customer_wallet_response = await customer_wallet(formdata);
    setLoading(false);
    if (customer_wallet_response.success == 1) {
      setBalance(customer_wallet_response.balance);
      setWallet(customer_wallet_response);
    }
  }
  const awardbucks = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("ContractID", contractid);
    formdata.append("AwardAmount", AwardAmount.current.value);
    formdata.append("AwardAction", "Awarded Bucks");
    const awardedwallet_response = await awardedwallet(formdata);
    setLoading(false);
    if (awardedwallet_response.success == 1) {
      setBalance(awardedwallet_response.balance);
      setWallet(awardedwallet_response);
    } else if (awardedwallet_response.success == 0) {
      addToast(awardedwallet_response.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const redeembucks = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("ContractID", contractid);
    formdata.append("RedeemAmount", RedeemAmount.current.value);
    formdata.append("RedeemAction", "Redeem");
    const redeemedwallet_response = await redeemedwallet(formdata);
    setLoading(false);
    if (redeemedwallet_response.success == 1) {
      setBalance(redeemedwallet_response.balance);
      setWallet(redeemedwallet_response);
    } else if (redeemedwallet_response.success == 0) {
      addToast(redeemedwallet_response.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <React.Fragment>
      <PreLoad loading={loading} />

      <Modal show={true} size="lg" onHide={Close}>
        <Modal.Header>
          <Modal.Title>
            Redemption Service ({wallet?.dealersetting?.WalletCurrencyName})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  Award/Redeem {wallet?.dealersetting?.WalletCurrencyName}
                  <div className="float-right">
                    Current Balance :
                    <span
                      className="text-success CurrenBalance"
                      style={{ color: "#5cb85c", fontWeight: "bold" }}
                    >
                      ${balance}
                    </span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <tbody>
                      {+wallet?.usersetting?.AllowWalletAward === 1 ? (
                        <tr>
                          <td>
                            Award {wallet?.dealersetting?.WalletCurrencyName}
                          </td>
                          <td colSpan={2}>
                            <div className="input-group input-group-md">
                              <InputGroup.Text id="basic-addon2">
                                $
                              </InputGroup.Text>

                              <input
                                type="text"
                                className="form-control"
                                ref={AwardAmount}
                                name="AwardAmount"
                              />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr></tr>

                      {+wallet?.usersetting?.AllowWalletAward === 1 ? (
                        <tr>
                          <td colSpan={2}></td>
                          <td>
                            <Button
                              active={true}
                              onClick={awardbucks}
                              className="btn btn-success btnclass float-right btn-block mb-5"
                            >
                              Award Bucks
                            </Button>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}

                      {+wallet?.usersetting?.AllowWalletRedeem === 1 ? (
                        <React.Fragment>
                          <tr>
                            <td>Redeem</td>
                            <td colSpan={2}>
                              <div className="input-group input-group-md">
                                <InputGroup.Text id="basic-addon2">
                                  $
                                </InputGroup.Text>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="RedeemAmount"
                                  ref={RedeemAmount}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Remaining Service Balance </td>
                            <td colSpan={2} align={"right"}>
                              ${balance}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}> </td>
                            <td>
                              <Button
                                onClick={redeembucks}
                                className="btn btnclass btn-info float-right mb-5"
                              >
                                Redeem Bucks
                              </Button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  {wallet?.dealersetting?.WalletCurrencyName} History
                  <div className="float-right">
                    Current Balance :{" "}
                    <span
                      className="text-success CurrenBalance"
                      style={{ color: "#5cb85c", fontWeight: "bold" }}
                    >
                      ${balance}
                    </span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Transaction</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wallet?.contractbucks?.map((item, i) => (
                        <tr>
                          <td>{item.Name}</td>
                          <td>$ {item.xp_point}</td>
                          <td>{moment(item.pointdate).format("MM/DD/YYYY")}</td>
                          <td>{balance}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <small className="help-block text-danger">{error}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={Close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
