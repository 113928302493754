const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: JSON.parse(localStorage.getItem("leftmenu")) || false,
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      localStorage.setItem("leftmenu", !state.sidebarUnfoldable);
      return { ...state, ...rest };
    default:
      return state;
  }
};

const Sidebarstore = changeState;
export default Sidebarstore;
