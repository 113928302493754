import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { changeplanstatus } from "./PlanApi";
export default function PlanStatus(props) {
  const Reasontext = useRef("");
  const { addToast } = useToasts();
  const {
    handleClose,
    currentplan,
    formvalues,
    setPlanList,
    setPage,
    setCount,
    page,
    sortdata,
  } = props;
  console.log("formvalues", formvalues);
  console.log("currentplan", currentplan);
  const savechanges = async () => {
    if (Reasontext.current.value === "") {
      addToast("Please enter reason", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      const formdata = new FormData();
      // status of plan
      formdata.append("PlanStatus", +currentplan.Status === 1 ? "0" : "1");
      formdata.append("PlanSortByCol", sortdata.columnName);
      formdata.append("PlanSortByColType", sortdata.direction);
      formdata.append("offset", page);

      formdata.append("status", formvalues.status);
      formdata.append("planstatus", formvalues.planstatus);

      formdata.append("planname", formvalues.planname);
      formdata.append("DealerID", formvalues.DealerID);
      formdata.append("PlanID", currentplan.PlanID);
      formdata.append("Reason", Reasontext.current.value);
      const api_res = await changeplanstatus(formdata);
      if (api_res.success === 1) {
        addToast(api_res.message, {
          appearance: "success",
          autoDismiss: true,
        });
        setPlanList(api_res.plans);
        setCount(api_res.TotalRec);
        setPage(api_res.offset);
        handleClose();
      } else {
        addToast("An error occurred", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };
  return (
    <Modal show={true} onHide={handleClose} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          {+currentplan.Status === 0 ? "Activate Plan" : "Inactivate Plan"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="alert alert-danger">
          {+currentplan.Status === 1
            ? "Are you sure you want to Inactive selected Plan?"
            : "Are you sure you want to Active selected Plan?"}
        </div>
        <form
          method="post"
          action="https://modern.mypcp.us/plan/changeplanstatus/213355/3"
          id="activity_url_form"
        >
          <div className="row">
            <div className="col-lg-12">
              <label classname="control-label" htmlfor="name">
                Enter reason for &nbsp;
                {+currentplan.Status === 1 ? "Inactive" : "Active"} Plan
              </label>
              <textarea
                id="DeletionReason"
                name="DeletionReason"
                style={{ textTransform: "uppercase" }}
                placeholder="Enter reason ...."
                className="form-control"
                required="required"
                ref={Reasontext}
              />
              <small className="help-block text_red">
                * Reason for {+currentplan.Status === 1 ? "Inactive" : "Active"}
                Plan. Minimum 10 character
              </small>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={savechanges}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
