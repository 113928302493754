import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Table,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import PreLoad from "./PreLoad";
import { customer_note, savecallnote } from "./CustomerCallLog_Api";
export default function CustomerCallLog(props) {
  const [error, setError] = useState("");
  const [contractinfo, setContractinfo] = useState("");
  const [callnotes, setCallnotes] = useState("");
  const { contractid, f_customerlog } = props;
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const calllog = useFormik({
    initialValues: {
      call_date: "",
      call_notes: "",
      callcepp: "N",
      callknowmypcp: "N",
      callvisit: "N",
      callsales: "N",
      callservice: "N",
      callwebapp: "N",
    },
    onSubmit: async (data) => {
      setError("");
      setLoading(true);
      const formData = new FormData();
      formData.append("ContractID", contractid);
      Object.keys(data).forEach((key) => formData.append(key, data[key]));
      const savecallnote_response = await savecallnote(formData);
      setLoading(false);
      calllog.resetForm();
      if (savecallnote_response.success === 1) {
        setCallnotes(savecallnote_response.getcustomercallnotes);
        addToast(savecallnote_response.message, {
          appearance: "success",
          autoDismiss: true,
        });
      } else if (savecallnote_response.success === 0) {
        setError(savecallnote_response.message);
      }
    },
  });
  const Close = () => {
    f_customerlog();
    calllog.resetForm();
  };
  useEffect(() => {
    get_customer_call_log();
  }, [contractid]);
  async function get_customer_call_log() {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("ContractID", contractid);
    const customer_note_response = await customer_note(formdata);
    setLoading(false);

    if (customer_note_response.success == 1) {
      setContractinfo(customer_note_response.getcontractbyid);
      setCallnotes(customer_note_response.getcustomercallnotes);
    } else if (customer_note_response.success == 0) {
      addToast(customer_note_response.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  return (
    <React.Fragment>
      <PreLoad loading={loading} />
      <Modal show={true} size={"lg"} onHide={Close}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Call Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div className="col-lg-12 text_20 text_green">
                  {contractinfo?.ContractID}
                </div>
                <div className="col-lg-12 text_gray">
                  {contractinfo?.CustomerFName +
                    " " +
                    contractinfo?.CustomerLName}
                </div>
              </div>
              <div
                className="col-lg-9"
                style={{
                  textAlign: "left",
                }}
              >
                <Table className="table borderlesstable">
                  <tbody>
                    <tr>
                      <th className="text_blue">Plan</th>
                      <td>{contractinfo?.PlanDescription}</td>
                    </tr>
                    <tr>
                      <th className="text_blue">Expiration Date</th>
                      <td>
                        {contractinfo
                          ? moment
                              .unix(contractinfo.ValidityDate)
                              .format("MM/DD/YYYY")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="text_blue">Expiration Mileage</th>
                      <td>{contractinfo?.ValidityMileage}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-1 text-right">
                <img
                  src={
                    require(`../resources/images/customer-call-log-button.png`)
                      .default
                  }
                  alt="call-log"
                  className="img-icon"
                />
              </div>
            </div>
            <form onSubmit={calllog.handleSubmit}>
              <div className="row">
                <div className="col-lg-2 text-left"></div>
                <div className="col-lg-10 text-left">
                  <div className="form-group">
                    <label>Call Date</label>
                    <div className="input-group" id="datetimepicker1">
                      <input
                        type="date"
                        style={{ background: "transparent" }}
                        className="form-control RDate validate[required]"
                        name="call_date"
                        value={calllog.values.call_date}
                        onChange={calllog.handleChange}
                        required={true}
                      />
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-calendar"></span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <label>Conversation Notes</label>
                    <textarea
                      name="call_notes"
                      value={calllog.values.call_notes}
                      onChange={calllog.handleChange}
                      rows="5"
                      className="form-control validate[required]"
                    ></textarea>
                    <Button
                      name="save_call_note"
                      type="submit"
                      disabled={calllog.isSubmitting}
                      className="btn btn-yellow float-right mt-2"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12" id="msgcallnot"></div>
              </div>
              <div className="row">
                <div className="col-lg-2 "></div>
                <div className="col-lg-10 text-center mt-4">
                  <Table
                    striped
                    bordered
                    style={{
                      marginTop: 25,
                    }}
                  >
                    <tbody>
                      <tr>
                        <th className="width-20 pad-10"></th>
                        <th className="width-20 pad-10">YES</th>
                        <th className="width-20 pad-10">NO</th>
                        <th className="width-20 pad-10">N/A</th>
                        <th></th>
                      </tr>
                      <tr>
                        <td className="width-20 pad-10">
                          <i
                            className="fa fa-square"
                            style={{ color: "black" }}
                          ></i>
                        </td>
                        <td className="width-20 pad-10">
                          <input
                            id="radio1"
                            value="Y"
                            onChange={calllog.handleChange}
                            name="callcepp"
                            className="styled"
                            type="radio"
                          />
                        </td>
                        <td className="width-20 pad-10">
                          <input
                            id="radio2"
                            value="N"
                            name="callcepp"
                            onChange={calllog.handleChange}
                            className="styled"
                            type="radio"
                            defaultChecked={true}
                          />
                        </td>
                        <td className="width-20 pad-10">
                          <input
                            id="radio3"
                            value="N/A"
                            onChange={calllog.handleChange}
                            name="callcepp"
                            className="styled"
                            type="radio"
                          />
                        </td>
                        <td className="pad-10">
                          CEP sent email with login info
                        </td>
                      </tr>

                      <tr>
                        <td className="width-20">
                          <i
                            className="fa fa-square"
                            style={{ color: "rgb(0,153,204)" }}
                          ></i>
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            name="callknowmypcp"
                            onChange={calllog.handleChange}
                            value="Y"
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            name="callknowmypcp"
                            onChange={calllog.handleChange}
                            value="N"
                            defaultChecked={true}
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callknowmypcp"
                            value="N/A"
                          />
                        </td>
                        <td>Customer knew about their MyPCP Program</td>
                      </tr>

                      <tr>
                        <td className="width-20">
                          <i
                            className="fa fa-square"
                            style={{ color: "rgb(0,153,204)" }}
                          ></i>
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callvisit"
                            value="Y"
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callvisit"
                            value="N"
                            defaultChecked={true}
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callvisit"
                            value="N/A"
                          />
                        </td>
                        <td>
                          Customer visits are matching the service history
                        </td>
                      </tr>
                      <tr>
                        <td className="width-20">
                          <i
                            className="fa fa-square"
                            style={{ color: "rgb(255,51,51)" }}
                          ></i>
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callsales"
                            value="Y"
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callsales"
                            value="N"
                            defaultChecked={true}
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callsales"
                            value="N/A"
                          />
                        </td>
                        <td>
                          Customer was satisfied with the sales department
                        </td>
                      </tr>
                      <tr>
                        <td className="width-20">
                          <i
                            className="fa fa-square"
                            style={{ color: "rgb(102,204,0)" }}
                          ></i>
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callservice"
                            value="Y"
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callservice"
                            value="N"
                            defaultChecked={true}
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callservice"
                            value="N/A"
                          />
                        </td>
                        <td>
                          Customer was satisfied with the service department
                        </td>
                      </tr>
                      <tr>
                        <td className="width-20">
                          <i
                            className="fa fa-square"
                            style={{ color: "rgb(102,102,255)" }}
                          ></i>
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callwebapp"
                            value="Y"
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callwebapp"
                            value="N"
                            defaultChecked={true}
                          />
                        </td>
                        <td className="width-20">
                          <input
                            type="radio"
                            onChange={calllog.handleChange}
                            name="callwebapp"
                            value="N/A"
                          />
                        </td>
                        <td>
                          Customer has ability to access web portal or app
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </form>
            <Table className=" table borderlesstable">
              <thead>
                <tr>
                  <th>Call Date</th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Notes
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {typeof callnotes != "undeifned" && callnotes != "" ? (
                  callnotes.map((item, i) => (
                    <tr key={i}>
                      <td className="col-sm-2">
                        {moment(item.call_date).format("MM/DD/YYYY")}
                      </td>
                      <td
                        className="col-sm-7"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {item.call_notes}
                      </td>
                      <td className="col-sm-3">
                        <Table width="100%">
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  background: "black",
                                  color: "#fff",
                                  textAlign: "center",
                                  padding: 5,
                                  verticalAlign: "middle",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.callcepp}
                              </td>
                              <td
                                style={{
                                  background: "rgb(0,153,204)",
                                  color: "#fff",
                                  textAlign: "center",
                                  padding: 5,
                                  verticalAlign: "middle",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.callknowmypcp}
                              </td>
                              <td
                                style={{
                                  background: "rgb(255,204,0)",
                                  color: "#fff",
                                  textAlign: "center",
                                  padding: 5,
                                  verticalAlign: "middle",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.callvisit}
                              </td>
                              <td
                                style={{
                                  background: "rgb(255,51,51)",
                                  color: "#fff",
                                  textAlign: "center",
                                  padding: 5,
                                  verticalAlign: "middle",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.callsales}
                              </td>
                              <td
                                style={{
                                  background: "rgb(102,204,0)",
                                  color: "#fff",
                                  textAlign: "center",
                                  padding: 5,
                                  verticalAlign: "middle",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.callservice}
                              </td>
                              <td
                                style={{
                                  background: "rgb(102,102,255)",
                                  color: "#fff",
                                  textAlign: "center",
                                  padding: 5,
                                  verticalAlign: "middle",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.callwebapp}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} align="center">
                      <div className="alert alert-info text-center">
                        No Call History
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <div className="row">
              <div className="col-lg-4"></div> <div className="col-lg-7"></div>
              <div className="col-lg-1">
                <a
                  href={
                    process.env.REACT_APP_APP_URL +
                    "/contract/exportcallnote/" +
                    contractid
                  }
                  target="_blank"
                  className="btn btn-success"
                >
                  Export
                </a>
              </div>
            </div>
          </div>
          <small className="help-block text-danger">{error}</small>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
