import dot_green from "../../resources/c_type_img/dot_green.jpg";
import dot_blue from "../../resources/c_type_img/dot_blue.jpg";
import dot_orange from "../../resources/c_type_img/dot_orange.jpg";
import dot_blackorange from "../../resources/c_type_img/dot_blackorange.png";
import dot_red from "../../resources/c_type_img/dot_red.jpg";
import dot_black from "../../resources/c_type_img/dot_black.jpg";
import partially_matured from "../../resources/c_type_img/partially_matured.png";
import pending_mature from "../../resources/c_type_img/pending_mature.jpg";
import purple_ball from "../../resources/c_type_img/purple_ball.jpg";
import indigo_ball from "../../resources/c_type_img/indigo_ball.png";
import redeem from "../../resources/c_type_img/redeem.png";
import subscription_canceled from "../../resources/c_type_img/subscription_canceled.png";
import maintanance_products from "../../resources/c_type_img/maintanance_products.png";
import gifticon from "../../resources/c_type_img/gift-icon.png";
export const ContractStatus = [
  {
    id: "L",
    text: "Classic Active",
    value: "L",
    image: {
      avatar: true,
      src: dot_green,
    },
  },
  {
    id: "E",
    text: "Express Active",
    value: "E",
    image: {
      avatar: true,
      src: dot_blue,
    },
  },
  {
    id: "I",
    text: "Pending Contract",
    value: "I",
    image: {
      avatar: true,
      src: dot_orange,
    },
  },
  {
    id: "B",
    text: "Pending Batch Contract",
    value: "B",
    image: {
      avatar: true,
      src: dot_blackorange,
    },
  },
  {
    id: "C",
    text: "Cancelled Contract",
    value: "C",
    image: {
      avatar: true,
      src: dot_red,
    },
  },
  {
    id: "M",
    text: "Matured Contract",
    value: "M",
    image: {
      avatar: true,
      src: dot_black,
    },
  },
  {
    id: "Y",
    text: "Partially Contract",
    value: "Y",
    image: {
      avatar: true,
      src: partially_matured,
    },
  },
  {
    id: "P",
    text: "Pending Matured",
    value: "P",
    image: {
      avatar: true,
      src: pending_mature,
    },
  },
  {
    id: "MS",
    text: "Mark Services",
    value: "MS",
    image: {
      avatar: true,
      src: purple_ball,
    },
  },
  {
    id: "PC",
    text: "Perpetual Contract",
    value: "PC",
    image: {
      avatar: true,
      src: indigo_ball,
    },
  },
  {
    id: "R",
    text: "Redeem Services",
    value: "R",
    image: {
      avatar: true,
      src: redeem,
    },
  },
  {
    id: "A",
    text: "Suspended Account",
    value: "A",
    image: {
      avatar: true,
      src: subscription_canceled,
    },
  },
  {
    id: "SC",
    text: "Subscription Contract",
    value: "SC",
    image: {
      avatar: true,
      src: maintanance_products,
    },
  },
  {
    id: "1",
    text: "Gifted",
    value: "1",
    image: {
      avatar: true,
      src: gifticon,
    },
  },
];
export const contractsearchby = [
  { id: "all", text: "ALL", value: "" },
  { id: "Normal", text: "Normal", value: 0 },
  { id: "Guest", text: "Guest", value: 1 },
  { id: "VSC", text: "VSC", value: 2 },
];
