import { React, useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
export default function DealersDropdown(props) {
  const dealershiplist = useSelector((state) => state.Menustore.dealerships);

  const { id, data, handledealerchange, disabled } = props;
  const [dealersdata, setDealersdata] = useState(dealershiplist || []);
  const [value, setvalue] = useState(props.deafultvalue);
  const handlechange = (e, result) => {
    const { name, value } = result || e.target;
    setvalue(value);
    handledealerchange(value);
  };
  useEffect(() => {
    if (dealershiplist) {
      setDealersdata(dealershiplist);
    }
  }, [dealershiplist]);
  return (
    <Dropdown
      placeholder="Dealer"
      search
      fluid
      disabled={disabled}
      value={props.deafultvalue}
      onChange={handlechange}
      selection
      options={dealersdata}
    />
  );
}
