import { React, useState, useEffect } from "react";
import { Modal, Button, Table, Card } from "react-bootstrap";
import moment from "moment";
import "./loyaltycash.css";
import PreLoad from "../../../components/PreLoad";
import { redemptionreward, add_xprewardapi } from "./LoyaltyCashApi";
import DeleteRedemptionRewards from "./DeleteRedemptionRewards";
require("dotenv").config();
export default function RedemptionRewards(props) {
  const { show, f_redemptionmodal, contractid, p_servicelistredeem } = props;
  const [error, setError] = useState(props.error);
  const [loading, setLoading] = useState(false);

  const [deletexprewardmodal, setDeletexprewardmodal] = useState(false);
  const [currentitem, setCurrentitem] = useState("");
  const [dealerid, setDealerid] = useState("");
  const [success, setSuccess] = useState("");
  const [totalpoints, setTotalpoints] = useState("");
  const [servicereward, setServicereward] = useState("");
  const [rewardlist, setRewardlist] = useState("");
  const [servicelistredeem, setServicelistredeem] =
    useState(p_servicelistredeem);
  const handleClose = () => {
    f_redemptionmodal("", servicelistredeem);
  };
  const deleterewadshandlemodal = (data) => {
    setDeletexprewardmodal(!deletexprewardmodal);
    if (typeof data != "undefined") {
      setSuccess(data.messages);
      setRewardlist(data.rewardlist);
      setServicelistredeem(data.servicelistredeem);
    }
  };
  useEffect(() => {
    setSuccess("");
    if (contractid != "") {
      getdata();
      setLoading(true);
    }
  }, [contractid]);
  async function getdata() {
    const formData = new FormData();
    formData.append("ContractID", contractid);
    const xprewardsapi = await redemptionreward({ formData });
    if (xprewardsapi.success == 1) {
      setDealerid(+xprewardsapi.DealerID);
      setServicereward(xprewardsapi.servicereward);
      setRewardlist(xprewardsapi.rewardlist);
      setTotalpoints(xprewardsapi.totalpoints);
      setServicelistredeem(xprewardsapi.servicelistredeem);
    }
    setLoading(false);
  }
  const deletexprewards = (item) => {
    setCurrentitem(item);
    setDeletexprewardmodal(!deletexprewardmodal);
  };
  const addxpreward = async (item) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("ContractID", contractid);
    formData.append("DealerID", +item.DealerID);
    formData.append("xp_points", +item.xp_points);
    formData.append("name", item.Reward);
    formData.append("id", +item.xp_reward_id);

    const add_xpreward = await add_xprewardapi({ formData });
    if (add_xpreward.success == 1) {
      setRewardlist(add_xpreward.rewardlist);
      setTotalpoints(add_xpreward.totalpoints);
      setServicelistredeem(add_xpreward.servicelistredeem);
    }
    setLoading(false);
  };
  return (
    <div>
      <PreLoad loading={loading} />
      <DeleteRedemptionRewards
        show={deletexprewardmodal}
        error=""
        DealerID={dealerid}
        deleterewadshandlemodal={deleterewadshandlemodal}
        item={currentitem}
      />
      <Modal
        dialogClassName="redemption-modal"
        size={"xl"}
        show={show}
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title> Redemption Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              {success != "" ? (
                <div className="alert alert-success">{success}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-5">
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>Services</span>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Reward Name</th>

                        <th>Points</th>
                        <th style={{ width: 120 }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {servicereward != ""
                        ? servicereward.map((item) => (
                            <tr>
                              <td>{item.Reward}</td>
                              <td>{item.xp_points}</td>
                              <td>
                                {totalpoints != "" &&
                                totalpoints >= item.xp_points ? (
                                  <Button
                                    onClick={() => addxpreward(item)}
                                    className="btn btn-sm btn-success mt-10"
                                  >
                                    <i className="fas fa-plus"></i> Reward
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>

            <div className="col-7">
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  Rewards
                </Card.Header>
                <Card.Body>
                  <Table responsive className="table-color">
                    <thead>
                      <tr>
                        <th>Reward Name</th>
                        <th>Date</th>
                        <th>Points</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rewardlist != "" && typeof rewardlist != "undefined"
                        ? rewardlist.map((item) => (
                            <tr>
                              <td>{item.Name}</td>
                              <td>
                                {moment(item.pointdate).format("MM/DD/YYYY")}
                              </td>
                              <td>{item.xp_point}</td>
                              <td>
                                <a
                                  onClick={() => deletexprewards(item)}
                                  className="btn btn-danger btn-xs"
                                >
                                  <i className="far fa-trash-alt"></i>
                                </a>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
