import axios from "axios";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
const token = JSON.parse(localStorage.getItem("token"));
const API = process.env.REACT_APP_APP_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const header = {
  "Content-Type": "application/json",
};
export const plan_create_step_2 = async (formData) => {
  let url = API + "/plan/plan_create_step_2";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const account_preset_list = async (formData) => {
  let url = API + "/plan/account_preset_list";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const AddNewServiceFromPlanDetail = async (formData) => {
  let url = API + "/plan/AddNewServiceFromPlanDetail";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
