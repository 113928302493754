import axios from "axios";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
const token = JSON.parse(localStorage.getItem("token"));
const API = process.env.REACT_APP_APP_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const header = {
  "Content-Type": "application/json",
};
export const redemptionreward = async ({ formData }) => {
  let url = API + "/contract/xprewards";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const Addxpredeem = async ({ formData }) => {
  let url = API + "/contract/xpredeem";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const deletexpreward = async ({ formData }) => {
  let url = API + "/contract/deletedxpreward";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const add_xprewardapi = async ({ formData }) => {
  let url = API + "/contract/xpreward";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const deletexpoints = async ({ formData }) => {
  let url = API + "/contract/deletexppoint";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const savenewcarvin = async ({ formData }) => {
  let url = API + "/contract/savenewcarvin";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const loyaltyreward = async ({ formData }) => {
  let url = API + "/contract/loyaltyreward";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const redeemloyaltycash = async ({ formData }) => {
  let url = API + "/contract/redeemloyaltycash";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const deletenewcarinfo = async ({ formData }) => {
  let url = API + "/contract/deletenewcarinfo";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const editcarinfo = async ({ formData }) => {
  let url = API + "/contract/updatenewcarvin";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const disablenewcarinfo = async ({ formData }) => {
  let url = API + "/contract/disablenewcarinfo";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
