import React, { useState, useEffect } from "react";
import { Modal, Table, Spinner } from "react-bootstrap";
import { vehicheinfohistory } from "./EditContractApi";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
export function VinHistory(props) {
  const [error, setError] = useState("");
  const [loading, SetLoading] = useState(false);
  const [vehicheinfohistorylist, setVehicheinfohistorylist] = useState("");
  const { addToast } = useToasts();
  const { contractid, f_vinhistory } = props;
  const Close = () => {
    f_vinhistory();
  };
  useEffect(() => {
    get_car_info();
  }, [contractid]);
  async function get_car_info() {
    SetLoading(true);
    const formdata = new FormData();
    formdata.append("ContractID", contractid);
    const vehicheinfohistory_response = await vehicheinfohistory(formdata);
    SetLoading(false);
    if (vehicheinfohistory_response.success == 1) {
      setVehicheinfohistorylist(vehicheinfohistory_response.vehicheinfohistory);
    } else if (vehicheinfohistory_response.success == 0) {
      addToast(vehicheinfohistory_response.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }
  return (
    <React.Fragment>
      <Modal show={true} size={"lg"} onHide={Close}>
        <Modal.Header closeButton>
          <Modal.Title>Make/VIN Change History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Sr.#</th>
                <th>Date</th>
                <th>Make</th>
                <th>VIN</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            {loading ? (
              <span style={{ position: "fixed", left: "50%" }}>
                <Spinner animation="border" variant="success" />
              </span>
            ) : (
              <tbody>
                {typeof vehicheinfohistorylist != "undefined" &&
                vehicheinfohistorylist != "" ? (
                  vehicheinfohistorylist?.map((item, i) => (
                    <tr>
                      <td>{i++}</td>
                      <td>{moment(item.PostedDate).format("DD/MM/YYYY")}</td>
                      <td>{item.Make}</td>
                      <td>{item.VIN}</td>
                      <td>
                        {item.ChangeType == 1 ? (
                          <span className="badge badge-primary">
                            Vehicle VIN Change
                          </span>
                        ) : item.ChangeType == 2 ? (
                          <span className="badge badge-success">
                            Vehicle Make Change
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} align="center">
                      <div className="alert alert-info text-center">
                        No History
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </Table>
          <small className="help-block text-danger">{error}</small>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
