import React, { useState, useRef } from "react";
import useToken from "../useToken";
import LoginLoad from "../../components/LoginLoad";
import ContactUs from "../../components/ContactUs";
import axios from "axios";

import { useToasts } from "react-toast-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import "./Loginpage.css";
import Carousel from "react-bootstrap/Carousel";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
require("dotenv").config();
function Login() {
  const dispatch = useDispatch();
  const loginAxiosInstance = axios.create();
  const { token, setToken } = useToken();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [captcha_value, setCaptcha_value] = useState("");
  const [error, setError] = useState(false);
  const [forgotpass, setForgotpass] = useState(false);
  const [contactus, setContactus] = useState(false);
  const { addToast } = useToasts();
  const user_name = useRef(null);
  const user_password = useRef(null);
  const forgot_user = useRef(null);
  function onChange(value) {
    setCaptcha_value(value);
  }
  const handlelogin = () => {
    let username = user_name.current.value;
    let password = user_password.current.value;
    if (username == "" || password == "") {
      if (username == "" && password == "") {
        var obj = JSON.parse(
          "{ \"msg\": \"<div class='ui-state-error ui-corner-all ui-msg error-login'><h3></h3><img class='pass-img' alt='error' src='/images/icon-error.png'><p><span  style='float: left; margin-right: .3em;padding-top:10px'></span> Please, Enter your username and password.</p></div>\" }"
        );
        jq_msg(obj, 5000);
      } else if (username == "") {
        var obj = JSON.parse(
          "{ \"msg\": \"<div class='ui-state-error ui-corner-all ui-msg error-login'><h3></h3><img class='pass-img' alt='error' src='/images/icon-error.png'><p><span  style='float: left; margin-right: .3em; padding-top:10px'></span>Please, Enter your username.</p></div>\" }"
        );
        jq_msg(obj, 5000);
      } else if (password == "") {
        var obj = JSON.parse(
          "{ \"msg\": \"<div class='ui-state-error ui-corner-all ui-msg error-login'><h3></h3><img class='pass-img' alt='error' src='/images/icon-error.png'><p><span  style='float: left; margin-right: .3em; padding-top:10px'></span>Please, Enter your Password.</p></div>\" }"
        );
        jq_msg(obj, 5000);
      }
    } else {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("g-recaptcha-response", captcha_value);
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
      };

      loginAxiosInstance
        .post(
          process.env.REACT_APP_APP_URL + "/loginreactcopy/check_login",
          formData,

          { headers }
        )
        .then((response) => {
          if (response.data.success == "success") {
            console.log(response.data);
            setLoading(false);
            localStorage.setItem("user", JSON.stringify(response.data));
            //set user uuid in local storage
            localStorage.setItem("user_id", JSON.stringify(uuidv4()));
            setToken(response.data.Token);
            dispatch({ type: "set", menulist: response.data.menu });
            //dispatch({ type: "set", sidemenu: response.data.menu });
            //window.location.href = response.data.url;
            window.location.href = "/contracts/summary";
          } else if (response.data.success == "error") {
            setLoading(false);
            addToast(response.data.msg, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const jq_msg = (b, a) => {
    setError(true);
    setMessage(b.msg);

    setTimeout(() => setError(false), 5000);
  };
  const handleforgotpass = () => {
    setForgotpass(!forgotpass);
  };
  const f_forgotpass = () => {
    let forgot_username = forgot_user.current.value;
    if (forgot_username == "") {
      var obj = JSON.parse(
        "{ \"msg\": \"<div className='ui-state-error ui-corner-all ui-msg'><h3></h3><img className='pass-img' alt='error' src='/images/icon-error.png'><p><span  style='float: left; margin-right: .3em;'></span>Please, Enter your username.</p></div>\" }"
      );
      jq_msg(obj, 3000);
    } else {
      const formData = new FormData();
      formData.append("username", forgot_username);
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
      };

      loginAxiosInstance
        .post(
          process.env.REACT_APP_APP_URL +
            "/loginreactcopy/forget_password_retrive",
          formData,

          { headers }
        )
        .then((response) => {
          if (response.data.success === 1) {
            setLoading(false);
            addToast(response.data.msg, {
              appearance: "success",
              autoDismiss: true,
            });
            setForgotpass(!forgotpass);
          } else if (response.data.success === 0) {
            setLoading(false);
            addToast(response.data.msg, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handlecontact = () => {
    setContactus(!contactus);
  };
  return (
    <React.Fragment>
      <LoginLoad loading={loading} />
      {contactus && <ContactUs handlecontact={handlecontact} />}
      <div id="myPCPSlier">
        <div className="form-sec text-center">
          <img
          alt='logo'
            src={require("../../resources/images/logo.png").default}
            className="img-fluid logo"
          />
          <p className="text-white" style={{ fontWeight: 500 }}>
            Preferred Customer Program
          </p>
          {forgotpass ? (
            <form className="pt-3 m-auto" style={{ position: "relative" }}>
              {error ? (
                <div
                  id="jq_msg"
                  dangerouslySetInnerHTML={{ __html: message }}
                ></div>
              ) : (
                ""
              )}
              <div className="form-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Login ID"
                  name="username"
                  title="Enter Login/Email Address"
                  ref={forgot_user}
                />
              </div>

              <div className="clear pad-5"></div>
              <div className="row">
                <div className="text-right col-lg-12 col-md-12 col-sm-12 col-sm-12">
                  <button
                    name="submit"
                    id="submit"
                    type="button"
                    onClick={f_forgotpass}
                    className="btn btn-primary login-btn mt-4"
                  >
                    Forgot Password
                  </button>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <a href="#" onClick={handleforgotpass} className="text-white">
                  Login
                </a>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                  />
                  <label
                    className="form-check-label"
                    style={{ color: "#4CA5E1" }}
                  >
                    <span style={{ fontWeight: 500 }}>Remember</span>
                  </label>
                </div>
              </div>
              <p className="text-white mt-4 mb-4" style={{ fontWeight: 500 }}>
                Customer Support
              </p>
            </form>
          ) : (
            <form className="pt-3 m-auto" style={{ position: "relative" }}>
              {error ? (
                <div
                  id="jq_msg"
                  dangerouslySetInnerHTML={{ __html: message }}
                ></div>
              ) : (
                ""
              )}
              <div className="form-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Login ID"
                  name="username"
                  title="Enter Login/Email Address"
                  ref={user_name}
                />
              </div>
              <div className="form-group mb-2">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  title="password"
                  ref={user_password}
                />
              </div>
              <div className="clear pad-5"></div>
              <div className="row">
                <div className="col-lg-9 col-md-9 col-sm-9 col-sm-9">
                  <ReCAPTCHA
                    sitekey={`${process.env.REACT_APP_CAPTCHA}`}
                    onChange={onChange}
                    size={"normal"}
                  />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-sm-3">
                  <button
                    name="submit"
                    id="submit"
                    type="button"
                    onClick={handlelogin}
                    className="btn btn-primary login-btn w-100 mt-4"
                  >
                    Login
                  </button>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <a href="#" onClick={handleforgotpass} className="text-white">
                  Forgot Password?
                </a>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                  />
                  <label
                    className="form-check-label"
                    style={{ color: "#4CA5E1" }}
                  >
                    <span style={{ fontWeight: 500 }}>Remember</span>
                  </label>
                </div>
              </div>
              <p className="text-white mt-4 mb-4" style={{ fontWeight: 500 }}>
                Customer Support
              </p>
            </form>
          )}
          <div
            className="btn-group w-100"
            role="group"
            aria-label="Basic example"
          >
            <a
              href="tel:251-990-3131"
              type="button"
              className="btn btn-primary w-100 rounded-0 d-flex justify-content-around align-items-center"
            >
              <img src={require("../../resources/images/call.png").default}
              alt="call" />
              <span>251-990-3131</span>
            </a>
            <a
              href="#"
              onClick={handlecontact}
              type="button"
              className="btn btn-primary w-100 rounded-0 d-flex justify-content-around align-items-center"
            >
              <img src={require("../../resources/images/email.png").default} alt="email" />
              <span>support@procarma.com</span>
            </a>
          </div>
        </div>

        <div className="para-sec mt-4 pt-4 pb-4 mb-5">
          <div className="d-flex justify-content-between align-items-center">
            <div className="ml-5 mr-5">
              <img
                src={require("../../resources/images/icons.png").default}
                className="img-fluid"
                alt="icons"
              />
            </div>
            <div className="mr-5 ml-5">
              <p className="text-white mb-1" style={{ fontSize: 14 }}>
                Track your available services and history
              </p>
              <p className="text-white mb-1" style={{ fontSize: 14 }}>
                Share your services with friends
              </p>
              <p className="text-white mb-1" style={{ fontSize: 14 }}>
                Schedule appointments with dealership
              </p>
              <p className="text-white mb-1" style={{ fontSize: 14 }}>
                Chat help directly through your app or site
              </p>
              <p className="text-white m-0" style={{ fontSize: 14 }}>
                Save other vehicle related information
              </p>
            </div>
          </div>
        </div>
      </div>

      <Carousel id="myPCPSlier">
        {[...Array(23)].map((x, i) => (
          <Carousel.Item key={i}>
            <img
              className="d-block w-100"
              src={
                require(`../../resources/images/slider-img${i + 1}.jpg`).default
              }
              alt={`slider-${i}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </React.Fragment>
  );
}
export default Login;
