import React, { useState, useEffect } from "react";

import PreLoad from "../../../components/PreLoad";
import { contractinfoapi } from "./EditContractApi";
export default function Printselectedservice() {
  const [loading, setLoading] = useState(false);
  const [htmlview, setHtmlview] = useState("");
  useEffect(() => {
    getdata();
    setLoading(true);
  }, []);
  async function getdata() {
    const formData = new FormData();
    formData.append(
      "ContractID",
      parseInt(JSON.parse(localStorage.getItem("contract_id_coupon")))
    );
    Object.keys(JSON.parse(localStorage.getItem("coupons"))).forEach((key) =>
      formData.append("couponID[]", parseInt(key))
    );
    const contractinfo_response = await contractinfoapi(formData);
    setLoading(false);
    localStorage.removeItem("coupons");
    if (contractinfo_response.success == 1) {
      setHtmlview(contractinfo_response.html);
    }
  }
  const print = () => {
    window.print();
  };
  return (
    <React.Fragment>
      <PreLoad loading={loading} />
      <div className="printMessageBox" onClick={print}></div>

      <div dangerouslySetInnerHTML={{ __html: htmlview }}></div>
    </React.Fragment>
  );
}
