export const token = JSON.parse(localStorage.getItem("token"));
const userdata = JSON.parse(localStorage.getItem("user"));

// get user uuid
const user_uuid_id = JSON.parse(localStorage.getItem("user_id"));
export const user_id =
  userdata != null ? (userdata.user_id ? userdata.user_id : "") : "";
export const pcp_user_id =
  userdata != null ? (userdata.pcp_user_id ? userdata.pcp_user_id : "") : "";
export const role_id =
  userdata != null
    ? userdata.user_cizacl_role_id
      ? userdata.user_cizacl_role_id
      : ""
    : "";
export const user_name =
  userdata != null ? (userdata.user_name ? userdata.user_name : "") : "";
export const AllowRedeemExpiredContract =
  userdata != null
    ? userdata.AllowRedeemExpiredContract
      ? userdata.AllowRedeemExpiredContract
      : ""
    : "";
export const AllowRedeemInactiveContract =
  userdata != null
    ? userdata.AllowRedeemInactiveContract
      ? userdata.AllowRedeemInactiveContract
      : ""
    : "";
export const user_img =
  userdata != null ? (userdata.UserImage ? userdata.UserImage : "") : "";
export const user_uuid = user_uuid_id != null ? user_uuid_id : "";
