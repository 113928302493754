import moment from "moment";
export function f_customer_info(customereinfo, data) {
  let count = 0;
  const customerformdata = new FormData();
  if (customereinfo.CustomerFName !== data.CustomerFName) {
    count++;
  }
  if (customereinfo.CustomerLName !== data.CustomerLName) {
    count++;
  }
  if (customereinfo.CustomerAddressLine1 !== data.CustomerAddressLine1) {
    count++;
  }
  if (customereinfo.PrimaryEmail !== data.PrimaryEmail) {
    count++;
  }
  if (customereinfo.PhoneHome !== data.PhoneHome) {
    count++;
  }
  if (customereinfo.CityName !== data.CityName) {
    count++;
  }
  if (
    moment(data.CustomerDOB).format("YYYY-MM-DD") !==
    moment(customereinfo.CustomerDOB).format("YYYY-MM-DD")
  ) {
    count++;
  }
  if (customereinfo.StateID !== data.StateID) {
    count++;
  }
  if (customereinfo.CountryID !== data.CountryID) {
    count++;
  }
  if (customereinfo.CustomerZIP !== data.CustomerZIP) {
    count++;
  }
  if (customereinfo.DealerID !== data.DealerID) {
    count++;
  }
  if (+customereinfo.SendEmail !== data.SendEmail) {
    count++;
  }
  if (+customereinfo.SendSms !== data.SendSms) {
    count++;
  }
  if (count > 0) {
    customerformdata.append("CustomerLName", data.CustomerLName);
    customerformdata.append("CustomerAddressLine1", data.CustomerAddressLine1);
    customerformdata.append("Email", data.PrimaryEmail);
    customerformdata.append("PhoneHome", data.PhoneHome);
    customerformdata.append("CityName", data.CityName);
    customerformdata.append("CustomerDOB", data.CustomerDOB);
    customerformdata.append("StateID", data.StateID);
    customerformdata.append("CountryID", data.CountryID);
    customerformdata.append("CustomerZIP", data.CustomerZIP);
    customerformdata.append("DealerID", data.DealerID);
    customerformdata.append("SendEmail", data.SendEmail);
    customerformdata.append("CustomerFName", data.CustomerFName);
    customerformdata.append("CustomerID", customereinfo.CustomerID);
    customerformdata.append("SendSms", data.SendSms);
  }
  let return_data = [];
  return_data["fomdata"] = customerformdata;
  return_data["count"] = count;
  return return_data;
}
