import axios from "axios";
import { user_id, role_id, pcp_user_id } from "../CurrentUser";
const token = JSON.parse(localStorage.getItem("token"));
const API = process.env.REACT_APP_APP_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const header = {
  "Content-Type": "blob",
};

export const dashboardexport = async ({ formData }) => {
  let url = API + "/dashboard/dashboardexport";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.request({
      responseType: "arraybuffer",
      url: url,
      method: "post",
      data: formData,
      formData,
      header,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const subscriptionexport = async ({ formData }) => {
  let url = API + "/dashboard/subscriptionexport";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.request({
      responseType: "arraybuffer",
      url: url,
      method: "post",
      data: formData,
      formData,
      header,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const allowmenu = async (formData) => {
  let url = API + "/loginreactcopy/allowmenu";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.request({
      responseType: "arraybuffer",
      url: url,
      method: "post",
      data: formData,
      formData,
      header,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
