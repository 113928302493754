import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./AddServices.css";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import { AddNewServiceFromPlanDetail } from "./plancreateapi";
export default function AddServices(props) {
  const dispatch = useDispatch();
  const { close, newservicelist, setNewservicelist } = props;

  const [shownewadd, setShownewadd] = useState(false);

  const validationSchema = yup.object().shape({
    ser_no: yup.string().required("Required"),

    ser_unit_price: yup.string().required("Required"),
  });
  const serviceform = useFormik({
    initialValues: {
      ser_no: "",
      ser_type: "",
      ser_unit_price: "",
      ser_mile_reminder: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      let newdata = newservicelist[+data.ser_type];
      data.ser_type = newdata;

      dispatch({ type: "set", ServicesList: data });
    },
  });
  return (
    <Modal show={true} centered={true}>
      <Modal.Header>
        <Modal.Title>Service List</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ backgroundColor: "#f7c632" }}>
        <div className="service-popup">
          {shownewadd ? (
            <AddNewservice
              setNewservicelist={setNewservicelist}
              close={close}
            />
          ) : (
            <div className="row">
              <div className="col-sm-2 col-xs-2 col-md-2">
                <div className="form-group">
                  <label>No</label>
                  <input
                    type="text"
                    name="ser_no"
                    onChange={serviceform.handleChange}
                    value={serviceform.values.ser_no}
                    className="form-control"
                  />
                </div>
                {serviceform.errors.ser_no && serviceform.touched.ser_no && (
                  <div className="input-feedback">
                    {serviceform.errors.ser_no}
                  </div>
                )}
              </div>
              <div className="col-sm-6 col-xs-6 col-md-6">
                <div className="form-group">
                  <label>Service Title</label>
                  <select
                    onChange={(e) =>
                      serviceform.setFieldValue("ser_type", e.target.value)
                    }
                    name="ser_type"
                    className="form-control"
                  >
                    <option value={-1}>select</option>
                    {newservicelist.map((item, index) => (
                      <option key={index} value={index}>
                        {item.ServiceDesc}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-2 col-xs-2 col-md-2">
                <div className="form-group">
                  <label>$</label>
                  <input
                    type="text"
                    className="form-control"
                    name="ser_unit_price"
                    onChange={serviceform.handleChange}
                    value={serviceform.values.ser_unit_price}
                  />
                </div>
                {serviceform.errors.ser_unit_price &&
                  serviceform.touched.ser_unit_price && (
                    <div className="input-feedback">
                      {serviceform.errors.ser_unit_price}
                    </div>
                  )}
              </div>
              <div className="col-sm-2 col-xs-2 col-md-2">
                <div className="form-group">
                  <label>$</label>
                  <input
                    type="text"
                    name="ser_mile_reminder"
                    onChange={serviceform.handleChange}
                    value={serviceform.values.ser_mile_reminder}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="new-service-section text-center">
            {!shownewadd ? (
              <React.Fragment>
                <Button
                  type="submit"
                  onClick={serviceform.handleSubmit}
                  variant="primary"
                >
                  Add
                </Button>{" "}
                <p className="mt-1"> OR </p>
              </React.Fragment>
            ) : null}

            <p>
              <span
                className="pointer"
                onClick={() => setShownewadd(!shownewadd)}
              >
                Add New Services
              </span>
            </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => close()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export function AddNewservice(props) {
  const { setNewservicelist, close } = props;
  const [loading, setLoading] = useState(false);
  const schema = yup
    .object({
      ServiceDesc: yup.string().required(),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    const apiresp = await AddNewServiceFromPlanDetail(formData);
    setLoading(false);
    if (apiresp.success === 1) {
      setNewservicelist(apiresp.PlanServiceData);
      close();
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-sm-12 col-xs-12 col-md-12">
        <div className="form-group">
          <label style={{ color: "#000" }}>Service Title</label>
          <input className="form-control" {...register("ServiceDesc")} />
        </div>
      </div>
      {errors.ServiceDesc && (
        <p className="text_red">{errors.ServiceDesc.message}</p>
      )}
      <div className="new-service-section text-center">
        {loading ? (
          <Spinner animation="border" variant="success" />
        ) : (
          <Button type="submit" variant="primary">
            Add
          </Button>
        )}
        <p> OR </p>
      </div>
    </form>
  );
}
