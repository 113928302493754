import React, { Component } from "react";
import "./DashboardGraphContract.css";
class DashboardGraphContract extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    var Title2 = this.props.mysampledata.Title;

    let lastmonth = this.props.mysampledata.lastmonth
      ? this.props.mysampledata.lastmonth
      : "";
    let lastmonthcontracts = this.props.mysampledata.lastmonthcontracts
      ? this.props.mysampledata.lastmonthcontracts
      : 0;
    let contract_max = this.props.mysampledata.contract_max
      ? this.props.mysampledata.contract_max
      : 0;
    let contract_avg = this.props.mysampledata.contract_avg
      ? this.props.mysampledata.contract_avg
      : 0;
    let contract_min = this.props.mysampledata.contract_min
      ? this.props.mysampledata.contract_min
      : 0;
    let contract_total = this.props.mysampledata.contract_total
      ? this.props.mysampledata.contract_total
      : 0;
    let contract_least = this.props.mysampledata.contract_least
      ? this.props.mysampledata.contract_least
      : "";
    let contract_least_difference = this.props.mysampledata
      .contract_least_difference
      ? this.props.mysampledata.contract_least_difference
      : "";
    let contract_least_duration = this.props.mysampledata
      .contract_least_duration
      ? this.props.mysampledata.contract_least_duration
      : "";

    let lastmonthservices = this.props.mysampledata.lastmonthservices
      ? this.props.mysampledata.lastmonthservices
      : "";
    let service_max = this.props.mysampledata.service_max
      ? this.props.mysampledata.service_max
      : 0;
    let service_avg = this.props.mysampledata.service_avg
      ? this.props.mysampledata.service_avg
      : 0;
    let service_min = this.props.mysampledata.service_min
      ? this.props.mysampledata.service_min
      : 0;
    let service_total = this.props.mysampledata.service_total
      ? this.props.mysampledata.service_total
      : 0;
    let service_least = this.props.mysampledata.service_least
      ? this.props.mysampledata.service_least
      : "";
    let service_least_difference = this.props.mysampledata
      .service_least_difference
      ? this.props.mysampledata.service_least_difference
      : "";
    let service_least_duration = this.props.mysampledata.service_least_duration
      ? this.props.mysampledata.service_least_duration
      : "";

    return (
      <div className="sale-service-section">
        <div className="row">
          <div className="col-md-6">
            <div className="col-left col">
              <div className="sale-col">
                <div className="figures">
                  <div className="fig-row row-one pt-0">
                    <p>
                      Most Sold:<span className="fig-bg">{contract_max}</span>
                    </p>
                  </div>
                  <div className="fig-row row-two">
                    <p>
                      Average:<span className="fig-bg">{contract_avg}</span>
                    </p>
                  </div>
                  <div className="fig-row row-three">
                    <p>
                      Least Sold:<span className="fig-bg">{contract_min}</span>
                    </p>
                  </div>
                </div>
                <div className="dates-col">
                  <div className="colored clr-blue" />
                  <div className="date-content">
                    <p>
                      <span className="fa fa-arrow-left left-arrow"></span>
                      Last Month <br />
                      {lastmonth}
                    </p>
                  </div>
                  <div className="bordered-sec bordered-left-col" />
                  <div className="difference">
                    <p>
                      <span className="fa fa-arrow-left left-arrow"></span>
                      Difference between <br />
                      most and least{" "}
                      <span className="fig-bg">
                        {" "}
                        {contract_least_difference}
                      </span>{" "}
                      <br />
                      <span className="fig-bg"> {contract_least_duration}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-right col">
              <div className="sale-col">
                <div className="figures">
                  <div className="fig-row row-one pt-0">
                    <p>
                      Most Redemptions:
                      <span className="fig-bg">{service_max}</span>
                    </p>
                  </div>
                  <div className="fig-row row-two">
                    <p>
                      Average:<span className="fig-bg">{service_avg}</span>
                    </p>
                  </div>
                  <div className="fig-row row-three">
                    <p>
                      Least Redemptions:
                      <span className="fig-bg">{service_min}</span>
                    </p>
                  </div>
                </div>
                <div className="dates-col">
                  <div className="colored clr-green" />
                  <div className="date-content">
                    <p>
                      <span className="fa fa-arrow-left left-arrow"></span>
                      Last Month <br />
                      {lastmonth}
                    </p>
                  </div>
                  <div className="bordered-sec bordered-left-col" />
                  <div className="difference">
                    <p>
                      <span className="fa fa-arrow-left left-arrow"></span>
                      Difference between most and least
                      <span className="fig-bg">{service_least_difference}</span>
                      <span className="fig-bg">{service_least_duration}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardGraphContract;
