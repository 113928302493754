const initialMenuState = {
  sidemenu: [],
  dealerships: [],
};

const Menulist = (state = initialMenuState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const Menustore = Menulist;
export default Menustore;
