import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { finalizedstatuschange } from "./PlanApi";

import { useToasts } from "react-toast-notifications";
export default function LockAccount(props) {
  const { addToast } = useToasts();
  const { handleClose, DealerID } = props;
  const savechanges = async () => {
    const formdata = new FormData();
    formdata.append("DealerID", DealerID);
    formdata.append("LockTypeID", 0);

    const api_res = await finalizedstatuschange(formdata);
    if (api_res.success === 1) {
      addToast(api_res.message, {
        appearance: "success",
        autoDismiss: true,
      });
      handleClose();
    } else {
      addToast("An error occurred", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <Modal centered={true} show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lock Unfinalized Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to lock Unfinalized Account?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={savechanges}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
