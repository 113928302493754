import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { requestunlock, locksave } from "./PlanApi";
import { InputGroup, FormControl } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import $ from "jquery";
import Loader from "react-loader-spinner";
export default function UnLockAccount(props) {
  const { addToast } = useToasts();
  const imageRef = useRef("");
  const { handleClose, DealerID } = props;
  const [auditimg, setAuditimg] = useState("");
  const [requestunlockdata, setRequestunlockdata] = useState([]);
  useEffect(() => {
    get_data();
  }, [DealerID]);
  const get_data = async () => {
    const formdata = new FormData();
    formdata.append("DealerID", DealerID);
    const api_res = await requestunlock(formdata);
    if (api_res.success === 1) {
      setRequestunlockdata(api_res);
    }
  };
  const savechanges = async () => {
    const formdata = new FormData();
    formdata.append("AuditUpload", auditimg);

    formdata.append("DealerID", DealerID);
    formdata.append("LockTypeID", 3);
    let form = $("#unlock_form").serializeArray();
    form.map((item, index) => {
      if (item.value != "") {
        formdata.append(item.name, item.value);
      }
    });
    const api_res = await locksave(formdata);
    if (api_res.success === 1) {
      addToast(api_res.message, {
        appearance: "success",
        autoDismiss: true,
      });
      handleClose();
    } else {
      addToast(api_res.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <Modal centered={true} show={true} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>UnLock Plans Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="unlock_form" encType="multipart/form-data">
          <div className="form-group">
            <label>
              <strong>Unlock Reason</strong>
            </label>
            <input
              type="text"
              className="form-control validate[required]"
              name="UnlockReason"
            />
          </div>
          <div className="form-group">
            <label>
              <strong>Unlock Duration</strong>
            </label>
            <select
              name="UnlockDuration"
              defaultValue={0}
              className="form-control validate[required]"
            >
              <option value={0}>Select Duration</option>
              <option value={5}>5 Hours</option>
              <option value={10}>10 Hours</option>
              <option value={24}>24 Hours</option>
              <option value={48}>48 Hours</option>
              <option value={72}>72 Hours</option>
            </select>
          </div>
          <div className="form-group">
            <label>
              <strong>Audit Upload</strong>
            </label>
            <div className="input-group">
              <label className="input-group-btn">
                <span className="btn btn-primary">
                  <i className="fa fa-image" /> Browse File{" "}
                  <input
                    type="file"
                    name="AuditUpload"
                    style={{ display: "none" }}
                    className="file"
                    onChange={(e) => setAuditimg(e.target.files[0])}
                  />
                </span>
              </label>

              <input
                type="text"
                className="form-control"
                value={
                  typeof auditimg.name !== "undefined" ? auditimg.name : ""
                }
                readOnly
                id="filename"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-12">
              <strong>Users</strong>
            </div>

            {requestunlockdata.hasOwnProperty("users") ? (
              requestunlockdata.users.map((item, index) => (
                <div key={index} className="col-sm-4 mb-5">
                  <InputGroup className="mb-3">
                    <InputGroup.Checkbox
                      name={`USERIDS${item.UserID}`}
                      id={`account_ids${item.UserID}`}
                      aria-label="Checkbox for following text input"
                      value={1}
                    />
                    <div className="form-control">
                      {item.Name} ({item.UserName})
                    </div>
                  </InputGroup>
                </div>
              ))
            ) : (
              <div className="col-sm-12">
                <Loader
                  type="Bars"
                  color="#007bff"
                  height={100}
                  width={100}
                  visible={true}
                  className="text-center"
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <table width="100%">
                <tbody>
                  <tr>
                    <td
                      className="col-lg-10 col-md-10 col-sm-10 col-sm-10"
                      style={{ verticalAlign: "bottom" }}
                    >
                      <h4 style={{ color: "#939598" }}>Request Submited For</h4>
                      <h4
                        style={{
                          color: "#808285",
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {requestunlockdata.hasOwnProperty("dealer")
                          ? requestunlockdata.dealer.DealerTitle
                          : ""}
                      </h4>
                    </td>{" "}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={savechanges}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
