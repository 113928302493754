import React, { useState } from "react";
import style from "./GroupedButton.module.css";
export default function GroupedButton(props) {
  const { ServiceType, selectedcoupon, Couponcountincrement } = props;
  const [counter, setCounter] = useState(+props.count);
  const [error, setError] = useState(false);
  const handleDecrement = () => {
    setError(false);
    if (!props.disable && +props.count - 1 < counter) {
      setCounter(counter - 1);
      props.Couponcount(
        counter - 1,
        props.CouponID,
        ServiceType,
        selectedcoupon
      );
    } else {
      setError(true);
      setTimeout(() => setError(false), 3000);
    }
  };
  const handleIncrement = () => {
    setError(false);
    if (!props.disable && props.count >= counter + 1) {
      setCounter(counter + 1);

      props.Couponcountincrement(
        counter + 1,
        props.CouponID,
        ServiceType,
        selectedcoupon
      );
    }
  };
  return (
    <React.Fragment>
      <div className="output" id="output-1">
        {counter}
      </div>
      {error ? (
        <div
          style={{
            Opacity: 0.87,
            position: "absolute",
            top: 320.456,
            left: 307.997,
            MarginTop: -67,
          }}
        >
          <div className={style.formErrorContent}>
            * You cannot redeem more than one service of the same type on the
            same RO number 1
          </div>
        </div>
      ) : (
        ""
      )}
      <i
        className="fa fa-minus minus ml-2"
        id="decrease1"
        data-index="1"
        onClick={handleDecrement}
        aria-hidden="true"
      ></i>
      <i
        className="fa fa-plus plus ml-2"
        id="increase1"
        onClick={handleIncrement}
        data-index="1"
        aria-hidden="true"
      ></i>
    </React.Fragment>
  );
}
