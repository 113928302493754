import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { dashboaremailpopup, emaildashboard_post } from "./DashboardApi";
import $ from "jquery";
import { useToasts } from "react-toast-notifications";

import Loader from "react-loader-spinner";
export default function DashboardEmailModal(props) {
  const {
    DealerID,
    FixedDateParameter,
    fromdate,
    toDate,
    close,
    dealerredemptiondata,
    PcpBreakDown,
    UpsellAmount,
    ReservedAmountBreakDown,
    DashboardContract,
    UniqueVisit,
    CurrentThirtDays,
    LastThirtDays,
    Mobileussage,
  } = props;
  const [userlist, setUserlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [prespin, setPrespin] = useState(false);
  const { addToast } = useToasts();
  const handleClose = () => {
    close();
  };
  useEffect(() => {
    getemaildata();
  }, [DealerID]);
  const getemaildata = async () => {
    setPrespin(true);
    const form = new FormData();
    form.append("DealerID", DealerID);
    form.append("FomDate", fromdate);
    form.append("ToDate", toDate);
    form.append("FixedDateParameter", FixedDateParameter);
    const dashboaremailpopup_api = await dashboaremailpopup(form);
    setPrespin(false);
    if (dashboaremailpopup_api.success === 1) {
      setUserlist(dashboaremailpopup_api.DashboardEmail);
    }
  };
  const onsubmit = async () => {
    setLoading(true);
    const formdata = new FormData();

    //breakdown
    const chartInstance1 = window.Apex._chartInstances.find(
      (chart) => chart.id === "breakdown"
    );
    const breakdown = await chartInstance1.chart.dataURI();
    formdata.append("pie", breakdown.imgURI);

    //contractsold
    const chartInstance2 = window.Apex._chartInstances.find(
      (chart) => chart.id === "contractsold"
    );
    const contractsold = await chartInstance2.chart.dataURI();
    formdata.append("graph", contractsold.imgURI);

    //servicedistribution
    const chartInstance3 = window.Apex._chartInstances.find(
      (chart) => chart.id === "servicedistribution"
    );
    const servicedistribution = await chartInstance3.chart.dataURI();
    formdata.append("heatgraph", servicedistribution.imgURI);

    //contract sold
    const chartInstance4 = window.Apex._chartInstances.find(
      (chart) => chart.id === "serviceredmption"
    );
    const serviceredmption = await chartInstance4.chart.dataURI();
    formdata.append("bar", serviceredmption.imgURI);
    //end

    let form = $("#emailform").serializeArray();
    form.map((item, index) => {
      if (item.value != "") {
        formdata.append(item.name, item.value);
      }
    });
    formdata.append("DealerID", DealerID);
    formdata.append("FomDate", fromdate);
    formdata.append("ToDate", toDate);
    formdata.append("IsPrint", 0);
    formdata.append("FixedDateParameter", FixedDateParameter);
    formdata.append("UniqueVisit", UniqueVisit);

    formdata.append(
      "EnableAuthenticom",
      dealerredemptiondata.EnableAuthenticom
    );
    formdata.append(
      "DashboardContractReserveOptionalDetails",
      JSON.stringify(dealerredemptiondata)
    );
    formdata.append(
      "PlanServicesReservedAmountOverrite",
      dealerredemptiondata.PlanServicesReservedAmountOverrite
    );
    formdata.append(
      "ShopingCartEnable",
      dealerredemptiondata.ShopingCartEnable
    );
    formdata.append(
      "EnableReserveGraph",
      dealerredemptiondata.EnableReserveGraph
    );
    formdata.append(
      "EnableDisplayServiceWtithAmount",
      dealerredemptiondata.EnableDisplayServiceWtithAmount
    );
    formdata.append("Servicemultiple", dealerredemptiondata.Servicemultiple);
    formdata.append(
      "ReserveGraph",
      JSON.stringify(dealerredemptiondata.ReserveGraph)
    );
    formdata.append("PcpBreakDown", JSON.stringify(PcpBreakDown));
    formdata.append("UpsellAmount", JSON.stringify(UpsellAmount));
    formdata.append("dashboardContract", JSON.stringify(DashboardContract));
    formdata.append(
      "ReservedAmountBreakDown",
      JSON.stringify(ReservedAmountBreakDown)
    );
    formdata.append("CurrentThirtyday", JSON.stringify(CurrentThirtDays));
    formdata.append("Mobileussage", JSON.stringify(Mobileussage));
    formdata.append("LastThirtyday", JSON.stringify(LastThirtDays));
    formdata.append("row1", JSON.stringify(dealerredemptiondata.row1));
    formdata.append("row2", JSON.stringify(dealerredemptiondata.row2));
    formdata.append("row3", JSON.stringify(dealerredemptiondata.row3));
    formdata.append("row4", JSON.stringify(dealerredemptiondata.row4));
    formdata.append("row5", JSON.stringify(dealerredemptiondata.row5));
    formdata.append("row1_show", dealerredemptiondata.row1_show);
    formdata.append("row2_show", dealerredemptiondata.row2_show);
    formdata.append("row3_show", dealerredemptiondata.row3_show);
    formdata.append("row4_show", dealerredemptiondata.row4_show);
    formdata.append("row5_show", dealerredemptiondata.row5_show);

    const email_res = await emaildashboard_post(formdata);
    console.log("payment_done_api", email_res);
    if (email_res.success === 1) {
      setLoading(false);
      close();
      addToast("Email send successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      setError(email_res.message);
      setTimeout(() => setError(""), 3000);
    }
  };
  return (
    <Modal size="lg" show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Email Dashboard Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="emailform">
          <table className="table borderlesstable">
            <tbody>
              <tr>
                <td width="15%">
                  <label className="username">
                    <span>
                      Users List <i className="fa fa-user" />
                    </span>
                  </label>
                </td>
                <td width="80%">
                  <div
                    className="col-sm-12"
                    style={{ maxHeight: 200, overflow: "auto" }}
                  >
                    {prespin ? (
                      <div className="text-center mt-5">
                        <Loader
                          type="Bars"
                          color="#007bff"
                          height={100}
                          width={100}
                          visible={loading}
                        />
                      </div>
                    ) : (
                      <ul>
                        {userlist.map((item, i) => (
                          <li
                            key={i}
                            className="ml-2"
                            style={{
                              listStyle: "none",
                              display: "inline-block",
                              whiteSpace: "nowrap",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              fontSize: 12,
                            }}
                          >
                            <input
                              style={{ width: "auto !important", margin: 0 }}
                              name={`dash_email[${item.UserID}]`}
                              type="checkbox"
                              defaultChecked={
                                item.DashboarEmail == 1 ? true : false
                              }
                              value={1}
                              className="dash_email"
                            />
                            &nbsp; {item.Name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="password">
                    {" "}
                    <span>Subject</span>
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    id="Subject"
                    name="Subject"
                    className="form-control"
                    required={true}
                    style={{ textTransform: "uppercase" }}
                    defaultValue=" - Performance Report"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="password">
                    {" "}
                    <span>Body</span>
                  </label>
                </td>
                <td>
                  <textarea
                    cols={24}
                    rows={5}
                    id="Comments"
                    name="Comments"
                    className="form-control"
                    defaultValue={""}
                    required={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onsubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
