import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PreLoad from "../../../components/PreLoad";
import { disablenewcarinfo } from "./LoyaltyCashApi";

export function DisableCar(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const Close = () => {
    setError("");
    props.f_disablecar("", "");
  };
  const handledisable = async () => {
    setError("");
    setLoading(true);
    const formData = new FormData();
    formData.append("VinStatus", +props.disablecardata.VinStatus === 1 ? 0 : 1);
    formData.append("ID", props.disablecardata.ID);
    const disablecar_api = await disablenewcarinfo({ formData });
    setLoading(false);

    if (disablecar_api.success === 1) {
      props.f_disablecar("", disablecar_api);
    } else {
      setError(disablecar_api.message);
    }
  };
  return (
    <React.Fragment>
      <PreLoad loading={loading} />
      <Modal show={props.show}>
        <Modal.Header>
          <Modal.Title>
            <i className="fa fa-exclamation-triangle"></i>{" "}
            {+props.disablecardata.VinStatus == 1
              ? "Disable Car VIN"
              : "Enable Car VIN"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">
            <i className="fa fa-exclamation-triangle"></i>&nbsp;
            <span>
              Are you sure you want to{" "}
              {+props.disablecardata.VinStatus == 1 ? "disable" : "enable"} this
              car info?
            </span>
          </div>
          <small className="help-block text-danger">{error}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={Close}>
            Close
          </Button>
          <Button variant="primary" onClick={handledisable}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
