import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PreLoad from "../../../components/PreLoad";
import { deletenewcarinfo } from "./LoyaltyCashApi";

export function CarVin(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const Close = () => {
    setError("");
    props.f_deletecar("", "");
  };
  const deletecarinfo = async () => {
    setError("");
    setLoading(true);
    const formData = new FormData();

    formData.append("ID", props.carid);
    const deletecarinfo_api = await deletenewcarinfo({ formData });
    setLoading(false);
    if (deletecarinfo_api.success === 1) {
      props.f_deletecar("", deletecarinfo_api);
    } else {
      setError(deletecarinfo_api.message);
    }
  };
  return (
    <React.Fragment>
      <PreLoad loading={loading} />
      <Modal show={props.show}>
        <Modal.Header>
          <Modal.Title>
            <i className="fa fa-exclamation-triangle"></i> Delete Car info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">
            <i className="fa fa-exclamation-triangle"></i>&nbsp;
            <span>Are you sure you want to delete this car info?</span>
          </div>
          <small className="help-block text-danger">{error}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={Close}>
            Close
          </Button>
          <Button variant="primary" onClick={deletecarinfo}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
