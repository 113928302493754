import axios from "axios";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
const token = JSON.parse(localStorage.getItem("token"));
const API = process.env.REACT_APP_APP_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const header = {
  "Content-Type": "application/json",
};
export const reactivateservicewithstatuschange = async ({ formData }) => {
  let url = API + "/contract/reactivateservicewithstatuschange";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const reactivateservice = async ({ formData }) => {
  let url = API + "/contract/reactivateservice";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const validatereedemservices = async ({ formdata }) => {
  let url = API + "/contract/validatereedemservices";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const contractservicesreedem = async (formdata) => {
  let url = API + "/contract/contractservicesreedem";
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const validatenormalcoupon = async (formdata) => {
  let url = API + "/contract/validatenormalcoupon";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const resendemail = async (formdata) => {
  let url = API + "/contract/resendemail";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const contractinfoapi = async (formdata) => {
  let url = API + "/contract/contractinfo";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const get_resell_plans = async (formdata) => {
  let url = API + "/contract/resellcontractpopup";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const resellcontractplanservices = async (formdata) => {
  let url = API + "/contract/resellcontractplanservices";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const continuewithoutresellcontract = async (formdata) => {
  let url = API + "/contract/continuewithoutresellcontract";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const vehicheinfohistory = async (formdata) => {
  let url = API + "/contract/vehicheinfohistory";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const getvindetail = async (formdata) => {
  let url = API + "/contract/getvindetail";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const updatecustomer = async (formdata) => {
  let url = API + "/contract/updatecustomer";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const updatecontract = async (formdata) => {
  let url = API + "/contract/updatecontract";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const emailcustomercontract = async (formdata) => {
  let url = API + "/contract/emailcontract";
  formdata.append("pcp_user_id", pcp_user_id);
  formdata.append("role_id", role_id);
  formdata.append("user_id", user_id);
  try {
    const response = await axios.post(url, formdata, { header });

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
