import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import OtherMail from "./OtherMail";
import { resendemail } from "./EditContractApi";

import PreLoad from "../../../components/PreLoad";
import { useToasts } from "react-toast-notifications";
import { checknull } from "../../../components/helpers";
const Badge = styled.span`
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid #29aae1;
  background: #fff;
  color: #000;
`;
const Badgebtn = styled.a`
  color: #ffffff;
  background-color: #0384c9;
  border-color: #1ea1e6;
`;
export default function Giftedservices(props) {
  const { giftedservices, contractinfo } = props;
  const [other_email_modal, setOther_email_modal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const resendmail = async (email) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("DealerID", contractinfo.DealerID);
    formdata.append("ContractID", contractinfo.ContractID);
    formdata.append("ContractNo", contractinfo.ContractNo);
    formdata.append("CustomerID", contractinfo.CustomerID);
    formdata.append("Email", email);

    //api
    const resendemail_response = await resendemail(formdata);
    setLoading(false);
    if (resendemail_response.success === 1) {
      addToast(resendemail_response.message, {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };
  return (
    <div className="row mt-4">
      {other_email_modal && (
        <OtherMail
          setOther_email_modal={setOther_email_modal}
          contractinfo={contractinfo}
        />
      )}
      <div className="col-md-12">
        <PreLoad loading={loading} />
        <table width="100%" className="table table borderlesstable">
          <tbody>
            {giftedservices != "" && typeof giftedservices !== "undefined"
              ? giftedservices.map((item, i) => (
                  <>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#ccc",
                          height: 30,
                          textAlign: "left",
                        }}
                      >
                        {item.GiftedTo_Email}
                      </th>
                      {checknull(item.GiftedContractID) === "" ? (
                        <React.Fragment>
                          <th>
                            <a
                              href="#"
                              className="RsendGiftedEmail"
                              onClick={() => setOther_email_modal(true)}
                            >
                              Other Email
                            </a>
                          </th>
                          <th>
                            <a
                              href="#"
                              className="sendGiftedEmail"
                              onClick={() => resendmail(item.GiftedTo_Email)}
                            >
                              Resend Email
                            </a>
                          </th>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      <th
                        colSpan="2"
                        style={{ borderBottom: "5px !important" }}
                      >
                        {checknull(item.GiftedContractID) !== "" ? (
                          <Badgebtn>
                            <a
                              href={
                                process.env.REACT_APP_BASE_URL +
                                "/contracts/edit/" +
                                item.GiftedContractID
                              }
                              className="btn btn-sm btn-info"
                            >
                              {item.GiftedTo_ContractNo}
                            </a>
                          </Badgebtn>
                        ) : (
                          ""
                        )}
                      </th>
                    </tr>
                    {item.Services != "" && typeof item.Services != "undefined"
                      ? item.Services.map((value, index) => (
                          <tr>
                            <td colSpan="2">
                              <Badge>
                                <span>{value.Gifted_CouponCount}</span>
                              </Badge>
                              {value.Gifted_CouponTitle}
                            </td>
                            <td align="center">
                              {moment(value.GiftedDate).format("MM-DD-YYYY")}
                            </td>
                          </tr>
                        ))
                      : ""}
                  </>
                ))
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
}
