import React, { Component, useState } from "react";
import ChartistGraph from "react-chartist";
import { Tab, Tabs } from "react-bootstrap";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import Chart from "react-apexcharts";
import $ from "jquery";
import CompairLastYear from "./CompairLastYear";
import ServicesDistribution from "./ServicesDistribution";
function DashboardSalesRetention(props) {
  const {
    DealerID,
    FixedDateParameter,
    fromdate,
    toDate,
    ServiceMultiple,
    heatdistribution,
    ServiceRedData,
    ContractData,
  } = props;

  const [showlastyeargraph, setShowlastyeargraph] = useState(false);

  const selectionbtn = (e) => {
    e.preventDefault();

    setShowlastyeargraph(!showlastyeargraph);
  };

  var totalcars = ContractData.Total;
  var myXasis = ContractData.xaxis;
  var usedcars = Object.keys(ContractData.Used).map(
    (key) => ContractData.Used[key]
  );
  var newcars = ContractData.New;
  var heatarray = [];
  $.each(heatdistribution[0], function (index, value) {
    heatarray.push({ value });
  });

  var data3 = {
    labels: heatdistribution[1],
    series: [{ name: "Total Cars", data: heatarray }],
  };
  var myarraylabel2 = [];
  var myarraydata2 = [];

  var Title2 = ServiceRedData.Title;
  $.each(ServiceRedData.data, function (index, value) {
    myarraylabel2.push(index);
    myarraydata2.push(value);
  });
  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-body" style={{ padding: "0.75rem" }}>
          <div className="row">
            <div className="col-md-6">
              <button
                onClick={() => setShowlastyeargraph(false)}
                className="btn Btngraph Btngraphselected heading_orng"
                type="button"
              >
                Sales and Retention
              </button>{" "}
              <button
                onClick={() => setShowlastyeargraph(true)}
                className="btn Btngraph Btngraphnotselected"
                style={{ backgroundColor: "#0384c9", color: "white" }}
                type="button"
              >
                Compare Last Year
              </button>
            </div>

            {showlastyeargraph ? (
              <CompairLastYear
                DealerID={DealerID}
                FixedDateParameter={FixedDateParameter}
                fromdate={fromdate}
                toDate={toDate}
                Title2={Title2}
                ServiceMultiple={ServiceMultiple}
              />
            ) : (
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-5 text-center">
                  <div>
                    <Chart
                      options={{
                        chart: {
                          height: 350,
                          type: "bar",
                          id: "contractsold",

                          toolbar: {
                            show: false,
                            tools: {
                              reset: true,
                              pan: false,
                            },
                          },
                        },

                        dataLabels: {
                          enabled: false,
                          offsetY: -20,
                          style: {
                            fontSize: "12px",
                            colors: ["#304758"],
                          },
                        },
                        colors: ["#86C6C2", "#E985D5", "#A7EFEC"],
                        tooltip: {
                          enabled: true,
                          enabledOnSeries: true,
                          shared: false,
                          followCursor: false,
                        },

                        xaxis: {
                          type: "category",
                          categories: myXasis,
                        },
                        legend: {
                          show: false,
                        },
                      }}
                      series={[
                        { name: "Total Cars", data: totalcars },
                        { name: "Used Cars", data: usedcars },
                        { name: "New Cars", data: newcars },
                      ]}
                      type="bar"
                      height={350}
                    />
                  </div>
                  <span className="font-16">Contracts Sold</span>
                </div>
                <div className="col-md-2 text-center no-padding">
                  <span
                    className="pt-3"
                    style={{
                      paddingTop: "7.5rem !important",
                      fontSize: "0.71rem",
                    }}
                  >
                    Contract Sale : Service Redemption
                  </span>
                  <br />
                  <span className="mr-3">
                    <i
                      style={{ color: "#26a69a" }}
                      className="font-22 fas fa-arrow-up"
                    ></i>
                  </span>
                  <span className="mr-3 font-22">{ServiceMultiple}</span>
                  <span>
                    <i className="font-22 mdi mdi-chart-areaspline "></i>
                  </span>
                </div>

                <div className="col-md-5  text-center">
                  <Tabs
                    defaultActiveKey="fill_contract"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="fill_contract" title="Service Redmption">
                      <span className="font-16 my-4">{Title2}</span>

                      <Chart
                        options={{
                          chart: {
                            height: 350,
                            type: "bar",
                            id: "serviceredmption",

                            toolbar: {
                              show: false,
                              tools: {
                                reset: true,
                                pan: false,
                              },
                            },
                          },

                          dataLabels: {
                            enabled: false,
                            offsetY: -20,
                            style: {
                              fontSize: "12px",
                              colors: ["#304758"],
                            },
                          },
                          colors: ["#86C6C2", "#E985D5", "#A7EFEC"],
                          tooltip: {
                            enabled: true,
                            enabledOnSeries: true,
                            shared: false,
                            followCursor: false,
                          },

                          xaxis: {
                            type: "category",
                            categories: myarraylabel2,
                          },
                          legend: {
                            show: false,
                          },
                        }}
                        series={[
                          { name: "Service Redmption", data: myarraydata2 },
                        ]}
                        type="bar"
                        height={350}
                      />
                    </Tab>

                    <Tab eventKey="price_print" title="Service Distribution">
                      <div className="tab-content">
                        <div
                          className="tab-pane p-3 active"
                          id="#price_print"
                          role="tabpanel"
                        >
                          <span className="font-16 my-4">{Title2}</span>

                          <Chart
                            options={{
                              chart: {
                                height: 350,
                                type: "bar",
                                id: "servicedistribution",

                                toolbar: {
                                  show: false,
                                  tools: {
                                    reset: true,
                                    pan: false,
                                  },
                                },
                              },

                              dataLabels: {
                                enabled: false,
                                offsetY: -20,
                                style: {
                                  fontSize: "12px",
                                  colors: ["#304758"],
                                },
                              },
                              colors: ["#86C6C2", "#E985D5", "#A7EFEC"],
                              tooltip: {
                                enabled: true,
                                enabledOnSeries: true,
                                shared: false,
                                followCursor: false,
                              },

                              xaxis: {
                                type: "category",
                                categories: heatdistribution[1],
                              },
                              legend: {
                                show: false,
                              },
                            }}
                            series={[
                              {
                                name: "Service Distribution",
                                data: heatdistribution[0],
                              },
                            ]}
                            type="bar"
                            height={350}
                          />
                        </div>
                      </div>
                    </Tab>
                  </Tabs>

                  <span className="font-16">Service Redemption</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardSalesRetention;
