import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import PreLoad from "../components/PreLoad";
import {
  f_dashboardexport,
  f_subscriptionexport,
} from "../components/helpers";
export default function DashboardMaturedContract(props) {
  const { ShopingCartEnable, DealerID, fromdate, toDate, FixedDateParameter } =
    props;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const exportdata = (export_type) => {
    if (DealerID == "" || typeof DealerID == "undefined") {
      addToast("please select dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("FomDate", fromdate);
    formData.append("ToDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    formData.append("export_type", export_type);
    setLoading(true);
    //api
    if (ShopingCartEnable === 1) {
      f_subscriptionexport(formData).then((response) => {
        setLoading(false);
      });
    } else {
      f_dashboardexport(formData).then((response) => {
        setLoading(false);
      });
    }
  };
  return (
    <React.Fragment>
      <PreLoad loading={loading} />

      <div className="col-md-4 text-center" style={{ display: "grid" }}>
        <div className="card">
          <div className="card-body" style={{ padding: "0.75rem" }}>
            <span className="font-18">Matured Contract Forfeited Services</span>
            <br />
            <br />
            <span className="mr-4 services_font_28">
              {isNaN(props.UniqueVisit) ? "" : props.UniqueVisit}
            </span>

            <a href="#" onClick={() => exportdata(33)}>
              <span className="delete_icon">
                <i className="dripicons-document-delete services_font_28"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
