import { React, useEffect, useState } from "react";
import { Tabs, Tab, Form, FormControl } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import DealersDropdown from "../../../components/DealersDropdown";
import AddModel from "../../../components/AddModel";
import AddBankAddress from "../AddBankAddress";
import "./createcontract.css";
import axios from "axios";
import * as Yup from "yup";
import $ from "jquery";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
import { formatbankaddress, checknull } from "../../../components/helpers";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import Subcription from "../subcription/index";
import {
  verifyvin,
  plancostapi,
  autofillapi,
  planexpiryapi,
  statesapi,
  modelsapi,
  contractcreateapi,
  addbankapi,
  dataagainstdealerid,
  editcontract,
  resellmatured,
  setdefaultplanforuser,
  setdefaultsalerepforuser,
} from "./CreateContractApi";
import moment from "moment";
require("dotenv").config();
export default function CreateContract() {
  const [yeardata, setYeardata] = useState("");
  const { addToast } = useToasts();
  var roleid = role_id;
  const [showdata, setShowdata] = useState(false);
  const [showsubcription, setShowsubcription] = useState(false);
  const [newbank, setNewbank] = useState(true);
  const [selecteddealer, setSelecteddealer] = useState();
  const [dealerdata, setDealerdata] = useState("");
  const [banklist, setBanklist] = useState("");
  const [bankaddress, setBankaddress] = useState("");
  const [states, setStates] = useState("");
  const [model, setModel] = useState("");
  const [bankaddressmodal, setBankaddressmodal] = useState(false);
  const [Countries, setCountries] = useState("");
  const [plancost, setPlancost] = useState("");
  const [plans, setPlans] = useState("");
  const [loading, setLoading] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [user_defaultplan, setUser_defaultplan] = useState(false);
  const [user_salerep, setUser_salerep] = useState(false);
  const [paymentdata, setPaymentdata] = useState([]);
  const handlemodal = () => {
    setShowmodal(!showmodal);
  };
  const handledealerchange = (dealer_id) => {
    setSelecteddealer(dealer_id);
    formik.setFieldValue("DealerID", dealer_id);
    if (!Number.isNaN(dealer_id)) {
      getadata(dealer_id);
    }
  };
  const phoneRegExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  const validationSchema = Yup.object().shape({
    Email: Yup.string().email("Invalid Email").required("Required"),
    SellingRep: Yup.number().required("Required"),
    PhoneHome: Yup.string().matches(
      phoneRegExp,
      "phone Required in this format 123-123-1234"
    ),
    VIN: Yup.string().required("Vin required"),
    ModelID: Yup.number().required("Required"),
    VehYear: Yup.number().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      CustomerFName: "",
      CustomerLName: "",
      CustomerAddressLine1: "",
      CountryID: 0,
      CityName: "",
      StateID: 0,
      Email: "",
      CustomerZIP: "",
      PhoneHome: "",
      dob: "",

      HideTestContract: false,
      AddNewbank: "",
      DealerID: "",
      VIN: "",
      contractno: "",
      MakeID: "",
      PlanID: "",
      ModelID: "",
      SellingRep: "",
      SIManagerID: "",
      VehYear: "",
      VehicleType: "N",
      Mileage: "",
      StockDealNo: "",
      SaleDate: "",
      ValidityDate: "",
      RO: "",
      RO_NUMBER: "",

      ContractTotalCost: "",
      BankId: "",
      ServiceContract: "",
      SendEmail: "",
      SendSms: "",
      Loyalty_Map: "",
      LoyaltyCashEnable: "",
      isServiceContract: "",
      EnableWelcomeSms: 0,
      ResellContractPopup: "",
      OldContractID: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (data) => {
      data.SendEmail = data.SendEmail ? 1 : 0;
      data.SendSms = data.SendSms ? 1 : 0;
      data.isServiceContract = data.isServiceContract ? 1 : 0;
      data.EnableWelcomeSms = data.EnableWelcomeSms ? 1 : 0;
      data.Loyalty_Map = data.Loyalty_Map ? 1 : 0;
      data.HideTestContract = data.HideTestContract ? 1 : 0;
      data.LienholderAddress = bankaddress;
      data.DealerID = selecteddealer;
      if (
        dealerdata.dealersetting.ShopingCartEnable &&
        plancost.product_id !== 0
      ) {
        setShowsubcription(true);
        setPaymentdata(data);
      } else {
        setLoading(true);
        const formData = new FormData();
        Object.keys(data).forEach((key) => formData.append(key, data[key]));

        const addcontract = await contractcreateapi({ formData });
        if (addcontract.success == 1) {
          setLoading(false);
          window.location.href =
            process.env.REACT_APP_BASE_URL +
            "/contracts/edit/" +
            addcontract.ContractID;
        } else if (addcontract.success == 0) {
          setLoading(false);
          addToast(addcontract.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
        setLoading(false);
      }
    },
  });
  const updateClipboard = () => {
    navigator.clipboard.writeText(formik.values.contractno).then(
      () => {
        console.log("Copied!");
      },
      () => {
        console.log("Copy failed!");
      }
    );
  };
  const getBankAddress = async (event) => {
    formik.setFieldValue("BankId", event.target.value);
    let city = event.target.selectedOptions[0].getAttribute("city");
    let state = event.target.selectedOptions[0].getAttribute("statetitle");
    let statecode = event.target.selectedOptions[0].getAttribute("statecode");
    setBankaddress(formatbankaddress(city, state, statecode));
  };
  const functionaddnewbank = () => {
    setLoading(true);
    let AddNewbank = formik.values.AddNewbank;
    if (AddNewbank === "") {
      formik.errors.AddNewbank = "Please Add Bank Name";
      formik.touched.AddNewbank = true;

      setLoading(false);
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", selecteddealer);
    formData.append("AddNewbank", AddNewbank);
    const newbankresponse = addbankapi({ formData });
    setLoading(false);
    if (newbankresponse.success == 1) {
      formik.setFieldValue("BankId", +newbankresponse.BankID);
      setNewbank(true);
      setBanklist(newbankresponse.Banks);
    }
  };
  const handlebankaddress = (event, Banks, address) => {
    if (Banks != "" && typeof Banks != "undefined") {
      setBanklist(Banks);
    }
    setBankaddress(address);
    setBankaddressmodal(!bankaddressmodal);
  };
  const handleplanchange = async (val, setFieldValue) => {
    setLoading(true);
    if (selecteddealer == "undefined") {
      formik.touched.DealerID = "Please Select Dealership";
      formik.errors.DealerID = "Please Select Dealership";
      setLoading(false);
      return false;
    }
    var PlanID = val;
    setFieldValue("PlanID", PlanID);

    let saledate = $("#SaleDate").val();
    const formData = new FormData();
    formData.append("DealerID", selecteddealer);
    formData.append("SaleDate", saledate.replace(/-/g, "/"));
    formData.append("PlanID", parseInt(PlanID));

    const getplancost = await plancostapi({ formData });
    if (getplancost.success == 1) {
      setPlancost(getplancost);
      formik.setFieldValue(
        "ContractTotalCost",
        getplancost.PlanCostAndExpiry.customerprice
      );
    }

    setLoading(false);
  };
  const getplanexpiry = async (e, setFieldValue) => {
    setLoading(true);

    if (typeof selecteddealer == "undefined") {
      formik.touched.DealerID = "Please Select Dealership";
      formik.errors.DealerID = "Please Select Dealership";
      setLoading(false);
      return false;
    }
    let saledate = e.target.value;
    setFieldValue("SaleDate", saledate);

    let planid = formik.values.PlanID;
    if (planid == "") {
      formik.touched.PlanID = "Please select Plan";
      formik.errors.PlanID = "Please select Plan";
      setLoading(false);
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", selecteddealer);
    formData.append("SaleDate", saledate.replace(/-/g, "/"));
    formData.append("PlanID", parseInt(planid));
    const planexpiry = await planexpiryapi({ formData });
    if (planexpiry.success == 1) {
      formik.setFieldValue(
        "ValidityDate",
        planexpiry.contractexpirydate.MakePlanExpiryDate
      );
      handleplanchange(planid, formik.setFieldValue);
      setLoading(false);
    }
  };
  const getmodel = async (e, setFieldValue) => {
    setLoading(true);
    var makeid = e.target.value;
    setFieldValue("MakeID", makeid);

    const formData = new FormData();

    formData.append("DealerID", parseInt(selecteddealer));
    formData.append("MakeID", parseInt(makeid));

    const modelapi = await modelsapi({ formData });
    if (modelapi.success == 1) {
      setModel(modelapi.SpecifixModels);
    }
    setLoading(false);
  };
  const getadata = async (dealer_id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("DealerID", parseInt(dealer_id));
    const dealerresponse = await dataagainstdealerid({ formData });

    if (dealerresponse.success == 1) {
      setDealerdata(dealerresponse);
      setPlans(dealerresponse.Plans);
      setBanklist(dealerresponse.Banks);

      if (checknull(dealerresponse.UserSpecifixSellingRep) !== "") {
        formik.setFieldValue(
          "SellingRep",
          dealerresponse.UserSpecifixSellingRep.FIManagerID
        );
      }
      formik.setFieldValue("PlanID", dealerresponse.UserPlans.PlanID);
      if (dealerresponse.UserPlans != "") {
        setUser_defaultplan(!user_defaultplan);
      }
      if (dealerresponse.UserSpecifixSellingRep != "") {
        setUser_salerep(!user_salerep);
      }
      setShowdata(true);
    }
    setLoading(false);
  };

  const getstates = async (e, setFieldValue) => {
    setLoading(true);
    var CountryID = e.target.value;

    setFieldValue("CountryID", +CountryID);
    const formData = new FormData();
    formData.append("CountryID", parseInt(CountryID));
    const states = await statesapi({ formData });
    if (states.success == 1) {
      setStates(states.States);
    }
    setLoading(false);
  };
  const functionverifyvin = async () => {
    setLoading(true);
    if (typeof selecteddealer == "undefined") {
      formik.touched.DealerID = "Please Select Dealership";
      formik.errors.DealerID = "Please Select Dealership";
      setLoading(false);
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", parseInt(selecteddealer));
    formData.append("VIN", formik.values.VIN);

    const verifyvinresponse = await verifyvin({ formData });
    setLoading(false);
    if (verifyvinresponse.success == 1) {
      setModel(verifyvinresponse.SpecifixModels);
      formik.setFieldValue("ModelID", parseInt(verifyvinresponse.ModelID));
      formik.setFieldValue("MakeID", parseInt(verifyvinresponse.MakeID));
      formik.setFieldValue("VehYear", parseInt(verifyvinresponse.ModelYear));
    }
  };
  const getautofilldata = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("DealerID", parseInt(selecteddealer));

    const autofill = await autofillapi({ formData });
    setLoading(false);
    formik.setFieldValue("CustomerFName", autofill.FIRST_NAME);
    formik.setFieldValue("CustomerLName", autofill.LAST_NAME);
    formik.setFieldValue("CustomerAddressLine1", autofill.ADDRESS);
    formik.setFieldValue("PhoneHome", autofill.PHONE);
    formik.setFieldValue("Email", autofill.EMAIL);
    formik.setFieldValue("RO", autofill.RO);
    formik.setFieldValue("SellingRep", autofill.SALESREP);
    formik.setFieldValue("VIN", autofill.VIN);
    formik.setFieldValue("VehYear", autofill.YEAR);
    formik.setFieldValue("CustomerZIP", autofill.ZIP);
    formik.setFieldValue("ValidityDate", autofill.validatydate);
    formik.setFieldValue("StateID", autofill.STATE);
    formik.setFieldValue("Mileage", autofill.MILEAGE);
    formik.setFieldValue("CityName", autofill.CITY);
    formik.setFieldValue("MakeID", autofill.MAKE);
    formik.setFieldValue("ModelID", autofill.MODEL);
    formik.setFieldValue("BankId", autofill.BANK);
  };
  useEffect(() => {
    var vehyear = [];
    for (let i = 0; i <= 100; i++) {
      vehyear.push({ year: moment().year() - i });
    }
    setYeardata(vehyear);

    setLoading(true);

    let contractid = localStorage.getItem("ContractID");
    let PlanID = localStorage.getItem("PlanID");
    let ResellContractPopup = localStorage.getItem("ResellContractPopup");
    if (
      typeof contractid != "undefined" &&
      checknull(contractid) != "" &&
      typeof PlanID != "undefined" &&
      checknull(PlanID) != "" &&
      typeof ResellContractPopup != "undefined" &&
      checknull(ResellContractPopup) != ""
    ) {
      fetchdata(contractid);
    } else {
      //get default data for creating new contract
      createcontract();
    }
  }, []);
  async function fetchresellcontractdetails(
    contractid,
    PlanID,
    ResellContractPopup
  ) {
    const formdata = new FormData();
    formdata.append("ContractID", contractid);
    formdata.append("ResellContractPopup", ResellContractPopup);
    formdata.append("PlanID", PlanID);
    //remove data
    localStorage.removeItem("ContractID");
    localStorage.removeItem("ResellContractPopup");
    localStorage.removeItem("PlanID");
    formik.setFieldValue("OldContractID", contractid);
    formik.setFieldValue("ResellContractPopup", ResellContractPopup);
    formik.setFieldValue("PlanID", +PlanID);
    const resellmatured_response = await resellmatured(formdata);

    if (resellmatured_response.success == 1) {
      formik.setFieldValue(
        "contractno",
        resellmatured_response.AssignNewContractNumber
      );
      formik.setFieldValue("SaleDate", moment().format("YYYY-MM-DD"));
      formik.setFieldValue("ValidityDate", "");
      setBanklist(resellmatured_response.AllBanks);
    }
  }
  async function fetchdata(ContractID) {
    setLoading(true);
    setShowdata(true);
    const formData = new FormData();
    formData.append("ContractID", ContractID);
    const editresponse = await editcontract({ formData });
    if (editresponse.success == 1) {
      setSelecteddealer(editresponse.DealerID);
      setBanklist(editresponse.Banks);

      setCountries(editresponse.Countries);

      setStates(editresponse.States);
      setBankaddress(editresponse.contractinfo.LienholderAddress);
      formik.setValues(editresponse.customereinfo);
      setModel(editresponse.SpecifixModels);
      setDealerdata(editresponse);
      setPlans(editresponse.Plans);
      //formik.setValues(editresponse.customereinfo);
      formik.setValues(editresponse.contractinfo);
      formik.setFieldValue(
        "CustomerFName",
        editresponse.customereinfo.CustomerFName
      );
      formik.setFieldValue(
        "CustomerLName",
        editresponse.customereinfo.CustomerLName
      );
      formik.setFieldValue(
        "CustomerAddressLine1",
        editresponse.customereinfo.CustomerAddressLine1
      );
      formik.setFieldValue("PhoneHome", editresponse.customereinfo.PhoneHome);
      formik.setFieldValue("StateID", editresponse.customereinfo.StateID);
      formik.setFieldValue("CityName", editresponse.customereinfo.CityName);
      formik.setFieldValue("CountryID", editresponse.customereinfo.CountryID);
      formik.setFieldValue(
        "CustomerZIP",
        editresponse.customereinfo.CustomerZIP
      );
      formik.setFieldValue("Email", editresponse.customereinfo.PrimaryEmail);
      formik.setFieldValue(
        "dob",
        moment(editresponse.customereinfo.CustomerDOB).format("YYYY-MM-DD")
      );
      formik.setFieldValue("SendEmail", +editresponse.customereinfo.SendEmail);
      formik.setFieldValue("SendSms", +editresponse.customereinfo.SendSms);
      formik.setFieldValue("contractno", editresponse.contractinfo.ContractNo);
      formik.setFieldValue(
        "Mileage",
        editresponse.contractinfo.ContractMileage
      );
      formik.setFieldValue("SellingRep", editresponse.contractinfo.FIManagerID);
      formik.setFieldValue(
        "isServiceContract",
        editresponse.contractinfo.isServiceContract
      );
      formik.setFieldValue("ModelID", +editresponse.contractinfo.ModelID);
      formik.setFieldValue(
        "ValidityDate",
        moment.unix(editresponse.contractinfo.ValidityDate).format("MM/DD/YYYY")
      );
      formik.setFieldValue(
        "SaleDate",
        moment.unix(editresponse.contractinfo.SaleDate).format("YYYY-MM-DD")
      );
      formik.setFieldValue("password", editresponse.getuserbyid.pass);
    }

    setLoading(false);
    let contractid = localStorage.getItem("ContractID");
    let PlanID = localStorage.getItem("PlanID");
    let ResellContractPopup = localStorage.getItem("ResellContractPopup");
    if (
      typeof contractid != "undefined" &&
      contractid != "" &&
      typeof PlanID != "undefined" &&
      PlanID != "" &&
      typeof ResellContractPopup != "undefined" &&
      ResellContractPopup != ""
    ) {
      fetchresellcontractdetails(contractid, PlanID, ResellContractPopup);
    }
  }
  async function createcontract() {
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/contractcreate",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == 1) {
          setDealerdata(response.data);
          setBanklist(response.data.Banks);
          setSelecteddealer(response.data.DealerID);
          formik.setFieldValue(
            "contractno",
            response.data.AssignNewContractNumber
          );
          setCountries(response.data.Countries);
          formik.setFieldValue(
            "SellingRep",
            response.data.UserSpecifixSellingRep.FIManagerID
          );
          formik.setFieldValue("PlanID", response.data.UserPlans.PlanID);
          if (response.data.UserPlans != "") {
            setUser_defaultplan(!user_defaultplan);
          }
          if (response.data.UserSpecifixSellingRep != "") {
            setUser_salerep(!user_salerep);
          }

          if (role_id != 1) {
            setShowdata(true);
          }
        }
        setLoading(false);
      });
  }
  const f_defaultplan = async () => {
    if (
      typeof selecteddealer != "undefined" &&
      typeof formik.values.PlanID != ""
    ) {
      setUser_defaultplan(!user_defaultplan);
      const formdata = new FormData();
      formdata.append("DealerID", selecteddealer);
      formdata.append("PlanID", formik.values.PlanID);
      formdata.append("isDefault", user_defaultplan ? 0 : 1);
      const setdefaultplanforuser_response = await setdefaultplanforuser(
        formdata
      );
    } else {
      addToast("Select Dealership and plan", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const f_defaultsalerep = async () => {
    if (
      typeof selecteddealer != "undefined" &&
      typeof formik.values.SellingRep != "undefined"
    ) {
      setUser_salerep(!user_salerep);
      const formdata = new FormData();
      formdata.append("DealerID", selecteddealer);
      formdata.append("FIManagerID", formik.values.SellingRep);
      formdata.append("isDefault", user_salerep ? 0 : 1);
      const setdefaultsalerepforuser_response = await setdefaultsalerepforuser(
        formdata
      );
    } else {
      addToast("Select Dealership and Selling rep", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <div>
      {showsubcription && (
        <Subcription
          hidesubcription={setShowsubcription}
          paymentdata={paymentdata}
          Countries={Countries}
          allowoverride={dealerdata.dealersetting.SellingpriceOverride}
          plancost={plancost}
        />
      )}
      {loading ? (
        <div className="overlay-sec">
          <img
          alt="loadinfo"
            className="preload-img"
            src={require("../../../resources/images/loadinfo.gif").default}
          />
        </div>
      ) : (
        ""
      )}
      <AddBankAddress
        show={bankaddressmodal}
        BankID={formik.values.BankId}
        handlebankaddress={handlebankaddress}
        DealerID={selecteddealer}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <div className="float-right">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">MyPCP</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Account</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Create New Contract
                  </li>
                </ol>
              </div>
              <h4 className="page-title">Create New Contract</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <Tabs
                  defaultActiveKey="fill_contract"
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="fill_contract" title="Fill Contract">
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-12 ">
                        <h5>Contact Info</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Customer First Name*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CustomerFName"
                                style={{ textTransform: "uppercase" }}
                                value={formik.values.CustomerFName}
                                onChange={formik.handleChange}
                                required={true}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Customer Last Name*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CustomerLName"
                                style={{ textTransform: "uppercase" }}
                                required={true}
                                value={formik.values.CustomerLName}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Street Address*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CustomerAddressLine1"
                                style={{ textTransform: "uppercase" }}
                                required={true}
                                value={formik.values.CustomerAddressLine1}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="useremail">Country*</label>
                              <select
                                onChange={(e) =>
                                  getstates(e, formik.setFieldValue)
                                }
                                value={formik.values.CountryID}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                name="CountryID"
                              >
                                <option value={0}>Select option</option>
                                {Countries != "" &&
                                typeof Countries != "undefined"
                                  ? Countries.map((item, i) => (
                                      <option
                                        id={item.id}
                                        key={i + 1}
                                        value={item.id}
                                      >
                                        {item.nicename}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>City*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CityName"
                                style={{ textTransform: "uppercase" }}
                                value={formik.values.CityName}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="State_Providence">
                                State/Providence*
                              </label>
                              <select
                                name="StateID"
                                value={formik.values.StateID}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                              >
                                <option key={-1} value={0}>
                                  Select Option
                                </option>
                                {states != ""
                                  ? states.map((item, i) => (
                                      <option key={i} value={item.StateID}>
                                        {item.StateTitle}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                              {formik.errors.StateID &&
                                formik.touched.StateID && (
                                  <div className="input-feedback">
                                    {formik.errors.StateID}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email Address*</label>
                              <input
                                type="Email"
                                className="form-control"
                                name="Email"
                                required={true}
                                value={formik.values.Email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.errors.Email && formik.touched.Email && (
                                <div className="input-feedback">
                                  {formik.errors.Email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="zipcode">Zipcode*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="CustomerZIP"
                                required={true}
                                value={formik.values.CustomerZIP}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Phone #*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="PhoneHome"
                                required={true}
                                onBlur={formik.handleBlur}
                                value={formik.values.PhoneHome}
                                onChange={formik.handleChange}
                              />
                            </div>

                            {formik.errors.PhoneHome &&
                              formik.touched.PhoneHome && (
                                <div className="input-feedback">
                                  {formik.errors.PhoneHome}
                                </div>
                              )}
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="useremail">Date of Birth</label>
                              <input
                                type="date"
                                className="form-control"
                                name="dob"
                                value={formik.values.dob}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-11">
                            <div className="my-2">
                              <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox
                                    aria-label="Checkbox for following text input"
                                    name="SendEmail"
                                    value={formik.values.SendEmail}
                                    onChange={formik.handleChange}
                                  />
                                </InputGroup.Prepend>
                                <FormControl
                                  placeholder="Opt out for Smart Marketing"
                                  aria-label="Text input with checkbox"
                                  disabled={true}
                                />
                              </InputGroup>
                            </div>
                            <div className="my-2">
                              <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox
                                    aria-label="Checkbox for following text input"
                                    name="SendSms"
                                    value={formik.values.SendSms}
                                    onChange={formik.handleChange}
                                  />
                                </InputGroup.Prepend>
                                <FormControl
                                  placeholder=" Opt out customer from SMS Marketing"
                                  aria-label="Text input with checkbox"
                                  disabled={true}
                                />
                              </InputGroup>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-11">
                            <InputGroup
                              className="mb-3"
                              style={{ position: "relative" }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  aria-label="Checkbox for following text input"
                                  name="HideTestContract"
                                  value={formik.values.HideTestContract}
                                  onChange={formik.handleChange}
                                />
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Hide Test Contract from Remittance and Marketplace history "
                                aria-label="Text input with checkbox"
                                disabled={true}
                                className="form-control"
                              />
                              <img
                              alt="remitance"
                                className="img-thumnail opt-ghost-image"
                                width="24"
                                src={
                                  require(`../../../includedfiles/root/assets/images/remitance.png`)
                                    .default
                                }
                              />
                            </InputGroup>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6 col-sm-12 ">
                        <h5>Plan & Vehicle Info</h5>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Select Account</label>
                              <DealersDropdown
                                id="contract_dealer_dropdown"
                                deafultvalue={selecteddealer}
                                handledealerchange={handledealerchange}
                              />
                            </div>
                            {formik.errors.DealerID &&
                              formik.touched.DealerID && (
                                <div className="input-feedback">
                                  {formik.errors.DealerID}
                                </div>
                              )}
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>VIN*</label>
                              <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                  <button
                                    type="button"
                                    onClick={functionverifyvin}
                                    className="vin-button"
                                  ></button>
                                </InputGroup.Prepend>
                                <FormControl
                                  name="VIN"
                                  value={formik.values.VIN}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              {formik.errors.VIN && formik.touched.VIN && (
                                <div className="input-feedback">
                                  {formik.errors.VIN}
                                </div>
                              )}
                            </div>

                            <div className="form-group">
                              <label>Make*</label>
                              <select
                                onChange={(e) => {
                                  getmodel(e, formik.setFieldValue);
                                }}
                                onBlur={formik.handleBlur}
                                name="MakeID"
                                value={formik.values.MakeID}
                                required={true}
                                className="form-control"
                              >
                                <option key={0} value={0}>
                                  Select option
                                </option>
                                {dealerdata != ""
                                  ? dealerdata.Makes.map((item, i) => (
                                      <option key={i + 1} value={item.MakeID}>
                                        {item.Make}
                                      </option>
                                    ))
                                  : ""}
                              </select>

                              {formik.errors.MakeID &&
                                formik.touched.MakeID && (
                                  <div className="input-feedback">
                                    {formik.errors.MakeID}
                                  </div>
                                )}
                            </div>
                            <div className="form-group">
                              <label>Model*</label>
                              <select
                                name="ModelID"
                                value={formik.values.ModelID}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                              >
                                <option>Select option</option>
                                {model != "" && model != "undefined"
                                  ? model.map((item, i) => (
                                      <option key={i + 1} value={item.ModelID}>
                                        {item.Model}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                              {formik.errors.ModelID &&
                                formik.touched.ModelID && (
                                  <div className="input-feedback">
                                    {formik.errors.ModelID}
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <button
                                    type="button"
                                    onClick={handlemodal}
                                    className="btn btn-success btn-square waves-effect waves-light"
                                    style={{ MarginTop: -14 }}
                                  >
                                    Add Model
                                  </button>
                                  <AddModel
                                    show={showmodal}
                                    handlemodal={handlemodal}
                                    DealerID={selecteddealer}
                                    MakeID={formik.values.MakeID}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Year*</label>
                                  <select
                                    onChange={formik.handleChange}
                                    value={formik.values.VehYear}
                                    onBlur={formik.handleBlur}
                                    className="form-control"
                                    name="VehYear"
                                  >
                                    <option value={0}>Select option</option>

                                    {yeardata != ""
                                      ? yeardata.map((item) => (
                                          <option
                                            key={item.year}
                                            value={item.year}
                                          >
                                            {item.year}
                                          </option>
                                        ))
                                      : ""}
                                  </select>
                                  {formik.errors.VehYear &&
                                    formik.touched.VehYear && (
                                      <div className="input-feedback">
                                        {formik.errors.VehYear}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Vehicle Type</label>
                              <select
                                name="VehicleType"
                                value={formik.values.VehicleType}
                                onChange={formik.handleChange}
                                className="form-control"
                              >
                                <option key={1} value="N">
                                  New Vehicle
                                </option>
                                <option key={2} value="O">
                                  Old Vehicle
                                </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Stock/Deal #</label>
                              <input
                                type="text"
                                className="form-control"
                                name="StockDealNo"
                                required={
                                  typeof dealerdata.dealersetting !=
                                    "undefined" &&
                                  dealerdata.dealersetting != ""
                                    ? dealerdata.dealersetting.StockDealNo
                                    : false
                                }
                                value={formik.values.StockDealNo}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label>RO #</label>
                              <input
                                type="text"
                                className="form-control"
                                name="RO"
                                value={checknull(formik.values.RO)}
                                onChange={formik.handleChange}
                              />
                            </div>
                            {plancost != "" &&
                            plancost.PlanCostAndExpiry.IsAutoReedem == 1 ? (
                              <div className="form-group">
                                <label>Auto Redeem ro number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="RO_NUMBER"
                                  value={formik.values.RO_NUMBER}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="contractno">Contract No</label>

                              <div className="input-group input-group-lg">
                                <InputGroup className="m-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="contractno"
                                    value={formik.values.contractno}
                                  />
                                  <InputGroup.Text id="basic-addon2">
                                    <i
                                      onClick={updateClipboard}
                                      style={{ cursor: "pointer" }}
                                      className="fas fa-copy"
                                    ></i>
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="my-2">
                                <label htmlFor="PlanID">Plan*</label>
                                <InputGroup className="mb-3">
                                  <select
                                    id="PlanID"
                                    name="PlanID"
                                    value={formik.values.PlanID}
                                    onChange={(e) =>
                                      handleplanchange(
                                        e.target.value,
                                        formik.setFieldValue
                                      )
                                    }
                                    className="form-control"
                                  >
                                    <option value={""}>Select option</option>
                                    {dealerdata != ""
                                      ? dealerdata.Plans.map((item, i) => (
                                          <option key={i} value={item.PlanID}>
                                            {item.PlanDescription}
                                          </option>
                                        ))
                                      : ""}
                                  </select>
                                  <InputGroup.Prepend>
                                    <InputGroup.Checkbox
                                      aria-label="Checkbox for following text input"
                                      name="SendEmail"
                                      checked={user_defaultplan ? true : false}
                                      onChange={f_defaultplan}
                                    />
                                  </InputGroup.Prepend>
                                </InputGroup>
                                {formik.errors.PlanID &&
                                  formik.touched.PlanID && (
                                    <div className="input-feedback">
                                      {formik.errors.PlanID}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="my-2">
                                <label htmlFor="SellingRep">
                                  Product Selling Rep*
                                </label>
                                <InputGroup className="mb-3">
                                  <select
                                    name="SellingRep"
                                    value={formik.values.SellingRep}
                                    onChange={formik.handleChange}
                                    required={true}
                                    defaultValue={5760}
                                    className="form-control"
                                    onBlur={formik.handleBlur}
                                  >
                                    <option key={0} value={0}>
                                      Select option
                                    </option>
                                    {dealerdata != ""
                                      ? dealerdata.ProductSellingReps.map(
                                          (item, i) => (
                                            <option
                                              key={i}
                                              value={item.FIManagerID}
                                            >
                                              {item.FIManagerName}
                                            </option>
                                          )
                                        )
                                      : ""}
                                  </select>
                                  <InputGroup.Prepend>
                                    <InputGroup.Checkbox
                                      aria-label="Checkbox for following text input"
                                      name="SendEmail"
                                      checked={user_salerep ? true : false}
                                      onChange={f_defaultsalerep}
                                    />
                                  </InputGroup.Prepend>
                                </InputGroup>
                                {formik.errors.SellingRep &&
                                  formik.touched.SellingRep && (
                                    <div className="input-feedback">
                                      {formik.errors.SellingRep}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Sales Person*</label>
                              <select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="SIManagerID"
                                value={formik.values.SIManagerID}
                                required={true}
                                className="form-control"
                              >
                                <option key={0} value={0}>
                                  Select option
                                </option>
                                {dealerdata != ""
                                  ? dealerdata.SalesSellingReps.map(
                                      (item, i) => (
                                        <option
                                          key={i + 1}
                                          value={item.FIManagerID}
                                        >
                                          {item.FIManagerName}
                                        </option>
                                      )
                                    )
                                  : ""}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Mileage</label>
                              <input
                                type="text"
                                className="form-control"
                                name="Mileage"
                                value={formik.values.Mileage}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label>Sale Date*</label>
                              <input
                                type="date"
                                className="form-control"
                                name="SaleDate"
                                id="SaleDate"
                                required={true}
                                value={formik.values.SaleDate}
                                onChange={(e) =>
                                  getplanexpiry(e, formik.setFieldValue)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Expiration Date*</label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="ValidityDate"
                                value={formik.values.ValidityDate}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12"></div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {dealerdata != "" &&
                            dealerdata.dealersetting.AllowAutoFill ? (
                              <button
                                type="button"
                                onClick={getautofilldata}
                                disabled={formik.isSubmitting}
                                className="btn btn-success btn-square waves-effect waves-light"
                              >
                                Auto Fill
                              </button>
                            ) : (
                              ""
                            )}
                            <button
                              type="submit"
                              disabled={formik.isSubmitting}
                              style={{ float: "right" }}
                              className="btn btn-success btn-square waves-effect waves-light"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="price_print" title="Price Print">
                    <div className="tab-content">
                      <div
                        className="tab-pane p-3 active"
                        id="#price_print"
                        role="tabpanel"
                      >
                        <div>
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                              <h5>Contract Price Info</h5>
                              <div className="row">
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Price on printed Contract*
                                        </label>

                                        <input
                                          type="text"
                                          className="form-control"
                                          name="ContractTotalCost"
                                          value={
                                            formik.values.ContractTotalCost
                                          }
                                          disabled={
                                            dealerdata != "" &&
                                            dealerdata.dealersetting
                                              .SellingpriceOverride
                                              ? true
                                              : false
                                          }
                                          onChange={formik.handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      {newbank ? (
                                        <div className="form-group">
                                          <label htmlFor="useremail">
                                            Bank*
                                          </label>
                                          <select
                                            onChange={getBankAddress}
                                            required={
                                              dealerdata != "" &&
                                              dealerdata.dealersetting
                                                .BankRequired
                                                ? true
                                                : false
                                            }
                                            value={formik.values.BankId}
                                            name="BankId"
                                            className="form-control"
                                          >
                                            <option>Select option</option>
                                            {banklist != "" &&
                                            typeof banklist != "undefined"
                                              ? banklist.map((item, i) => (
                                                  <option
                                                    key={i + 1}
                                                    value={item.BankID}
                                                    city={item.CityName}
                                                    statetitle={item.StateTitle}
                                                    statecode={item.StateCode}
                                                  >
                                                    {item.BankName}
                                                  </option>
                                                ))
                                              : ""}
                                          </select>
                                        </div>
                                      ) : (
                                        <div
                                          className="form-group"
                                          style={{ position: "relative" }}
                                        >
                                          <label>Bank Name*</label>
                                          <FormControl
                                            name="AddNewbank"
                                            value={formik.values.AddNewbank}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            aria-describedby="basic-addon1"
                                          />

                                          <button
                                            type="button"
                                            onClick={functionaddnewbank}
                                            className="btn btn-info btn-xs add-new-bank"
                                          >
                                            save
                                          </button>

                                          {formik.errors.AddNewbank &&
                                            formik.touched.AddNewbank && (
                                              <div className="input-feedback">
                                                {formik.errors.AddNewbank}
                                              </div>
                                            )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6"></div>{" "}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Address</label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          value={bankaddress}
                                          className="form-control"
                                          name="LienholderAddress"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Customer Price</label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          className="form-control"
                                          defaultValue={
                                            plancost != ""
                                              ? plancost.PlanCostAndExpiry
                                                  .PlanCost
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <button
                                          type="button"
                                          className="btn btn-success btn-square waves-effect waves-light ml-2"
                                          onClick={() => setNewbank(!newbank)}
                                        >
                                          {newbank ? "Add Bank" : "List Bank"}
                                        </button>

                                        <button
                                          type="button"
                                          className="btn btn-success btn-square waves-effect waves-light ml-2"
                                          onClick={handlebankaddress}
                                        >
                                          Add Address
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Plan Price</label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          className="form-control"
                                          defaultValue={
                                            plancost != ""
                                              ? plancost.PlanCostAndExpiry
                                                  .PlanCost
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Hide Price</label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          className="form-control"
                                          defaultValue={
                                            plancost != ""
                                              ? plancost.PlanCostAndExpiry
                                                  .PlanCost
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <div className="checkbox checkbox-primary">
                                          <input
                                            id="isServiceContract"
                                            type="checkbox"
                                            name="isServiceContract"
                                            onChange={formik.handleChange}
                                            value={
                                              formik.values.isServiceContract
                                            }
                                          />
                                          <label htmlFor="isServiceContract">
                                            Service Contract
                                          </label>
                                        </div>
                                        {formik.values.isServiceContract ? (
                                          <div className="my-2">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                              <Form.Control
                                                as="textarea"
                                                rows={2}
                                                name="ServiceContract"
                                                onChange={formik.handleChange}
                                                value={
                                                  formik.values.ServiceContract
                                                }
                                                placeholder="Enter Your Contract Term & Condition Here"
                                              />
                                            </Form.Group>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {dealerdata != "" &&
                                      dealerdata.dealersetting
                                        .EnableWelcomeSms ? (
                                        <div className="my-2">
                                          <div className="checkbox checkbox-primary">
                                            <input
                                              id="EnableWelcomeSms"
                                              type="checkbox"
                                              name="EnableWelcomeSms"
                                              onChange={formik.handleChange}
                                              value={
                                                formik.values.EnableWelcomeSms
                                              }
                                            />
                                            <label htmlFor="EnableWelcomeSms">
                                              Send Welcome SMS
                                            </label>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {dealerdata != "" &&
                                      dealerdata.dealersetting
                                        .EnableLoyaltyCash ? (
                                        <div className="my-2">
                                          <div className="checkbox checkbox-primary">
                                            <input
                                              id="Loyalty_Map"
                                              type="checkbox"
                                              name="Loyalty_Map"
                                              onChange={formik.handleChange}
                                              value={formik.values.Loyalty_Map}
                                            />
                                            <label htmlFor="Loyalty_Map">
                                              Enroll in Loyalty Cash
                                            </label>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {formik.values.Loyalty_Map ? (
                                        <div className="my-2">
                                          <div className="checkbox checkbox-primary">
                                            <input
                                              id="LoyaltyCashEnable"
                                              type="checkbox"
                                              onChange={formik.handleChange}
                                              value={
                                                formik.values.LoyaltyCashEnable
                                              }
                                              name="LoyaltyCashEnable"
                                            />
                                            <label htmlFor="LoyaltyCashEnable">
                                              Loyalty Points Vehicle Sale Bonus
                                            </label>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="col-md-6 col-lg-6 col-sm-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label>Email Address*</label>
                                            <div style={{ display: "flex" }}>
                                              <input
                                                type="text"
                                                className="col-md-8 form-control"
                                                id="customer_first_name"
                                                required=""
                                              />
                                              <button
                                                type="button"
                                                className="btn btn-success btn-square waves-effect waves-light ml-4"
                                              >
                                                Resend Email
                                              </button>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-12">
                                            <label></label>
                                            <div style={{ display: "flex" }}>
                                              <input
                                                type="text"
                                                className="col-md-8 form-control"
                                                id="customer_first_name"
                                                required=""
                                              />
                                              <button
                                                type="button"
                                                className="btn btn-success btn-square waves-effect waves-light ml-4"
                                              >
                                                Email Info
                                              </button>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row mt-5">
                                          <div className="col-md-12">
                                            <img
                                              src={
                                                require("../../resources/img/print.png")
                                                  .default
                                              }
                                              className=""
                                              height="40"
                                              alt=""
                                            />
                                            <button
                                              type="button"
                                              className="btn btn-success btn-square waves-effect waves-light ml-2"
                                            >
                                              Print Contract
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-success btn-square waves-effect waves-light ml-2"
                                            >
                                              Print Letter
                                            </button>
                                          </div>
                                        </div>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
