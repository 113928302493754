import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { duplicate_plan } from "./PlanApi";

import { useToasts } from "react-toast-notifications";
export default function DuplicatePlan(props) {
  const { addToast } = useToasts();
  const {
    handleClose,
    currentplan,
    formvalues,
    setPlanList,
    setPage,
    setCount,
    page,
    sortdata,
  } = props;
  const savechanges = async () => {
    const formdata = new FormData();
    formdata.append("PlanSortByCol", sortdata.columnName);
    formdata.append("PlanSortByColType", sortdata.direction);
    formdata.append("offset", page);
    formdata.append("status", formvalues.status);
    formdata.append("plan_status", formvalues.plan_status);
    formdata.append("planname", formvalues.planname);
    formdata.append("DealerID", formvalues.DealerID);
    formdata.append("PlanID", currentplan.PlanID);
    const api_res = await duplicate_plan(formdata);
    if (api_res.success === 1) {
      addToast("Plan duplicated successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      setPlanList(api_res.plans);
      setCount(api_res.TotalRec);
      setPage(api_res.offset);
      handleClose();
    } else {
      addToast("An error occurred", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <Modal centered={true} show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Duplicate Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to Duplicate this Plan?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={savechanges}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
