import { dashboardexport, subscriptionexport, allowmenu } from "./helperApi";
import fileSaver from "file-saver";
import moment from "moment";
import { token } from "../CurrentUser";
export const formatbankaddress = (city, state, statecode) => {
  let lineofaddress = "";
  if (city.trim() != "") {
    lineofaddress = city.trim();
  }
  if (state.trim() != "") {
    lineofaddress = lineofaddress + ", " + state.trim();
  }
  if (statecode.trim() != "") {
    lineofaddress = lineofaddress + " - " + statecode.trim();
  }
  return lineofaddress;
};
export function checknull(value) {
  if (value == null || value.length === 0) {
    return "";
  } else {
    return value;
  }
}
export async function f_subscriptionexport(formData) {
  let CurrentDate = moment().unix();
  const response = await subscriptionexport({ formData });

  if (response.success == 0) {
    return false;
  } else {
    var blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fileSaver.saveAs(blob, CurrentDate + ".xlsx");
  }
}
export async function f_dashboardexport(formData) {
  let CurrentDate = moment().unix();
  const response = await dashboardexport({ formData });

  if (response.success == 0) {
    return false;
  } else {
    var blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fileSaver.saveAs(blob, CurrentDate + ".xlsx");
  }
}
export const array_sum = (array) => {
  if (typeof array != "undefined") {
    return array.reduce((a, v) => (a = a + v), 0);
  } else {
    return "";
  }
};
export const redirectclassic = async (data) => {
  // const formData = new FormData();
  // const classicapi_api = await classicapi(url);
  if (data.ismodern === 1) {
    window.location.href = process.env.REACT_APP_BASE_URL + data.url;
  } else {
    window.location.href =
      process.env.REACT_APP_APP_URL +
      "/Loginreactcopy/classicapi/" +
      token +
      "?RedirectURL=" +
      data.url +
      "&isLogin=1";
  }
};
export const checkpermission = async (controller_name, function_name) => {
  const formData = new FormData();
  formData.append("controller_name", controller_name);
  formData.append("function_name", function_name);

  const api_response = await allowmenu(formData);
  return api_response;
};
