import React from "react";
import { InputGroup, FormControl, Card, Row } from "react-bootstrap";
export default function Plansetting() {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Plan Setting</Card.Title>
        <Row>
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"YEARLY EXPIRATION"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"EXPRESS PLAN"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
        </Row>
        <Row>
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"PERPETUAL PLAN"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"COMPLIMENTARY"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
        </Row>
        <Row>
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"PLAN RENEWAL"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"NON DEALERSHIP USERS"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
        </Row>{" "}
        <Row>
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"UNLIMITED MILEAGE PLAN"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>

          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"UNLIMITED PLAN TERM"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
        </Row>
        <Row>
          <InputGroup className="col-12 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"MAINTENANCE PRODUCT SERVICE PLAN"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
          <InputGroup className="col-12 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <InputGroup.Prepend>
              <FormControl
                value={"Transaction Percentage"}
                aria-label="Text input with checkbox"
                readOnly
              />
            </InputGroup.Prepend>
            <FormControl aria-label="Text input with checkbox" />
          </InputGroup>{" "}
          <InputGroup className="col-12 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <InputGroup.Prepend>
              <FormControl
                value={"CLP Percentage"}
                aria-label="Text input with checkbox"
                readOnly
              />
            </InputGroup.Prepend>
            <FormControl aria-label="Text input with checkbox" />
            <FormControl
              value={"%"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
          <InputGroup className="col-12 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              />
            </InputGroup.Prepend>
            <FormControl
              value={"HIDE CUSTOMER PRICE"}
              aria-label="Text input with checkbox"
              readOnly
            />
          </InputGroup>
        </Row>
        <div className="row">
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              >
                min
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl aria-label="Text input with checkbox" />
          </InputGroup>
          <InputGroup className="col-6 mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              >
                max
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl aria-label="Text input with checkbox" />
          </InputGroup>
          <InputGroup className="col-12 mb-3">
            <InputGroup.Prepend>
              <InputGroup
                aria-label="Checkbox for following text input"
                name="YearlyExpiration"
              >
                <select
                  className="form-control"
                  name="YearCondition"
                  id="YearCondition"
                  style={{ width: 100 }}
                >
                  <option value>Select</option>
                  <option value=">=">&gt;=</option>
                  <option value="<=" selected="selected">
                    &lt;=
                  </option>
                </select>
              </InputGroup>
            </InputGroup.Prepend>
            <FormControl
              value={"HIDE CUSTOMER PRICE"}
              aria-label="Text input with checkbox"
            />
          </InputGroup>{" "}
        </div>
      </Card.Body>
    </Card>
  );
}
