import axios from "axios";
import { user_id, role_id, pcp_user_id } from "../../CurrentUser";
const token = JSON.parse(localStorage.getItem("token"));
const API = process.env.REACT_APP_APP_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const header = {
  "Content-Type": "application/json",
};
export const mainmenu = async () => {
  let url = API + "/loginreactcopy/menu";
  const formData = new FormData();
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const classicapi = async (rurl) => {
  let url = API + "/loginreactcopy/classicapi/" + token;

  try {
    const response = await axios.get(url, {
      params: {
        RedirectURL: rurl,
      },
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const dealershiplist = async () => {
  let url = API + "/dashboard/accountdropdown";
  const formData = new FormData();
  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
