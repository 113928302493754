import React, { useState, useEffect } from "react";

import { emaildashboard_post } from "./DashboardApi";

import { useToasts } from "react-toast-notifications";
import PreLoad from "../components/PreLoad";
export const DashboardPrintModal = (props) => {
  const {
    DealerID,
    FixedDateParameter,
    fromdate,
    toDate,
    close,
    dealerredemptiondata,
    PcpBreakDown,
    UpsellAmount,
    ReservedAmountBreakDown,
    DashboardContract,
    UniqueVisit,
    CurrentThirtDays,
    LastThirtDays,
    Mobileussage,
  } = props;

  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();

  useEffect(() => {
    onsubmit();
  }, [DealerID]);

  const onsubmit = async () => {
    setLoading(true);

    const formdata = new FormData();

    //breakdown
    const chartInstance1 = window.Apex._chartInstances.find(
      (chart) => chart.id === "breakdown"
    );
    const breakdown = await chartInstance1.chart.dataURI();
    formdata.append("pie", breakdown.imgURI);

    //contractsold
    const chartInstance2 = window.Apex._chartInstances.find(
      (chart) => chart.id === "contractsold"
    );
    const contractsold = await chartInstance2.chart.dataURI();
    formdata.append("graph", contractsold.imgURI);

    //servicedistribution
    const chartInstance3 = window.Apex._chartInstances.find(
      (chart) => chart.id === "servicedistribution"
    );
    const servicedistribution = await chartInstance3.chart.dataURI();
    formdata.append("heatgraph", servicedistribution.imgURI);

    //contract sold
    const chartInstance4 = window.Apex._chartInstances.find(
      (chart) => chart.id === "serviceredmption"
    );
    const serviceredmption = await chartInstance4.chart.dataURI();
    formdata.append("bar", serviceredmption.imgURI);
    //end

    formdata.append("DealerID", DealerID);
    formdata.append("FomDate", fromdate);
    formdata.append("ToDate", toDate);
    formdata.append("IsPrint", 1);
    formdata.append("FixedDateParameter", FixedDateParameter);
    formdata.append("UniqueVisit", UniqueVisit);

    formdata.append(
      "EnableAuthenticom",
      dealerredemptiondata.EnableAuthenticom
    );
    formdata.append(
      "DashboardContractReserveOptionalDetails",
      JSON.stringify(dealerredemptiondata)
    );
    formdata.append(
      "PlanServicesReservedAmountOverrite",
      dealerredemptiondata.PlanServicesReservedAmountOverrite
    );
    formdata.append(
      "ShopingCartEnable",
      dealerredemptiondata.ShopingCartEnable
    );
    formdata.append(
      "EnableReserveGraph",
      dealerredemptiondata.EnableReserveGraph
    );
    formdata.append(
      "EnableDisplayServiceWtithAmount",
      dealerredemptiondata.EnableDisplayServiceWtithAmount
    );
    formdata.append("Servicemultiple", dealerredemptiondata.Servicemultiple);
    formdata.append(
      "ReserveGraph",
      JSON.stringify(dealerredemptiondata.ReserveGraph)
    );
    formdata.append("PcpBreakDown", JSON.stringify(PcpBreakDown));
    formdata.append("UpsellAmount", JSON.stringify(UpsellAmount));
    formdata.append("dashboardContract", JSON.stringify(DashboardContract));
    formdata.append(
      "ReservedAmountBreakDown",
      JSON.stringify(ReservedAmountBreakDown)
    );
    formdata.append("CurrentThirtyday", JSON.stringify(CurrentThirtDays));
    formdata.append("Mobileussage", JSON.stringify(Mobileussage));
    formdata.append("LastThirtyday", JSON.stringify(LastThirtDays));
    formdata.append("row1", JSON.stringify(dealerredemptiondata.row1));
    formdata.append("row2", JSON.stringify(dealerredemptiondata.row2));
    formdata.append("row3", JSON.stringify(dealerredemptiondata.row3));
    formdata.append("row4", JSON.stringify(dealerredemptiondata.row4));
    formdata.append("row5", JSON.stringify(dealerredemptiondata.row5));
    formdata.append("row1_show", dealerredemptiondata.row1_show);
    formdata.append("row2_show", dealerredemptiondata.row2_show);
    formdata.append("row3_show", dealerredemptiondata.row3_show);
    formdata.append("row4_show", dealerredemptiondata.row4_show);
    formdata.append("row5_show", dealerredemptiondata.row5_show);

    const email_res = await emaildashboard_post(formdata);

    if (email_res.success === 1) {
      setLoading(false);
      close();
      window.open(email_res.url, "_blank");
    } else {
      addToast("An error occurred", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <React.Fragment>{loading && <PreLoad loading={loading} />}</React.Fragment>
  );
};
