import React, { useState } from "react";
import $ from "jquery";
import "./topmenu.css";
import DealersDropdown from "../../components/DealersDropdown";
import { user_name, user_img, token } from "../../CurrentUser";
import MenuIcons from "./MenuIcons";
import { useToasts } from "react-toast-notifications";
import { checknull } from "../../components/helpers";
import { useDispatch, useSelector } from "react-redux";
function Topmenu() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const unfoldable = useSelector(
    (state) => state.Sidebarstore.sidebarUnfoldable
  );

  const [selectteddealer, setSelectteddealer] = useState("");
  const [showmodal, setShowmodal] = useState(false);
  const [prfilemenu, setPrfilemenu] = useState(false);

  const handledealerchange = (val) => {
    setSelectteddealer(val);
  };
  const handleclick = () => {
    //e.preventDefault();
    setShowmodal(!showmodal);
  };
  const handleprofile = () => {
    //e.preventDefault();
    setPrfilemenu(!prfilemenu);
  };

  const checkTopSearch = (e) => {
    e.preventDefault();
    let search_by = $("#combo_search_by").val();
    let parameter = $("#top_search_field").val().trim();
    let dealerid = selectteddealer;
    if (dealerid === "") {
      addToast("Please Select Dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    if (search_by === "") {
      addToast("Please Select search By", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    if (parameter === "") {
      addToast("Please Enter valid aurgement", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    localStorage.setItem("dealerid", dealerid);
    localStorage.setItem("search_by", search_by);
    localStorage.setItem("parameter", parameter);
    window.location.href = process.env.REACT_APP_BASE_URL + "/contracts/index/";
  };
  const handlelogout = (e) => {
    e.preventDefault();

    if (checknull(token) !== "") {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href =
        process.env.REACT_APP_APP_URL +
        "/Loginreactcopy/classicapi/" +
        token +
        "?RedirectURL='logout'&isLogin=0";
    } else {
      window.location.href = "https://newdash.mypcp.us";
    }
  };

  const redirectclassic = async (url) => {
    window.location.href =
      process.env.REACT_APP_APP_URL +
      "/Loginreactcopy/classicapi/" +
      token +
      "?RedirectURL=" +
      url +
      "&isLogin=1";
  };
  return (
    <div className="topbar">
      <nav
        className={unfoldable ? "navbar-custom ml-80" : "navbar-custom ml-250"}
      >
        <ul className="list-unstyled topbar-nav float-right mb-0 ">
          <li
            className="show-right-menu hidden-sm"
            style={{ margin: "18px 0px" }}
          >
            <a onClick={handleclick} href="#">
              <i className="fas fa-bars fa-2x"></i>
            </a>
          </li>

          <li className="dropdown">
            <a
              className="nav-link dropdown-toggle waves-effect waves-light nav-user"
              data-toggle="dropdown"
              href="#"
              onClick={handleprofile}
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                src={user_img}
                alt="profile-user"
                className="rounded-circle"
              />
            </a>
            <div
              className={
                prfilemenu
                  ? "dropdown-menu dropdown-menu-right d-block"
                  : "dropdown-menu dropdown-menu-right"
              }
            >
              <a className="dropdown-item" href="#">
                {user_name}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() =>
                  redirectclassic("https://newdash.mypcp.us/users/profile")
                }
              >
                <i className="dripicons-user text-muted mr-2"></i> Profile
              </a>

              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={handlelogout}
              >
                <i className="dripicons-exit text-muted mr-2"></i> Logout
              </a>
            </div>
          </li>
        </ul>

        <ul className="list-unstyled topbar-nav mb-0 pl-3">
          <li className="dropdown" style={{ margin: "18px 0px" }}>
            <a
              onClick={() =>
                dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
              }
              href="#"
            >
              <i className="fas fa-bars fa-2x"></i>
            </a>
          </li>
          <li
            className="hide-phone app-search top-serach-w"
            style={{ width: 200 }}
          >
            <DealersDropdown
              id="header_account_list"
              handledealerchange={handledealerchange}
            />
          </li>

          <li className="hide-phone app-search top-serach-w">
            <select
              id="combo_search_by"
              className="form-control"
              name="search_by"
              style={{ borderRadius: 4 }}
              defaultValue="VIN"
            >
              <option value="ContractNo">Search by - CONTRACT#</option>
              <option value="VIN">Search by - VIN#</option>
              <option value="CustomerName">Search by - Customer Name</option>
              <option value="PrimaryEmail">Search by - EMAIL ADDRESS</option>
              <option value="PhoneHome">Search by - CUSTOMER PHONE</option>
              <option value="RO">Search by - RO #</option>
              <option value="LOYALTYVIN">Search by Loyalty Cash - VIN#</option>
            </select>
          </li>
          <li className="hide-phone app-search top-serach-w">
            <form role="search" className="">
              <input
                type="text"
                id="top_search_field"
                placeholder="Search..."
                className="form-control"
              />
              <a onClick={(e) => checkTopSearch(e)}>
                <i className="fas fa-search"></i>
              </a>
            </form>
          </li>
        </ul>
        <ul className="hide-right-menu list-unstyled topbar-nav float-right mb-0">
          <MenuIcons />
        </ul>
      </nav>
      {showmodal ? (
        <div
          className="modal modal-rightbar fade show"
          tabindex="-1"
          role="dialog"
          style={{ display: "block", paddingRight: "16px !important" }}
          aria-labelledby="MetricaRightbar"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div
              className="modal-content"
              style={{
                height: "auto",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="MetricaRightbar">
                  Appearance
                </h5>
                <button
                  type="button"
                  className="btn btn-sm btn-soft-primary btn-circle-sm btn-square"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={handleclick}
                >
                  <i className="mdi mdi-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <ul className="list-unstyled topbar-nav float-right mb-0 right-menu-item">
                  <MenuIcons />
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Topmenu;
