import React, { Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  f_dashboardexport,
  f_subscriptionexport,
} from "../components/helpers";

import { useToasts } from "react-toast-notifications";
import PreLoad from "../components/PreLoad";
function Row1(props) {
  const {
    dealerredemption,
    ShopingCartEnable,
    DealerID,
    fromdate,
    toDate,
    FixedDateParameter,
    EnableDisplayServiceWtithAmount,
    row,
    f1,
    f2,
    f3,
    f4,
    f5,
    f6,
  } = props;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const exportdata = (export_type) => {
    if (DealerID == "" || typeof DealerID == "undefined") {
      addToast("please select dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("FomDate", fromdate);
    formData.append("ToDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    formData.append("export_type", export_type);
    setLoading(true);

    // //api
    if (ShopingCartEnable === 1) {
      f_subscriptionexport(formData).then((response) => {
        setLoading(false);
      });
    } else {
      f_dashboardexport(formData).then((response) => {
        setLoading(false);
      });
    }
  };

  let tooltip1 =
    "This is the number of contracts issued in the selected date period<br>This number include reserve of Matured and cancelled contracts.";
  let tooltip2 =
    "This is the number of services redeemed From contracts issued in the selected date period<br>This includes Active, Inactive, Matured and Cancelled contracts";
  let tooltip3 =
    "This is the number of services pending From<br/>contracts issued in the selected <br />date period exclude Cancelled,<br />Matured contracts and Magenta Servicves or Factory Oil Changes";
  let tooltip4 =
    "This is the number of contracts matured in the selected date period";
  return (
    <Fragment>
      <PreLoad loading={loading} />
      <div className="card">
        <div className="card-body" style={{ padding: "0.75rem" }}>
          <div className="row">
            <div className="col-md-3" style={{ fontSize: "15px !important" }}>
              {dealerredemption.ReservedAmount > "0" ? (
                <Fragment>
                  {" "}
                  <span className="mr-2">Contract Reserve</span>
                  <a href="#" onClick={() => exportdata([f1])}>
                    <span data-tip={tooltip1} className="delete_icon">
                      <i className="dripicons-document-delete"></i>
                    </span>
                  </a>
                  <br />
                  <span className="mr-1">
                    {dealerredemption.Reserved ? dealerredemption.Reserved : 0}
                  </span>
                  <span className="mr-1 play_btn_color">
                    <i className="mdi mdi-play"></i>
                  </span>
                  <span>
                    $
                    {dealerredemption.ReservedAmount
                      ? dealerredemption.ReservedAmount
                      : 0}
                  </span>
                </Fragment>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-3" style={{ fontSize: "15px !important" }}>
              <span className="mr-2">Service Redeemed</span>
              <a href="#" onClick={() => exportdata([f2])}>
                <span data-tip={tooltip2} className="delete_icon">
                  <i className="dripicons-document-delete"></i>
                </span>
              </a>
              <br />
              <span className="mr-1">
                {dealerredemption.serviceredeem
                  ? dealerredemption.serviceredeem
                  : 0}
              </span>
              <span className="mr-1 play_btn_color">
                <i className="mdi mdi-play"></i>
              </span>
              <span>
                $
                {dealerredemption.serviceredeemamount
                  ? dealerredemption.serviceredeemamount
                  : 0}
              </span>

              {+EnableDisplayServiceWtithAmount === 1 &&
              dealerredemption.redem_service_count > "0" ? (
                <div className="text_red">
                  <a
                    href="#"
                    className="text_red  "
                    onClick={() => exportdata([f5])}
                    name="c4"
                  >
                    {dealerredemption.redem_service_count
                      ? dealerredemption.redem_service_count
                      : 0}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-2" style={{ fontSize: "15px !important" }}>
              <span className="mr-2">Service Pending</span>
              <a href="#" onClick={() => exportdata([f3])} data-tip={tooltip3}>
                <span className="delete_icon">
                  <i className="dripicons-document-delete"></i>
                </span>
              </a>
              <br />
              <span>
                {dealerredemption.servicepending
                  ? dealerredemption.servicepending
                  : 0}
              </span>
              <span className="mr-1 play_btn_color">
                <i className="mdi mdi-play"></i>
              </span>
              <span>
                $
                {dealerredemption.servicependingamount
                  ? dealerredemption.servicependingamount
                  : 0}
              </span>
              {+EnableDisplayServiceWtithAmount === 1 &&
              dealerredemption.pending_service_count > "0" ? (
                <div className="text_red">
                  <a
                    href="#"
                    className="text_red  "
                    onClick={() => exportdata([f6])}
                    name="c4"
                  >
                    {dealerredemption.pending_service_count
                      ? dealerredemption.pending_service_count
                      : 0}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-4" style={{ fontSize: "15px !important" }}>
              {row !== 5 ? (
                <Fragment>
                  <span className="mr-2">Matured Contracts</span>
                  <a
                    href="#"
                    onClick={() => exportdata([f4])}
                    data-tip={tooltip4}
                  >
                    <span className="delete_icon">
                      <i className="dripicons-document-delete"></i>
                    </span>
                  </a>
                  <br />
                  <span>
                    {dealerredemption.maturedcontract
                      ? dealerredemption.maturedcontract
                      : 0}
                  </span>
                  <span style={{ color: "green", fontSize: "16px" }}>
                    <i className="mdi mdi-play"></i>
                  </span>
                  <span
                    style={{ fontSize: 13 }}
                    className="badge badge-success"
                  >
                    $
                    {dealerredemption.maturedcontracttotalCost
                      ? dealerredemption.maturedcontracttotalCost
                      : 0}
                  </span>
                  <span
                    className="badge badge-warning"
                    style={{ fontSize: 13 }}
                  >
                    $
                    {dealerredemption.maturedcontracttakenin
                      ? dealerredemption.maturedcontracttakenin
                      : 0}
                  </span>
                  <span className="mr-3">
                    <i className="dripicons-question"></i>
                  </span>
                  <span
                    className="badge badge-warning"
                    style={{ backgroundColor: "#fe8023", fontSize: 12 }}
                  >
                    $
                    {dealerredemption.maturedcontracttakeout
                      ? dealerredemption.maturedcontracttakeout
                      : 0}
                  </span>
                  <span className="">
                    <i className="dripicons-question"></i>
                  </span>
                </Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
          <ReactTooltip multiline={true} />
        </div>
      </div>
    </Fragment>
  );
}

export default Row1;
