import { token } from "../../CurrentUser";
import ReactTooltip from "react-tooltip";
export default function MenuIcons() {
  let tooltip1 = "Switch to Classic";
  const redirectclassic = async (url) => {
    window.location.href =
      "https://mypcp.us/webservices/Loginreactcopy/classicapi/" +
      token +
      "?RedirectURL=" +
      url +
      "&isLogin=1";
  };
  return (
    <>
      <ReactTooltip multiline={true} />
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() => redirectclassic("https://newdash.mypcp.us/")}
          aria-haspopup="false"
          aria-expanded="false"
          data-tip={tooltip1}
          style={{ cursor: "pointer" }}
        >
          <img
            src={require("../../resources/images/classic.png").default}
            className="nav-icon-img"
            width="60"
            alt="classic"
          />
        </span>
      </li>
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() => redirectclassic("https://newdash.mypcp.us/twoway/index")}
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/trackometer.png").default
            }
            className="nav-icon-img"
            height="43"
            alt="trackometer"
          />
        </span>
      </li>
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() =>
            redirectclassic("https://newdash.mypcp.us/servicedrive/index")
          }
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/tutorial-video.png")
                .default
            }
            className="nav-icon-img"
            height="43"
            alt="tutorial"
          />
        </span>
      </li>
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() =>
            redirectclassic("https://newdash.mypcp.us/referralchat/index")
          }
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/generate-sitrip.png")
                .default
            }
            className="nav-icon-img"
            height="43"
            alt="generate"
          />
        </span>
      </li>
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() =>
            redirectclassic(
              "hhttps://newdash.mypcp.us/accounts/expresscontractsale"
            )
          }
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/tutorial-tour.png")
                .default
            }
            className="nav-icon-img"
            height="43"
            alt="tutorial"
          />
        </span>
      </li>
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() => redirectclassic("https://newdash.mypcp.us/twoway/index")}
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/new-graph-report.png")
                .default
            }
            className="nav-icon-img"
            height="43"
            alt="new-graph"
          />
        </span>
      </li>
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() => redirectclassic("https://newdash.mypcp.us/twoway/index")}
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/add-new-contract.png")
                .default
            }
            className="nav-icon-img"
            height="43"
            alt="add-new-contract"
          />
        </span>
      </li>

      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() => redirectclassic("https://newdash.mypcp.us/twoway/index")}
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/add-express-contract.png")
                .default
            }
            className="nav-icon-img"
            height="43"
            alt="add-express-contract"
          />
        </span>
      </li>
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          onClick={() => redirectclassic("https://newdash.mypcp.us/twoway/index")}
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/referral-chat.png")
                .default
            }
            className="nav-icon-img"
            height="43"
            alt="referral-chat"
          />
        </span>
      </li>
      {/* 
      <li className="hidden-sm">
        <span
          className="nav-link nav-link-custom"
          role="button"
          aria-haspopup="false"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              require("../../resources/images/top_bar/notifications.png")
                .default
            }
            className="nav-icon-img"
            height="43"
            alt=""
          />
        </span>
      </li> */}
    </>
  );
}
