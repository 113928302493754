import React, { useState, useEffect } from "react";
import { dashboardcomparegraph } from "./DashboardApi";
import { WaveLoading } from "react-loadingg";
import Chart from "react-apexcharts";
function CompairLastYear(props) {
  const {
    DealerID,
    FixedDateParameter,
    fomDate,
    toDate,
    Title2,
    ServiceMultiple,
  } = props;
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [serviceRedemption, setServiceRedemption] = useState([]);
  useEffect(() => {
    fetchData();
  }, [DealerID]);
  const fetchData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("FomDate", fomDate);
    formData.append("ToDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);

    //api call
    const dashboardcomparegraph_resp = await dashboardcomparegraph({
      formData,
    });
    setLoading(false);
    if (dashboardcomparegraph_resp.success === 1) {
      setContracts(dashboardcomparegraph_resp.contracts);
      setServiceRedemption(dashboardcomparegraph_resp.services);
    }
  };
  return (
    <React.Fragment>
      {loading ? (
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <WaveLoading color="#4ac7ec" />
            </div>
          </div>
        </div>
      ) : (
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-5 text-center">
            <ContractsSold
              current={contracts.current}
              previous={contracts.previous}
              xaxis={contracts.legend}
            />
            <span className="font-16">Contracts Sold</span>
          </div>
          <div className="col-md-2 text-center no-padding">
            <span
              className="pt-3"
              style={{
                paddingTop: "7.5rem !important",
                fontSize: "0.71rem",
              }}
            >
              Contract Sale : Service Redemption
            </span>
            <br />
            <span className="mr-3">
              <i
                style={{ color: "#26a69a" }}
                className="font-22 fas fa-arrow-up"
              ></i>
            </span>
            <span className="mr-3 font-22">{ServiceMultiple}</span>
            <span>
              <i className="font-22 mdi mdi-chart-areaspline "></i>
            </span>
          </div>
          <div className="col-md-5  text-center">
            <span className="font-16">{Title2}</span>

            <ServiceRedemptionGraph
              current={serviceRedemption.current}
              previous={serviceRedemption.previous}
              xaxis={serviceRedemption.legend}
            />

            <span className="font-16">Service Redemption</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default CompairLastYear;

export const ContractsSold = (props) => {
  const { current, previous, xaxis } = props;
  const [category, setCategory] = useState(["jan", "feb"]);

  useEffect(() => {
    if (typeof props.xaxis !== "undefined") {
      setCategory(props.xaxis);
    }
  }, [props.previous]);
  return (
    <Chart
      options={{
        chart: {
          height: 350,
          type: "bar",
          id: "compairlastcontractsold",
          toolbar: {
            show: false,
            tools: {
              reset: true,
              pan: false,
            },
          },
        },

        dataLabels: {
          enabled: false,
        },
        colors: ["#E985D5", "#86C6C2"],
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          shared: false,
          followCursor: false,
        },

        xaxis: {
          type: "category",
          categories: category,
        },
        legend: {
          show: false,
        },
      }}
      series={[
        {
          name: "Current",
          data: current,
        },
        {
          name: "Previous",
          data: previous,
        },
      ]}
      type="bar"
      height={350}
    />
  );
};
export const ServiceRedemptionGraph = (props) => {
  const { current, previous, xaxis } = props;
  const [legend, setlegend] = useState(["jan", "feb"]);

  useEffect(() => {
    if (typeof props.xaxis !== "undefined") {
      setlegend(props.xaxis);
    }
  }, [props.previous]);
  return (
    <Chart
      options={{
        chart: {
          height: 350,
          type: "bar",
          id: "serviceredemption",
          toolbar: {
            show: false,
            tools: {
              reset: true,
              pan: false,
            },
          },
        },
        colors: ["#E985D5", "#86C6C2"],
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          shared: false,
          followCursor: false,
        },

        xaxis: {
          type: "category",
          categories: legend,
        },
        legend: {
          show: false,
        },
      }}
      series={[
        {
          name: "Current",
          data: current,
        },
        {
          name: "Previous",
          data: previous,
        },
      ]}
      type="bar"
      height={350}
    />
  );
};
