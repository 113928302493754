import React, { Component } from "react";
import Chart from "react-apexcharts";

function DashboardPCPBreakDown(props) {
  const { PcpBreakDown } = props;

  let Subscriptions = PcpBreakDown.subscriptions
    ? PcpBreakDown.subscriptions
    : 0;
  let Express = PcpBreakDown.express ? PcpBreakDown.express : 0;
  let Complimentary = PcpBreakDown.complimentary
    ? PcpBreakDown.complimentary
    : 0;
  let classic = PcpBreakDown.classic ? PcpBreakDown.classic : 0;
  let Marketplace = PcpBreakDown.TotalMarketPlace
    ? PcpBreakDown.TotalMarketPlace
    : 0;

  let myseries = [
    parseInt(Subscriptions),
    parseInt(Express),
    parseInt(Complimentary),
    parseInt(classic),
    parseInt(Marketplace),
  ];

  return (
    <div className="col-md-4">
      <div className="card">
        <div className="card-body" style={{ padding: "0.75rem" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="donut">
                <Chart
                  options={{
                    chart: {
                      id: "breakdown",
                    },
                    legend: {
                      show: false,
                    },
                    colors: [
                      "#ff9800",
                      "#ffcd03",
                      "#fcba17",
                      "#00aeef",
                      "#165b76",
                    ],
                  }}
                  series={myseries}
                  labels={[
                    "Subscriptions",
                    "Express",
                    "Complimentary",
                    "Classic",
                    "Marketplace",
                  ]}
                  type="donut"
                  width="300"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12 text-center font-18"
              style={{ lineHeight: "22px" }}
            >
              PCP Breakdown
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPCPBreakDown;
