import React, { useState, useRef } from "react";
import Datatable from "./Datatable";
import { InputGroup, Button } from "react-bootstrap";
import DealersDropdown from "../../../components/DealersDropdown";
import { useFormik } from "formik";
import { planlistdata, serviceamountexport } from "./PlanApi";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import "./planlist.css";
import LockAccount from "./LockAccount";
import UnLockAccount from "./UnLockAccount";
import moment from "moment";
import { saveAs } from "file-saver";
import PreLoad from "../../../components/PreLoad";
export default function PlanList() {
  const { addToast } = useToasts();
  const exportRef = useRef(null);

  // states
  const [selecteddealer, setSelecteddealer] = useState(null);
  const [planslist, setPlanslist] = useState([]);
  const [plancount, setPlancount] = useState(0);
  const [dataloading, setDataloading] = useState(false);
  const [dealerinfo, setDealerinfo] = useState("");
  const [remamingtime, setRemamingtime] = useState(0);
  const [showmodal, setShowmodal] = useState({
    lock_account: false,
    unlock_account: false,
  });
  const [loading, setLoading] = useState(false);
  //functions

  const planlsitform = useFormik({
    initialValues: {
      status: "-1",
      planstatus: -1,
      planname: "",
      DealerID: "",
    },
    onSubmit: async (data) => {
      setDealerinfo("");
      if (data.DealerID === "") {
        addToast("Please Select Dealership", {
          appearance: "error",
          autoDismiss: true,
        });
        return false;
      }
      setDataloading(true);
      const formData = new FormData();

      Object.keys(data).forEach((key) => formData.append(key, data[key]));

      //api
      formData.append("offset", 0);
      const api = await planlistdata(formData);
      setDataloading(false);
      if (api.success === 1) {
        setPlanslist(api.plans);
        setPlancount(api.TotalRec);
        setDealerinfo(api);
        //         if (typeof api.dealerlock !== null) {
        //           if (+api.IsNewAccount === 0 && +api.dealerlock.LockStatus === 1) {

        // let now = moment(api.dealerlock.TimeEnd, "YYYY-MM-DD HH:mm:ss");  //todays date
        // let end = moment(api.servertime,"YYYY-MM-DD HH:mm:ss");// another date
        // let duration = moment.duration(now.diff(end));
        // setRemamingtime((~~duration.asHours())+':'+duration.minutes());

        //           }
        //         }
      }
    },
  });
  const checkeverymin = () => {
    if (dealerinfo.dealerlock !== "") {
      if (
        +dealerinfo.IsNewAccount === 0 &&
        +dealerinfo.dealerlock.LockStatus === 1
      ) {
        let newtime = moment(remamingtime)
          .subtract(1, "minutes")
          .format("HH:mm");
        console.log("remamingtime", remamingtime);
        console.log("n", newtime);
        let duration = moment.duration();

        //setRemamingtime((~~duration.asHours())+':'+duration.minutes());
      }
    }
  };
  // setInterval(checkeverymin, 5000);
  const handledealerchange = async (val) => {
    setSelecteddealer(val);
    planlsitform.setFieldValue("DealerID", val);

    setDataloading(true);
    const formData = new FormData();
    formData.append("DealerID", val);

    //api
    formData.append("offset", 0);
    const api = await planlistdata(formData);
    setDataloading(false);
    if (api.success === 1) {
      setPlanslist(api.plans);
      setPlancount(api.TotalRec);
      setDealerinfo(api);
      //         if (typeof api.dealerlock !== null) {
      //           if (+api.IsNewAccount === 0 && +api.dealerlock.LockStatus === 1) {

      // let now = moment(api.dealerlock.TimeEnd, "YYYY-MM-DD HH:mm:ss");  //todays date
      // let end = moment(api.servertime,"YYYY-MM-DD HH:mm:ss");// another date
      // let duration = moment.duration(now.diff(end));
      // setRemamingtime((~~duration.asHours())+':'+duration.minutes());

      //           }
      //         }
    }
  };
  const handlenormalexport = async () => {
    if (+exportRef.current.value === 0) {
      addToast("Please Select Export Type", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    if (planlsitform.values.DealerID === "") {
      addToast("Please Select Dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    setLoading(true);
    formData.append("export_type", exportRef.current.value);
    formData.append("DealerID", planlsitform.values.DealerID);
    const api = await serviceamountexport(formData);
    setLoading(false);
    if (api.success === 1) {
      var date = moment(new Date()).format("YYYY_MM_DD-HH_mm_ss");
      saveAs(api.url, date + ".xlsx");
    } else {
      addToast("Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const handlezeroexport = async () => {
    if (+exportRef.current.value === 0) {
      addToast("Please Select Export Type", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    if (planlsitform.values.DealerID === "") {
      addToast("Please Select Dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    setLoading(true);
    formData.append("export_type", exportRef.current.value);
    formData.append("DealerID", planlsitform.values.DealerID);
    formData.append("amount", "no");
    const api = await serviceamountexport(formData);
    setLoading(false);
    if (api.success === 1) {
      var date = moment(new Date()).format("YYYY_MM_DD-HH_mm_ss");
      saveAs(api.url, date + ".xlsx");
    } else {
      addToast("Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <React.Fragment>
      <PreLoad loading={loading} />
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="javascript:void(0);">MyPCP</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="javascript:void(0);">Plans</a>
                </li>
                <li className="breadcrumb-item active">Plan List</li>
              </ol>
            </div>
            <h4 className="page-title">Plan List</h4>
          </div>
          {/*end page-title-box*/}
        </div>
        {/*end col*/}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-7 col-sm-12 col-lg-7">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-4 col-lg-2 mt-2"
                        >
                          <h5>Dealer</h5>
                        </label>
                        <div className="col-sm-8 col-lg-6">
                          <DealersDropdown
                            deafultvalue={selecteddealer}
                            id="selecteddealer"
                            handledealerchange={handledealerchange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div id="planlist" className="row">
                      <div className="col-md-4 col-sm-6 col-lg-2">
                        <h5 style={{ display: "inline" }}>Plan Status</h5>
                      </div>
                      <div className="col-md-8 col-sm-6 col-lg-8">
                        <input
                          type="radio"
                          name="planstatus"
                          style={{ width: 90 }}
                          onChange={() => {
                            planlsitform.setFieldValue("planstatus", -1);
                          }}
                          checked={
                            planlsitform.values.planstatus === -1 ? true : false
                          }
                          id="status_all"
                        />

                        <label for="status_all">All</label>
                        <input
                          type="radio"
                          name="planstatus"
                          style={{ width: 90 }}
                          onChange={() => {
                            planlsitform.setFieldValue("planstatus", 1);
                          }}
                          checked={
                            +planlsitform.values.planstatus === 1 ? true : false
                          }
                          id="status_active"
                        />

                        <label for="status_active">Active</label>
                        <input
                          type="radio"
                          name="planstatus"
                          style={{ width: 90 }}
                          onChange={() => {
                            planlsitform.setFieldValue("planstatus", 0);
                          }}
                          checked={
                            +planlsitform.values.planstatus === 0 ? true : false
                          }
                          id="status_inactive"
                        />

                        <label for="status_inactive">Inactive</label>
                      </div>
                    </div>
                    <div id="planlist" className="row mt-3">
                      <div className="col-md-4 col-sm-6 col-lg-2">
                        <h5 className="mr-2" style={{ display: "inline" }}>
                          Plan Type
                        </h5>
                      </div>
                      <div className="col-md-8 col-sm-6 col-lg-10">
                        <input
                          type="radio"
                          name="status"
                          style={{ width: 90 }}
                          onChange={() => {
                            planlsitform.setFieldValue("status", -1);
                          }}
                          checked={
                            +planlsitform.values.status === -1 ? true : false
                          }
                          id="plan_status_all"
                        />

                        <label for="plan_status_all">All</label>
                        <input
                          type="radio"
                          name="status"
                          style={{ width: 90 }}
                          onChange={() => {
                            planlsitform.setFieldValue("status", "C");
                          }}
                          checked={
                            planlsitform.values.status === "C" ? true : false
                          }
                          id="plan_status_classic"
                        />

                        <label for="plan_status_classic">Classic</label>
                        <input
                          type="radio"
                          name="status"
                          style={{ width: 90 }}
                          onChange={() => {
                            planlsitform.setFieldValue("status", "E");
                          }}
                          checked={
                            planlsitform.values.status === "E" ? true : false
                          }
                          id="plan_status_express"
                        />

                        <label for="plan_status_express">Express</label>
                        <input
                          type="radio"
                          name="status"
                          style={{ width: 90 }}
                          onChange={() => {
                            planlsitform.setFieldValue("status", 1);
                          }}
                          checked={
                            +planlsitform.values.status === 1 ? true : false
                          }
                          id="plan_status_Complimentary"
                        />

                        <label for="plan_status_Complimentary">
                          Complimentary
                        </label>
                        <input
                          type="radio"
                          name="status"
                          style={{ width: 90 }}
                          onChange={() => {
                            planlsitform.setFieldValue("status", 2);
                          }}
                          checked={
                            +planlsitform.values.status === 2 ? true : false
                          }
                          id="plan_status_Subscription"
                        />

                        <label for="plan_status_Subscription">
                          Subscription
                        </label>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-lg-2 col-md-3 col-sm-3 mt-2"
                          >
                            <h5>Plan Name</h5>
                          </label>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              name="planname"
                              value={planlsitform.values.planname}
                              onChange={planlsitform.handleChange}
                              className="form-control"
                              placeholder="Enter Plan Name Here"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-8">
                            {/* {dealerinfo !== "" &&
                          +dealerinfo.IsNewAccount === 1 ? (
                            <span
                              onClick={() =>
                                setShowmodal({
                                  ...showmodal,
                                  lock_account: true,
                                })
                              }
                              className="btn mt-0 float-right"
                              style={{ color: "#00AEEF" }}
                            >
                              Unfinalized Account
                              <FaLockOpen
                                className="ml-2"
                                color="#00AEEF"
                                size={30}
                              />
                            </span>
                          ) : dealerinfo !== "" &&
                            +dealerinfo.dealerlock.LockStatus === 1 ? (
                            <span
                              onClick={() =>
                                setShowmodal({
                                  ...showmodal,
                                  lock_account: true,
                                })
                              }
                              className="btn mt-0 float-right"
                              style={{ color: "#00AEEF" }}
                            >
                              {remamingtime}
                              <FaLockOpen
                                className="ml-2"
                                color="#00AEEF"
                                size={30}
                              />
                            </span>
                          ) : (
                            ""
                          )} */}
                          </div>

                          <div className="col-1">
                            <button
                              type="button"
                              disabled={planlsitform.isSubmitting}
                              className="btn btn-success float-right"
                              onClick={() => {
                                planlsitform.submitForm();
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 col-lg-5">
                  {planlsitform.values.DealerID !== "" ? (
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="form-group row text-right">
                          <label
                            htmlFor="example-text-input"
                            className="col-sm-4 col-form-label text-right"
                          >
                            Export Excel For
                          </label>
                          <div className="col-sm-8">
                            <InputGroup className="mb-3">
                              <Button
                                className="btn-secondary"
                                id="button-addon1"
                                onClick={() => handlezeroexport()}
                              >
                                Export $0
                              </Button>

                              <select
                                defaultValue={0}
                                className="form-control"
                                ref={exportRef}
                              >
                                <option value={0}>
                                  Export Service Amount/No Amount
                                </option>
                                <option value={1}>All Plans Type</option>
                                <option value={2}>Active Plan only</option>
                                <option value={3}>In Active Plan only</option>
                                <option value={4}>Export Plan Reserved</option>
                              </select>
                            </InputGroup>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 col-lg-12 text-right">
                        <button
                          type="submit"
                          onClick={() => handlenormalexport()}
                          className="btn btn-sm btn-success px-4"
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/*  */}
              <div className="row mt-2">
                <div className="col-12">
                  <Datatable
                    dataloading={dataloading}
                    planslist={dealerinfo.plans}
                    setPlanslist={setPlanslist}
                    plancount={plancount}
                    setPlancount={setPlancount}
                    formvalues={planlsitform.values}
                    dealerinfo={dealerinfo}
                  />
                </div>{" "}
                {/* end col */}
              </div>{" "}
              {/* end row */}
              {/*  */}
            </div>
          </div>
        </div>
      </div>
      {showmodal.lock_account && (
        <LockAccount
          handleClose={() =>
            setShowmodal({ ...showmodal, lock_account: false })
          }
          DealerID={planlsitform.values.DealerID}
        />
      )}
      {showmodal.unlock_account && (
        <UnLockAccount
          handleClose={() =>
            setShowmodal({ ...showmodal, unlock_account: false })
          }
          DealerID={planlsitform.values.DealerID}
        />
      )}
      {/*end row*/}
    </React.Fragment>
  );
}
