import RedemmedContract from "./RedemmedContract";
import UnusedServices from "./UnusedServices";
import React, { useState } from "react";
import { Form, Tabs, Tab, Alert } from "react-bootstrap";
import "./RedemptionHead.css";
import moment from "moment";
import { useFormik } from "formik";
import Giftedservices from "./Giftedservices";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { validatereedemservices } from "./EditContractApi";
import { checknull } from "../../../components/helpers";
import RedemptionConfirmation from "./RedemptionConfirmation";
import ExpiredServices from "./ExpiredServices";
import {
  AllowRedeemExpiredContract,
  AllowRedeemInactiveContract,
} from "../../../CurrentUser";
import printinfocontext from "../../../context/print-info-context";
export default function ServiceRedmption(props) {
  const [redeemconfirm, setRedeemconfirm] = useState(false);
  const [alertmsg, setAlertmsg] = useState("");
  const [data, setData] = useState("");
  const [disbaleapi, setDisbaleapi] = useState(false);
  const [selectedcopuonsprint, setSelectedcopuonsprint] = useState("");
  const { addToast } = useToasts();
  const {
    contractinfo,
    contractcoupon,
    redeemcontract,
    RecentMileage,
    customer_name,
    iphone,
    android,
    window_phone,
    web,
    getDealerByID,
    latestRedeem,
    hidden_service,
    giftedservices,
    PlanDescription,
    expiredservices,
  } = props.data;
  const validationSchema = Yup.object().shape({
    LatestMileage: Yup.string()
      .test(
        "len",
        "Mileage should be greater than the previous service mileage",
        (val) => val > RecentMileage
      )
      .required("Required"),
    RONumber: Yup.string().required("Required"),
    //totalCoupon: Yup.array().required("Required"),
    // DateOfService: Yup.string()
    //     .test(
    //       "len",
    //       "Mileage should be greater than the previous service mileage",
    //       (val) => val <= current_date
    //     )
    //     .required("Required"),
  });
  const redmptionform = useFormik({
    initialValues: {
      LatestMileage: "0",
      RONumber: "",
      DateOfService: "",
      UpsellAmount: "0",
      VehicleType: "C",
      emailnotsent: false,
      SkipOrangeServiceReason: "",
      EnableSkipOrangeService: "0",
      RedeemServiceInBetween: "0",
      ServiceReason: "",
      totalCoupon: "",
      TotalServicesRemaining: "0",
      RedeemCouponID: "",
      UnlimitedService: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      const formdata = new FormData();
      if (Object.keys(data.totalCoupon).length === 0) {
        addToast("Please select at least one service to redeem", {
          appearance: "error",
          autoDismiss: true,
        });
        return false;
      }

      //manger code
      Object.keys(data.RedeemCouponID).forEach(
        (item) => formdata.append("RedeemCouponID[]", item)
        //console.log("RedeemCouponID[]", item)
      );
      Object.keys(data.totalCoupon).forEach((key) =>
        Object.keys(data.totalCoupon[key]).forEach((item) =>
          formdata.append(item, checknull(data.totalCoupon[key][item]))
        )
      );

      formdata.append("isPerpetualPlan", contractinfo.isPerpetualPlan);
      formdata.append("UnlimitedMileage", contractinfo.UnlimitedMileage);
      formdata.append("UnlimitedTerm", contractinfo.UnlimitedTerm);
      formdata.append("ContractIdForRedeem", contractinfo.ContractID);
      formdata.append(
        "latestRedeem",
        latestRedeem.RecievedDate ? latestRedeem.RecievedDate : ""
      );
      formdata.append("OriginalDate", contractinfo.SaleDate);
      formdata.append("ContractvalidayDate", contractinfo.ValidityDate);
      formdata.append("ValidityMileage", contractinfo.ValidityMileage);
      formdata.append("hidden_service", hidden_service);
      formdata.append("UnlimitedService", data.UnlimitedService);
      formdata.append("LatestMileage", data.LatestMileage);
      formdata.append("RONumber", data.RONumber);
      formdata.append("DateOfService", data.DateOfService);
      formdata.append("UpsellAmount", data.UpsellAmount);
      formdata.append("VehicleType", data.VehicleType);
      formdata.append("emailnotsent", data.emailnotsent);
      formdata.append(
        "EnableStopRedemption",
        getDealerByID.EnableStopRedemption
      );
      formdata.append(
        "EnableStopRedemptionDays",

        moment()
          .subtract(+getDealerByID.EnableStopRedemptionDays, "days")
          .calendar()
      );
      formdata.append(
        "StopRedemptionDays",
        getDealerByID.EnableStopRedemptionDays
      );
      formdata.append("RedeemServiceInBetween", data.RedeemServiceInBetween);
      formdata.append("Reason", data.ServiceReason);
      formdata.append("EnableSkipOrangeService", data.EnableSkipOrangeService);
      formdata.append("SkipOrangeServiceReason", data.SkipOrangeServiceReason);
      formdata.append("TotalServicesRemaining", data.TotalServicesRemaining);
      formdata.append("RecentMileage", contractinfo.RecentMileage);
      formdata.append("ContractID", contractinfo.ContractID);
      //api
      const validatereedemservices_response = await validatereedemservices({
        formdata,
      });
      if (validatereedemservices_response.confrimbox === 2) {
        setRedeemconfirm(true);
        setDisbaleapi(true);
        setAlertmsg(validatereedemservices_response.alertmsg);
      }
      if (validatereedemservices_response) {
        setRedeemconfirm(true);
        validatereedemservices_response.CoupoinIDs.map((item, i) => {
          formdata.append("ReedemCouponID" + "[" + i + "]", item);
        });
        setData(formdata);
        setAlertmsg(validatereedemservices_response.alertmsg);
      }
    },
  });
  const handleprint = async () => {
    //api
    localStorage.setItem("coupons", JSON.stringify(selectedcopuonsprint));
    localStorage.setItem("contract_id_coupon", contractinfo.ContractID);
    window.open(
      "/contracts/Printselectedservice/",
      "_blank",
      "location=yes,height=1000,width=1000,scrollbars=yes,status=yes"
    );
  };
  let date = "";
  let showform = 0;
  let showexpireinfo = 0;
  if (typeof contractinfo != "undefined" && contractinfo != "") {
    date = new Date(contractinfo.ExpirayDate);
    date.setDate(date.getDate() + +getDealerByID.RedeemServicesNoofDays);
  }

  if (typeof contractinfo != "undefined" && contractinfo != "") {
    if (contractinfo.Status === "M") {
      if (
        +getDealerByID.HideMaturedContractRedeemServices === 1 &&
        +AllowRedeemExpiredContract === 1
      ) {
        if (+getDealerByID.RedeemServicesNoofDays > 0) {
          if (new Date(date).getTime() > new Date().getTime()) {
            showform = 1;
            showexpireinfo = 1;
          } else {
            showform = 0;
          }
        } else {
          showform = 1;
        }
      } else {
        showform = 0;
      }
    } else if (
      contractinfo.Status === "I" &&
      AllowRedeemInactiveContract == 1
    ) {
      showform = 1;
    } else if (
      contractinfo.Status === "I" &&
      AllowRedeemInactiveContract == 0
    ) {
      showform = 0;
    } else if (contractinfo.Status === "C") {
      showform = 0;
    } else if (contractinfo.Status === "A") {
      showform = 0;
    } else {
      showform = 1;
    }
  }
  return (
    <printinfocontext.Provider
      value={{
        selectedcopuons: selectedcopuonsprint,
        add: (c) =>
          setSelectedcopuonsprint((prevState) => ({
            ...prevState,
            [c.CouponID]: c,
          })),
        remove: (item) => delete selectedcopuonsprint[item.CouponID],
      }}
    >
      <section id="redemption-sec">
        {redeemconfirm && (
          <RedemptionConfirmation
            setRedeemconfirm={setRedeemconfirm}
            alertmsg={alertmsg}
            data={data}
            disbaleapi={disbaleapi}
            setDisbaleapi={setDisbaleapi}
            IsManagerApproval={redmptionform.values.IsManagerApproval}
          />
        )}
        <div className="container-fluid" style={{ paddingLeft: 0 }}>
          <div className="row">
            <div className="col-md-5">
              <div className="text-right">
                <p>
                  <b>
                    <span className="mr-5">Latest Mileage:</span>{" "}
                    {RecentMileage}
                  </b>{" "}
                </p>
              </div>
              <div className="icons">
                <i
                  className={
                    +iphone === 1
                      ? "fab fa-apple fa-2x mr-3 text_green"
                      : "fab fa-apple fa-2x mr-3"
                  }
                ></i>
                <i
                  className={
                    +android === 1
                      ? "fab fa-android fa-2x mr-3 text_green"
                      : "fab fa-android fa-2x mr-3"
                  }
                ></i>
                <i
                  className={
                    +window_phone === 1
                      ? "fab fa-windows fa-2x mr-3 text_green"
                      : "fab fa-windows fa-2x mr-3"
                  }
                ></i>
                <i
                  className={
                    +web === 1
                      ? "fas fa-desktop fa-2x mr-3 text_green"
                      : "fas fa-desktop fa-2x mr-3"
                  }
                ></i>
              </div>
              {contractinfo != "" && typeof contractinfo != "undefined" ? (
                contractinfo.Status === "M" || contractinfo.Status === "S" ? (
                  <div className="alert alert-info">
                    Your Contract is Matured Due to
                    <div style={{ clear: "both", display: "block" }}>
                      {contractinfo.ExpiryReason}
                      <br />
                      {moment(contractinfo.ExpirayDate).format("MMMM DD,YYYY")}
                    </div>
                  </div>
                ) : contractinfo.Status === "C" ? (
                  <div className="alert alert-info">
                    Your Contract is Cancelled Due to
                    <div style={{ clear: "both", display: "block" }}>
                      {contractinfo.CancellationReason}
                      <br />
                      {moment
                        .unix(contractinfo.CancelDate)
                        .format("MMMM DD,YYYY")}
                    </div>
                  </div>
                ) : (contractinfo.Status === "L" ||
                    contractinfo.Status === "I") &&
                  contractinfo.PartiallyMature === "Y" ? (
                  <div className="alert alert-info">
                    Your Contract is Partially Matured
                  </div>
                ) : contractinfo.Status === "P" ? (
                  <div className="alert alert-info">
                    Your Contract is Partially Matured
                    <div style={{ clear: "both", display: "block" }}>
                      {contractinfo.ExpiryReason}
                      <br />
                      {moment(contractinfo.ExpirayDate).format("MMMM DD,YYYY")}
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <UnusedServices
                contractcoupon={contractcoupon}
                setFieldValue={redmptionform.setFieldValue}
              />
            </div>
            <div className="col-md-7">
              <p>
                <b>
                  <span className="mr-5 mb-4">Plan:</span>
                  {PlanDescription}
                  <br />
                  <span className="mr-3">Customer:</span> {customer_name}
                </b>
              </p>
              {+showexpireinfo === 1 ? (
                <div className="col-md-8">
                  <a
                    className="mb-4 btn"
                    style={{
                      color: "#FFF",
                      backgroundColor: "maroon",
                      bordercolor: "maroon",
                    }}
                  >
                    Redeem Service After Expiration
                  </a>
                </div>
              ) : (
                ""
              )}
              {+showform == 1 ? (
                <form
                  className="right-form"
                  onSubmit={redmptionform.handleSubmit}
                >
                  <div className="row d-flex">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="LatestMileage">Latest Mileage</label>
                        <input
                          type="text"
                          onChange={redmptionform.handleChange}
                          values={redmptionform.values.LatestMileage}
                          onBlur={redmptionform.handleBlur}
                          className="form-control"
                          name="LatestMileage"
                          placeholder="Latest Mileage"
                        />
                      </div>
                      {redmptionform.errors.LatestMileage &&
                        redmptionform.touched.LatestMileage && (
                          <div className="input-feedback">
                            {redmptionform.errors.LatestMileage}
                          </div>
                        )}
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="ronumber">RO Number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="RONumber"
                          style={{ textTransform: "uppercase" }}
                          value={redmptionform.values.RONumber}
                          onChange={redmptionform.handleChange}
                          onBlur={redmptionform.handleBlur}
                          placeholder="RO Number"
                        />
                      </div>
                      {redmptionform.errors.RONumber &&
                        redmptionform.touched.RONumber && (
                          <div className="input-feedback">
                            {redmptionform.errors.RONumber}
                          </div>
                        )}
                    </div>
                    <div className="col-md-3">
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="C"
                          onChange={redmptionform.handleChange}
                          checked={
                            redmptionform.values.VehicleType === "C"
                              ? true
                              : false
                          }
                          name="VehicleType"
                          id="C"
                        />
                        <label
                          className="form-check-label font-s"
                          htmlFor="VehicleType"
                        >
                          Contracted Vehicle
                        </label>
                      </div>
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={
                            +redmptionform.values.emailnotsent ? false : true
                          }
                          checked={
                            +redmptionform.values.emailnotsent === 1
                              ? true
                              : false
                          }
                          onChange={redmptionform.handleChange}
                          name="emailnotsent"
                        />
                        <label
                          className="form-check-label font-s"
                          htmlFor="emailnotsent"
                        >
                          Don`t send Service Redeem email
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check mt-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="VehicleType"
                          value="NC"
                          onChange={redmptionform.handleChange}
                          checked={
                            redmptionform.values.VehicleType === "NC"
                              ? true
                              : false
                          }
                          id="NC"
                        />
                        <label
                          className="form-check-label font-s"
                          htmlFor="gifted"
                        >
                          Gifted or Non Contracted
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Date of Service
                        </label>
                        <input
                          type="date"
                          name="DateOfService"
                          className="form-control"
                          value={redmptionform.values.DateOfService}
                          onChange={redmptionform.handleChange}
                          onBlur={redmptionform.handleBlur}
                          placeholder="Date of Service"
                        />
                      </div>
                      {redmptionform.errors.DateOfService &&
                        redmptionform.touched.DateOfService && (
                          <div className="input-feedback">
                            {redmptionform.errors.DateOfService}
                          </div>
                        )}
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Upsell Amount
                        </label>
                        <input
                          type="text"
                          name="UpsellAmount"
                          className="form-control"
                          value={redmptionform.values.UpsellAmount}
                          onChange={redmptionform.handleChange}
                          placeholder="Upsell Amount"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          disabled={true}
                          className="form-control"
                          value="Redeem Service in between"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            <input
                              type="radio"
                              value={1}
                              checked={
                                redmptionform.values.RedeemServiceInBetween ===
                                1
                                  ? true
                                  : false
                              }
                              id="yes"
                              onChange={redmptionform.handleChange}
                              type="radio"
                              name="RedeemServiceInBetween"
                            />
                            Yes
                            <input
                              type="radio"
                              id="no"
                              value={0}
                              checked={
                                +redmptionform.values.RedeemServiceInBetween ===
                                0
                                  ? true
                                  : false
                              }
                              onChange={redmptionform.handleChange}
                              name="RedeemServiceInBetween"
                            />
                            No
                          </span>
                        </div>
                      </div>
                      {+redmptionform.values.RedeemServiceInBetween === 1 ? (
                        <div className="my-2">
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                              as="textarea"
                              rows={2}
                              onChange={redmptionform.handleChange}
                              value={redmptionform.values.ServiceContract}
                              name="ServiceContract"
                              placeholder="Enter Your Reason Here"
                            />
                          </Form.Group>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          disabled={true}
                          value="Override Orange Services"
                          className="form-control"
                          style={{ background: "#f57f1e", color: "#fff" }}
                          aria-label=" Override Orange Services"
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            <input
                              type="radio"
                              value={1}
                              checked={
                                +redmptionform.values
                                  .EnableSkipOrangeService === 1
                                  ? true
                                  : false
                              }
                              id="yes"
                              onChange={redmptionform.handleChange}
                              type="radio"
                              name="EnableSkipOrangeService"
                            />
                            Yes
                            <input
                              type="radio"
                              id="no"
                              value={0}
                              checked={
                                +redmptionform.values
                                  .EnableSkipOrangeService === 0
                                  ? true
                                  : false
                              }
                              onChange={redmptionform.handleChange}
                              name="EnableSkipOrangeService"
                            />
                            No
                          </span>
                        </div>
                      </div>
                      {+redmptionform.values.EnableSkipOrangeService === 1 ? (
                        <div className="my-2">
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                              as="textarea"
                              rows={2}
                              onChange={redmptionform.handleChange}
                              value={
                                redmptionform.values.SkipOrangeServiceReason
                              }
                              name="SkipOrangeServiceReason"
                              placeholder="Skip Orange Service Reason"
                            />
                          </Form.Group>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row d-flex mt-4">
                    <div className="col-md-9">
                      <button
                        type="button"
                        onClick={redmptionform.handleSubmit}
                        disabled={redmptionform.isSubmitting}
                        className="btn btn-primary redeem"
                      >
                        Redeem Services
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                ""
              )}
              <Tabs
                className="mt-4"
                defaultActiveKey="services"
                id="uncontrolled-tab-example2"
              >
                <Tab eventKey="services" title="Used Services">
                  <RedemmedContract
                    redeemcontract={redeemcontract}
                    contractinfo={contractinfo}
                  />
                </Tab>
                {giftedservices != "" &&
                typeof giftedservices != "undefined" ? (
                  <Tab eventKey="gifted_services" title="Gifted Services">
                    <Giftedservices
                      giftedservices={giftedservices}
                      contractinfo={contractinfo}
                    />
                  </Tab>
                ) : (
                  ""
                )}
              </Tabs>
              {expiredservices != "" &&
              typeof expiredservices != "undefined" ? (
                <Tabs
                  className="mt-4 expired-services"
                  defaultActiveKey="expired_services"
                  id="uncontrolled-tab-expired"
                >
                  <Tab
                    eventKey="expired_services"
                    title=" Redeemed Services After Expiry"
                  >
                    <ExpiredServices
                      expiredservices={expiredservices}
                      contractinfo={contractinfo}
                    />
                  </Tab>
                </Tabs>
              ) : (
                ""
              )}
              <div className="col-md-8">
                <button
                  type="button"
                  onClick={handleprint}
                  className="btn btn-primary print"
                >
                  Print invoice for selected services
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </printinfocontext.Provider>
  );
}
