import React, { Component } from "react";

class Dashboard30Days extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let CurrentThirtydayclassic = this.props.Current30Days.classic
      ? this.props.Current30Days.classic
      : 0;
    let CurrentThirtydayexpress = this.props.Current30Days.express
      ? this.props.Current30Days.express
      : 0;
    let CurrentThirtydaycomplimentary = this.props.Current30Days.complimentary
      ? this.props.Current30Days.complimentary
      : 0;

    let LastThirtydayclassic = this.props.Last30Days.classic
      ? this.props.Last30Days.classic
      : 0;
    let LastThirtydayexpress = this.props.Last30Days.express
      ? this.props.Last30Days.express
      : 0;
    let LastThirtydaycomplimentary = this.props.Last30Days.complimentary
      ? this.props.Last30Days.complimentary
      : 0;

    let ClassicCalc = LastThirtydayclassic - CurrentThirtydayclassic;
    let ExpressCalc = LastThirtydayexpress - CurrentThirtydayexpress;
    let complimentaryCalc =
      LastThirtydaycomplimentary - CurrentThirtydaycomplimentary;
    return (
      <div className="col-md-5 text-center" style={{ display: "grid" }}>
        <div className="card">
          <div className="card-body" style={{ padding: "0.75rem" }}>
            <span className="font-18">30 Days Comparison</span>
            <br />
            <br />
            <div className="row">
              <div className="col-sm-4">
                <table>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <div
                          style={{
                            float: "right",
                            fontSize: "18px",
                            color: "#f57f1e",
                          }}
                        >
                          {ClassicCalc > 0 ? (
                            <span className="fas fa-arrow-up"></span>
                          ) : (
                            <span className="fas fa-arrow-down text_orng"></span>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          style={{
                            float: "left",
                            fontSize: "18px",
                            color: "#29aae1",
                          }}
                        >
                          {LastThirtydayclassic}
                        </span>
                      </td>
                      <td>
                        <span
                          className=""
                          style={{
                            float: "right",
                            fontSize: "18px",
                            color: "#29aae1",
                          }}
                        >
                          {CurrentThirtydayclassic}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <span style={{ fontSize: "20px" }}>Classic</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-sm-4">
                <table>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <div
                          style={{
                            float: "right",
                            fontSize: "18px",
                            color: "#f57f1e",
                          }}
                        >
                          {complimentaryCalc > 0 ? (
                            <span className="fas fa-arrow-up"></span>
                          ) : (
                            <span className="fas fa-arrow-down text_orng"></span>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          style={{
                            float: "left",
                            fontSize: "18px",
                            color: "#f57f1e",
                          }}
                        >
                          {LastThirtydaycomplimentary}
                        </span>
                      </td>
                      <td>
                        <span
                          className=""
                          style={{
                            float: "right",
                            fontSize: "18px",
                            color: "#f57f1e",
                          }}
                        >
                          {CurrentThirtydaycomplimentary}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <span style={{ fontSize: "20px" }}>Complimentary</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-sm-4">
                <table>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <div
                          style={{
                            float: "right",
                            fontSize: "18px",
                            color: "#f57f1e",
                          }}
                        >
                          {ExpressCalc > 0 ? (
                            <span className="fas fa-arrow-up"></span>
                          ) : (
                            <span className="fas fa-arrow-down text_orng"></span>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          style={{
                            float: "left",
                            fontSize: "18px",
                            color: "#f57f1e",
                          }}
                        >
                          {LastThirtydayexpress}
                        </span>
                      </td>
                      <td>
                        <span
                          className=""
                          style={{
                            float: "right",
                            fontSize: "18px",
                            color: "#f57f1e",
                          }}
                        >
                          {CurrentThirtydayexpress}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <span style={{ fontSize: "20px" }}>Express</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard30Days;
