import React, { useState } from "react";
import $, { map } from "jquery";
import { useToasts } from "react-toast-notifications";
import "./RetentionMap.css";
import { useFormik } from "formik";
import Slider from "react-rangeslider";
import Toggle from "react-bootstrap-toggle";
import { Row, Col, Button } from "react-bootstrap";
import "react-rangeslider/lib/index.css";
import { WaveLoading } from "react-loadingg";
import { retentionmap } from "./DashboardApi";
import {
  f_dashboardexport,
  f_subscriptionexport,
} from "../components/helpers";
require("dotenv").config();

function RetentionMapPreDefined(props) {
  const {
    mapdata,
    ShopingCartEnable,
    DealerID,
    fromdate,
    toDate,
    FixedDateParameter,
  } = props;
  const [mydatakept, setMydatakept] = useState(mapdata ? mapdata : "");
  const [toggleActive, setToggleActive] = useState(false);
  const [toggleActiveMap, setToggleActiveMap] = useState(false);
  const [rangeValue, setRangeValue] = useState(5);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const mapform = useFormik({
    initialValues: {
      all: false,
      active: false,
      pending: true,
      cancelled: false,
      Matured: false,
      pmatured: false,
      IsSubscription: false,
      monthbefore: 0,
      IsMarketPlace: false,
      submonthbefore: 0,
      SearchType: false,
    },
    onSubmit: async (data) => {
      const formData = new FormData();

      // Object.keys(data).forEach((key) => formData.append(key, data[key]));
      formData.append("monthbefore", data.monthbefore);
      formData.append("submonthbefore", data.submonthbefore);
      formData.append("DealerID", DealerID);
      formData.append("FomDate", fromdate);
      formData.append("ToDate", toDate);
      formData.append("mapview", toggleActiveMap ? 1 : 0);
      if (toggleActiveMap) {
        formData.append("mapview_rad", rangeValue);
      }
      formData.append("FixedDateParameter", FixedDateParameter);
      if (data.all) {
        formData.append("AllContractStatus", 1);
      } else {
        formData.append("AllContractStatus", 0);
      }
      if (data.active) {
        formData.append("ContractStatus[]", "L");
      }
      if (data.pending) {
        formData.append("ContractStatus[]", "I");
      }
      if (data.cancelled) {
        formData.append("ContractStatus[]", "C");
      }
      if (data.Matured) {
        formData.append("ContractStatus[]", "M");
      }
      if (data.pmatured) {
        formData.append("ContractStatus[]", "P");
      }
      if (data.IsSubscription) {
        formData.append("IsSubscription", 1);
      } else if (!data.IsSubscription) {
        formData.append("IsSubscription", 0);
      }
      if (data.IsMarketPlace) {
        formData.append("IsMarketPlace", 1);
      } else if (!data.IsMarketPlace) {
        formData.append("IsMarketPlace", 0);
      }
      if (data.SearchType) {
        formData.append("SearchType", 1);
      } else if (!data.SearchType) {
        formData.append("SearchType", 0);
      }
      //api
      setLoading(true);
      const retentionmap_api = await retentionmap({ formData });

      if (retentionmap_api.success === 1) {
        setMydatakept(retentionmap_api);

        //render data on map
        var latcenter = parseFloat(retentionmap_api.json.center.lat);
        var lngcenter = parseFloat(retentionmap_api.json.center.lng);
        var mypublic = "https://newdash.mypcp.us/mypcp/public";
        var mygetplaces = JSON.stringify(retentionmap_api.json.myPlaces);
        $.ajax({
          url: process.env.REACT_APP_BASE_URL + "/mapbuildercustom.php",
          // url: "https://newdash.newdash.mypcp.us/mapbuildercustom.php",
          //url: "http://localhost/pcp/public/mapbuildercustom.php",
          type: "post",
          data: {
            mapCenterLat: latcenter,
            mapCenterLong: lngcenter,
            MyPlaces: mygetplaces,
            mapview_rad: rangeValue,
            isNormalView: toggleActiveMap ? 1 : 0,
          },
          success: function (result) {
            window.mytestingfunction(result);
          },
        });
        setLoading(false);
      } else {
        setLoading(false);
        addToast("An error occurd while loading map", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    },
  });
  const handleChangeStart = () => {};
  const exportdata = (export_type) => {
    if (DealerID == "" || typeof DealerID == "undefined") {
      addToast("please select dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("FomDate", fromdate);
    formData.append("ToDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    formData.append("export_type", export_type);
    setLoading(true);
    //api
    if (ShopingCartEnable === 1) {
      f_subscriptionexport(formData).then((response) => {
        setLoading(false);
      });
    } else {
      f_dashboardexport(formData).then((response) => {
        setLoading(false);
      });
    }
  };
  const handlesliderChange = (value) => {
    setRangeValue(value);
  };
  const handleoption = (value) => {
    if (value === 1) {
      mapform.setFieldValue("all", !mapform.values.all);
      mapform.setFieldValue("active", !mapform.values.active);
      mapform.setFieldValue("pending", !mapform.values.pending);
      mapform.setFieldValue("cancelled", !mapform.values.cancelled);
      mapform.setFieldValue("Matured", !mapform.values.Matured);
      mapform.setFieldValue("pmatured", !mapform.values.pmatured);
    }
    if (value === 2) {
      mapform.setFieldValue("active", !mapform.values.active);
    }
    if (value === 3) {
      mapform.setFieldValue("pending", !mapform.values.pending);
    }
    if (value === 4) {
      mapform.setFieldValue("cancelled", !mapform.values.cancelled);
    }
    if (value === 5) {
      mapform.setFieldValue("Matured", !mapform.values.Matured);
    }
    if (value === 6) {
      mapform.setFieldValue("pmatured", !mapform.values.pmatured);
    }
    if (value === 7) {
      mapform.setFieldValue("IsSubscription", !mapform.values.IsSubscription);
    }
    if (value === 8) {
      mapform.setFieldValue("IsMarketPlace", !mapform.values.IsMarketPlace);
    }
    if (value === "SearchType") {
      mapform.setFieldValue("SearchType", !mapform.values.SearchType);
    }
  };
  const handleChangeComplete = (e, v) => {
    updatemap();
  };
  async function updatemap() {
    mapform.handleSubmit();
  }
  // handleScroll() {}
  const onToggle = () => {
    setToggleActive(!toggleActive);
  };
  const changemapviewToggle = () => {
    setToggleActiveMap(!toggleActiveMap);
    let checkvalue = toggleActiveMap;
    let isNormalView = 0;
    if (checkvalue == false) {
      isNormalView = 1;
    }
    updatemap();
    let latcenter = parseFloat(mydatakept.json.center.lat);
    let lngcenter = parseFloat(mydatakept.json.center.lng);
    let mygetplaces = JSON.stringify(mydatakept.json.myPlaces);
    setLoading(true);
    $.ajax({
      url: process.env.REACT_APP_BASE_URL + "/mapbuildercustom.php",
      type: "post",
      data: {
        mapCenterLat: latcenter,
        mapCenterLong: lngcenter,
        MyPlaces: mygetplaces,
        mapview_rad: rangeValue,
        isNormalView: isNormalView,
      },
      success: function (result) {
        setLoading(false);
        window.mytestingfunction(result);
      },
    });
  };
  let used_services = 0;
  let unused_services = 0;
  let latcenter = "";
  let lngcenter = "";
  var Allhtml = "";

  let DealerTitle = "";
  let google_rating = "";
  let app_web = "";
  let monthbefore_no_service_used = "";
  let used_app = "";
  let used_chat = "";

  if (mydatakept.hasOwnProperty("json")) {
    used_services = mydatakept.used_services ? mydatakept.used_services : 0;
    unused_services = mydatakept.unused_services
      ? mydatakept.unused_services
      : 0;
    //setMydatakept(mydatakept.json);
    latcenter = parseFloat(mydatakept.json ? mydatakept.json.center.lat : "");
    lngcenter = parseFloat(mydatakept.json ? mydatakept.json.center.lng : "");

    DealerTitle = mydatakept.DealerTitle ? mydatakept.DealerTitle : "";
    google_rating = mydatakept.google_rating ? mydatakept.google_rating : 0;
    app_web = mydatakept.app_web ? mydatakept.app_web : 0;
    monthbefore_no_service_used = mydatakept.monthbefore_no_service_used
      ? mydatakept.monthbefore_no_service_used
      : 0;
    used_app = mydatakept.used_app ? mydatakept.used_app : 0;
    used_chat = mydatakept.used_chat ? mydatakept.used_chat : 0;
  }

  return (
    <React.Fragment>
      {loading && <WaveLoading color="#4ac7ec" />}
      <Row>
        <Col lg={12} md={12}>
          <h2>
            <span style={{ color: "#165b76" }}>RE</span>
            <span style={{ color: "#00aeef" }}>TENTION</span>{" "}
            <span style={{ color: "#808080" }}>MAP</span>
          </h2>
        </Col>
      </Row>
      <Row>
        <Col className="premap" lg={3} md={3}>
          <Toggle
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                Redemption
              </span>
            }
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                Contract Sold
              </span>
            }
            onClick={() => handleoption("SearchType")}
            name="SearchType"
            value={mapform.values.SearchType}
            size=""
            offstyle="secondary"
            onstyle="secondary"
            width="100%"
            height="20px"
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.SearchType}
          />
        </Col>
        <Col className="premap" lg={3} md={3}>
          <Toggle
            onClick={changemapviewToggle}
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                Advance View
              </span>
            }
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                Normal View
              </span>
            }
            size=""
            offstyle="secondary"
            onstyle="secondary"
            width="100%"
            height="20px"
            handlestyle="default"
            recalculateOnResize={true}
            active={toggleActiveMap}
          />
        </Col>
        <Col lg={3} md={3}>
          <label>Ongoing Subscriptions</label>
          <select
            className="form-control"
            onChange={mapform.handleChange}
            name="monthbefore"
            value={mapform.values.monthbefore}
          >
            <option value="0">0 Month</option>
            <option value="1">1 Month</option>
            <option value="2">2 Month</option>
            <option value="3">3 Month</option>
            <option value="4">4 Month</option>
            <option value="5">5 Month</option>
            <option value="6">6 Month</option>
            <option value="7">7 Month</option>
            <option value="8">8 Month</option>
            <option value="9">9 Month</option>
            <option value="10">10 Month</option>
            <option value="11">11 Month</option>
            <option value="12">12 Month</option>
          </select>
        </Col>

        <Col lg={3} md={3}>
          <label>Contracts with unused services</label>
          <select
            className="form-control"
            onChange={mapform.handleChange}
            name="submonthbefore"
            value={mapform.values.submonthbefore}
          >
            <option value="0">0 Month</option>
            <option value="1">1 Month</option>
            <option value="2">2 Month</option>
            <option value="3">3 Month</option>
            <option value="4">4 Month</option>
            <option value="5">5 Month</option>
            <option value="6">6 Month</option>
            <option value="7">7 Month</option>
            <option value="8">8 Month</option>
            <option value="9">9 Month</option>
            <option value="10">10 Month</option>
            <option value="11">11 Month</option>
            <option value="12">12 Month</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col lg={10} style={{ marginBottom: "15px" }}>
          <Toggle
            onClick={() => handleoption(1)}
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-times"></i> All
              </span>
            }
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-check"></i> All
              </span>
            }
            size=""
            offstyle="all"
            onstyle="all"
            width="120px"
            height="20px"
            style={{ margin: 15 }}
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.all}
          />
          <Toggle
            onClick={() => handleoption(2)}
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-times"></i> Active
              </span>
            }
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-check"></i> Active
              </span>
            }
            size=""
            offstyle="active"
            onstyle="active"
            width="120px"
            height="20px"
            style={{ margin: 15 }}
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.active}
          />
          <Toggle
            onClick={() => handleoption(3)}
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-times"></i> Pending
              </span>
            }
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-check"></i> Pending
              </span>
            }
            size=""
            offstyle="pr"
            onstyle="pr"
            width="120px"
            height="20px"
            style={{ margin: 15 }}
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.pending}
          />
          <Toggle
            onClick={() => handleoption(4)}
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-times"></i> Cancelled
              </span>
            }
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-check"></i> Cancelled
              </span>
            }
            size=""
            offstyle="c"
            onstyle="c"
            width="120px"
            height="20px"
            style={{ margin: 15 }}
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.cancelled}
          />
          <Toggle
            onClick={() => handleoption(5)}
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-times"></i> Matured
              </span>
            }
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-check"></i> Matured
              </span>
            }
            size=""
            offstyle="m"
            onstyle="m"
            width="120px"
            height="20px"
            style={{ margin: 15 }}
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.Matured}
          />
          <Toggle
            onClick={() => handleoption(6)}
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-times"></i> Pending Matured
              </span>
            }
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-check"></i> Pending Matured
              </span>
            }
            size=""
            offstyle="pm"
            onstyle="pm"
            width="140px"
            height="20px"
            style={{ margin: 15 }}
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.pmatured}
          />
          <Toggle
            onClick={() => handleoption(7)}
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-times"></i> Subscriptions
              </span>
            }
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-check"></i> Subscriptions
              </span>
            }
            size=""
            offstyle="s"
            onstyle="s"
            width="120px"
            height="20px"
            style={{ margin: 15 }}
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.IsSubscription}
          />
          <Toggle
            onClick={() => handleoption(8)}
            off={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-times"></i> Market place
              </span>
            }
            on={
              <span style={{ width: "100%", fontSize: "15px" }}>
                <i className="fas fa-check"></i> Market place
              </span>
            }
            size=""
            offstyle="market"
            onstyle="market"
            width="120px"
            height="20px"
            style={{ margin: 15 }}
            handlestyle="default"
            recalculateOnResize={true}
            active={mapform.values.IsMarketPlace}
          />
        </Col>
        <Col lg={2} style={{ marginBottom: "15px" }}>
          <Button
            type="button"
            className="td_btns mt-3"
            size="lg"
            style={{
              height: "calc(1.8em + .65rem + 2px)",
              borderRadius: 10,
              background: "#00aeef",
              border: "none",
              color: "#fff",
            }}
            disabled={mapform.isSubmitting}
            onClick={mapform.handleSubmit}
          >
            Search
          </Button>
        </Col>
      </Row>
      <div className="col-md-12">
        <div id="showallhtml" style={{ width: "100%", height: "600px" }}></div>
      </div>
      <div className="col-md-12 pt-4 table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td
                colSpan="4"
                className={toggleActiveMap == false ? "hideme" : "showme"}
              >
                <div className="slider">
                  <Slider
                    min={0}
                    max={100}
                    value={rangeValue}
                    onChangeStart={handleChangeStart}
                    onChange={handlesliderChange}
                    onChangeComplete={handleChangeComplete}
                  />
                  <div
                    className="value"
                    style={{
                      fontSize: "20px",
                      color: "#7cb342",
                      textAlign: "center",
                    }}
                  >
                    {rangeValue}
                  </div>
                </div>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == false
                  ? "table_eng_overview hideme"
                  : "table_eng_overview showme"
              }
            >
              <td style={{ textAlign: "center" }}>
                <span
                  style={{
                    padding: "0px 5px",
                    borderRadius: "50%",
                    backgroundColor: "#44454a",
                    color: "#44454a",
                  }}
                >
                  a
                </span>
              </td>
              <td>{mydatakept?.rad_circle1_text}</td>

              <td>
                {typeof mydatakept.rad_circle1 !== "undefined"
                  ? mydatakept.rad_circle1
                  : 0}
              </td>
              <td>
                <span className="delete_icon">
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == false
                  ? "table_eng_overview hideme"
                  : "table_eng_overview showme"
              }
            >
              <td style={{ textAlign: "center" }}>
                <span
                  style={{
                    padding: "0px 5px",
                    borderRadius: "50%",
                    backgroundColor: "#75858a",
                    color: "#75858a",
                  }}
                >
                  a
                </span>
              </td>
              <td>{mydatakept?.rad_circle2_text}</td>
              <td>{mydatakept?.rad_circle2}</td>
              <td>
                <span className="delete_icon">
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == false
                  ? "table_eng_overview hideme"
                  : "table_eng_overview showme"
              }
            >
              <td style={{ textAlign: "center" }}>
                <span
                  style={{
                    padding: "0px 5px",
                    borderRadius: "50%",
                    backgroundColor: "#3c5461",
                    color: "#3c5461",
                  }}
                >
                  a
                </span>
              </td>
              <td>{mydatakept?.rad_circle3_text}</td>
              <td>{mydatakept?.rad_circle3}</td>
              <td>
                <span className="delete_icon">
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == true
                  ? "table_eng_overview"
                  : "table_eng_overview"
              }
            >
              <td style={{ textAlign: "center" }}>
                <span
                  style={{
                    padding: "0px 5px",
                    borderRadius: "50%",
                    backgroundColor: "#026802",
                    color: "#026802",
                  }}
                >
                  a
                </span>
              </td>
              <td>Customers that used at least 1 Service</td>
              <td>{used_services}</td>
              <td>
                <span
                  onClick={() => exportdata(6)}
                  style={{ cursor: "pointer" }}
                  className="delete_icon"
                >
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == true
                  ? "table_eng_overview "
                  : "table_eng_overview"
              }
            >
              <td style={{ textAlign: "center" }}>
                <span
                  style={{
                    padding: "0px 5px",
                    borderRadius: "50%",
                    backgroundColor: "#ca0f0c",
                    color: "#ca0f0c",
                  }}
                >
                  a
                </span>
              </td>
              <td>Customers that have not used any Services</td>
              <td>{unused_services}</td>
              <td>
                <span
                  onClick={() => exportdata()}
                  style={{ cursor: "pointer" }}
                  className="delete_icon"
                >
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == true
                  ? "table_eng_overview "
                  : "table_eng_overview"
              }
            >
              <td className="font-16" style={{ textAlign: "center" }}>
                <i className="fab fa-google"></i>
              </td>
              <td>
                Customers that have rated {DealerTitle} on Google Through PCP
              </td>
              <td>{google_rating}</td>
              <td>
                <span
                  onClick={() => exportdata(21)}
                  style={{ cursor: "pointer" }}
                  className="delete_icon"
                >
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == true
                  ? "table_eng_overview "
                  : "table_eng_overview "
              }
            >
              <td className="font-16" style={{ textAlign: "center" }}>
                <i className="mdi mdi-chat"></i>
              </td>
              <td>Customers that have used CEP Chat</td>
              <td>{used_chat}</td>
              <td>
                <span
                  onClick={() => exportdata(3)}
                  style={{ cursor: "pointer" }}
                  className="delete_icon"
                >
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == true
                  ? "table_eng_overview "
                  : "table_eng_overview"
              }
            >
              <td className="font-16" style={{ textAlign: "center" }}>
                <i className="mdi mdi-whatsapp"></i>
              </td>
              <td>Customers that have called through App</td>
              <td>{used_app}</td>
              <td>
                <span
                  onClick={() => exportdata(4)}
                  style={{ cursor: "pointer" }}
                  className="delete_icon"
                >
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
            <tr
              className={
                toggleActiveMap == true
                  ? "table_eng_overview "
                  : "table_eng_overview "
              }
            >
              <td className="font-16" style={{ textAlign: "center" }}>
                <i className="dripicons-device-tablet"></i>
              </td>
              <td>Customers that have Logged on to App or Web Portal</td>
              <td>{app_web}</td>
              <td>
                <span
                  onClick={() => exportdata(7)}
                  style={{ cursor: "pointer" }}
                  className="delete_icon"
                >
                  <i className="dripicons-document-delete font-20"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}
export default RetentionMapPreDefined;
