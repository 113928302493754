import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Table,
  Spinner,
} from "react-bootstrap";
import { getvindetail } from "./EditContractApi";
import { useToasts } from "react-toast-notifications";
import PreLoad from "../../../components/PreLoad";
export function VehicleDetail(props) {
  const { contractid, contractno, VIN, CarfaxVin, f_vehicalhistory } = props;
  const [error, setError] = useState("");
  const [vin_number, setVin_number] = useState(
    CarfaxVin != "" ? CarfaxVin : VIN
  );
  const [loading, setLoading] = useState(false);
  const [serviceHistory, setServiceHistory] = useState("");

  const { addToast } = useToasts();
  const vinmuber = useRef(null);
  const Close = () => {
    f_vehicalhistory();
  };
  useEffect(() => {
    vindetail(CarfaxVin != "" ? CarfaxVin : VIN);
  }, [contractid]);
  async function vindetail(vin_No) {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("ContractID", contractid);
    formdata.append("VIN", vin_No);
    formdata.append("ContractNo", contractno);
    const getvindetail_response = await getvindetail(formdata);
    setLoading(false);
    if (getvindetail_response.success == 1) {
      setServiceHistory(getvindetail_response.serviceHistory);
    }
  }
  const checkvin = () => {
    vindetail(vin_number);
  };
  const handlevinchange = () => {
    setVin_number(vinmuber.current.value);
  };
  return (
    <React.Fragment>
      <PreLoad loading={loading} />
      <Modal show={true} size={"lg"} onHide={Close}>
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4}></Col>
            <div className="my-2">
              <label htmlFor="PlanID">VIN</label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <input
                    type="text"
                    style={{ height: "3rem" }}
                    className="form-control"
                    onChange={handlevinchange}
                    value={vin_number}
                    ref={vinmuber}
                    name="vinumber"
                  />
                </InputGroup.Prepend>
                <button
                  className="btn btn-success btn-lg"
                  type="button"
                  id="searchcarfacvin"
                  onClick={checkvin}
                >
                  <i className="fa fa-search"></i>
                </button>
              </InputGroup>
            </div>
          </Row>
          <Row>
            <Col lg={4}>
              <h6 className="text_blue">Vehicle Information</h6>
              <Table hover>
                <tbody>
                  <tr>
                    <td>VIN</td>
                    <td>{serviceHistory.vin}</td>
                  </tr>
                  <tr>
                    <td>Make</td>
                    <td>{serviceHistory.make}</td>
                  </tr>
                  <tr>
                    <td>Model</td>
                    <td>{serviceHistory.model}</td>
                  </tr>
                  <tr>
                    <td>Year</td>
                    <td>{serviceHistory.year}</td>
                  </tr>
                  <tr>
                    <td>Body Type Description</td>
                    <td>{serviceHistory.bodyTypeDescription}</td>
                  </tr>
                  <tr>
                    <td>Engine Information</td>
                    <td>{serviceHistory.engineInformation}</td>
                  </tr>
                  <tr>
                    <td>Drive Line</td>
                    <td>{serviceHistory.driveline}</td>
                  </tr>
                  <tr>
                    <td>Number of Service</td>

                    <td>{serviceHistory.numberOfServiceRecords}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <h6 className="text_blue">Service Categories</h6>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Sr.#</th>
                        <th>Date</th>
                        <th>Service Name</th>
                        <th>Mileage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceHistory.serviceCategories != "" &&
                      typeof serviceHistory.serviceCategories != "undefined"
                        ? serviceHistory.serviceCategories.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.dateOfLastService}</td>
                              <td>{item.serviceName}</td>
                              <td>{item.odometerOfLastService}</td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
                <Col lg={12}>
                  <h6 className="text_blue">Service History</h6>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Sr.#</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Mileage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceHistory.displayRecords != "" &&
                      typeof serviceHistory.displayRecords != "undefined"
                        ? serviceHistory.displayRecords.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.displayDate}</td>
                              <td>{item.type}</td>
                              <td>{item.text}</td>
                              <td>{item.odometer}</td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
          <small className="help-block text-danger">{error}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={Close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
