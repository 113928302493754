import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Dropdown } from "semantic-ui-react";
import { Row, Col, Spinner } from "react-bootstrap";
import DealersDropdown from "../../components/DealersDropdown";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import "./customercontract.css";
import ContractModal from "../../components/ContractModal";
import DeleteModal from "../../components/DeleteModal";
import { ContractStatus, contractsearchby } from "./shared_data";
import { user_id, role_id, pcp_user_id } from "../../CurrentUser";
import LoyaltyCash from "./Loyaltycash/LoyaltyCash";
import { useToasts } from "react-toast-notifications";

import { TableFooter, TableCell, TableRow } from "@material-ui/core";
require("dotenv").config();

const Contractlist = () => {
  const showbytype = ContractStatus;
  const [contractlist, setContractlist] = useState();
  const [page, setPage] = useState(0);
  const [dashboardcontract, setDashboardContract] = useState("");
  const [count, setCount] = useState(0);
  const [sortOrder, setSortOrder] = useState({});
  const [showmodal, setShowmodal] = useState(false);
  const [pauseid, setPauseid] = useState(0);
  const [statuscontract, setStatuscontract] = useState(0);
  const [deletemodal, setDeletemodal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modaldata, setModaldata] = useState([]);
  const [deletecontractid, setDeletecontractid] = useState(0);
  const [selecteddealer, setSelecteddealer] = useState();
  const [fixedDateParameter, setFixedDateParameter] = useState();
  const [showbytypevalue, setShowbytypevalue] = useState("");
  const [startdate, setStartdate] = useState("");
  const [loyaltycashshow, setLoyaltycashshow] = useState(false);
  const [enddate, setEnddate] = useState();
  const { addToast } = useToasts();
  const [loyaltycash_contractid, setLoyaltycash_contractid] = useState("");
  const [contract_search_by, setContract_search_by] = useState("");
  const [searchcontractby, setSearchcontractby] = useState("");
  const [dealerid, setDealerid] = useState(localStorage.getItem("dealerid"));
  const [search_by, setSearch_by] = useState(localStorage.getItem("search_by"));
  const [parameter, setParameter] = useState(localStorage.getItem("parameter"));
  useEffect(() => {
    if (dealerid) {
      FetchData(0, "");
      setLoading(true);
    }

    localStorage.removeItem("dealerid");
    localStorage.removeItem("search_by");
    localStorage.removeItem("parameter");
  }, []);
  const FetchData = async (page, sortOrder) => {
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", parseInt(dealerid));
    formData.append("search_by", search_by);
    formData.append("top_search_field", parameter);
    formData.append("SortByCol", sortOrder.name ? sortOrder.name : "");
    formData.append(
      "SortByColType",
      sortOrder.direction ? sortOrder.direction : ""
    );
    formData.append("offset", page * 20);
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/contractsearching",
        formData,

        { headers }
      )
      .then((response) => {
        if (response.data.success == 1) {
          setDashboardContract(response.data.contracts_all);
          setContractlist(response.data.contracts);
          setCount(response.data.TotalRec);

          setPage(page);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleserachcontractby = (e, result) => {
    const { name, value } = result || e.target;
    setSearchcontractby(value);
  };
  const daterange = (c, e) => {
    if (c == 1) {
      setStartdate(e.target.value);
    } else {
      setEnddate(e.target.value);
    }
  };
  const handlestatusfilter = (e, result) => {
    const { name, value } = result || e.target;
    setShowbytypevalue(value);
  };
  const selectionbtn = (e, value) => {
    e.preventDefault();
    setFixedDateParameter(value);

    getadata(value);
  };

  const contract_searchby = contractsearchby;
  const f_searchby = (e, result) => {
    const { name, value } = result || e.target;
    setContract_search_by(value);
  };
  const deleterow = (id) => {
    setDeletecontractid(id);
    setDeletemodal(true);
  };
  const pasuecontract = (contract) => {
    let newDataElement = "";
    setPauseid(contract.ContractID);
    if (contract.RevokeServiceRedeem == 0) {
      setStatuscontract(1);
    } else if (contract.RevokeServiceRedeem == 1) {
      setStatuscontract(2);
    } else if (contract.Status == "A") {
      setStatuscontract(3);
    } else if (contract.ActivityPaused == 0) {
      setStatuscontract(4);
    } else if (contract.ActivityPaused == 1) {
      setStatuscontract(5);
    }
    if (contract.contracttype == 0) {
      setStatuscontract(4);
      newDataElement = {
        title: "Activity Pause",
        question: "Are you sure you want to Pause selected Contracts?",
        messgaetitle: "Enter reason for Pause contract(s)",
        dangertext: "* Reason for Active contract Minimum 10 character",
      };
    } else {
      newDataElement = {
        title: " Active Contract",
        question: "Are you sure you want to Active selected Contracts?",
        messgaetitle: "Enter reason for Active contract",
        dangertext: "* Reason for Active contract Minimum 10 character",
      };
    }
    setModaldata(newDataElement);
    setShowmodal(true);
  };
  const handlemodal = () => {
    setShowmodal(!showmodal);
  };
  const handledealerchange = (val) => {
    setSelecteddealer(val);
  };
  const deletehandlemodal = () => {
    setDeletemodal(!deletemodal);
  };
  const functionLoyaltyCash = (id) => {
    setLoyaltycashshow(!loyaltycashshow);
    setLoyaltycash_contractid(id);
  };
  const columns = [
    {
      name: "ContractNo",
      label: "Contract No",
      options: {
        filter: true,

        sort: true,

        customBodyRender: (value, tableMeta) => {
          if (typeof value != "undefined") {
            var ContractStatus =
              contractlist[tableMeta.rowIndex].ContractStatus;
            var Status = contractlist[tableMeta.rowIndex].Status;
            var PartiallyMature =
              contractlist[tableMeta.rowIndex].PartiallyMature;
            var PaymentStatus = contractlist[tableMeta.rowIndex].PaymentStatus;
            var status_img = "dot_green.jpg";

            if (Status == "M" && PartiallyMature == "N") {
              status_img = "dot_black.jpg";
            } else if (Status == "L") {
              if (ContractStatus == "C") {
                status_img = "dot_green.jpg";
              } else if (ContractStatus == "E") {
                status_img = "dot_blue.jpg";
              }
            } else if (Status == "E") {
              status_img = "dot_blue.jpg";
            } else if (Status == "I") {
              if (PaymentStatus == "B") {
                status_img = "dot_blackorange.png";
              } else {
                status_img = "dot_orange.jpg";
              }
            } else if (Status == "C") {
              status_img = "dot_red.jpg";
            } else if (Status == "A") {
              status_img = "subscription_canceled.png";
            } else if (Status == "S") {
              status_img = "dot_indigogreen.png";
            } else if (Status == "P") {
              status_img = "pending_mature.jpg";
            }
            if (PartiallyMature == "Y") {
              status_img = "partially_matured.png";
            }

            var purble_ball_img = "";
            if (
              contractlist[tableMeta.rowIndex].PurpleBall &&
              contractlist[tableMeta.rowIndex].PurpleBall == 1
            ) {
              purble_ball_img = "purple_ball.jpg";
            }

            var indigo_ball_img = "";
            if (contractlist[tableMeta.rowIndex].isPerpetualPlan == "1") {
              indigo_ball_img = "indigo_ball.png";
            }
            var maintanance_product_img = "";
            if (
              contractlist[tableMeta.rowIndex].MaintananceProductContract ==
                1 ||
              contractlist[tableMeta.rowIndex].IsSubscription > 0
            ) {
              maintanance_product_img = "maintanance_products.png";
            }
            return (
              <div>
                <img
                  alt="status"
                  src={
                    require(`../../resources/c_type_img/${status_img}`).default
                  }
                />
                &nbsp;
                {purble_ball_img !== "" ? (
                  <img
                    alt="ball_img"
                    src={
                      require(`../../resources/c_type_img/${purble_ball_img}`)
                        .default
                    }
                  />
                ) : (
                  ""
                )}
                &nbsp;
                {indigo_ball_img !== "" ? (
                  <img
                    alt="indigo_ball_img"
                    src={
                      require(`../../resources/c_type_img/${indigo_ball_img}`)
                        .default
                    }
                  />
                ) : (
                  ""
                )}
                &nbsp;
                {maintanance_product_img !== "" ? (
                  <img
                    alt="maintanance_product_img"
                    src={
                      require(`../../resources/c_type_img/${maintanance_product_img}`)
                        .default
                    }
                  />
                ) : (
                  ""
                )}
                &nbsp;
                <a
                  style={{ color: "#000" }}
                  href={
                    process.env.REACT_APP_BASE_URL +
                    "/contracts/edit/" +
                    contractlist[tableMeta.rowIndex].ContractID
                  }
                >
                  <span className="mr-1">
                    {contractlist[tableMeta.rowIndex].ContractNo}
                  </span>
                </a>
                &nbsp;
                {contractlist[tableMeta.rowIndex].TotalUsedServices > 0 ? (
                  <i className="fa fa-wrench"></i>
                ) : (
                  ""
                )}
                &nbsp;
                {contractlist[tableMeta.rowIndex].TotalGifted > 0 ? (
                  <i className="fa fa-gift" aria-hidden="true"></i>
                ) : (
                  ""
                )}
              </div>
            );
          }
        },
      },
    },
    {
      name: "CustomerName",
      label: "Name",

      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (typeof value != "undefined") {
            return (
              <div>
                <span className="mr-1">
                  {contractlist[tableMeta.rowIndex].CustomerName}
                </span>
                {contractlist[tableMeta.rowIndex].totalamount > 0 &&
                contractlist[tableMeta.rowIndex].EnableLoyaltyCash == "1" ? (
                  <span
                    onClick={() =>
                      functionLoyaltyCash(
                        contractlist[tableMeta.rowIndex].ContractID
                      )
                    }
                    className="btn badge badge-pill badge-success ml-2  pull-right "
                    style={{ color: "#fff", fontSize: 12, borderRadius: 10 }}
                  >
                    $ {contractlist[tableMeta.rowIndex].totalamount}
                  </span>
                ) : (
                  ""
                )}
              </div>
            );
          }
        },
      },
    },
    {
      name: "PlanDesc",
      label: "PLAN",
      options: {
        filter: true,

        sort: true,
      },
    },
    {
      name: "SaleDate",
      label: "SALE DATE",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if (typeof value != "undefined") {
            return moment.unix(value).format("MM/DD/YYYY");
          }
        },
      },
    },
    {
      name: "ValidityDate",
      label: "Validity Date",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if (typeof value != "undefined") {
            return moment.unix(value).format("MM/DD/YYYY");
          }
        },
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          if (contractlist != "Loading...") {
            var actionimg = "suspend";
            var contracttype = "";
            var signedcontract = "";
            if (contractlist[tableMeta.rowIndex].ContractSigned) {
              signedcontract = contractlist[tableMeta.rowIndex].ContractSigned;
            }
            if (contractlist[tableMeta.rowIndex].isPerpetualPlan == 1) {
              if (
                contractlist[tableMeta.rowIndex].ActivityPaused == 0 &&
                contractlist[tableMeta.rowIndex].RevokeServiceRedeem == 0 &&
                contractlist[tableMeta.rowIndex].Status != "A"
              ) {
                actionimg = "suspend";
                contracttype = 0;
              } else if (
                contractlist[tableMeta.rowIndex].ActivityPaused == 1 &&
                contractlist[tableMeta.rowIndex].Status != "A"
              ) {
                actionimg = "player_play";
                contracttype = 1;
              } else if (
                contractlist[tableMeta.rowIndex].RevokeServiceRedeem == 1 &&
                contractlist[tableMeta.rowIndex].Status != "A"
              ) {
                actionimg = "player_play";
                contracttype = 1;
              }
            } else if (
              contractlist[tableMeta.rowIndex].RevokeServiceRedeem == 0 &&
              contractlist[tableMeta.rowIndex].Status != "A"
            ) {
              actionimg = "suspend";
              contracttype = 0;
            } else if (
              contractlist[tableMeta.rowIndex].RevokeServiceRedeem == 1 &&
              contractlist[tableMeta.rowIndex].Status != "A"
            ) {
              actionimg = "player_play";
              contracttype = 1;
            }
            if (role_id == 1) {
              if (contractlist[tableMeta.rowIndex].Status == "A") {
                actionimg = "player_play";
                contracttype = 1;
              }
            }
            return (
              <div>
                <a
                  id={`edit-${contractlist[tableMeta.rowIndex].ContractID}`}
                  className="btn btn-sm btn-soft-success btn-circle"
                  style={{ color: "#000" }}
                  href={
                    process.env.REACT_APP_BASE_URL +
                    "/contracts/edit/" +
                    contractlist[tableMeta.rowIndex].ContractID
                  }
                >
                  <i className="dripicons-pencil"></i>
                </a>
                {+contractlist[tableMeta.rowIndex].IsSubscription === 0 ? (
                  <button
                    id={`delete-${contractlist[tableMeta.rowIndex].ContractID}`}
                    type="button"
                    className="btn btn-sm btn-soft-danger btn-circle"
                    onClick={(e) => {
                      deleterow(contractlist[tableMeta.rowIndex].ContractID);
                    }}
                  >
                    <i className="dripicons-trash" aria-hidden="true"></i>
                  </button>
                ) : (
                  ""
                )}
                <button
                  id={`pause-${contractlist[tableMeta.rowIndex].ContractID}`}
                  type="button"
                  className="btn btn-sm btn-soft-purple btn-circle"
                  onClick={(e) => {
                    pasuecontract(contractlist[tableMeta.rowIndex]);
                  }}
                >
                  <img
                    className="action-img"
                    src={
                      require(`../../resources/c_type_img/${actionimg}.png`)
                        .default
                    }
                    alt="actionimg"
                  />
                </button>
                {signedcontract ? (
                  <a href={signedcontract} target="_blank">
                    <img
                      alt="signed"
                      className="action-img"
                      src={
                        require(`../../resources/c_type_img/doc-signed.png`)
                          .default
                      }
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            );
          }
        },
      },
    },
  ];

  const getadata = (value) => {
    if (value != "Search") {
      setStartdate("");
      setEnddate("");
    }

    if (typeof selecteddealer == "undefined") {
      setLoading(false);
      addToast("please select Dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      setLoading(false);
      return false;
    }
    if (value == "Search" && startdate == "" && enddate == "") {
      addToast("please select date", {
        appearance: "error",
        autoDismiss: true,
      });
      setLoading(false);
      return false;
    }

    setContractlist(["Loading..."]);
    // POST request using axios inside useEffect React hook
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("DealerID", parseInt(selecteddealer));
    formData.append(
      "from_date",
      startdate ? moment(startdate).format("MM/DD/YYYY") : ""
    );
    formData.append(
      "to_date",
      enddate ? moment(enddate).format("MM/DD/YYYY") : ""
    );
    formData.append("FixedDateParameter", value == "Search" ? "" : value);
    formData.append("SortByCol", "");
    formData.append("SortByColType", "");
    formData.append("status_filter", showbytypevalue);
    formData.append("markservies_filter", "");
    formData.append("MaintananceProductContract", "");
    formData.append("PerpetualPlan_filter", "");
    formData.append(
      "SearchContractBy",
      searchcontractby != "" ? searchcontractby : ""
    );
    formData.append("status_unset", "");
    formData.append(
      "searchby",
      contract_search_by != "" ? contract_search_by : 0
    );

    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/contractlist",
        formData,
        {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == 1) {
          setDashboardContract(response.data.contracts_all);
          setContractlist(response.data.contracts);
          setCount(response.data.TotalRec);
        }
        setLoading(false);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const changePage = (page, sortOrder) => {
    setLoading(true);
    setContractlist(["Loading..."]);
    if (dealerid != null && search_by != null && parameter != null) {
      FetchData(page, sortOrder);
    } else {
      if (fixedDateParameter != "Search") {
        setStartdate("");
        setEnddate("");
      }

      if (typeof selecteddealer == "undefined") {
        addToast("please select Dealership", {
          appearance: "error",
          autoDismiss: true,
        });
        return false;
        setLoading(false);
      }
      if (fixedDateParameter == "Search" && startdate == "" && enddate == "") {
        addToast("please select date", {
          appearance: "error",
          autoDismiss: true,
        });
        return false;
        setLoading(false);
      }
      const formData = new FormData();
      formData.append("pcp_user_id", pcp_user_id);
      formData.append("role_id", role_id);
      formData.append("user_id", user_id);
      formData.append("DealerID", parseInt(selecteddealer));
      formData.append(
        "from_date",
        startdate ? moment(startdate).format("MM/DD/YYYY") : ""
      );
      formData.append(
        "to_date",
        enddate ? moment(enddate).format("MM/DD/YYYY") : ""
      );
      formData.append(
        "FixedDateParameter",
        fixedDateParameter == "Search" ? "" : fixedDateParameter
      );
      formData.append("SortByCol", sortOrder.name ? sortOrder.name : "");
      formData.append(
        "SortByColType",
        sortOrder.direction ? sortOrder.direction : ""
      );
      formData.append(
        "status_filter",
        showbytypevalue === "MS"
          ? ""
          : showbytypevalue === "PC"
          ? ""
          : showbytypevalue === "SC"
          ? ""
          : showbytypevalue
      );
      formData.append("markservies_filter", showbytypevalue === "MS" ? 1 : "");
      formData.append(
        "MaintananceProductContract",
        showbytypevalue === "SC" ? 1 : ""
      );
      formData.append(
        "PerpetualPlan_filter",
        showbytypevalue === "PC" ? 1 : ""
      );
      formData.append("searchby", "");

      formData.append("offset", page * 20);
      formData.append(
        "SearchContractBy",
        searchcontractby != "" ? searchcontractby : ""
      );
      formData.append(
        "searchby",
        contract_search_by != "" ? contract_search_by : 0
      );
      axios
        .post(
          process.env.REACT_APP_APP_URL + "/contract/contractlist",
          formData,
          {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success == 1) {
            setContractlist(response.data.contracts);
            setCount(response.data.TotalRec);

            setPage(page);
            setSortOrder(sortOrder);
          }
          setLoading(false);
        });
    }
  };

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    filterType: "dropdown",
    responsive: "standard",
    page: page,
    count: count,
    Total: count,
    serverSide: true,
    rowsPerPage: 20,
    rowsPerPageOptions: [20],

    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          changePage(tableState.page, tableState.sortOrder);
          break;
        default:
      }
    },
  };
  return (
    <React.Fragment>
      {loyaltycashshow && (
        <LoyaltyCash
          show={true}
          handlemodalloyalty={functionLoyaltyCash}
          contractid={loyaltycash_contractid}
        />
      )}

      <ContractModal
        show={showmodal}
        handlemodal={handlemodal}
        modaldata={modaldata}
        ContractID={pauseid}
        statuscontract={statuscontract}
      />
      <DeleteModal
        show={deletemodal}
        error=""
        handlemodal={deletehandlemodal}
        contractid={deletecontractid}
      />

      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">MyPCP</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Account</a>
                </li>
                <li className="breadcrumb-item active">Contracts List</li>
              </ol>
            </div>
            <h4 className="page-title">Contracts List</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div
                  className="col-lg-3 col-md-6 col-sm-6"
                  style={{ marginBottom: 10 }}
                >
                  <div className="form-group row">
                    <label className="col-lg-12 col-sm-4  col-form-label text-left">
                      Dealer:
                    </label>
                    <div className="col-lg-12 col-sm-8">
                      <DealersDropdown
                        deafultvalue={selecteddealer}
                        id="selecteddealer"
                        handledealerchange={handledealerchange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label text-left mt-1">
                      Search by:
                    </label>
                    <div className="col-lg-12">
                      <Dropdown
                        placeholder="Select Option"
                        fluid
                        onChange={f_searchby}
                        selection
                        options={contract_searchby}
                      />
                    </div>
                  </div>

                  <div className="row text-left">
                    <div className="col-md-12">
                      <div>
                        <label className="col-form-label text-left">
                          Search by date:
                        </label>
                        <div>
                          <div>
                            <div className="form-group">
                              <input
                                value={startdate}
                                onChange={(e) => daterange(1, e)}
                                className="form-control"
                                id="fromDate"
                                type="date"
                              />
                            </div>
                          </div>
                          {/* <div
                                  className=""
                                  style={{ textAlign: "center", margin: 10 }}
                                >
                                  -
                                </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-12 col-sm-4 col-form-label text-left">
                      By Creation Date
                    </label>
                    <div className="col-lg-12 col-sm-8">
                      <select
                        id="SearchContractBy"
                        className="form-control"
                        onChange={handleserachcontractby}
                        defaultValue={searchcontractby}
                      >
                        <option key={0} value="">
                          Select option
                        </option>
                        <option key={1} value={1}>
                          SALE DATE
                        </option>
                        <option key={2} value={2}>
                          POSTED DATE
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6">
                  <label
                    className="col-lg-12 col-sm-4  col-form-label text-left"
                    style={{ visibility: "hidden" }}
                  >
                    Dealer
                  </label>
                  <div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <button
                          type="button"
                          onClick={(e) => selectionbtn(e, "ITD")}
                          className="btn btn-success btn-square waves-effect waves-light ml-2"
                        >
                          ITD
                        </button>
                        <button
                          type="button"
                          onClick={(e) => selectionbtn(e, "YTD")}
                          className="btn btn-success btn-square waves-effect waves-light ml-2"
                        >
                          YTD
                        </button>
                        <button
                          type="button"
                          onClick={(e) => selectionbtn(e, "MTD")}
                          className="btn btn-success btn-square waves-effect waves-light ml-2"
                        >
                          MTD
                        </button>
                      </div>
                    </div>

                    <div className="form-group row pt-2">
                      <label className="col-lg-12 col-sm-4 col-form-label text-left">
                        Show by:
                      </label>
                      <div className="col-lg-12 col-sm-8">
                        <Dropdown
                          onChange={handlestatusfilter}
                          value={showbytypevalue}
                          placeholder="Select contract type"
                          fluid
                          selection
                          options={showbytype}
                        />
                      </div>
                    </div>
                    <div className="row text-left">
                      <div className="col-md-12">
                        <div>
                          <label
                            className="col-form-label text-left mt-1"
                            style={{ visibility: "hidden" }}
                          >
                            Search by date:
                          </label>
                          <div>
                            <div>
                              <div className="form-group">
                                <input
                                  value={enddate}
                                  className="form-control"
                                  onChange={(e) => {
                                    daterange(2, e);
                                  }}
                                  id="todate"
                                  type="date"
                                />
                              </div>
                            </div>
                            {/* <div
                                  className=""
                                  style={{ textAlign: "center", margin: 10 }}
                                >
                                  -
                                </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row text-right">
                      <div className="col-md-12">
                        <button
                          type="button"
                          id="search"
                          className="btn btn-success btn-square"
                          onClick={(e) => {
                            selectionbtn(e, "Search");
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-2 no-l-padding-5px-r-padding">
                      <div className="file-box customer-contract-box">
                        <div className="text-center" style={{ color: "white" }}>
                          <h3 style={{ color: "white" }}>
                            {dashboardcontract.hasOwnProperty("active_per")
                              ? dashboardcontract.active_per
                              : 0}
                            %
                          </h3>
                          <span>
                            {" "}
                            {dashboardcontract.hasOwnProperty("active")
                              ? dashboardcontract.active
                              : 0}
                          </span>
                          <br />
                          <span>Active</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 no-l-padding-5px-r-padding">
                      <div className="file-box customer-contract-box">
                        <div className="text-center" style={{ color: "white" }}>
                          <h3 style={{ color: "white" }}>
                            {" "}
                            {dashboardcontract.hasOwnProperty(
                              "pending_matured_per"
                            )
                              ? dashboardcontract.pending_matured_per
                              : 0}
                            %
                          </h3>
                          <span>
                            {" "}
                            {dashboardcontract.hasOwnProperty("pendingmatured")
                              ? dashboardcontract.pendingmatured
                              : 0}
                          </span>
                          <br />
                          <span>Pending</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2 no-l-padding-5px-r-padding">
                      <div className="file-box customer-contract-box">
                        <div className="text-center" style={{ color: "white" }}>
                          <h3 style={{ color: "white" }}>
                            {" "}
                            {dashboardcontract.hasOwnProperty("matured_per")
                              ? dashboardcontract.matured_per
                              : 0}
                            %
                          </h3>
                          <span>
                            {dashboardcontract.hasOwnProperty("matured")
                              ? dashboardcontract.matured
                              : 0}
                          </span>
                          <br />
                          <span>Matured</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 no-l-padding-5px-r-padding">
                      <div className="file-box customer-contract-box">
                        <div className="text-center" style={{ color: "white" }}>
                          <h3 style={{ color: "white" }}>
                            {dashboardcontract.hasOwnProperty(
                              "pending_matured_per"
                            )
                              ? dashboardcontract.pending_matured_per
                              : 0}{" "}
                            %
                          </h3>
                          <span>
                            {dashboardcontract.hasOwnProperty("pendingmatured")
                              ? dashboardcontract.pendingmatured
                              : 0}
                          </span>
                          <br />
                          <span style={{ fontSize: 14 }}>Pending Matured</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 no-l-padding-5px-r-padding">
                      <div className="file-box customer-contract-box">
                        <div className="text-center" style={{ color: "white" }}>
                          <h3 style={{ color: "white" }}>
                            {dashboardcontract.hasOwnProperty("cancelled_per")
                              ? dashboardcontract.cancelled_per
                              : 0}{" "}
                            %
                          </h3>
                          <span>
                            {dashboardcontract.hasOwnProperty("cancelled")
                              ? dashboardcontract.cancelled
                              : 0}
                          </span>
                          <br />
                          <span>Cancelled</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 no-l-padding-5px-r-padding">
                      <div className="file-box customer-contract-box">
                        <div className="text-center" style={{ color: "white" }}>
                          <h3 style={{ color: "white" }}>
                            {dashboardcontract.hasOwnProperty("suspended_per")
                              ? dashboardcontract.suspended_per
                              : 0}
                            %
                          </h3>
                          <span>
                            {dashboardcontract.hasOwnProperty("suspended")
                              ? dashboardcontract.suspended
                              : 0}
                          </span>
                          <br />
                          <span>Sub Cancelled</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row text-left mt-3">
                    <div className="col-lg-12">
                      <div className="row text-right">
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn btn-success btn-square ml-1 text-uppercase"
                          >
                            <i className="fa fa-envelope"></i> Email Report
                          </button>
                          <button
                            type="button"
                            className="btn btn-success btn-square ml-1 text-uppercase"
                          >
                            <i className="fa fa-print"></i> Print Report
                          </button>
                          <button
                            type="button"
                            className="btn btn-success btn-square ml-1 text-uppercase"
                          >
                            <i className="fas fa-file-export"></i> Export
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-success btn-square waves-effect waves-light ml-2"
                        >
                          Classic
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-square waves-effect waves-light ml-2"
                        >
                          Guest
                        </button>
                        <button
                          type="button"
                          className="btn btn-info btn-square waves-effect waves-light ml-2"
                        >
                          VSC
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-square waves-effect waves-light ml-2"
                        >
                          Gifited
                        </button>
                      </div>
                    </div> */}

              <div className="row mt-4">
                <div className="col-12">
                  <MUIDataTable
                    title={
                      <div>
                        Contract List{" "}
                        {loading && (
                          <span style={{ padding: 10 }}>
                            <Spinner animation="border" variant="success" />
                          </span>
                        )}
                      </div>
                    }
                    data={contractlist}
                    columns={columns}
                    options={options}
                  />
                </div>
              </div>
              <div className="col-12">
                <Row className="p-10">
                  {showbytype.map((item, i) => (
                    <Col key={i} className="col-2">
                      <img src={item.image.src} alt="img-src" />
                      <span className="p-10">{item.text}</span>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="col-md-12 text-center">
                <button
                  type="button"
                  className="btn btn-success btn-square ml-1 text-uppercase"
                >
                  <i className="fa fa-envelope"></i> Email Report
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-square ml-1 text-uppercase"
                >
                  <i className="fa fa-print"></i> Print Report
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-square ml-1 text-uppercase"
                >
                  <i className="fas fa-file-export"></i> Export
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contractlist;
