import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Card, Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
import "./loyaltycash.css";
import moment from "moment";
import PreLoad from "../../../components/PreLoad";
import RedemptionRewards from "./RedemptionRewards";
import RedeemLoyaltyCash from "./RedeemLoyaltyCash";
import { Addxpredeem } from "./LoyaltyCashApi";
import DeleteXpPoints from "./DeleteXpPoints";
import DeleteRedemptionRewards from "./DeleteRedemptionRewards";
import EnrollCar from "./EnrollCar";
import { CarVin } from "./CarVin";
import { DisableCar } from "./DisableCar";
require("dotenv").config();
export default function Loyaltychash(props) {
  const { show, handlemodalloyalty, contractid } = props;
  const [error, setError] = useState(props.error);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [servicelistredeem, setServicelistredeem] = useState("");
  const [servicelist, setServicelist] = useState("");
  const [contractvins, setContractvins] = useState("");
  const [loyaltycash, setLoyaltycash] = useState("");
  const [loyaltycash_total, setLoyaltycash_total] = useState("");
  const [xp_count, setXp_count] = useState("");
  const [deletemodal, setDeletemodal] = useState(false);
  const [currentitem, setCurrentitem] = useState("");
  const [caritem, setCaritem] = useState("");
  const [redemptionmodal, setRedemptionmodal] = useState(false);
  const [redeemLoyaltyCash, setRedeemLoyaltyCash] = useState(false);

  const [deletexprewardmodal, setDeletexprewardmodal] = useState(false);
  const [addnewcar, setAddnewcar] = useState(false);
  const [dealerid, setDealerid] = useState("");
  const [deletecar, setDeletecar] = useState(false);
  const [deletecarid, setDeletecarid] = useState("");
  const [disablecar, setDisablecar] = useState(false);
  const [disablecardata, setDisablecardata] = useState("");
  const handleClose = () => {
    setServicelistredeem("");
    setContractvins("");
    setLoyaltycash("");
    setLoyaltycash_total("");
    setXp_count("");
    setSuccess("");
    handlemodalloyalty("");
    setCurrentitem("");
    setCaritem("");
  };
  const handledetelereward = (item) => {
    if (+item.xp_type == 2) {
      deletexprewards(item);
    } else {
      setCurrentitem(item);
      setDeletemodal(!deletemodal);
    }
  };
  const deletehandlemodal = (data) => {
    setDeletemodal(!deletemodal);
    if (typeof data != "undefined") {
      setSuccess(data.message);
      setServicelistredeem(data.servicelistredeem);
    }
  };
  const deleterewadshandlemodal = (data) => {
    setDeletexprewardmodal(!deletexprewardmodal);
    if (typeof data != "undefined") {
      setSuccess(data.messages);
      setServicelistredeem(data.servicelistredeem);
    }
  };
  const f_redemptionmodal = (e, data) => {
    if (typeof data != "undefined" && data != "") {
      setServicelistredeem(data);
    }
    setRedemptionmodal(!redemptionmodal);
  };
  const f_redeemLoyaltyCash = (e, data) => {
    if (typeof data != "undefined" && data != "") {
      setLoyaltycash(data.loyaltycash);
      setSuccess(data.message);
      setLoyaltycash_total(data.loyaltycash_total);
    }
    setRedeemLoyaltyCash(!redeemLoyaltyCash);
  };
  const f_deletecar = (item, data) => {
    setDeletecar(!deletecar);
    if (item !== "" && typeof item != "undefined") {
      setDeletecarid(item.ID);
    }
    if (data !== "" && typeof data != "undefined") {
      setContractvins(data.contractvins);
      setSuccess(data.message);
    }
  };
  const f_disablecar = (item, data) => {
    setDisablecar(!disablecar);
    if (item !== "" && typeof item != "undefined") {
      setDisablecardata(item);
    }
    if (data !== "" && typeof data != "undefined") {
      setContractvins(data.contractvins);
      setSuccess(data.message);
    }
  };
  useEffect(() => {
    if (contractid != "") {
      getdata();
      setLoading(true);
    }
  }, [show]);
  function getdata() {
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("ContractID", contractid);
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/xpplusloyaltycash",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success == 1) {
          setServicelistredeem(response.data.servicelistredeem);
          setServicelist(response.data.servicelist);
          setContractvins(response.data.contractvins);
          setLoyaltycash(response.data.loyaltycash);
          setLoyaltycash_total(response.data.loyaltycash_total);
          setXp_count(response.data.xp_count);
          setDealerid(+response.data.DealerID);
        }
      });
  }
  const addpoints = async (index) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("ContractID", contractid);

    formData.append("DealerID", servicelist[index].DealerID);

    formData.append("xp_points", servicelist[index].xp_value);

    formData.append("name", servicelist[index].xp_key);

    formData.append("id", servicelist[index].xp_point_id);
    const xpredeem = await Addxpredeem({ formData });
    if (xpredeem.success == 1) {
      setServicelistredeem(xpredeem.servicelistredeem);
      setContractvins(xpredeem.contractvins);
      setLoyaltycash(xpredeem.loyaltycash);
      setLoyaltycash_total(xpredeem.loyaltycash_total);
      setXp_count(xpredeem.xp_count);
      setSuccess(xpredeem.messages);
    }
    setLoading(false);
  };
  const enrollnewcar = (e, data) => {
    setCaritem("");
    if (typeof data !== "undefined" && data !== "") {
      setSuccess(data.messages);
      setContractvins(data.contractvins);
    }
    setAddnewcar(!addnewcar);
  };
  const editcar = (item, data) => {
    if (typeof data !== "undefined" && data != "") {
      setSuccess(data.messages);
      setContractvins(data.contractvins);
    }
    if (typeof item !== "undefined" && item != "") {
      setCaritem(item);
    }
    setAddnewcar(!addnewcar);
  };
  const deletexprewards = (item) => {
    setCurrentitem(item);
    setDeletexprewardmodal(!deletexprewardmodal);
  };
  return (
    <div>
      <EnrollCar
        show={addnewcar}
        contractid={contractid}
        error=""
        DealerID={dealerid}
        enrollnewcar={enrollnewcar}
        item={caritem}
      />
      <DeleteRedemptionRewards
        show={deletexprewardmodal}
        error=""
        DealerID={dealerid}
        deleterewadshandlemodal={deleterewadshandlemodal}
        item={currentitem}
      />
      <DeleteXpPoints
        show={deletemodal}
        error=""
        DealerID={dealerid}
        handlemodal={deletehandlemodal}
        item={currentitem}
      />
      <RedemptionRewards
        contractid={contractid}
        show={redemptionmodal}
        p_servicelistredeem={servicelistredeem}
        f_redemptionmodal={f_redemptionmodal}
      />
      <RedeemLoyaltyCash
        show={redeemLoyaltyCash}
        DealerID={dealerid}
        contractid={contractid}
        loyaltycash_total={loyaltycash_total}
        f_redeemLoyaltyCash={f_redeemLoyaltyCash}
      />
      <CarVin show={deletecar} f_deletecar={f_deletecar} carid={deletecarid} />
      <DisableCar
        show={disablecar}
        f_disablecar={f_disablecar}
        disablecardata={disablecardata}
      />
      <PreLoad loading={loading} />
      <Modal size={"xl"} show={true} scrollable={true}>
        <Modal.Header>
          <Modal.Title>Loyalty Cash</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              {success != "" ? (
                <div className="alert alert-success">{success}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-5">
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>Services</span>
                  <a
                    onClick={f_redemptionmodal}
                    className="btn btn-sm float-right btn-info "
                  >
                    Redemption Rewards
                  </a>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Service Name</th>

                        <th>Points</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {servicelist != "" && typeof servicelist != "undefined"
                        ? servicelist.map((item, i) => (
                            <tr>
                              <td>{item.xp_key}</td>
                              <td>{item.xp_value}</td>
                              <td>
                                <Button
                                  onClick={() => addpoints(i)}
                                  className="btn btn-sm btn-success mt-10"
                                >
                                  <i className="fas fa-plus"></i> Award points
                                </Button>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>

            <div className="col-7">
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  History
                  <div className="btn-points float-right">
                    Points{" "}
                    <span id="dynamic_xp_points" className="badge">
                      {xp_count != "" ? xp_count : ""}
                    </span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Table responsive className="table-color">
                    <thead>
                      <tr>
                        <th>Service Name</th>
                        <th>Date</th>
                        <th>Points</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {servicelistredeem != "" &&
                      typeof servicelistredeem != "undefined"
                        ? servicelistredeem.map((item) => (
                            <tr
                              className={
                                +item.xp_type == 2 ? "alert alert-success" : ""
                              }
                            >
                              <td>{item.Name}</td>
                              <td>
                                {moment(item.pointdate).format("MM/DD/YYYY")}
                              </td>
                              <td>{item.xp_point}</td>
                              <td>
                                {+item.xp_type == 1 || +item.xp_type == 2 ? (
                                  <Button
                                    onClick={() => handledetelereward(item)}
                                    className="btn btn-danger btn-sm"
                                  >
                                    <i className="far fa-trash-alt"></i>
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {+item.xp_type == 2 ? (
                                  <img
                                    className="ml-2"
                                    src={
                                      require("../../../resources/images/award.png")
                                        .default
                                    }
                                    alt="award"
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Tabs defaultActiveKey="cashaward" id="uncontrolled-tab-example">
                <Tab eventKey="cashaward" title="Loyalty Cash Award">
                  <Card
                    className="tab-pane p-3 active"
                    id="#cashaward"
                    role="tabpanel"
                  >
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <span className="d-flex align-items-center">
                        {" "}
                        History{" "}
                        <a
                          onClick={f_redeemLoyaltyCash}
                          className="ml-2 btn btn-sm float-right btn-info "
                        >
                          Redemption
                        </a>
                      </span>
                      <div className="btn-points float-right">
                        Current Balance{" "}
                        <span id="dynamic_xp_points" className="badge">
                          {loyaltycash_total != "" ? loyaltycash_total : ""}
                        </span>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Table responsive className="table-color">
                        <thead>
                          <tr>
                            <th>VIN</th>
                            <th>Reason</th>
                            <th>Date</th>
                            <th>Ro</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loyaltycash != "" &&
                          typeof loyaltycash != "undefined"
                            ? loyaltycash.map((item) => (
                                <tr>
                                  <td>{item.VIN}</td>
                                  <td>{item.Action}</td>
                                  <td>
                                    {moment(item.created).format("MM/DD/YYYY")}
                                  </td>
                                  <td>{item.RoNo}</td>

                                  {item.amount < 0 ? (
                                    <td>
                                      {" "}
                                      <font color="red">
                                        -${item.amount * -1}
                                      </font>
                                    </td>
                                  ) : (
                                    <td>+${item.amount}</td>
                                  )}
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </Table>
                    </Card.Body>
                    <Card.Footer style={{ border: 0, backgroundColor: "#fff" }}>
                      <a
                        className="btn btn-success float-right"
                        href={
                          process.env.REACT_APP_BASE_URL +
                          "/contracts/loyaltycashprint/" +
                          contractid
                        }
                        target="_blank"
                      >
                        <i className="fa fa-print"></i> Print Receipt
                      </a>
                    </Card.Footer>
                  </Card>
                </Tab>
                <Tab eventKey="mycar" title="My Car">
                  <div
                    className="tab-pane p-3 active"
                    id="#price_print"
                    role="tabpanel"
                  >
                    <Button
                      onClick={enrollnewcar}
                      className="btn btn-warning float-right"
                    >
                      ENROLL NEW CAR
                    </Button>
                    <Table responsive className="table-car table-bordered">
                      <thead>
                        <tr>
                          <th>VIN#</th>
                          <th>Make</th>
                          <th>Model</th>
                          <th>Year</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contractvins != "" &&
                        typeof contractvins != "undefined"
                          ? contractvins.map((item, i) => (
                              <tr>
                                <td>{item.VIN}</td>
                                <td>{item.Make}</td>
                                <td>{item.Model}</td>
                                <td>{item.VehYear}</td>
                                <td>
                                  {i !== 0 ? (
                                    <React.Fragment>
                                      <a
                                        className="btn btn-success btn-sm mr-2"
                                        href="#"
                                        onClick={() => editcar(item, "")}
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </a>

                                      <a
                                        className="btn btn-danger btn-sm mr-2"
                                        href="#"
                                        onClick={() => f_deletecar(item, "")}
                                      >
                                        <i className="far fa-trash-alt"></i>
                                      </a>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  {+item.VinStatus === 1 ? (
                                    <a
                                      className="btn btn-success btn-sm"
                                      href="#"
                                      onClick={() => f_disablecar(item, "")}
                                    >
                                      <i className="far fa-thumbs-up"></i>
                                    </a>
                                  ) : (
                                    <a
                                      className="btn btn-danger btn-sm"
                                      href="#"
                                      onClick={() => f_disablecar(item, "")}
                                    >
                                      <i className="far fa-thumbs-down"></i>
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
