import React from "react";
import MyScripts from "../scriptloader";
export default function NewAccount() {
  return (
    <div>
      <div className="">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <div className="float-right">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">MyPCP</a>
                  </li>
                  <li className="breadcrumb-item active">Account Setup</li>
                </ol>
              </div>
              <h4 className="page-title">Account Setup</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form
                  id="form-vertical"
                  className="form-horizontal form-wizard-wrapper"
                >
                  <h3>Account Setup</h3>
                  <fieldset>
                    <div className="row">
                      <div className="col-md-4">
                        <div>
                          <div className="card-body">
                            <input
                              type="file"
                              id="input-file-now"
                              className="dropify"
                            />
                          </div>
                        </div>

                        <div className="col-md-12 bottom-fields-upload">
                          <div className="form-group margin-bottom-zero">
                            <label>Subscription Plan</label>
                            <input
                              type="text"
                              className="form-control"
                              id="subscription_plan"
                              name="subscription_plan"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-12 bottom-fields-upload">
                          <div className="form-group margin-bottom-zero">
                            <label>Predicted Launch Date*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="predicted_launch_date"
                              name="predicted_launch_date"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-12 bottom-fields-upload">
                          <div className="form-group margin-bottom-zero">
                            <label>Service DMS Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="service_dms_name"
                              name="service_dms_name"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-12 bottom-fields-upload">
                          <div className="form-group margin-bottom-zero">
                            <label>REV*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="rev"
                              name="rev"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-0 row">
                            <label className="col-md-6 my-1 control-label"></label>
                            <div className="col-md-6">
                              <div className="form-check-inline my-1">
                                <div className="custom-control custom-radio custom-control-change">
                                  <label htmlFor="car_fax_yes">Yes</label>
                                </div>
                              </div>
                              <div className="form-check-inline my-1 ">
                                <div className="custom-control custom-radio custom-control-change">
                                  <label>No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-0 row">
                            <label
                              className="col-md-3 my-1 control-label"
                              style={{ TextAlign: "left" }}
                            >
                              CarFax
                            </label>
                            <label className="col-md-3 my-1 control-label"></label>
                            <div className="col-md-6">
                              <div className="form-check-inline my-1 my-1-1">
                                <div className="custom-control custom-radio custom-control-change">
                                  <input
                                    type="radio"
                                    id="car_fax_yes"
                                    name="car_fax"
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="car_fax_yes"
                                  ></label>
                                </div>
                              </div>
                              <div className="form-check-inline my-1 my-1-2">
                                <div className="custom-control custom-radio custom-control-change">
                                  <input
                                    type="radio"
                                    id="car_fax_no"
                                    name="car_fax"
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="car_fax_no"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-0 row">
                            <label
                              className="col-md-6 my-1 control-label"
                              style={{ TextAlign: "left" }}
                            >
                              Google Feedback
                            </label>

                            <div className="col-md-6">
                              <div className="form-check-inline my-1 my-1-1">
                                <div className="custom-control custom-radio custom-control-change">
                                  <input
                                    type="radio"
                                    id="google_feedback_yes"
                                    name="google_feedback_rb"
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="google_feedback_yes"
                                  ></label>
                                </div>
                              </div>
                              <div className="form-check-inline my-1 my-1-2">
                                <div className="custom-control custom-radio custom-control-change">
                                  <input
                                    type="radio"
                                    id="google_feedback_no"
                                    name="google_feedback_rb"
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="google_feedback_no"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Account Name*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="account_name"
                                name="account_name"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Telephone*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="telephone"
                                name="telephone"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Website*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="website"
                                name="website"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Address*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group"></div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Point of Contact*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="point_of_contact"
                                name="point_of_contact"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Country*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="country"
                                name="country"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Time Zone*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="time_zone"
                                name="time_zone"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <button
                                className="btn btn-primary btn-sm add-file ml-3"
                                style={{ MarginTop: 25, BorderRadius: 50 }}
                              >
                                Set open hours
                              </button>
                            </div>
                          </div>
                          <div className="col-md-2"></div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>City*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Zip Code*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="zip_code"
                                name="zip_code"
                              />
                            </div>
                          </div>
                          <div className="col-md-5"></div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>State/Province*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="state_province"
                                name="state_province"
                              />
                            </div>
                          </div>
                          <div className="col-md-3"></div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Latera*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="state_province"
                                name="state_province"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Longitude*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="state_province"
                                name="state_province"
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <h4>Account Goals</h4>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Vehicles Sold</label>
                              <input
                                type="text"
                                className="form-control"
                                id="vehicles_sold"
                                name="vehicles_sold"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Product Penetration</label>
                              <input
                                type="text"
                                className="form-control"
                                id="product_penetration"
                                name="product_penetration"
                              />
                            </div>
                          </div>
                          <div className="col-md-4"></div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Product Sold</label>
                              <input
                                type="text"
                                className="form-control"
                                id="product_sold"
                                name="product_sold"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Upsell Amount</label>
                              <input
                                type="text"
                                className="form-control"
                                id="upsell_amount"
                                name="upsell_amount"
                              />
                            </div>
                          </div>
                          <div className="col-md-4"></div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Google Feedback Title</label>
                              <input
                                type="text"
                                className="form-control"
                                id="google_feedback_title"
                                name="google_feedback_title"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="form-group">
                              <label>Google Feedback URL</label>
                              <input
                                type="text"
                                className="form-control"
                                id="google_feedback_url"
                                name="google_feedback_url"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary px-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </fieldset>

                  <h3>Contract Settings</h3>
                  <fieldset style={{ Padding: 0 }}>
                    <div className="row" style={{ paddingBottom: 12 }}>
                      <div className="col-md-4">
                        <h5 style={{ MarginLeft: 13 }}>Contract Card Image</h5>
                        <div>
                          <div className="card-body">
                            <input
                              type="file"
                              id="input-file-now"
                              className="dropify"
                            />
                          </div>
                        </div>

                        <p className="p-heading">Cancellation Options</p>

                        <div style={{ Paddingleft: 20 }}>
                          <div
                            className="rad-sec"
                            style={{
                              Display: "inline-block",
                              Width: "100%",
                              Height: "auto",
                            }}
                          >
                            <label
                              className="radio-inline account-radio-line"
                              style={{ PaddingLeft: 0 }}
                            >
                              Yes <br />
                            </label>
                            <label
                              className="radio-inline account-radio-line"
                              style={{ PaddingLeft: 10 }}
                            >
                              No <br />
                            </label>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-4">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="optradio1"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="optradio1"
                                  className="account-radio-text"
                                />
                              </label>
                            </div>
                            <div className="col-md-8" style={{ Padding: 0 }}>
                              <span>
                                Services used subtract from refunded price
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{ Paddingleft: 20 }}>
                          <div
                            className="rad-sec"
                            style={{
                              Display: "inline-block",
                              Width: "100%",
                              Height: "auto",
                            }}
                          >
                            <label
                              className="radio-inline account-radio-line"
                              style={{ PaddingLeft: 0 }}
                            >
                              Yes <br />
                            </label>
                            <label
                              className="radio-inline account-radio-line"
                              style={{ PaddingLeft: 10 }}
                            >
                              No <br />
                            </label>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-4">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="optradio1"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="optradio1"
                                  className="account-radio-text"
                                />
                              </label>
                            </div>
                            <div className="col-md-8" style={{ Padding: 0 }}>
                              <span>Contract deletion reason dialogue</span>
                            </div>
                          </div>
                        </div>

                        <p className="p-heading">Cancellation Variable</p>
                        <div className="col-md-11">
                          <select
                            style={{ MarginLeft: 10 }}
                            className="form-control nav-link ml-1 p-2 bg-soft-classic nav-user-name hidden-sm rounded"
                          >
                            <option>All</option>
                            <option>Time Based</option>
                            <option>Mileage Based</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <h5>Contract Settings</h5>
                        <div
                          className="rad-sec"
                          style={{
                            Display: "inline-block",
                            Width: "100%",
                            Height: "auto",
                          }}
                        >
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 0 }}
                          >
                            Yes <br />
                          </label>
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 10 }}
                          >
                            No <br />
                          </label>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>Contract Entry Welcome SMS</span>
                            <button
                              style={{
                                BackgroundColor: "#42ba6e !important",
                                Border: "1px solid #cccccc !important",
                                Padding: "0px 4px 0px 4px",
                                MarginLeft: 5,
                              }}
                              type="button"
                              className="btn btn-primary"
                            >
                              <i className="fas fa-edit"></i>
                            </button>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>Hide Service Page on Print Contract</span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>
                              Allowing selling contract for less than the
                              selling price of contract
                            </span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>Auto Fill contract form</span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>Expired Contract can redeem service</span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>
                              Block service redemption for inactive contracts
                            </span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>Bank Address Mandatory</span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>In-Active Contract Gifting</span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>Customer e-signature</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4" style={{ paddingTop: 30 }}>
                        <p>Sending Hours (CDT/CST)*</p>
                        <div className="row">
                          <div className="col-md-5">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="time"
                                id="example-time-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="useremail">To</label>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="time"
                                id="example-time-input"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                rows="5"
                                id="message"
                                placeholder="Type SMS Msg Here..."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 text-right">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary px-4"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div
                          className="rad-sec"
                          style={{
                            Display: "inline-block",
                            Width: "100%",
                            Height: "auto",
                          }}
                        >
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 0 }}
                          >
                            Yes <br />
                          </label>
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 10 }}
                          >
                            No <br />
                          </label>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-4">
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="include_opt_sms_msg"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="include_opt_sms_msg"
                                className="account-radio-text"
                              />
                            </label>
                          </div>
                          <div className="col-md-8" style={{ Padding: 0 }}>
                            <span>Include OPT SMS Msg</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <p
                          className="p-heading"
                          style={{ MarginLeft: "30px !important" }}
                        >
                          Cancellation Options
                        </p>
                        <div
                          className="form-group"
                          style={{ PaddingLeft: "30px !important" }}
                        >
                          <textarea
                            className="form-control"
                            rows="5"
                            id="message"
                            placeholder="Ex. If Cancellation occurs within the first..."
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary px-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </fieldset>

                  <h3>DSS & Report Settings</h3>
                  <fieldset style={{ Padding: 0 }}>
                    <div className="row" style={{ paddingBottom: 12 }}>
                      <div className="col-md-6">
                        <h5 style={{ MarginLeft: 13 }}>
                          Dashboard Display Settings
                        </h5>
                        <div style={{ Paddingleft: 20 }}>
                          <div
                            className="rad-sec"
                            style={{
                              Display: "inline-block",
                              Width: "100%",
                              Height: "auto",
                            }}
                          >
                            <label
                              className="radio-inline account-radio-line"
                              style={{ PaddingLeft: 0 }}
                            >
                              Hide <br />
                            </label>
                            <label
                              className="radio-inline account-radio-line"
                              style={{ PaddingLeft: 10 }}
                            >
                              Show <br />
                            </label>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-3">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="mcpr_rb"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="mcpr_rb"
                                  className="account-radio-text-dss"
                                />
                              </label>
                            </div>
                            <div className="col-md-9" style={{ Padding: 0 }}>
                              <span>Middle Column in Performance Report</span>
                            </div>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-3">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="hcirm_rb"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="hcirm_rb"
                                  className="account-radio-text-dss"
                                />
                              </label>
                            </div>
                            <div className="col-md-9" style={{ Padding: 0 }}>
                              <span>Hide Customer Info on Retention Map</span>
                            </div>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-3">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="crod_rb"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="crod_rb"
                                  className="account-radio-text-dss"
                                />
                              </label>
                            </div>
                            <div className="col-md-9" style={{ Padding: 0 }}>
                              <span>Contract Reserve Optional Details</span>
                            </div>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-3">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="afrcmre_rb"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="afrcmre_rb"
                                  className="account-radio-text-dss"
                                />
                              </label>
                            </div>
                            <div className="col-md-9" style={{ Padding: 0 }}>
                              <span>
                                Admin Fee & Retained Columns in Matured Report
                                Export
                              </span>
                            </div>
                          </div>
                        </div>
                        <h5 style={{ MarginLeft: 13 }}>
                          Suspended Subscription
                        </h5>
                        <div style={{ Paddingleft: 20 }}>
                          <div
                            className="rad-sec"
                            style={{
                              Display: "inline-block",
                              Width: "100%",
                              Height: "auto",
                            }}
                          >
                            <label
                              className="radio-inline account-radio-line"
                              style={{ PaddingLeft: 0 }}
                            >
                              Hide <br />
                            </label>
                            <label
                              className="radio-inline account-radio-line"
                              style={{ PaddingLeft: 10 }}
                            >
                              Show <br />
                            </label>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-3">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="ssd_rb"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="ssd_rb"
                                  className="account-radio-text-dss"
                                />
                              </label>
                            </div>
                            <div className="col-md-9" style={{ Padding: 0 }}>
                              <span>Suspended Subscription on Dashboard</span>
                            </div>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-3">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="scnd_rb"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="scnd_rb"
                                  className="account-radio-text-dss"
                                />
                              </label>
                            </div>
                            <div className="col-md-9" style={{ Padding: 0 }}>
                              <span>Service Cancelled on normal Dashboard</span>
                            </div>
                          </div>

                          <div className="row account-radio-row">
                            <div className="col-md-3">
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="psraod_rb"
                                  checked={true}
                                />
                                <span style={{ visibility: "hidden" }}>
                                  Yes
                                </span>
                              </label>
                              <label className="radio-inline account-radio-line">
                                <input
                                  type="radio"
                                  name="psraod_rb"
                                  className="account-radio-text-dss"
                                />
                              </label>
                            </div>
                            <div className="col-md-9" style={{ Padding: 0 }}>
                              <span>
                                Plan Services Reserved Amount Override on
                                Dashboard
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <h5 style={{ MarginLeft: 13 }}>Sitrep Account</h5>
                        <div className="col-md-12">
                          <div className="form-group margin-bottom-zero">
                            <label>Sitrep Person</label>
                            <input
                              type="text"
                              className="form-control"
                              id="sitrep_person"
                              name="sitrep_person"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group margin-bottom-zero">
                            <label>Phone #</label>
                            <input
                              type="text"
                              className="form-control"
                              id="phone_number"
                              name="phone_number"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group margin-bottom-zero">
                            <label>Email Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="emai_address"
                              name="emai_address"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary px-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </fieldset>

                  <h3>Email Defaults</h3>
                  <fieldset style={{ Padding: 0 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 style={{ MarginLeft: 13 }}>Email Default SetUp</h5>
                      </div>
                    </div>

                    <div className="row email-default-row">
                      <div className="col-md-12">
                        <label>Add Field Images</label>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Top Logo</label>
                          <input
                            type="file"
                            id="input-file-now"
                            className="dropify"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>App QR Image</label>
                          <input
                            type="file"
                            id="input-file-now"
                            className="dropify"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Select Email Categories</label>
                          <select
                            className="form-control select2 mb-3 select2-multiple email_multiple_select"
                            style={{ width: "100%" }}
                            multiple="multiple"
                          >
                            <option value="chat_admin_customer">
                              Chat - Admin to Customer
                            </option>
                            <option value="chat_customer_to_admin">
                              Chat - Customer to Admin
                            </option>
                            <option value="contract_entry_notification">
                              Contract Entry Notification
                            </option>
                            <option value="chat_admin_customer">
                              Contract Sale Format
                            </option>
                            <option value="chat_admin_customer">
                              Dashboard Report
                            </option>
                            <option value="chat_admin_customer">
                              Customer Contract Info
                            </option>
                            <option value="chat_admin_customer">
                              Gifted Services Notification
                            </option>
                            <option value="chat_admin_customer">
                              Referra Product to Customer
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <label
                        className="col-md-1 col-form-label"
                        style={{
                          TextAlign: "left",
                          MarginLeft: 15,
                          MarginRight: -30,
                        }}
                      >
                        Select
                      </label>
                      <div className="col-md-4">
                        <select className="form-control">
                          <option value="chat_admin_customer">
                            Chat - Admin to Customer
                          </option>
                          <option value="chat_customer_toadmin">
                            Chat - Customer to Admin
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="row" style={{ Padding: 10 }}>
                      <div className="col-md-12">
                        <textarea id="elm1" name="area"></textarea>
                      </div>
                    </div>

                    <div className="row email-default-row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label style={{ MarginTop: 10 }}>Fields</label>
                          <ul className="fields-list">
                            <li>CustomerFName</li>
                            <li>CustomerLName</li>
                            <li>DealerTitle</li>
                            <li>UserName</li>
                            <li>Password</li>
                            <li>ContractNo</li>
                            <li>Product</li>
                            <li>Coverage</li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group" style={{ PaddingTop: 10 }}>
                          <button className="btn btn-primary float-left mt-4 mb-1">
                            <i className="mdi mdi-plus-circle-outline mr-2"></i>
                            Add Field
                          </button>
                        </div>
                        <p style={{ display: "inline-flex" }}>
                          To use, select field and click “+Add Field” or type
                          words with [ ] surrounding in email template.
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary px-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </fieldset>

                  <h3>Customer Letter</h3>
                  <fieldset style={{ Padding: 0 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 style={{ MarginLeft: 13 }}>
                          Customer Letter SetUp
                        </h5>
                      </div>
                    </div>

                    <div className="row email-default-row">
                      <div className="col-md-12">
                        <label>Add Field Images</label>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Top Logo</label>
                          <input
                            type="file"
                            id="input-file-now"
                            className="dropify"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>App QR Image</label>
                          <input
                            type="file"
                            id="input-file-now"
                            className="dropify"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="row customer-letter-default">
                          <div className="col-md-8">
                            <div className="form-group">
                              <select className="form-control">
                                <option value="chat_admin_customer">
                                  PROCARMA DEFAULT
                                </option>
                                <option value="chat_admin_customer">
                                  PROCARMA DEFAULT
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <button className="btn btn-primary float-left">
                                <i className="mdi mdi-plus-circle-outline mr-2"></i>
                                Default Letter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ Padding: 10 }}>
                      <div className="col-md-12">
                        <textarea
                          id="customer_letter_email"
                          name="area"
                        ></textarea>
                      </div>
                    </div>

                    <div className="row email-default-row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label style={{ MarginTop: 10 }}>Fields</label>
                          <ul className="fields-list">
                            <li>CustomerFName</li>
                            <li>CustomerLName</li>
                            <li>DealerTitle</li>
                            <li>UserName</li>
                            <li>Password</li>
                            <li>ContractNo</li>
                            <li>Product</li>
                            <li>Coverage</li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group" style={{ PaddingTop: 10 }}>
                          <button className="btn btn-primary float-left mt-4 mb-1">
                            <i className="mdi mdi-plus-circle-outline mr-2"></i>
                            Add Field
                          </button>
                        </div>
                        <p style={{ Display: "inline-flex" }}>
                          To use, select field and click “+Add Field” or type
                          words with [ ] surrounding in email template.
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary px-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </fieldset>

                  <h3>App Settings</h3>
                  <fieldset style={{ Padding: 0 }}>
                    <div
                      className="row"
                      style={{ PaddingLeft: 10, PaddingTop: 10 }}
                    >
                      <div className="col-md-4">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="guest_interface"
                            checked={true}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                          >
                            <h4 style={{ MarginLeft: 13, MarginTop: 0 }}>
                              PROCARMA APP
                            </h4>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch2"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch2"
                          >
                            <h4 style={{ MarginLeft: 13, MarginTop: 0 }}>
                              CUSTOM APP
                            </h4>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="col-form-label">App ID</label>
                          </div>
                          <div className="col-sm-9">
                            <input type="email" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <h5 style={{ MarginLeft: 13 }}>App Settings</h5>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12" style={{ PaddingLeft: 28 }}>
                        <div
                          className="rad-sec"
                          style={{
                            Display: "inline-block",
                            Width: "100%",
                            Height: "auto",
                          }}
                        >
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 0 }}
                          >
                            Enable <br />
                          </label>
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 10 }}
                          >
                            Disable <br />
                          </label>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>First Time Video Playback on Log In</span>
                            <button type="button" className="btn btn-primary">
                              <i className="fas fa-edit"></i>
                            </button>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2"></div>
                          <div className="col-md-3" style={{ Padding: 0 }}>
                            <div className="custom-file mb-3">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFile"
                              ></label>
                            </div>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>Enable Google Feedback</span>
                          </div>
                        </div>
                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>Secondary Dashboard</span>
                          </div>
                        </div>
                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>Dashboard Slider</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12" style={{ PaddingLeft: 28 }}>
                        <div
                          className="rad-sec"
                          style={{
                            Display: "inline-block",
                            Width: "100%",
                            Height: "auto",
                          }}
                        >
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 0 }}
                          >
                            Show <br />
                          </label>
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 23 }}
                          >
                            Hide <br />
                          </label>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>Mileage in apps and customer portal</span>
                          </div>
                        </div>
                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>Variable Dashboard</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ PaddingLeft: 15 }}>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Bottom Copyright Text
                          </label>
                          <input type="email" className="form-control" />
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ PaddingLeft: 15 }}>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            External URL Title
                          </label>
                          <input type="email" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">URL</label>

                          <input type="email" className="form-control" />
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ MarginLeft: 6 }}
                          >
                            <i className="fas fa-plus-square"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary px-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </fieldset>

                  <h3>Guest Settings</h3>
                  <fieldset style={{ Padding: 0 }}>
                    <div
                      className="row"
                      style={{ PaddingLeft: 10, PaddingTop: 10 }}
                    >
                      <div className="col-md-4">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="guest_interface"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="guest_interface"
                          >
                            <h5 style={{ MarginLeft: 13, MarginTop: 0 }}>
                              Guest Interface
                            </h5>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <h5 style={{ MarginLeft: 13 }}>
                          Guest Product Selling
                        </h5>
                      </div>
                    </div>

                    <div className="row" style={{ PaddingLeft: 15 }}>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Selling Rep*</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Sales Person*</label>
                          <div>
                            <select className="form-control">
                              <option></option>
                              <option>Sales Person</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12" style={{ paddingLeft: 28 }}>
                        <div
                          className="rad-sec"
                          style={{
                            Display: "inline-block",
                            Width: "100%",
                            Height: "auto",
                          }}
                        >
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 0 }}
                          >
                            Enable <br />
                          </label>
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 10 }}
                          >
                            Disable <br />
                          </label>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>Guest Can Use All Features</span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>
                              Only Use Core: services, schedule appointments,and
                              view Quick Specials(if applicable)
                            </span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>Can Use Core, plus all that apply</span>
                          </div>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2"></div>
                          <div className="col-md-3" style={{ Padding: 0 }}>
                            <select className="form-control">
                              <option>No Enabled Features</option>
                              <option>Yes Enabled Features</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12" style={{ paddingLeft: 28 }}>
                        <div
                          className="rad-sec"
                          style={{
                            Display: "inline-block",
                            Width: "100%",
                            Height: "auto",
                          }}
                        >
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 0 }}
                          >
                            Enable <br />
                          </label>
                          <label
                            className="radio-inline account-radio-line"
                            style={{ PaddingLeft: 10 }}
                          >
                            Disable <br />
                          </label>
                        </div>

                        <div className="row account-radio-row">
                          <div className="col-md-2">
                            <label className="radio-inline account-radio-line app-settings-checkbox-enable">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={true}
                              />
                              <span style={{ visibility: "hidden" }}>Yes</span>
                            </label>
                            <label className="radio-inline account-radio-line">
                              <input
                                type="radio"
                                name="optradio1"
                                className="app-settings-checkbox-disable"
                              />
                            </label>
                          </div>
                          <div className="col-md-4" style={{ Padding: 0 }}>
                            <span>Guest RoadMap</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Paste URL here"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-6">
                            <div>
                              <input
                                type="file"
                                id="input-file-now"
                                className="dropify"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Image Portal</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary px-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
