import React, { Fragment, useState } from "react";
import RadialBar from "./apexchartradialbar";
import ReactTooltip from "react-tooltip";
import graphdashboard from "./graphdashboard.module.css";

import { useToasts } from "react-toast-notifications";
import PreLoad from "../components/PreLoad";
import {
  f_dashboardexport,
  f_subscriptionexport,
} from "../components/helpers";
function DashboardSoldGraph(props) {
  const {
    dashboardContract,
    ShopingCartEnable,
    DealerID,
    fromdate,
    toDate,
    FixedDateParameter,
  } = props;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const exportdata = (export_type) => {
    if (DealerID == "" || typeof DealerID == "undefined") {
      addToast("please select dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("FomDate", fromdate);
    formData.append("ToDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    formData.append("export_type", export_type);
    setLoading(true);
    //api
    if (ShopingCartEnable === 1) {
      f_subscriptionexport(formData).then((response) => {
        setLoading(false);
      });
    } else {
      f_dashboardexport(formData).then((response) => {
        setLoading(false);
      });
    }
  };

  let inactive = dashboardContract.hasOwnProperty("inactive")
    ? dashboardContract.inactive
    : 0;
  let active = dashboardContract.hasOwnProperty("active")
    ? dashboardContract.active
    : 0;
  let matured = dashboardContract.hasOwnProperty("matured")
    ? dashboardContract.matured
    : 0;
  let cancelled = dashboardContract.hasOwnProperty("cancelled")
    ? dashboardContract.cancelled
    : 0;
  let pendingmatured = dashboardContract.hasOwnProperty("pendingmatured")
    ? dashboardContract.pendingmatured
    : 0;
  let Suspended = dashboardContract.hasOwnProperty("Suspended")
    ? dashboardContract.Suspended
    : 0;
  let totalcontracts = dashboardContract.hasOwnProperty("total")
    ? dashboardContract.total
    : 0;

  /*
let salematured = dashboardContract.salematured?dashboardContract.salematured:0;
let salecancelled = dashboardContract.salecancelled?dashboardContract.salecancelled:0;
let salependingmatured = dashboardContract.salependingmatured?dashboardContract.salependingmatured:0;
*/
  let activepercent = dashboardContract.active_per
    ? dashboardContract.active_per
    : 0;

  let inactive_per = dashboardContract.inactive_per
    ? dashboardContract.inactive_per
    : 0;
  let pending_matured_per = dashboardContract.pending_matured_per
    ? dashboardContract.pending_matured_per
    : 0;
  let matured_per = dashboardContract.matured_per
    ? dashboardContract.matured_per
    : 0;
  let cancelled_per = dashboardContract.cancelled_per
    ? dashboardContract.cancelled_per
    : 0;
  let suspended_per = dashboardContract.suspended_per
    ? dashboardContract.suspended_per
    : 0;

  let tootltip1 =
    "This is the number of contract<br/>issued in the selected date period";

  return (
    <Fragment>
      <PreLoad loading={loading} />
      <div id="chart-box" style={{ width: "100%", display: "inline-flex" }}>
        <div className="col-md-2" style={{ display: "grid" }}>
          <div className="card">
            <div className="card-body text-center text-color-constracts-div">
              <span className="font-30 pt-4 pb-4" style={{ fontWeight: "600" }}>
                {totalcontracts}
              </span>
              <span className="font-14">&nbsp; Total Contract Sold</span>
              <br />
              <button
                onClick={() => exportdata(1)}
                className={graphdashboard.detail_btn}
                data-tip={tootltip1}
              >
                Details
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-10">
          <div className="row">
            <div className="col-md-2">
              <div className="card">
                <div
                  className="card-body text-center text-color-constracts-div"
                  style={{ padding: "0.75rem" }}
                >
                  <RadialBar label={active} percent={activepercent} />
                  <span>Active</span>
                  <br />
                  <button
                    onClick={() => exportdata(30)}
                    className={graphdashboard.detail_btn}
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card">
                <div
                  className="card-body text-center text-color-constracts-div"
                  style={{ padding: "0.75rem" }}
                >
                  <RadialBar label={inactive} percent={inactive_per} />
                  <span>Pending Remittance</span>
                  <br />
                  <button
                    onClick={() => exportdata(7)}
                    className={graphdashboard.detail_btn}
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card">
                <div
                  className="card-body text-center text-color-constracts-div"
                  style={{ padding: "0.75rem" }}
                >
                  <RadialBar label={cancelled} percent={cancelled_per} />
                  <span>Cancelled</span>
                  <br />
                  <button className={graphdashboard.detail_btn}>Details</button>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card">
                <div
                  className="card-body text-center text-color-constracts-div"
                  style={{ padding: "0.75rem" }}
                >
                  <RadialBar label={matured} percent={matured_per} />
                  <span>Matured</span>
                  <br />
                  <button
                    onClick={() => exportdata(33)}
                    className={graphdashboard.detail_btn}
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card">
                <div
                  className="card-body text-center text-color-constracts-div"
                  style={{ padding: "0.75rem" }}
                >
                  <RadialBar
                    label={pendingmatured}
                    percent={pending_matured_per}
                  />
                  <span>Pending Matured</span>
                  <br />

                  <button
                    onClick={() => exportdata(16)}
                    className={graphdashboard.detail_btn}
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card">
                <div
                  className="card-body text-center text-color-constracts-div"
                  style={{ padding: "0.75rem" }}
                >
                  <RadialBar label={Suspended} percent={suspended_per} />
                  <span>Suspended</span>
                  <br />
                  <a style={{ width: "100%", textAlign: "center" }} href="#">
                    Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip multiline={true} />
      </div>
    </Fragment>
  );
}

export default DashboardSoldGraph;
