import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Sidebar from "./views/sidebar/sidebar";
import CreateContract from "./views/contracts/create/CreateContract";
import EditContract from "./views/contracts/editcontract/EditContract";
import Contractlist from "./views/contracts/index";
import Dashboard from "./views/Dashboard";
import Login from "./views/login/Loginpage";
import NewAccount from "./views/contracts/NewAccount";
import useToken from "./views/useToken";
import Example from "./views/pages/Example";
import LoyaltyCashPrint from "./views/contracts/Loyaltycash/LoyaltyCashPrint";
import Printselectedservice from "./views/contracts/editcontract/Printselectedservice.js";
import "./core.css";
import { SigninWithToken } from "./views/signin/SigninWithToken";
import PlanList from "./views/plans/list/PlanList";
import CreatePlan from "./views/plans/create/CreatePlan";
import { useSelector } from "react-redux";
export default function Core() {
  const { token, setToken } = useToken();
  const unfoldable = useSelector(
    (state) => state.Sidebarstore.sidebarUnfoldable
  );
  // if (!token) {
  //   window.location.href = "https://newdash.mypcp.us";
  // }
  return (
    <React.Fragment>
      <Router basename="/modern">
        <Switch>
          <Route path="/" exact>
            <Login />
          </Route>
        </Switch>
      </Router>

      <Router basename="/modern">
        <Switch>
          <Route path="/contracts/summary/">
            <Sidebar />
            <div className="page-wrapper">
              <div
                className={
                  unfoldable
                    ? "page-content-tab responsive-right-menu"
                    : "page-content-tab"
                }
              >
                <Dashboard />
              </div>
            </div>
          </Route>
          <Route path="/signin/:usertoken">
            <SigninWithToken />
          </Route>
          <Route path="/contracts/create">
            <Sidebar />
            <div className="page-wrapper">
              <div
                className={
                  unfoldable
                    ? "page-content-tab responsive-right-menu"
                    : "page-content-tab"
                }
              >
                <CreateContract />
              </div>
            </div>
          </Route>
          <Route path="/contracts/index/">
            <Sidebar />
            <div className="page-wrapper">
              <div
                className={
                  unfoldable
                    ? "page-content-tab responsive-right-menu"
                    : "page-content-tab"
                }
              >
                <Contractlist />
              </div>
            </div>
          </Route>

          <Route path="/contracts/newaccount">
            <Sidebar />
            <div className="page-wrapper">
              <div
                className={
                  unfoldable
                    ? "page-content-tab responsive-right-menu"
                    : "page-content-tab"
                }
              >
                <NewAccount />
              </div>
            </div>
          </Route>
          <Route path="/contracts/edit/:ContractID">
            <Sidebar />
            <div className="page-wrapper">
              <div
                className={
                  unfoldable
                    ? "page-content-tab responsive-right-menu"
                    : "page-content-tab"
                }
              >
                <EditContract />
              </div>
            </div>
          </Route>

          {/* palns routes */}
          <Route path="/plan/index">
            <Sidebar />
            <div className="page-wrapper">
              <div
                className={
                  unfoldable
                    ? "page-content-tab responsive-right-menu"
                    : "page-content-tab"
                }
              >
                <PlanList />
              </div>
            </div>
          </Route>
          <Route path="/plan/plan_create">
            <Sidebar />
            <div className="page-wrapper">
              <div
                className={
                  unfoldable
                    ? "page-content-tab responsive-right-menu"
                    : "page-content-tab"
                }
              >
                <CreatePlan />
              </div>
            </div>
          </Route>
          <Route path="/contracts/loyaltycashprint/:ContractID">
            <LoyaltyCashPrint />
          </Route>
          <Route path="/contracts/Printselectedservice/">
            <Printselectedservice />
          </Route>
          {/* <Route path="/example">
            <Example />
          </Route>
          <Route path="">
            <NotFoundPage />
          </Route> */}
        </Switch>
      </Router>
    </React.Fragment>
  );
}
