import React, { useState, useEffect, useRef } from "react";

import { Chart } from "react-google-charts";
const ContractSoldGraph = (props) => {
  const { contractsold_data, title, setContractSoldimg } = props;
  const [chartImageURI, setChartImageURI] = useState(null);

  useEffect(() => {
    if (chartImageURI !== null) {
      setContractSoldimg(chartImageURI);
    }
  }, [chartImageURI]);
  const chartRef = useRef(null);

  let chart_events = [
    {
      eventName: "ready",
      callback: (ChartRef) => {
        // Returns Chart so you can access props and  the ChartWrapper object from chart.wrapper
        setChartImageURI(ChartRef.chartWrapper.getChart().getImageURI());
      },
    },
  ];
  const [chartEvents, setChartEvents] = useState(chart_events);
  return (
    <Chart
      width={"100%"}
      height={"300px"}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={[["Month", "Total", "New", "Used"], ...contractsold_data]}
      options={{
        // Material design options
        chart: {
          title: "Contract sold" + title,
        },
        colors: ["#E481D3", "#8DC8C2", "#AFF1EC"],
      }}
      chartEvents={chartEvents}
      // For tests
      rootProps={{ "data-testid": "5" }}
    />
  );
};

export default ContractSoldGraph;
