import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
import { useParams } from "react-router-dom";
import style from "./loyaltyprint.module.css";
require("dotenv").config();
export default function Loyaltychash() {
  const [loyaltycash, setLoyaltycash] = useState();
  const [todayloyaltycash, setTodayloyaltycash] = useState();
  const [dealer_data, setDealer_data] = useState("");
  const [loyaltycash_total, setLoyaltycash_total] = useState("");
  const { ContractID } = useParams();
  useEffect(() => {
    if (ContractID != "") {
      getdata();
    }
  }, []);
  function getdata() {
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("ContractID", ContractID);
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/loyaltycashprint",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == 1) {
          setLoyaltycash(response.data.loyaltycash);
          setTodayloyaltycash(response.data.todayloyaltycash);
          setDealer_data(response.data.dealer_data);
          setLoyaltycash_total(response.data.loyaltycash_total);
        }
      });
  }
  const print = () => {
    window.print();
  };
  return (
    <React.Fragment>
      <div className={style.printMessageBox} onClick={print}></div>
      <div style={{ width: 730, margin: "0 auto" }}>
        <table className="table borderlesstable">
          <tbody>
            <tr>
              <td
                className="col-sm-3"
                style={{ verticalAlign: "middle", Border: "none" }}
              >
                <img
                  src={
                    process.env.REACT_APP_ASSEST +
                    "/dealerlogo/" +
                    dealer_data.DealerLogo
                  }
                  alt="DealerLogo"
                  className="img-responsive"
                  width={96}
                />
              </td>
              <td className="col-sm-4" style={{ Border: "none" }}></td>
              <td
                className="col-sm-5 text-right"
                style={{ verticalAlign: "middle", Border: "none" }}
              >
                {dealer_data != "" ? dealer_data.CustomerFName : ""}
                <br />
                {dealer_data != "" ? dealer_data.ContractNo : ""}
                <br />
                {moment(new Date()).format("MM/DD/YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row">
          <div className="col-sm-6">
            <h4 style={{ fontWeight: "bold" }}>History</h4>
          </div>
          <div className="col-sm-6">
            <div className="float-right">
              <h4 style={{ fontWeight: "bold" }}>
                Cash Reward: ${loyaltycash_total != "" ? loyaltycash_total : ""}
              </h4>
            </div>
          </div>{" "}
        </div>
        <table className={(style.loyaltyprinttable, "table")}>
          <thead>
            <tr>
              <th>VIN</th>
              <th>Reason</th>
              <th>Date</th>
              <th>Ro</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="xp_points_history">
            {typeof loyaltycash != "undefined"
              ? loyaltycash.map((item, i) => (
                  <tr>
                    <td>{item.VIN}</td>
                    <td>{item.Action}</td>
                    <td>{moment(item.AddedDate).format("MM/DD/YYYY")}</td>
                    <td>{item.RoNo}</td>
                    {item.amount < 0 ? (
                      <td>
                        {" "}
                        <font color="red">-${item.amount * -1}</font>
                      </td>
                    ) : (
                      <td>+${item.amount}</td>
                    )}
                  </tr>
                ))
              : ""}
          </tbody>
        </table>

        {typeof todayloyaltycash != "undefined" ? (
          <h4 style={{ fontWeight: "bold" }}>Today's Transaction</h4>
        ) : (
          ""
        )}
        {typeof todayloyaltycash != "undefined" ? (
          <table className={(style.loyaltyprinttable, "table")}>
            <thead>
              <tr>
                <th>VIN</th>
                <th>Reason</th>
                <th>Date</th>
                <th>Ro</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="xp_points_history">
              {typeof todayloyaltycash != "undefined"
                ? todayloyaltycash.map((item, i) => (
                    <tr>
                      <td>{item.VIN}</td>
                      <td>{item.Action}</td>
                      <td>{moment(item.AddedDate).format("MM/DD/YYYY")}</td>
                      <td>{item.RoNo}</td>
                      {item.amount < 0 ? (
                        <td>
                          {" "}
                          <font color="red">-${item.amount * -1}</font>
                        </td>
                      ) : (
                        <td>+${item.amount}</td>
                      )}
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        ) : (
          ""
        )}
        <table className="table borderlesstable" style={{ marginTop: 100 }}>
          <tbody>
            <tr>
              <td
                className="col-sm-12"
                style={{ fontSize: 20, padding: 0, margin: 0 }}
              >
                Customer Signature{" "}
              </td>
            </tr>
          </tbody>
        </table>
        <table
          className="table borderlesstable"
          style={{ verticalAlign: "top", marginBottom: 2 }}
        >
          <tbody>
            <tr>
              <td
                className="col-sm-3"
                style={{ verticalAlign: "middle", Border: "none" }}
              >
                {dealer_data != "" ? dealer_data.DealerTitle : ""}
                <br />
                {dealer_data != "" ? dealer_data.ContPersonPhone : ""}
                <br />
                {dealer_data != "" ? dealer_data.DealerURL : ""}
              </td>
              <td
                className="col-sm-6"
                style={{ verticalAlign: "middle", Border: "none" }}
              ></td>
              <td
                className="col-sm-3"
                style={{ verticalAlign: "bottom", Border: "none" }}
              >
                <img
                alt="text-logo"
                  src={
                    require("../../../resources/images/text-logo.png").default
                  }
                  className="img-fluid"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}
