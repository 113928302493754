import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spinner } from "react-bootstrap";
import { FaCopy, FaPencilAlt, FaPlus, FaEye } from "react-icons/fa";
import { checknull, checkpermission } from "../../../components/helpers";
import { role_id } from "../../../CurrentUser";
import DuplicatePlan from "./DuplicatePlan";
import PlanStatus from "./PlanStatus";
import GhostPlan from "./GhostPlan";
import { planlistdata } from "./PlanApi";
import CustomFooter from "./CustomFooter";
import moment from "moment";

import ReactTooltip from "react-tooltip";
export default function Datatable(props) {
  let tooltip1 = "This is the number of Subscription contract";
  const { plancount, planslist, dataloading, formvalues, dealerinfo } = props;
  const [planList, setPlanList] = useState(planslist);
  const [count, setCount] = useState(plancount);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(dataloading);
  const [showmodals, setShowmodals] = useState({
    duplicate: false,
    ghost: false,
    plan_status: false,
  });
  const [currentplan, setCurrentPlan] = useState(null);
  const [sortdata, setSortdata] = useState({ columnName: "", direction: "" });
  useEffect(() => {
    setPlanList(planslist);
    setLoading(dataloading);
    setCount(plancount);
  }, [formvalues, planslist, dataloading]);
  // functions
  const handleaction = (actiontype, row) => {
    setCurrentPlan(row);
    if (actiontype === "duplicate") {
      setShowmodals({
        ...showmodals,
        duplicate: true,
      });
    } else if (actiontype === "ghost") {
      setShowmodals({
        ...showmodals,
        ghost: true,
      });
    } else if (actiontype === "status") {
      setShowmodals({
        ...showmodals,
        plan_status: true,
      });
    }
  };
  const columns = [
    {
      name: "PlanID",
      label: " Plan ID",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (typeof value != "undefined") {
            return (
              <div>
                <span className="btn btn-info btn-sm d-flex">
                  <FaPencilAlt className="mt-1" />
                  {""}&nbsp;
                  {planList[tableMeta.rowIndex].PlanID}
                </span>
              </div>
            );
          }
        },
      },
    },
    {
      label: "Plan Title",
      name: "PlanDescription",
      options: {
        sort: true,
      },
    },
    {
      label: "Date Created",
      name: "Datecreated",
      options: {
        sort: true,
        customBodyRender: (value) => {
          return moment(value).format("MM-DD-YYYY");
        },
      },
    },
    {
      label: "Plan Price",
      name: "actualplancost",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return "$" + value;
        },
      },
    },
    {
      label: "Comp Price (visible to customer)",
      name: "actualplancost",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (typeof value != "undefined") {
            return (
              <div>
                {+planList[tableMeta.rowIndex].actualplancost !==
                +planList[tableMeta.rowIndex].printplancost
                  ? planList[tableMeta.rowIndex].printplancost
                  : 0}
              </div>
            );
          }
        },
      },
    },
    {
      label: "Dealer Reserved / Remit Override / Reserve Override",
      name: "ReservedAmount",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (typeof value != "undefined") {
            return (
              <div>
                $ {planList[tableMeta.rowIndex].ReservedAmount} / $
                {checknull(planList[tableMeta.rowIndex].RemitOverrideAmt) !== ""
                  ? planList[tableMeta.rowIndex].RemitOverrideAmt
                  : 0}
                &nbsp; /${planList[tableMeta.rowIndex].ReservedAmountOverrite}
              </div>
            );
          }
        },
      },
    },
    {
      label: "Status",
      name: "Status",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (typeof value != "undefined") {
            return (
              <div
                onClick={() =>
                  handleaction("status", planList[tableMeta.rowIndex])
                }
              >
                {+planList[tableMeta.rowIndex].Status === 1 ? (
                  <span
                    style={{ textDecoration: "underline" }}
                    className="poshytip_live btn btn-success btn-xs planreason"
                  >
                    Active
                  </span>
                ) : (
                  <span
                    style={{ textDecoration: "underline" }}
                    className="poshytip_live btn btn-warning btn-xs planreason"
                  >
                    Inactive
                  </span>
                )}
              </div>
            );
          }
        },
      },
    },
    {
      label: "Count",
      name: "totalContract",
      options: {
        sort: false,
      },
    },
    {
      label: "Duplicate",
      name: "Status",

      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          if (typeof value != "undefined") {
            return (
              <div
                data-tip={tooltip1}
                onClick={() =>
                  handleaction("duplicate", planList[tableMeta.rowIndex])
                }
                className="pointer"
              >
                <FaCopy />
              </div>
            );
          }
        },
      },
    },
    {
      label: "Action",
      name: "Duplicate",
      options: {
        filter: false,
        sort: false,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          let statusimg = "";

          if (planList != "Loading...") {
            if (+planList[tableMeta.rowIndex].Status === 0) {
              if (+planList[tableMeta.rowIndex].LockID > 0) {
                if (+planList[tableMeta.rowIndex].LockStatus === 0) {
                  statusimg = "ghost-req-orange.png";
                } else if (+planList[tableMeta.rowIndex].LockStatus === 1) {
                  //img = "approved";
                  statusimg = "current-active-ghost-plan.png";
                }
              } else {
                statusimg = "plan-ghost-inactive-plan.png";
              }
            }
            return (
              <div className="d-flex">
                {1 === 1 ? (
                  <a
                    id={`edit-${planList[tableMeta.rowIndex].PlanID}`}
                    className="btn btn-sm btn-soft-success btn-circle"
                    style={{ color: "#000" }}
                    href={
                      process.env.REACT_APP_BASE_URL +
                      "/contracts/edit/" +
                      planList[tableMeta.rowIndex].PlanID
                    }
                  >
                    <i className="dripicons-pencil"></i>
                  </a>
                ) : (
                  ""
                )}
                {+planList[tableMeta.rowIndex].totalContract > 0 ? (
                  <span className="pointer">
                    <FaEye className="m-2" />
                  </span>
                ) : (
                  ""
                )}

                {+planList[tableMeta.rowIndex].Status === 0 &&
                +role_id === 1 ? (
                  <span className="m-1 pointer">
                    <FaPlus />
                  </span>
                ) : (
                  ""
                )}
                {statusimg !== "" ? (
                  <img
                    src={
                      require(`../../../includedfiles/root/images/${statusimg}`)
                        .default
                    }
                    onClick={() =>
                      handleaction("ghost", planList[tableMeta.rowIndex])
                    }
                    className="pointer"
                    width="32"
                    alt="Ghost"
                  />
                ) : (
                  ""
                )}
              </div>
            );
          }
        },
      },
    },
  ];
  const changePage = async (page_no, sortOrder) => {
    setLoading(true);
    setPlanList(["Loading..."]);
    setSortdata({ columnName: sortOrder.column, order: sortOrder.order });
    const formdata = new FormData();
    formdata.append(
      "PlanSortByCol",
      typeof sortOrder.name !== "undefined" ? sortOrder.name : ""
    );
    formdata.append(
      "PlanSortByColType",
      typeof sortOrder.direction !== "undefined" ? sortOrder.direction : ""
    );
    formdata.append("offset", page_no);
    formdata.append("status", formvalues.status);
    formdata.append("plan_status", formvalues.plan_status);
    formdata.append("planname", formvalues.planname);
    formdata.append("DealerID", formvalues.DealerID);
    const getproductlist_res = await planlistdata(formdata);
    setLoading(false);
    if (getproductlist_res.success === 1) {
      setPlanList(getproductlist_res.plans);
      setPage(+getproductlist_res.offset);
      setCount(+getproductlist_res.TotalRec);
    }
  };
  const options = {
    selectableRowsHideCheckboxes: true,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    filterType: "dropdown",
    responsive: "standard",
    page: page,
    count: count,
    Total: count,
    serverSide: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          options={options}
          setPlanList={setPlanList}
          setPage={setPage}
          setCount={setCount}
          changePage={changePage}
        />
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          //console.log("s", tableState.sortOrder);
          changePage(tableState.page, tableState.sortOrder);
          break;
        default:
      }
    },
  };

  return (
    <React.Fragment>
      {showmodals.duplicate && (
        <DuplicatePlan
          handleClose={() => setShowmodals({ ...showmodals, duplicate: false })}
          currentplan={currentplan}
          formvalues={formvalues}
          setPlanList={setPlanList}
          setPage={setPage}
          setCount={setCount}
          page={page}
          sortdata={sortdata}
        />
      )}
      {showmodals.plan_status && (
        <PlanStatus
          handleClose={() =>
            setShowmodals({ ...showmodals, plan_status: false })
          }
          currentplan={currentplan}
          formvalues={formvalues}
          setPlanList={setPlanList}
          setPage={setPage}
          setCount={setCount}
          page={page}
          sortdata={sortdata}
        />
      )}
      {showmodals.ghost && (
        <GhostPlan
          handleClose={() => setShowmodals({ ...showmodals, ghost: false })}
          currentplan={currentplan}
        />
      )}
      <MUIDataTable
        title={
          <div>
            Plan List{" "}
            {loading && (
              <span style={{ padding: 10 }}>
                <Spinner animation="border" variant="success" />
              </span>
            )}
          </div>
        }
        data={planList}
        columns={columns}
        options={options}
      />{" "}
      <ReactTooltip multiline={true} />
    </React.Fragment>
  );
}
