import React, { Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useToasts } from "react-toast-notifications";
import PreLoad from "../components/PreLoad";
import {
  f_dashboardexport,
  f_subscriptionexport,
} from "../components/helpers";
function DashboardCustomerInteraction(props) {
  const {
    Mobileussage,
    ShopingCartEnable,
    DealerID,
    fromdate,
    toDate,
    FixedDateParameter,
  } = props;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const exportdata = (export_type) => {
    if (DealerID == "" || typeof DealerID == "undefined") {
      addToast("please select dealership", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("DealerID", DealerID);
    formData.append("FomDate", fromdate);
    formData.append("ToDate", toDate);
    formData.append("FixedDateParameter", FixedDateParameter);
    formData.append("export_type", export_type);
    setLoading(true);
    //api
    if (ShopingCartEnable === 1) {
      f_subscriptionexport(formData).then((response) => {
        setLoading(false);
      });
    } else {
      f_dashboardexport(formData).then((response) => {
        setLoading(false);
      });
    }
  };
  let tooltip1 =
    "This is the number of users that have not logged<br/> on the app or web portal  in the selected date period";
  let tooltip2 =
    "This is the number of users that have logged<br/> on the app or web portal in the selected date period";
  return (
    <Fragment>
      <PreLoad loading={loading} />
      <div className="col-md-4">
        <div className="card">
          <div className="card-body" style={{ padding: "0.75rem" }}>
            <div className="row">
              <div className="col-md-12 font-14 text-center color4d4d4d pb-3">
                Customer Interaction
              </div>
              <div className="col-md-6 font-18 text-center color4d4d4d">
                <div className="row pb-2">
                  <div className="col-md-6">
                    <i className="mdi mdi-apple"></i>
                  </div>
                  <div className="col-md-6">
                    {Mobileussage.iPhone_users ? Mobileussage.iPhone_users : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-6 font-18 text-center color4d4d4d">
                <div className="row pb-2">
                  <div className="col-md-6">
                    <i className="dripicons-web"></i>
                  </div>
                  <div className="col-md-6">
                    {Mobileussage.web_users ? Mobileussage.web_users : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-6 font-18 text-center color4d4d4d">
                <div className="row pb-2">
                  <div className="col-md-6">
                    <i className="ti-android"></i>
                  </div>
                  <div className="col-md-6">
                    {Mobileussage.android_users
                      ? Mobileussage.android_users
                      : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-6 font-18 text-center color4d4d4d">
                <div className="row pb-2">
                  <div className="col-md-6">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="col-md-6">
                    {Mobileussage.Guest_users ? Mobileussage.Guest_users : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-6 font-16 text-center color4d4d4d">
                Users who have not utilized app or web
              </div>
              <div className="col-md-6 font-16 text-center color4d4d4d">
                Users who have utilized app or web
              </div>
              <div className="col-md-6 font-36 text-center color4d4d4d">
                {Mobileussage.notlogin ? Mobileussage.notlogin : 0}
              </div>
              <div className="col-md-6 font-36 text-center color4d4d4d">
                {Mobileussage.login ? Mobileussage.login : 0}
              </div>
              <div className="col-md-6 font-20 text-center">
                <a href="#" onClick={() => exportdata(6)} data-tip={tooltip1}>
                  <span className="delete_icon">
                    <i
                      style={{ padding: "6px 6px" }}
                      className="dripicons-document-delete"
                    ></i>
                  </span>
                </a>
              </div>
              <div className="col-md-6 font-20 text-center">
                <a href="#" onClick={() => exportdata(161)} data-tip={tooltip2}>
                  <span className="delete_icon">
                    <i
                      style={{ padding: "6px 6px" }}
                      className="dripicons-document-delete"
                    ></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip multiline={true} />
      </div>
    </Fragment>
  );
}

export default DashboardCustomerInteraction;
