import { React, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import $ from "jquery";
import "./loyaltycash.css";
import PreLoad from "../../../components/PreLoad";
import { redeemloyaltycash } from "./LoyaltyCashApi";
require("dotenv").config();
export default function RedeemLoyaltyCash(props) {
  const { show, f_redeemLoyaltyCash, contractid, DealerID, loyaltycash_total } =
    props;
  const [error, setError] = useState(props.error);
  const [loading, setLoading] = useState(false);
  const [loyaltycash, setLoyaltycash] = useState(loyaltycash_total);

  const handleClose = () => {
    f_redeemLoyaltyCash("", "");
    setError("");
  };

  const save = async () => {
    setError("");
    setLoading(true);
    var ServiceID = $("#ServiceID :selected").text();
    var Ro_Number = $("#Ro_Number").val();
    var RedeemAmount = $("#RedeemAmount").val();
    const formData = new FormData();

    formData.append("ContractID", contractid);
    formData.append("ServiceID", ServiceID);
    formData.append("Ro_Number", Ro_Number);
    formData.append("TotalAmount", loyaltycash_total);
    formData.append("RedeemAmount", RedeemAmount);
    const redeemloyaltycash_api = await redeemloyaltycash({ formData });
    setLoading(false);
    if (redeemloyaltycash_api.success == 1) {
      f_redeemLoyaltyCash("", redeemloyaltycash_api);
    } else if (redeemloyaltycash_api.success == 0) {
      setError(redeemloyaltycash_api.message);
    }
  };
  return (
    <div>
      <PreLoad loading={loading} />
      <Modal
        dialogClassName="redemption-modal"
        size={"lg"}
        show={show}
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title style={{ width: "100%" }}>
            <img
            alt="loyaltycash"
              src={require(`../../../resources/images/loyaltycash.png`).default}
              width="36"
              className="img-responsive float-left"
            />
            Redeem Loyalty Cash
            <div className="float-right">
              Available $
              {typeof loyaltycash_total != "undefined" ? loyaltycash_total : ""}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12">
            <div className="form-group">
              <label>Which transaction type is the redemption for</label>
              <select required={true} className="form-control" id="ServiceID">
                <option value="Service (Repair Order)">
                  Service (Repair Order)
                </option>
                <option value="Sale">Sale</option>
                <option value="Part">Part</option>
              </select>
            </div>
            <div className="form-group ">
              <input
                placeholder="Ro"
                required={true}
                type="text"
                id="Ro_Number"
                className="form-control mt-10"
              />
            </div>

            <div className="form-group ">
              <input
                type="text"
                required={true}
                placeholder="Amount of loyalty cash to redeem $"
                id="RedeemAmount"
                className="form-control mt-10"
              />
            </div>
          </div>
          <small className="help-block text-danger">{error}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={save}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
