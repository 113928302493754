import React, { useState } from "react";
import moment from "moment";

import { useFormik } from "formik";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import PreLoad from "../../../components/PreLoad";
import { validatenormalcoupon } from "./EditContractApi";
import { useToasts } from "react-toast-notifications";
import { checknull } from "../../../components/helpers";
export default function EditRedemmedContract(props) {
  const edititem =
    props.edititem != "" && typeof props.edititem != "undefined"
      ? props.edititem
      : "";
  const { contractinfo } = props;
  const { addToast } = useToasts();
  const [spinner, setSpinner] = useState(false);
  const editredeem = useFormik({
    initialValues: {
      LatestMileage: edititem.CouponMileage,
      RONumber: edititem.RepairOrderNo,
      DateOfService: edititem.RecievedDate,
      UpsellAmount: checknull(edititem.UpsellAmount),
      Vehicle_Type: edititem.VehicleType,
    },

    onSubmit: async (data) => {
      setSpinner(true);
      const formdata = new FormData();
      formdata.append("NewCouponID", edititem.CouponID);
      formdata.append("NewCouponTitle", edititem.CouponTitle);
      formdata.append("ContractStopDay", edititem.StopDay);
      formdata.append(
        "UnlimitedServiceedit",
        ++edititem.ServiceType === 4 ? 1 : 0
      );
      formdata.append("ContractStatus", edititem.ContractStatus);
      formdata.append("UnlimitedMileage", contractinfo.UnlimitedMileage);
      formdata.append("UnlimitedTerm", contractinfo.UnlimitedTerm);
      formdata.append("ContractStopMileage", edititem.StopMileage);
      formdata.append("ContractServiceType", edititem.ServiceType);
      formdata.append("DisplayOrder", edititem.DisplayOrder);
      formdata.append("CouponID", edititem.CouponID);
      formdata.append("OldVehicleType", edititem.VehicleType);
      formdata.append("RecentMileage", contractinfo.RecentMileage);
      formdata.append("ContractID", edititem.ContractID);
      formdata.append("ContractServiceID", edititem.ServiceID);
      formdata.append("latestRedeem", contractinfo.ContractMileage);
      formdata.append("OriginalDate", contractinfo.SaleDate);

      formdata.append("validayDate", contractinfo.ValidityDate);
      formdata.append("ValidityMileage", contractinfo.ValidityMileages);
      formdata.append("isPerpetualPlan", contractinfo.isPerpetualPlan);
      //append edit form data
      Object.keys(data).forEach((key) => formdata.append(key, data[key]));

      //api call
      const validatenormalcoupon_response = await validatenormalcoupon(
        formdata
      );
      setSpinner(false);
      if (validatenormalcoupon_response.success == 1) {
        addToast(validatenormalcoupon_response.message, {
          appearance: "success",
          autoDismiss: true,
        });
        props.handleedit("");
        window.location.reload();
      } else if (validatenormalcoupon_response.success == 0) {
        addToast(validatenormalcoupon_response.message, {
          appearance: "error",
          autoDismiss: true,
        });
        props.handleedit("");
      }
    },
  });
  const Close = () => {
    props.handleedit("");
  };
  return (
    <React.Fragment>
      <PreLoad loading={false} />
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>{edititem.CouponTitle}</Modal.Title>
        </Modal.Header>
        <form onSubmit={editredeem.handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="LatestMileage">
              <Form.Label>Latest Mileage</Form.Label>
              <Form.Control
                type="text"
                name="LatestMileage"
                placeholder="Latest Mileage"
                value={editredeem.values.LatestMileage}
                onChange={editredeem.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="RONumber">
              <Form.Label>RO Number</Form.Label>
              <Form.Control
                type="text"
                name="RONumber"
                placeholder="R O Number"
                value={editredeem.values.RONumber}
                onChange={editredeem.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="RecievedDate">
              <Form.Label>Date of Service</Form.Label>
              <Form.Control
                type="date"
                name="DateOfService"
                id="DateOfService"
                vaule={moment
                  .unix(editredeem.values.DateOfService)
                  .format("YYYY-MM-DD")}
                onChange={editredeem.handleChange}
                placeholder="Date of Service"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="UpsellAmount">
              <Form.Label>Upsell Amount</Form.Label>
              <Form.Control
                type="text"
                name="UpsellAmount"
                placeholder="Upsell Amount"
                value={editredeem.values.UpsellAmount}
                onChange={editredeem.handleChange}
              />
            </Form.Group>
            <Form.Group
              className="ml-4"
              style={{ float: "left", width: "50%" }}
              controlId="C"
            >
              <label htmlFor="radio" style={{ margin: "5px 2px" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  value="C"
                  onChange={editredeem.handleChange}
                  checked={
                    editredeem.values.Vehicle_Type === "C" ? true : false
                  }
                  name="Vehicle_Type"
                  id="C"
                />
                Contracted Vehicle
              </label>
            </Form.Group>
            <Form.Group className="ml-4" controlId="NC">
              <label htmlFor="radio" style={{ margin: "5px 2px" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="Vehicle_Type"
                  value="NC"
                  onChange={editredeem.handleChange}
                  checked={
                    editredeem.values.Vehicle_Type === "N" ? true : false
                  }
                  id="NC"
                />
                Gifted or Non Contracted
              </label>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            {spinner ? (
              <Spinner animation="border" variant="info" />
            ) : (
              <React.Fragment>
                <Button type="submit" variant="primary">
                  <i className="fas fa-pencil-alt"></i> Update
                </Button>
                <Button variant="secondary" onClick={Close}>
                  <i className="fas fa-times"></i> Cancel
                </Button>
              </React.Fragment>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  );
}
