import React, { useState, useEffect } from "react";
import { Card, Row, InputGroup, FormControl, Table } from "react-bootstrap";
import { plan_create_step_2, account_preset_list } from "./plancreateapi";
import DealersDropdown from "../../../components/DealersDropdown";
import "./style.css";
import Plansetting from "./Plansetting";
import AddServices from "./AddServices";
import { v4 as uuidv4 } from "uuid";
import ServicesTable from "./ServicesTable";
export default function CreatePlan() {
  // states
  const [selecteddealer, setSelecteddealer] = useState(null);
  const [planinfo, setPlaninfo] = useState("");
  const [showaddservice, setShowaddservice] = useState(false);
  const [newservicelist, setNewservicelist] = useState([]);
  const [presetlist, setPresetlist] = useState("");
  const [servicetablelist, setServicetablelist] = useState([]);
  const [serviceimgs, setServiceimgs] = useState([]);
  const [Boundless, setBoundless] = useState([
    {
      id: uuidv4(),
      fieldname: "BoundlessServices",
      value: "",
    },
  ]);
  useEffect(() => {
    getplancreatestep2();
  }, []);

  // handlers
  const handledealerchange = async (val) => {
    setSelecteddealer(val);
    const formData = new FormData();
    formData.append("DealerID", val);
    const presetinfo = await account_preset_list(formData);
    if (presetinfo.success === 1) {
      setPresetlist(presetinfo);
    }
    //planlsitform.setFieldValue("DealerID", val);
  };
  const getplancreatestep2 = async () => {
    const formdata = new FormData();
    const api_response = await plan_create_step_2(formdata);

    if (api_response.success === 1) {
      setPlaninfo(api_response);
      setNewservicelist(api_response.ListServices);

      // service img list
      let serviceimgs = [];
      api_response.service_img_array.map((item, i) => {
        //console.log(item);
        serviceimgs.push({
          key: item,
          text: "",
          value: item.i,
          image: {
            avatar: true,
            src: item.value,
          },
        });
      });
      setServiceimgs(serviceimgs);
    }
  };
  const removeboundlessfiled = (index) => {
    const list = [...Boundless];
    list.splice(index, 1);
    setBoundless(list);
  };
  const handleservicechange = () => {
    setShowaddservice(!showaddservice);
  };
  return (
    <React.Fragment>
      {showaddservice ? (
        <AddServices
          close={() => setShowaddservice(!showaddservice)}
          newservicelist={newservicelist}
          setNewservicelist={setNewservicelist}
        />
      ) : (
        ""
      )}
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="javascript:void(0);">MyPCP</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="javascript:void(0);">Plans</a>
                </li>
                <li className="breadcrumb-item active">Add New Plan</li>
              </ol>
            </div>
            <h4 className="page-title">Create New Plan</h4>
          </div>
          {/*end page-title-box*/}
        </div>
        {/*end col*/}
      </div>
      <div className="row" id="plan-create">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Account</label>
                    <DealersDropdown
                      deafultvalue={selecteddealer}
                      id="selecteddealer"
                      handledealerchange={handledealerchange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Plan preset</label>
                    <select className="form-control">
                      <option value={0}>Select</option>

                      {typeof presetlist !== "undefined" && presetlist !== ""
                        ? presetlist.ReturnPresetData.map((item, index) => (
                            <option key={index} value={item.preset_id}>
                              {item.preset_name}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Plan Title</label>
                    <input
                      type="text"
                      name="plan_title"
                      className="form-control"
                      id
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="col-md-8">
                      Plan valid mileage
                    </label>
                    <div className style={{ display: "flex" }}>
                      <input
                        type="text"
                        name="valid_mileage"
                        className="form-control col-md-8"
                      />

                      <select
                        id="Plankm"
                        name="Plankm"
                        style={{
                          width: 70,
                          fontSize: 12,
                          padding: 6,
                          marginBottom: 5,
                          display: "inline",
                        }}
                        className="form-control col-md-2 ml-2"
                      >
                        <option value={0}>Select</option>
                        <option value="MILES" selected="selected">
                          Miles
                        </option>
                        <option value="KM">KM</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Cancellation Type:
                    </label>
                    <select className="form-control">
                      <option value={0}>Select</option>
                      {planinfo !== "" && typeof planinfo !== "undefined"
                        ? planinfo.cancel_types.map((item, index) => (
                            <option key={index} value={item.CancellID}>
                              {item.Name}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="col-md-12">
                      Plan valid period (months)
                    </label>
                    <div className style={{ display: "flex" }}>
                      <input
                        type="text"
                        name="valid_duration_months"
                        className="form-control col-md-11"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Remit Override</label>
                    <input
                      type="text"
                      className="form-control"
                      name="remit_override"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Reserve Override</label>
                    <input
                      type="text"
                      className="form-control"
                      name="remit_override"
                      placeholder="Plan Services Reserved Amount Override"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Plan has own contract
                    </label>
                    <select className="form-control">
                      <option value={0}>Select</option>
                      {planinfo !== "" && typeof planinfo !== "undefined"
                        ? planinfo.ContractTypes.map((item, index) => (
                            <option key={index} value={item.FormatTypeID}>
                              {item.FormatTitle}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mt-4 p-2">
                    <div className="checkbox checkbox-primary">
                      <input
                        id="required_ro_field"
                        type="checkbox"
                        defaultChecked
                      />
                      <label htmlFor="required_ro_field">
                        Required RO Field
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Total Reserve Amount
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="total_reserve_amount"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Customer Price Adjustment
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="customer_price_adjustment"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  {/*  <div class="custom-control custom-radio">
                              <input type="radio" class="custom-control-input" id="" name="c_plan_rb" value="Plus">
                              <label class="custom-control-label">1</label>
                          </div>
                          <div class="custom-control custom-radio">
                              <input type="radio" class="custom-control-input" id="" name="c_plan_rb" value="minus">
                              <label class="custom-control-label">2</label>
                          </div> */}
                  <div className="button-list btn-social-icon mt-4 p-2">
                    <button
                      type="button"
                      className="btn btn-success btn-circle"
                    >
                      <i className="fas fa-plus-circle" />
                    </button>
                    <button type="button" className="btn btn-info btn-circle">
                      <i className="fas fa-minus-circle" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Default Email Template
                    </label>
                    <select className="form-control">
                      <option value={0}>Select</option>
                      {planinfo !== "" && typeof planinfo !== "undefined"
                        ? planinfo.emailtypes.map((item, index) => (
                            <option key={index} value={item.EmailTypeID}>
                              {item.EmailTypeTitle}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      DEDUCTIBLE REIMBURSEMENT
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="customer_price_adjustment"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Plan Status</label>
                    <select className="form-control">
                      <option value={-1}>Select</option>
                      <option value={0}>IN Active</option>
                      <option value={1}>Active</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Select car fuel type
                    </label>
                    <select className="form-control" multiple>
                      {planinfo !== "" && typeof planinfo !== "undefined"
                        ? planinfo.vehiclefueltypes.map((item, index) => (
                            <option key={index} value={item.FuelTypeID}>
                              {item.FuelCode}&nbsp;:&nbsp;{item.FuelType}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Select car vehicle type
                    </label>
                    <select className="form-control" multiple>
                      {planinfo !== "" && typeof planinfo !== "undefined"
                        ? planinfo.vehicletypes.map((item, index) => (
                            <option key={index} value={item.VehicleTypeID}>
                              {item.VehicleCode}&nbsp;:&nbsp;{item.VehicleType}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Select car body type
                    </label>
                    <select className="form-control" multiple>
                      <option>Choose frequency</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-4 col-md-6 col-sm-12">
                  <Plansetting />
                </div>
                <div className="col-4 col-md-6 col-sm-12">
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        CUSTOMER COMPLIMENTARY REMINDER MAIL
                      </Card.Title>
                      <Row>
                        <InputGroup className="col-12 mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Radio
                              aria-label="Checkbox for following text input"
                              name="YearlyExpiration"
                            />
                          </InputGroup.Prepend>
                          <FormControl
                            value={
                              "Customer receives email each month from date of sale"
                            }
                            aria-label="Text input with checkbox"
                            readOnly
                          />
                        </InputGroup>
                        <InputGroup className="col-12 mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Radio
                              aria-label="Checkbox for following text input"
                              name="YearlyExpiration"
                            />
                          </InputGroup.Prepend>
                          <FormControl
                            value={"Customer receives email every 3 months"}
                            aria-label="Text input with checkbox"
                            readOnly
                          />
                        </InputGroup>
                        <div className="col-12 mb-3 form-group text-center">
                          Customer receives expiration email{" "}
                          <select
                            name="ExpirationEmailMonths"
                            id="ExpirationEmailMonth"
                            className="form-control"
                            style={{
                              display: "inline",
                              width: 50,
                              paddingRight: 0,
                              paddingLeft: 2,
                            }}
                          >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </select>{" "}
                          months before expiration
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        Purchase Subscription on Single VIN
                      </Card.Title>
                    </Card.Body>
                    <Row>
                      <InputGroup className="ml-4 mb-2 col-10">
                        <InputGroup.Prepend>
                          <InputGroup.Radio
                            aria-label="Checkbox for following text input"
                            name="YearlyExpiration"
                            label="YearlyExpiration"
                          />
                          <InputGroup.Radio
                            label="Checkbox for following text input"
                            name="YearlyExpiration"
                          />
                        </InputGroup.Prepend>
                        <FormControl
                          value={
                            "Customer receives email each month from date of sale"
                          }
                          aria-label="Text input with checkbox"
                          readOnly
                        />
                      </InputGroup>
                    </Row>
                  </Card>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Boundless Services
                    </label>
                    {Boundless.map((item, index) => (
                      <div className="input-group mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Service Name"
                        />
                        <span className="input-group-append">
                          {index === 0 ? (
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                              onClick={() =>
                                setBoundless([
                                  ...Boundless,
                                  {
                                    id: uuidv4(),
                                    fieldname: "BoundlessServices",
                                    value: "",
                                  },
                                ])
                              }
                            >
                              <i className="fas fa-plus" />
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-danger"
                              type="button"
                              onClick={() => removeboundlessfiled(index)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-4 col-md-6 col-sm-12">
                  <div className="ml-2 d-flex text-center">
                    <h5>Add New Services</h5>
                    <button
                      type="button"
                      className="btn btn-success ml-2"
                      onClick={() => handleservicechange()}
                    >
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3"></div>
              </div>
              <Row>
                <div className="col-md-12">
                  <ServicesTable
                    serviceimgs={serviceimgs}
                    servicetablelist={servicetablelist}
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
