import React, { Component } from "react";
import $ from "jquery";
import { WaveLoading } from "react-loadingg";
import DashboardFilter1 from "./filterDashboard1";
import DealersDropdown from "../components/DealersDropdown";
import { pcp_user_id, role_id, user_id, token } from "../CurrentUser";
import DashboardEmailModal from "./DashboardEmailModal";
import { DashboardPrintModal } from "./DashboardPrintModal";
require("dotenv").config();
class DashboardFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mypwd: "color:'RED'",
      accounts: [],
      DashboardEmail: false,
      DashboardPrint: false,
    };
  }
  componentDidMount() {
    var self = this;
    $.ajax({
      headers: {
        Authorization: `${token}`,
      },
      url: process.env.REACT_APP_APP_URL + "/dashboard/accountdropdown",
      type: "post",
      data: { pcp_user_id: pcp_user_id, role_id: role_id, user_id: user_id },
      dataType: "json",
      success: function (adata) {
        var myarray = [];
        $.each(adata.dealers, function (index, value) {
          myarray.push({ id: value.DealerID, name: value.DealerTitle });
        });
        self.setState({
          accounts: myarray,
        });
      },
    });
    $("#xp_points_status").css("filter", "grayscale(100%)");
    $("#xp_points_status").parent().css("color", "rgb(162, 162, 162)");

    $("#claim_status").css("filter", "grayscale(100%)");
    $("#claim_status").parent().css("color", "rgb(162, 162, 162)");

    $("#loyalty_cash_status").css("filter", "grayscale(100%)");
    $("#loyalty_cash_status").parent().css("color", "rgb(162, 162, 162)");

    $("#n_gauge_status").css("filter", "grayscale(100%)");
    $("#n_gauge_status").parent().css("color", "rgb(162, 162, 162)");

    $("#value_my_trade_status").css("filter", "grayscale(100%)");
    $("#value_my_trade_status").parent().css("color", "rgb(162, 162, 162)");

    $("#referral_status").css("filter", "grayscale(100%)");
    $("#referral_status").parent().css("color", "rgb(162, 162, 162)");

    $("#marketplace_status").css("filter", "grayscale(100%)");
    $("#marketplace_status").parent().css("color", "rgb(162, 162, 162)");

    $("#integration_status").css("filter", "grayscale(100%)");
    $("#integration_status").parent().css("color", "rgb(162, 162, 162)");

    $("#autofill").css("filter", "grayscale(100%)");
    $("#autofill").parent().css("color", "rgb(162, 162, 162)");

    $("#gifting").css("filter", "grayscale(100%)");
    $("#gifting").parent().css("color", "rgb(162, 162, 162)");

    $("#guest").css("filter", "grayscale(100%)");
    $("#guest").parent().css("color", "rgb(162, 162, 162)");
  }

  selectionbtn = (e) => {
    e.preventDefault();
    $(".selectionbtn1").css("background", "rgb(153, 153, 153)");
    $(e.target).css("background", "rgb(0, 174, 239)");
    $("#mybuttonclick").trigger("click");
  };
  render() {
    const { accounts } = this.state;
    const { DealerID, FixedDateParameter, fromdate, toDate, close } =
      this.props;
    let accountsList =
      accounts.length > 0 &&
      accounts.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.name}
          </option>
        );
      }, this);

    const project = () => {
      switch (this.props.isLoading) {
        case "1":
          return <WaveLoading color="#4ac7ec" />;

        default:
          return <DashboardFilter1 />;
      }
    };

    return (
      <div className="col-md-12">
        {this.state.DashboardEmail && (
          <DashboardEmailModal
            close={() => this.setState({ DashboardEmail: false })}
            DealerID={DealerID}
            FixedDateParameter={FixedDateParameter}
            fromdate={fromdate}
            toDate={toDate}
            dealerredemptiondata={this.props.dealerredemptiondata}
            DashboardContract={this.props.DashboardContract}
            ReservedAmountBreakDown={this.props.ReservedAmountBreakDown}
            PcpBreakDown={this.props.PcpBreakDown}
            UpsellAmount={this.props.UpsellAmount}
            UniqueVisit={this.props.UniqueVisit}
            CurrentThirtDays={this.props.CurrentThirtDays}
            LastThirtDays={this.props.LastThirtDays}
            Mobileussage={this.props.Mobileussage}
          />
        )}
        {this.state.DashboardPrint && (
          <DashboardPrintModal
            close={() => this.setState({ DashboardEmail: false })}
            DealerID={DealerID}
            FixedDateParameter={FixedDateParameter}
            fromdate={fromdate}
            toDate={toDate}
            dealerredemptiondata={this.props.dealerredemptiondata}
            DashboardContract={this.props.DashboardContract}
            ReservedAmountBreakDown={this.props.ReservedAmountBreakDown}
            PcpBreakDown={this.props.PcpBreakDown}
            UpsellAmount={this.props.UpsellAmount}
            UniqueVisit={this.props.UniqueVisit}
            CurrentThirtDays={this.props.CurrentThirtDays}
            LastThirtDays={this.props.LastThirtDays}
            Mobileussage={this.props.Mobileussage}
          />
        )}
        <div className="card">
          <div className="card-body" style={{ padding: "1rem 1rem 1rem 1rem" }}>
            <div className="row">
              <div className="col-md-2">
                <div className="row">
                  <div className="col-md-12">
                    <img
                      id="dealerimage"
                      style={{ width: "100%", padding: "65px 4px" }}
                      src="https://procarma.info/dealer/procarma20210128090748.png"
                      alt="trident-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3">
                        {/* <select id="dealerSelection" className="form-control">
                          <option value="">Select Account</option>
                          {accountsList} 
                        </select> */}
                        <DealersDropdown
                          id="selectteddealer"
                          handledealerchange={this.props.handledealerchange}
                        />
                      </div>
                      <div className="col-md-2 no-padding">
                        <div className="input-group">
                          <input
                            style={{ height: "2.8rem" }}
                            id="fromDate"
                            type="date"
                            name=""
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 no-right-padding">
                        <div className="input-group">
                          <input
                            style={{ height: "2.8rem" }}
                            id="todate"
                            type="date"
                            name=""
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-5"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <button
                            type="button"
                            className="td_btns selectionbtn1"
                            onClick={(e) => this.selectionbtn(e)}
                            style={{
                              height: "calc(1.8em + .65rem + 2px)",
                              padding: ".375rem .65rem",
                              background: "#999",
                              border: "none",
                              color: "#fff",
                              marginRight: "10px",
                            }}
                            value="ITD"
                          >
                            ITD
                          </button>
                          <button
                            type="button"
                            className="td_btns selectionbtn1"
                            onClick={(e) => this.selectionbtn(e)}
                            style={{
                              height: "calc(1.8em + .65rem + 2px)",
                              padding: ".375rem .65rem",
                              background: "#999",
                              border: "none",
                              color: "#fff",
                              marginRight: "10px",
                            }}
                            value="YTD"
                          >
                            YTD
                          </button>
                          <button
                            type="button"
                            className="td_btns selectionbtn1"
                            onClick={(e) => this.selectionbtn(e)}
                            style={{
                              height: "calc(1.8em + .65rem + 2px)",
                              padding: ".375rem .65rem",
                              background: "#999",
                              border: "none",
                              color: "#fff",
                              marginRight: "10px",
                            }}
                            value="MTD"
                          >
                            MTD
                          </button>
                          <button
                            type="button"
                            id="mybuttonclick"
                            onClick={this.props.changed}
                            className=""
                            style={{
                              height: "calc(1.8em + .65rem + 2px)",
                              padding: ".400rem 1.65rem",
                              background: "#00aeef",
                              borderRadius: "10px",
                              border: "none",
                              color: "#fff",
                            }}
                          >
                            Search
                          </button>
                        </div>
                        <div style={{ float: "right" }}>
                          <button
                            onClick={() =>
                              this.setState({ DashboardPrint: true })
                            }
                            type="button"
                            style={{
                              borderRadius: "10px",
                              height: "calc(1.8em + .65rem + 2px)",
                              padding: ".375rem .50rem",
                              background: "#999",
                              border: "none",
                              color: "#fff",
                              marginRight: "10px",
                            }}
                          >
                            <i className="font-18 ti-printer"></i>
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ DashboardEmail: true })
                            }
                            style={{
                              borderRadius: "10px",
                              height: "calc(1.8em + .65rem + 2px)",
                              padding: ".375rem .50rem",
                              background: "#999",
                              border: "none",
                              color: "#fff",
                            }}
                          >
                            <i className="font-18 fas fa-envelope"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {project()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardFilter;
