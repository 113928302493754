import React, { Component } from "react";

import { token } from "../CurrentUser";
class DashboardFilter1 extends Component {
  constructor(props) {
    super(props);
  }
  redirectclassic = async (url) => {
    console.log(url);
    console.log(
      process.env.REACT_APP_APP_URL +
        "/Loginreactcopy/classicapi/" +
        token +
        "?RedirectURL=" +
        url +
        "&isLogin=1"
    );
    // window.location.href =
    //   process.env.REACT_APP_APP_URL +
    //   "/Loginreactcopy/classicapi/" +
    //   token +
    //   "?RedirectURL=" +
    //   url +
    //   "&isLogin=1";
  };
  render() {
    return (
      <div className="row">
        <div className="col-md-10 pt-4">
          <div className="row" style={{ borderLeft: "0px solid #a2a2a2" }}>
            <div className="col-md-3">
              <p className="font-13" style={{ marginBottom: "6px" }}>
                <img
                  id="xp_points_status"
                  style={{ width: "35px" }}
                  src={process.env.PUBLIC_URL + "/images/xp_points_status.png"}
                  alt="xp_points_status"
                  className="img-responsive dashboard_img"
                />{" "}
                XP Points & Rewards
              </p>
              <p className="font-13" style={{ marginBottom: "6px" }}>
                <img
                  id="claim_status"
                  style={{ width: "35px" }}
                  src={process.env.PUBLIC_URL + "/images/claim_status.png"}
                  alt="claim_status"
                  className="dashboard_img img-responsive"
                />{" "}
                Claims
              </p>
              <p
                className="font-13"
                style={{ marginBottom: "6px", color: "#a2a2a2" }}
              >
                <img
                  id="loyalty_cash_status"
                  style={{ width: "35px", filter: "grayscale(100%)" }}
                  src={
                    process.env.PUBLIC_URL + "/images/loyalty_cash_status.png"
                  }
                  alt="loyalty_cash_status"
                  className="img-responsive dashboard_img"
                />{" "}
                Loyalty Cash
              </p>
            </div>
            <div className="col-md-3">
              <p className="font-13" style={{ marginBottom: "6px" }}>
                <img
                  id="n_gauge_status"
                  style={{ width: "35px" }}
                  src={process.env.PUBLIC_URL + "/images/n_gauge_status.png"}
                  alt="n_gauge_status"
                  className="dashboard_img img-responsive"
                />{" "}
                N-Gauge
              </p>
              <p className="font-13" style={{ marginBottom: "6px" }}>
                <img
                  id="value_my_trade_status"
                  style={{ width: "35px" }}
                  src={
                    process.env.PUBLIC_URL + "/images/value_my_trade_status.png"
                  }
                  alt="value_my_trade_status"
                  className="dashboard_img img-responsive"
                />{" "}
                Value My Trade
              </p>
              <p
                className="font-13"
                style={{ marginBottom: "6px", color: "#a2a2a2" }}
              >
                <img
                  id="referral_status"
                  style={{ width: "35px", filter: "grayscale(100%)" }}
                  src={process.env.PUBLIC_URL + "/images/referral_status.png"}
                  alt="referral_status"
                  className="dashboard_img img-responsive"
                />{" "}
                Digital Referrals
              </p>
            </div>
            <div className="col-md-3">
              <p className="font-13" style={{ marginBottom: "6px" }}>
                <img
                  id="marketplace_status"
                  style={{ width: "35px" }}
                  src={
                    process.env.PUBLIC_URL + "/images/marketplace_status.png"
                  }
                  alt="marketplace_status"
                  className="dashboard_img img-responsive"
                />{" "}
                Marketplace
              </p>
              <p
                className="font-13"
                style={{ marginBottom: "6px", color: "#a2a2a2" }}
              >
                <img
                  id="integration_status"
                  style={{ width: "35px", filter: "grayscale(100%)" }}
                  src={
                    process.env.PUBLIC_URL + "/images/integration_status.png"
                  }
                  alt="integration_status"
                  className="dashboard_img img-responsive"
                />{" "}
                Intergration
              </p>
              <p className="font-13" style={{ marginBottom: "6px" }}>
                <img
                  id="autofill"
                  style={{ width: "35px" }}
                  src={process.env.PUBLIC_URL + "/images/autofill.png"}
                  alt="autofill"
                  className="dashboard_img img-responsive"
                />{" "}
                Autofill Contracts
              </p>
            </div>
            <div className="col-md-3">
              <p className="font-13" style={{ marginBottom: "6px" }}>
                <img
                  id="gifting"
                  style={{ width: "35px" }}
                  src={process.env.PUBLIC_URL + "/images/gifting.png"}
                  alt="gifting"
                  className="dashboard_img img-responsive"
                />{" "}
                Gifting Services
              </p>
              <p className="font-13" style={{ marginBottom: "6px" }}>
                <img
                  id="guest"
                  style={{ width: "35px" }}
                  src={process.env.PUBLIC_URL + "/images/guest.png"}
                  alt="guest"
                  className="dashboard_img img-responsive"
                />{" "}
                Guest Interface
              </p>
              <p
                className="font-14"
                style={{
                  marginBottom: "6px",
                  marginLeft: "22px",
                  textDecoration: "underline",
                  color: "#a2a2a2",
                  lineHeight: "30px",
                }}
              >
                View All Features
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-2 pt-4">
          <p
            className="font-14 text-center"
            style={{
              marginBottom: "6px",
              textDecoration: "underline",
              color: "#a2a2a2",
              cursor: "pointer",
            }}
            onClick={() => this.redirectclassic("/heatmap/index")}
          >
            <img
              style={{ width: "90%" }}
              src={process.env.PUBLIC_URL + "/images/mindmap.png"}
              alt="mindmap"
            />
            View Mindmap Tree
          </p>
        </div>
      </div>
    );
  }
}

export default DashboardFilter1;
