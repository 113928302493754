import React, { Component } from "react";
import { ToastProvider } from "react-toast-notifications";
import Core from "./Core";
import "./App.css";
import "./includedfiles/root/assets/css/app.css";
import "./includedfiles/root/assets/css/custom.css";

import "font-awesome/css/font-awesome.min.css";
import "./includedfiles/root/assets/css/icons.min.css";
import "./includedfiles/root/assets/css/metisMenu.min.css";
class App extends Component {
  render() {
    return (
      <ToastProvider>
        <Core />
      </ToastProvider>
    );
  }
}

export default App;
