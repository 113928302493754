import axios from "axios";
import { user_id, role_id, pcp_user_id } from "../../../CurrentUser";
const token = JSON.parse(localStorage.getItem("token"));
const API = process.env.REACT_APP_APP_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const header = {
  "Content-Type": "application/json",
};
export const planlistdata = async (formData) => {
  let url = API + "/plan/index";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const duplicate_plan = async (formData) => {
  let url = API + "/plan/duplicate_plan";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const changeplanstatus = async (formData) => {
  let url = API + "/plan/changeplanstatus";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const requestunlock = async (formData) => {
  let url = API + "/lock/requestunlock";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const locksave = async (formData) => {
  let url = API + "/lock/save";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const finalizedstatuschange = async (formData) => {
  let url = API + "/lock/finalizedstatuschange";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const ghostsave = async (formData) => {
  let url = API + "/ghost/save";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
export const serviceamountexport = async (formData) => {
  let url = API + "/plan/serviceamountexport";

  formData.append("pcp_user_id", pcp_user_id);
  formData.append("role_id", role_id);
  formData.append("user_id", user_id);
  try {
    const response = await axios.post(url, formData, { header });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
