import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
const Redemption = (props) => {
  const { setRedemption } = props;
  const [category, setCategory] = useState(props.xaxis);

  useEffect(() => {
    setCategory(props.xaxis);
  }, [props.row1]);
  useEffect(() => {
    ApexCharts.exec("basic", "dataURI")
      .then(({ imgURI, blob }) => {
        setRedemption(imgURI);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props]);
  return (
    <Chart
      options={{
        chart: {
          id: "basic",
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },

          toolbar: {
            show: true,
            tools: {
              reset: true,
              pan: false,
            },
          },
        },
        colors: props.color,
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return "$" + val.toFixed(2);
          },
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",

            colors: undefined,
          },
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          shared: false,
          followCursor: false,

          theme: false,
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            show: true,

            formatter: undefined,
          },
          y: {
            show: false,
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },

          marker: {
            show: false,
          },
          items: {
            display: "flex",
          },
          fixed: {
            enabled: false,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: props.title + " REDEMPTIONS",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 5,
        },
        xaxis: {
          type: "category",
          categories: props.xaxis,
        },
        yaxis: {
          title: {
            text: "",
          },
          labels: {
            formatter: function (value) {
              return "$" + value.toFixed(2);
            },
          },
        },
        legend: {
          show: false,
        },
      }}
      series={[
        {
          name: "Claim Amount",
          data: props.row1,
        },
        {
          name: "Claims Paid",
          data: props.row2,
        },
      ]}
      type="line"
      height={350}
    />
  );
};

export default Redemption;
