import { React, useRef, useState } from "react";
import {
  Modal,
  Button,
  FormControl,
  FormGroup,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import axios from "axios";

import { useToasts } from "react-toast-notifications";
import { user_id, role_id, pcp_user_id } from "../CurrentUser";
export default function ContractModal(props) {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const {
    show,
    handlemodal,
    title,
    question,
    messgaetitle,
    modaldata,
    ContractID,
    statuscontract,
  } = props;
  const reason = useRef();
  const handleClose = () => {
    handlemodal();
  };
  const save = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("pcp_user_id", pcp_user_id);
    formData.append("role_id", role_id);
    formData.append("user_id", user_id);
    formData.append("ContractID", ContractID);
    formData.append("ActivityPaused", +statuscontract);
    formData.append("Reason", reason.current.value);
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        process.env.REACT_APP_APP_URL + "/contract/revokeserviceredeem",
        formData,

        { headers }
      )
      .then((response) => {
        console.log(response.data.contracts_all);

        if (response.data.success === 1) {
          addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
          });
          handlemodal();
          setLoading(false);
        } else {
          addToast(response.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div>
        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>
              {modaldata.title != "" ? modaldata.title : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-danger">
              <i className="fa fa-exclamation-triangle"></i>&nbsp;
              <span>{modaldata.question != "" ? modaldata.question : ""}</span>
            </div>
            <FormGroup>
              <InputGroup>
                <InputGroup>
                  <InputGroup>
                    {modaldata.messgaetitle ? modaldata.messgaetitle : ""}
                  </InputGroup>
                </InputGroup>
                <FormControl
                  as="textarea"
                  aria-label="With textarea"
                  required={true}
                  ref={reason}
                  placeholder="Reason for Pause contract(s)....."
                />
              </InputGroup>
              <small className="help-block text-danger">
                {modaldata.dangertext !== "" ? modaldata.dangertext : ""}
              </small>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {loading ? (
              <Spinner animation="border" variant="info" />
            ) : (
              <Button variant="primary" onClick={save}>
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
