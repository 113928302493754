import React from "react";
export default function PreLoad(props) {
  return (
    <React.Fragment>
      {props.loading ? (
        <div className="overlay-sec">
          <img
            alt="loading"
            className="preload-img"
            src={require("../resources/images/loadinfo.gif").default}
          />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
