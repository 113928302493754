import { React, useState, useEffect } from "react";
import {
  Modal,
  Button,
  FormControl,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import $ from "jquery";
import PreLoad from "../../../components/PreLoad";
import { useToasts } from "react-toast-notifications";
import { deletexpoints } from "./LoyaltyCashApi";
export default function DeleteXpPoints(props) {
  const { show, handlemodal, item, DealerID } = props;
  const [error, setError] = useState(props.error);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const handleClose = () => {
    handlemodal();
  };

  const save = async () => {
    let deletereason = $("#deletereason").val();
    if (deletereason.length <= 0) {
      addToast("Enter a valid reason", {
        appearance: "error",
        autoDismiss: true,
      });
      setLoading(false);

      return false;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("ContractID", item.ContractID);
    formData.append("DealerID", DealerID);
    formData.append("ContractServiceID", item.ContractServiceID);
    formData.append("DeletionReason", deletereason);
    const api_deletexppoints = await deletexpoints({ formData });
    setLoading(false);
    if (api_deletexppoints.success == 1) {
      handlemodal(api_deletexppoints);
    } else if (api_deletexppoints.success == 0) {
      setError(api_deletexppoints.messages);
    }
  };

  return (
    <div>
      <PreLoad loading={loading} />
      <div>
        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>
              <i className="fa fa-exclamation-triangle"></i> XP Point Deletion
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-danger">
              <i className="fa fa-exclamation-triangle"></i>&nbsp;
              <span>Are you sure you want to Delete selected Xp Points?</span>
            </div>
            <FormGroup>
              <InputGroup>
                <InputGroup>
                  <InputGroup>Enter reason for deleting XP point(s)</InputGroup>
                </InputGroup>
                <FormControl
                  as="textarea"
                  id="deletereason"
                  aria-label="With textarea"
                  required={true}
                  placeholder="Xp Points deleting reason"
                />
              </InputGroup>
              <small className="help-block text-danger">
                * Reason for deleting XP Point(s). Minimum 20 character
              </small>
              <br />
              <small className="help-block text-danger">{error}</small>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={save}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
